import { styled, Table, TableCell, TableHead } from '@mui/material';

export const TableStyled = styled(Table)(({ theme }) => ({
  'th,td': {
    borderColor:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[500]
        : theme.palette.grey[400],
  },
}));

export const TableHeadStyled = styled(TableHead)(({ theme }) => ({
  th: {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.dark
        : theme.palette.primary.main,
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.contrastText
        : theme.palette.primary.contrastText,
    zIndex: 100,
  },
}));

export const FixedTableCell = styled(TableCell)(({ theme }) => ({
  position: 'sticky',
  left: 0,
  zIndex: 50,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.primary.dark
      : theme.palette.primary.main,
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.primary.contrastText
      : theme.palette.primary.contrastText,
  borderLeft: '1px solid',
  borderRight: '1px solid',
}));

export const StartGroupCell = styled(TableCell)(({ theme }) => ({
  borderLeft: '1px solid',
}));

export const LastCellOfRow = styled(TableCell)(({ theme }) => ({
  borderRight: '1px solid',
}));
