import { TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

// styled(TableRow, {
//   shouldForwardProp: (prop) =>
//     prop !== 'menuOpen' &&
//     prop !== 'menuDrawerWidth' &&
//     prop !== 'detailsDrawerOpen' &&
//     prop !== 'detailsDrawerWidth',
// })<AppBarProps>(
//   ({
//     theme,
//     menuOpen,
//     detailsDrawerOpen,
//     menuDrawerWidth,
//     detailsDrawerWidth,
//   }) =>

export const StyledTableRow = styled(TableRow)(({ hover, theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[800]
        : theme.palette.grey[200],
  },
  ...(hover && {
    '&:nth-of-type(odd):hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:nth-of-type(even):hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:nth-of-type(odd):hover td': {
      color: theme.palette.primary.contrastText,
    },
    '&:nth-of-type(even):hover td': {
      color: theme.palette.primary.contrastText,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
