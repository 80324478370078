import { ViewCategoryDto } from '../../../lib/api/categories/dto/view-category.dto';
import ExpandableTypography from '../../../components/ui/typography/ExpandableTypography';
import { ReactElement } from 'react';

interface Props {
  category: ViewCategoryDto;
  expandedComponent: ReactElement;
}

export default function StoreCategoryRow({
  category,
  expandedComponent,
}: Props) {
  return (
    <ExpandableTypography
      variant="h4"
      component="h2"
      expandedComponent={expandedComponent}
    >
      {category.name}
    </ExpandableTypography>
  );
}
