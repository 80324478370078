import { Grid } from '@mui/material';
import pluralize from 'pluralize';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CreateProductDto } from '../../../lib/api/products/dto/create-product.dto';
import RhfTagInput from '../../ui/forms/RhfTagInput';

interface Props {
  nestIndex: number;
  childIndex: number;
}

export default function ChildrenProductOptionValuesArray({
  nestIndex,
  childIndex,
}: Props) {
  const { control, watch } = useFormContext<CreateProductDto>();

  const options = watch().options;

  const { fields, remove, append } = useFieldArray({
    control,
    name: `options.${nestIndex}.children.${childIndex}.values`,
  });

  return (
    <Grid container spacing={2} sx={{ pl: 5 }}>
      {fields.map((item, valueIndex) => (
        <Grid key={item.id} container spacing={2} item>
          <Grid key={`${nestIndex}_${childIndex}_${valueIndex}`} item xs={12}>
            <RhfTagInput
              control={control}
              name={`options.${nestIndex}.children.${childIndex}.values.${valueIndex}.values`}
              defaultText={`${
                options[nestIndex].values[valueIndex]
              } ${pluralize(
                options[nestIndex].children[childIndex].name,
              )} (Press Enter after each value)`}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
