import { plainToInstance } from 'class-transformer';
import { buildFindAllQueryParams, getHeaders } from '../../util/http-utils';
import { TableState } from '../../util/table-util';
import { apiClientFactory } from '../../common/clients';
import { ViewManyDto } from '../../common/dto/view-many.dto';
import { CreateOrUpdateDeliveryRouteDto } from './dto/create-or-update-delivery-route.dto';
import { ViewDeliveryRouteDto } from './dto/view-delivery-route.dto';

const apiClient = apiClientFactory();

/****************************
Delivery Routes
****************************/

const resource = 'delivery-routes';

export enum DeliveryRouteQueryKeys {
  'findDeliveryRouteById' = 'FindDeliveryRouteById',
  'findAllDeliveryRoutes' = 'FindAllDeliveryRoutes',
}

export async function createDeliveryRoute(
  createDeliveryRouteDto: CreateOrUpdateDeliveryRouteDto,
): Promise<ViewDeliveryRouteDto> {
  const { data } = await apiClient.post(
    `v1/${resource}`,
    createDeliveryRouteDto,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewDeliveryRouteDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function getAllDeliveryRoutes(
  tableState?: TableState<ViewDeliveryRouteDto>,
): Promise<ViewManyDto<ViewDeliveryRouteDto>> {
  const queryParams = buildFindAllQueryParams<ViewDeliveryRouteDto>(tableState);

  let requestUrl = `v1/${resource}`;
  if (queryParams) requestUrl = `${requestUrl}?${queryParams}`;

  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });

  return {
    ...data,
    data: plainToInstance(ViewDeliveryRouteDto, data.data, {
      excludeExtraneousValues: true,
    }),
  };
}

export async function getDeliveryRouteById(
  deliveryRouteId: number,
): Promise<ViewDeliveryRouteDto> {
  const { data } = await apiClient.get(`v1/${resource}/${deliveryRouteId}`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewDeliveryRouteDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function deleteDeliveryRoute(
  deliveryRouteId: number,
): Promise<ViewDeliveryRouteDto> {
  const { data } = await apiClient.delete(`v1/${resource}/${deliveryRouteId}`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewDeliveryRouteDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function updateDeliveryRoute(
  id: number,
  updateDeliveryRouteDto: CreateOrUpdateDeliveryRouteDto,
): Promise<ViewDeliveryRouteDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${id}`,
    updateDeliveryRouteDto,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewDeliveryRouteDto, data, {
    excludeExtraneousValues: true,
  });
}
