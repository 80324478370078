import { Grid, Typography } from '@mui/material';

interface Props {
  text: string;
}

export default function CardItemTitle({ text }: Props) {
  return (
    <Grid item xs={12}>
      <Typography
        component="div"
        sx={{
          color: 'primary.light',
          fontWeight: 600,
          marginBottom: 1,
          fontSize: '1.3rem',
        }}
      >
        {text}
      </Typography>
    </Grid>
  );
}
