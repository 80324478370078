import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getRowsPerPageDefault, TableState } from '../../lib/util/table-util';
import { useDebounce } from 'use-debounce';
import useModal from '../../components/ui/modal/useModal';
import useAuth from '../../lib/api/auth/useAuth';
import { Navigate } from 'react-router-dom';
import { ViewOrderDto } from '../../lib/api/orders/dto/view-order.dto';
import OrdersTableInternal from '../../components/orders/OrdersTableInternal';
import {
  CustomerQueryKeys,
  getCustomerOrders,
} from '../../lib/api/customers/customers.service';
import OrdersTableCustomer from '../../components/orders/OrdersTableCustomer';

export default function OrdersPageCustomer() {
  const { authed, loggedInUser } = useAuth();

  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();

  const [tableState, setTableState] = useState<TableState<ViewOrderDto>>({
    pagination: { current: 1, pageSize: getRowsPerPageDefault() },
    filters: null,
    sorter: null,
  });

  const [tableStateDebounced] = useDebounce(tableState, 500);

  const { isError, data, error, isFetching } = useQuery(
    [CustomerQueryKeys.findAllCustomerOrders, tableStateDebounced],
    () => getCustomerOrders(loggedInUser.customerId, tableStateDebounced),
  );

  console.log({
    component: 'OrdersPage',
    message: 'rendered',
    data: data,
  });

  if (!authed()) return <Navigate to="/auth/login" />;

  return (
    <>
      <OrdersTableCustomer
        apiRes={data}
        isFetching={isFetching}
        setTableState={setTableState}
        tableState={tableState}
      />
    </>
  );
}
