import { plainToInstance } from 'class-transformer';
import { buildFindAllQueryParams, getHeaders } from '../../util/http-utils';
import { TableState } from '../../util/table-util';
import { apiClientFactory } from '../../common/clients';
import { ViewManyDto } from '../../common/dto/view-many.dto';
import { ViewProductDto } from '../products/dto/view-product.dto';
import { ViewSellableProductDto } from '../products/dto/view-sellable-product.dto';
import { CreateOrUpdateCategoryDto } from './dto/create-or-update-category.dto';
import { UpdateManyCategoriesSingleItemDto } from './dto/update-many-categories-single-item.dto';
import { ViewCategoryDto } from './dto/view-category.dto';

const apiClient = apiClientFactory();

/****************************
Categories
****************************/

const resource = 'categories';

export enum CategoryQueryKeys {
  'findCategoryById' = 'FindCategoryById',
  'findAllCategories' = 'FindAllCategories',
  'findSellableProducts' = 'FindSellableProducts',
  'findListOfProductsWithVariants' = 'FindListOfProductsWithVariants',
}

export async function createCategory(
  createCategoryDto: CreateOrUpdateCategoryDto,
): Promise<ViewCategoryDto> {
  const { data } = await apiClient.post(`v1/${resource}`, createCategoryDto, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewCategoryDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function getAllCategories(
  tableState?: TableState<ViewCategoryDto>,
): Promise<ViewManyDto<ViewCategoryDto>> {
  const queryParams = buildFindAllQueryParams<ViewCategoryDto>(tableState);

  let requestUrl = `v1/${resource}`;
  if (queryParams) requestUrl = `${requestUrl}?${queryParams}`;

  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });

  return {
    ...data,
    data: plainToInstance(ViewCategoryDto, data.data, {
      excludeExtraneousValues: true,
    }),
  };
}

export async function getCategoryById(
  categoryId: number,
): Promise<ViewCategoryDto> {
  const { data } = await apiClient.get(`v1/${resource}/${categoryId}`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewCategoryDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function deleteCategory(
  categoryId: number,
): Promise<ViewCategoryDto> {
  const { data } = await apiClient.delete(`v1/${resource}/${categoryId}`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewCategoryDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function updateCategory(
  id: number,
  updateCategoryDto: CreateOrUpdateCategoryDto,
): Promise<ViewCategoryDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${id}`,
    updateCategoryDto,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewCategoryDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function updateManyCategories(
  updateCategoriesDto: UpdateManyCategoriesSingleItemDto[],
): Promise<ViewCategoryDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}`,
    updateCategoriesDto,
    {
      headers: getHeaders(),
    },
  );
  //@TODO::Make sure this is working.
  return plainToInstance(ViewCategoryDto, data, {
    excludeExtraneousValues: true,
  });
}

/****************************
Category Products
****************************/
export async function getSellableProductsForCategory(
  categoryId: number,
): Promise<ViewSellableProductDto[]> {
  const { data } = await apiClient.get(
    `v1/${resource}/${categoryId}/sellable-products`,
    {
      headers: getHeaders(),
    },
  );

  return plainToInstance(
    ViewSellableProductDto,
    data as ViewSellableProductDto[],
    { excludeExtraneousValues: true },
  );
}

export async function getListOfProductsWithVariantsForCategory(
  categoryId: number,
): Promise<ViewProductDto[]> {
  const { data } = await apiClient.get(
    `v1/${resource}/${categoryId}/inventory/products-with-variants`,
    {
      headers: getHeaders(),
    },
  );

  return plainToInstance(ViewProductDto, data as ViewProductDto[], {
    excludeExtraneousValues: true,
  });
}
