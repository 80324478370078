import { Expose, Type } from 'class-transformer';
import { ViewBillingAddressDto } from '../../addresses/dto/view-billing-address.dto';
import { ViewShippingAddressDto } from '../../addresses/dto/view-shipping-address.dto';
import { ViewContactDto } from '../../contacts/dto/view-contact.dto';
import { ViewCustomerDto } from '../../customers/dto/view-customer.dto';
import { ViewUserDto } from '../../users/dto/view-user.dto';
import { ViewLineItemDto } from './view-line-item.dto';
import { ViewOrderDto } from './view-order.dto';

export class ViewOrderExtendedDto extends ViewOrderDto {
  @Expose()
  @Type(() => ViewUserDto)
  user: ViewUserDto;

  @Expose()
  @Type(() => ViewCustomerDto)
  customer: ViewCustomerDto;

  @Expose()
  @Type(() => ViewBillingAddressDto)
  billingAddress: ViewBillingAddressDto;

  @Expose()
  @Type(() => ViewShippingAddressDto)
  shippingAddress: ViewShippingAddressDto;

  @Type(() => ViewContactDto)
  @Expose()
  billingContact: ViewContactDto;

  @Type(() => ViewContactDto)
  @Expose()
  shippingContact: ViewContactDto;

  @Type(() => ViewLineItemDto)
  @Expose()
  items: ViewLineItemDto[];
}
