import { TableCell, TableRow } from '@mui/material';
import { ViewLineItemDto } from '../../../../lib/api/orders/dto/view-line-item.dto';
import { ViewOrderExtendedDto } from '../../../../lib/api/orders/dto/view-order-extended.dto';
import { LineItemVarianProduct } from './LineItemsTableCategoryRow';
import LineItemsTableVariantRow from './LineItemsTableVariantRow';

interface Props {
  product: LineItemVarianProduct;
  items: ViewLineItemDto[];
  order: ViewOrderExtendedDto;
}

export default function LineItemsTableVariantHeaderRow({
  product,
  items,
  order,
}: Props) {
  return (
    <>
      <TableRow
        key={product.sku}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell
          component="th"
          scope="row"
          colSpan={7}
          sx={{ fontStyle: 'italic' }}
        >
          {product.name}
        </TableCell>
      </TableRow>
      {items.map((variantItem) => (
        <LineItemsTableVariantRow
          key={variantItem.id}
          item={variantItem}
          order={order}
        />
      ))}
    </>
  );
}
