import { styled } from '@mui/material';

interface Props {
  text: string;
}

export default function RequiredInputLabel({ text }: Props) {
  const SuperScript = styled('div')(({ theme }) => ({
    color: theme.palette.error.main,
    display: 'inline',
    position: 'relative',
    top: '-0.3rem',
    marginLeft: '0.3rem',
  }));
  return (
    <>
      {text}
      <SuperScript>*</SuperScript>
    </>
  );
}
