import { Expose, Type } from 'class-transformer';

export class ViewProductOptionValueDto {
  @Type(() => ViewProductOptionValueValuesDto)
  @Expose()
  values: ViewProductOptionValueValuesDto[];
}

export class ViewChildProductOptionValueDto {
  @Expose() parentId: number;
  @Expose() parentValue: string;
  @Type(() => ViewProductOptionValueValuesDto)
  @Expose()
  values: ViewProductOptionValueValuesDto[];
}

export class ViewProductOptionValueValuesDto {
  @Expose() id: number;
  @Expose() value: string;
}
