import { Transform, TransformOptions } from 'class-transformer';
import Decimal from 'decimal.js';

type NoValueOptions = 'SetToZero' | 'SetToNull';

interface StringToDecimalOptions extends TransformOptions {
  emptyString?: NoValueOptions;
  nullValue?: NoValueOptions;
  undefinedValue?: NoValueOptions;
}

/**
 * Transforms number represented as a string to a Decimal from decimal.js
 * @param options Lets you specifiy how you want empty string, nulls, or undefined
 * values to be converted. You can either set them to null or set them to 0. The
 * default is to set them to null. Also lets you specify the typical transform options.
 * @Returns Decimal if value is not empty string, null, or undefined.
 * @Returns null if if value is empty string, null, or undefined
 */
export default function TransformStringToDecimal(
  options?: StringToDecimalOptions,
) {
  const {
    emptyString: emptyStringProvided,
    nullValue: nullValueProvided,
    undefinedValue: undefinedValueProvided,
    ...rest
  } = options;

  const emptyString = emptyStringProvided || 'SetToNull';
  const nullValue = nullValueProvided || 'SetToNull';
  const undefinedValue = undefinedValueProvided || 'SetToNull';

  return Transform(({ value }) => {
    if (value === '') {
      if (emptyString === 'SetToNull') return null;
      return new Decimal(0);
    }

    if (value === null) {
      if (nullValue === 'SetToNull') return null;
      return new Decimal(0);
    }

    if (value === undefined) {
      if (undefinedValue === 'SetToNull') return null;
      return new Decimal(0);
    }

    return new Decimal(value);
  }, rest);
}
