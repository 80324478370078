import { useQuery } from '@tanstack/react-query';
import {
  CategoryQueryKeys,
  getCategoryById,
} from '../../../lib/api/categories/categories.service';
import CategoryDetailsTab from './CategoryDetailsTab';
import TabGroup from '../../ui/drawer/DetailsDrawerContent';
import useTabs from '../../ui/tabs/useTabs';

interface DrawerContentProps {
  categoryId: number;
}

export default function CategoryDrawerContent({
  categoryId,
}: DrawerContentProps) {
  const {
    isError,
    data: category,
    error,
    isFetching,
  } = useQuery([CategoryQueryKeys.findCategoryById, { id: categoryId }], () =>
    getCategoryById(categoryId),
  );

  const { tabValue, handleChangeTab } = useTabs();

  if (!category) return null;

  return (
    <TabGroup tabValue={tabValue} onTabChange={handleChangeTab}>
      <CategoryDetailsTab
        label="Details"
        tabValue={tabValue}
        index={0}
        category={category}
      />
    </TabGroup>
  );
}
