import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ConfirmDialog,
  ConfirmDeleteBaseProps,
} from '../ui/dialog/ConfirmDialog';
import { useAppContext } from '../../contexts/AppContext';
import { CustomerQueryKeys } from '../../lib/api/customers/customers.service';
import { deleteAddress } from '../../lib/api/addresses/addresses.service';
import { getErrorMessage } from '../../lib/util/misc-utils';
import { getAddressFirstLine } from './address-utils';
import { ViewBillingAddressDto } from '../../lib/api/addresses/dto/view-billing-address.dto';
import { ViewShippingAddressDto } from '../../lib/api/addresses/dto/view-shipping-address.dto';

interface Props extends ConfirmDeleteBaseProps {
  address: ViewBillingAddressDto | ViewShippingAddressDto;
}

export function DeleteCustomerAddressConfirmDialog({
  address,
  isOpen,
  onClose,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();

  const deleteMutation = useMutation((addressId: string) =>
    deleteAddress(addressId),
  );

  const addressShortName = getAddressFirstLine(address);

  const handleOnConfirm = async (customerId: string, addressId: string) => {
    //We close the modal regardless of success.
    onClose();
    try {
      const deletedAddress = await deleteMutation.mutateAsync(addressId);
      queryClient.invalidateQueries([
        CustomerQueryKeys.findCustomerById,
        { id: customerId },
      ]);
      handleShowMessage(`Deleted customer address @ '${addressShortName}'`);
      onSuccess?.(deletedAddress);
    } catch (err: any) {
      handleShowMessage(getErrorMessage(err), 'error');
    }
  };

  return (
    <ConfirmDialog
      title={`Delete Address: ${addressShortName}`}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() => handleOnConfirm(address.customerId, address.id)}
    >
      <>
        <p>
          Are you sure you want to delete the address @ '{addressShortName}'?{' '}
          <strong>This action cannot be undone.</strong>
        </p>
        <p>Deleting the address will also delete its contacts.</p>
      </>
    </ConfirmDialog>
  );
}
