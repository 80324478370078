import { getHeaders } from '../../util/http-utils';
import { apiClientFactory } from '../../common/clients';
import { ViewStoreAvailabilityRow } from './dto/view-store-availability-row.dto';
import { plainToInstance } from 'class-transformer';

const apiClient = apiClientFactory();

/****************************
Reports
****************************/

const resource = 'reports';

export async function getOrdersSelectedForPicking(): Promise<any> {
  const requestUrl = `v1/${resource}/selected-for-picking`;
  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });

  return data;
}

export async function getDeliveryRackLabelsReport(): Promise<any> {
  const requestUrl = `v1/${resource}/delivery-rack-labels`;
  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });

  return data;
}

export async function getOrdersBetweenReport(
  startOrderWeek: string,
  endOrderWeek: string,
): Promise<any[]> {
  const requestUrl = `v1/${resource}/orders/${startOrderWeek}/${endOrderWeek}`;
  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });

  return data;
}

export async function getOrdersDetailsBetweenReport(
  startOrderWeek: string,
  endOrderWeek: string,
): Promise<any[]> {
  const requestUrl = `v1/${resource}/orders-details/${startOrderWeek}/${endOrderWeek}`;
  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });

  return data;
}

export async function getShippedCountsBetweenReport(
  startWeek: string,
  endWeek: string,
): Promise<any[]> {
  const requestUrl = `v1/${resource}/shipped-counts/${startWeek}/${endWeek}`;
  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });

  return data;
}

export async function getStoreAvailability(): Promise<
  ViewStoreAvailabilityRow[]
> {
  const requestUrl = `v1/${resource}/store-availability`;
  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });

  return plainToInstance(ViewStoreAvailabilityRow, data as [], {
    excludeExtraneousValues: true,
  });
}

export async function getStoreAvailabilityBasic(): Promise<
  ViewStoreAvailabilityRow[]
> {
  const requestUrl = `v1/${resource}/store-availability-basic`;
  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });

  return plainToInstance(ViewStoreAvailabilityRow, data as [], {
    excludeExtraneousValues: true,
  });
}
