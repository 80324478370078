import { Button, Chip, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { ViewProductOptionValueValuesDto } from '../../lib/api/product-option-values/dto/view-product-option-value.dto';
import {
  ViewChildProductOptionDto,
  ViewProductOptionDto,
} from '../../lib/api/product-options/dto/view-product-option.dto';
import { ViewProductExtendedDto } from '../../lib/api/products/dto/view-product-extended.dto';
import useConfirmDialog from '../ui/dialog/useConfirmDialog';
import { DeleteProductOptionValueConfirmDialog } from './DeleteProductOptionValueConfirmDialog';

interface ParentRowProps {
  option: ViewProductOptionDto;
  handleDeleteOptionValue: (value: ViewProductOptionValueValuesDto) => any;
  handleAddProductOptionValue: (option: ViewProductOptionDto) => any;
}

function ParentRow({
  option,
  handleDeleteOptionValue,
  handleAddProductOptionValue,
}: ParentRowProps) {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
          {option.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {option.values.map((optValues) =>
          optValues.values.map((optValue) => (
            <Chip
              key={optValue.id}
              variant="filled"
              onDelete={
                optValues.values.length > 1
                  ? () => handleDeleteOptionValue(optValue)
                  : null
              }
              label={optValue.value}
              sx={{ mr: 1, mb: 1 }}
            />
          )),
        )}
        <Button
          onClick={() => handleAddProductOptionValue(option)}
          variant="text"
        >{`+ ${option.name}`}</Button>
      </Grid>
    </>
  );
}

interface ChildRowProps {
  childOption: ViewChildProductOptionDto;
  handleDeleteOptionValue: (value: ViewProductOptionValueValuesDto) => any;
  handleAddProductOptionValue: (
    option: ViewChildProductOptionDto,
    parentOptionValue: ViewProductOptionValueValuesDto,
  ) => any;
}

function ChildRow({
  childOption,
  handleDeleteOptionValue,
  handleAddProductOptionValue,
}: ChildRowProps) {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mt: 2 }}>
          {childOption.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {childOption.values.map((optionValues) => (
          <Grid key={`c-opt-v-p-${optionValues.parentId}`} item xs={12}>
            <Typography color="graytext" variant="h6" sx={{ mt: 2, mb: 1 }}>
              {optionValues.parentValue}
            </Typography>
            <Grid item xs={12}>
              {optionValues.values.map((value) => (
                <Chip
                  key={`c-opt-v-v-${value.id}`}
                  variant="filled"
                  onDelete={
                    optionValues.values.length > 1
                      ? () => handleDeleteOptionValue(value)
                      : null
                  }
                  label={value.value}
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
              <Button
                onClick={() =>
                  handleAddProductOptionValue(childOption, {
                    id: optionValues.parentId,
                    value: optionValues.parentValue,
                  })
                }
                variant="text"
              >{`+ ${optionValues.parentValue} ${childOption.name}`}</Button>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

interface Props {
  product: ViewProductExtendedDto;
  handleAddProductOptionValue: (
    option: ViewProductOptionDto | ViewChildProductOptionDto,
    parentOptionValue?: ViewProductOptionValueValuesDto,
  ) => any;
}

export default function ProductOptionsList({
  product,
  handleAddProductOptionValue,
}: Props) {
  const {
    confirmDialogIsOpen,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
  } = useConfirmDialog();

  const [optionValueToDelete, setOptionValueToDelete] =
    useState<ViewProductOptionValueValuesDto>();

  const handleDeleteOptionValue = (value: ViewProductOptionValueValuesDto) => {
    setOptionValueToDelete(value);
    handleOpenConfirmDialog();
  };

  return (
    <>
      <Grid container spacing={1}>
        {product.options.map((parentOpt) => (
          <Grid key={`p-opt-${parentOpt.id}`} item xs={12}>
            <ParentRow
              option={parentOpt}
              handleDeleteOptionValue={handleDeleteOptionValue}
              handleAddProductOptionValue={handleAddProductOptionValue}
            />
            <Grid item xs={12} sx={{ ml: 4 }}>
              {parentOpt?.children?.map((childOpt) => (
                <ChildRow
                  key={`c-opt-${childOpt.id}`}
                  childOption={childOpt}
                  handleDeleteOptionValue={handleDeleteOptionValue}
                  handleAddProductOptionValue={handleAddProductOptionValue}
                />
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
      {optionValueToDelete && (
        <DeleteProductOptionValueConfirmDialog
          productOptionValue={optionValueToDelete}
          isOpen={confirmDialogIsOpen}
          onClose={handleCloseConfirmDialog}
        />
      )}
    </>
  );
}
