import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useAppContext } from '../../../contexts/AppContext';
import { AddressType } from '../../../lib/api/addresses/enums/address-type.enum';
import {
  createCustomerBillingAddress,
  CustomerQueryKeys,
} from '../../../lib/api/customers/customers.service';
import { CreateBillingAddressDto } from '../../../lib/api/addresses/dto/create-billing-address.dto';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { plainToInstance } from 'class-transformer';
import { StepperData } from '../../ui/forms/CustomStepper';
import useFormSubmitErrorAlert from '../../ui/forms/useFormSubmitErrorAlert';
import useMultiStepForm from '../../ui/forms/useMultiStepForm';
import { useState } from 'react';
import MultiStepForm from '../../ui/forms/MultiStepForm';
import MultiStepFormPage from '../../ui/forms/MultiStepFormPage';
import { onSuccessCallback } from '../../ui/forms/CustomForm';
import BillingAddressPartial from './BillingAddressPartial';
import CreateAddressContactPartial from '../CreateAddressContactPartial';
import { Grid } from '@mui/material';
import FromSubmitButton from '../../ui/forms/FormSubmitButton';

interface Props {
  onSuccess?: onSuccessCallback;
  customerId: string;
}

export default function AddBillingAddressForm({
  onSuccess,
  customerId,
}: Props) {
  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  const createMutation = useMutation(
    (createBillingAddressDto: CreateBillingAddressDto) =>
      createCustomerBillingAddress(customerId, createBillingAddressDto),
  );

  const [steps, setSteps] = useState<StepperData<CreateBillingAddressDto>[]>([
    {
      text: 'Add Adress Info',
      isActive: true,
      fieldsInStep: [
        'name',
        'code',
        'street1',
        'street2',
        'poBox',
        'city',
        'state',
        'zip',
      ],
      focusField: 'name',
      fieldsToValidateOnNext: [
        'name',
        'code',
        'street1',
        'street2',
        'poBox',
        'city',
        'state',
        'zip',
      ],
    },
    {
      text: 'Add Contact Info',
      fieldsInStep: [
        'contact.email',
        'contact.firstName',
        'contact.lastName',
        'contact.mobilePhone',
        'contact.officePhone',
        'contact.fax',
      ],
      focusField: 'contact.email',
    },
  ]);

  const form = useMultiStepForm<CreateBillingAddressDto>({
    resolver: classValidatorResolver(CreateBillingAddressDto, {
      forbidUnknownValues: true,
    }),
    defaultValues: {
      type: AddressType.billing,
      name: null,
      code: null,
      street1: null,
      street2: null,
      poBox: null,
      city: null,
      state: null,
      zip: null,
    },
    steps,
    setSteps,
  });

  const {
    formState: { errors, isSubmitting, isValid, isSubmitted },
    setError,
    handleSubmit,
    handleNextClick,
    handleBackClick,
    setActivePage,
  } = form;

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<CreateBillingAddressDto>();

  const onSubmit: SubmitHandler<CreateBillingAddressDto> = async (
    formData: CreateBillingAddressDto,
  ) => {
    clearFormSubmitError();

    const createBillingAddressDto = plainToInstance(
      CreateBillingAddressDto,
      formData,
      {
        excludeExtraneousValues: true,
        exposeDefaultValues: false,
        exposeUnsetFields: false,
      },
    );

    try {
      const createdAddress = await createMutation.mutateAsync(
        createBillingAddressDto,
      );
      queryClient.invalidateQueries([
        CustomerQueryKeys.findCustomerById,
        { id: customerId },
      ]);
      const addressShortName =
        createdAddress.street1 || `PO Box ${createdAddress.poBox}`;

      handleShowMessage(`Created Billing Address @ '${addressShortName}'`);
      onSuccess?.(createdAddress);
    } catch (err: any) {
      processFormSubmitError(err, setError, { steps, setActivePage });
    }
  };

  return (
    <FormProvider {...form}>
      <MultiStepForm
        steps={steps}
        handleSubmit={handleSubmit(onSubmit)}
        serverErrorMessage={serverErrorMessage}
      >
        <MultiStepFormPage
          step={steps[0]}
          onNext={handleNextClick}
          errors={errors}
        >
          <BillingAddressPartial />
        </MultiStepFormPage>
        <MultiStepFormPage
          step={steps[1]}
          onBack={handleBackClick}
          errors={errors}
        >
          <CreateAddressContactPartial />
          <Grid item xs={12}>
            <FromSubmitButton
              isSubmitted={isSubmitted}
              isSubmitting={isSubmitting}
              isValid={isValid}
              text="Create Billing Address"
            />
          </Grid>
        </MultiStepFormPage>
      </MultiStepForm>
    </FormProvider>
  );
}
