import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { plainToInstance } from 'class-transformer';
import { useAppContext } from '../../../contexts/AppContext';
import {
  updateProduct,
  ProductQueryKeys,
} from '../../../lib/api/products/products.service';
import useFormSubmitErrorAlert from '../../ui/forms/useFormSubmitErrorAlert';
import MultiStepFormPage from '../../ui/forms/MultiStepFormPage';
import {
  CategoryQueryKeys,
  getAllCategories,
} from '../../../lib/api/categories/categories.service';
import UpdateProductFormDetailsPartial from './UpdateProductFormDetailsPartial';
import MultiStepForm from '../../ui/forms/MultiStepForm';
import { StepperData } from '../../ui/forms/CustomStepper';
import useMultiStepForm from '../../ui/forms/useMultiStepForm';
import { UpdateProductDto } from '../../../lib/api/products/dto/update-product.dto';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import UpdateProductFormStoreAvailabilityPartial from './UpdateProductFormStoreAvailabilityPartial';
import { ViewProductExtendedDto } from '../../../lib/api/products/dto/view-product-extended.dto';
import { onSuccessCallback } from '../../ui/forms/CustomForm';

interface Props {
  product: ViewProductExtendedDto;
  onSuccess?: onSuccessCallback;
}

export default function UpdateProductForm({ product, onSuccess }: Props) {
  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();

  const updateMutation = useMutation((upateProductDto: UpdateProductDto) =>
    updateProduct(product.id, upateProductDto),
  );

  const {
    isError,
    data: categories,
    error,
    isFetching,
  } = useQuery([CategoryQueryKeys.findAllCategories], () => getAllCategories());

  const [steps, setSteps] = useState<StepperData<UpdateProductDto>[]>([
    {
      text: 'Product Details',
      isActive: true,
      fieldsInStep: [
        'categoryId',
        'name',
        'description',
        'upc',
        'itemNo',
        'sku',
        'qtyPerShelf',
        'shelvesPerRack',
        'price',
        'isProvenWinner',
      ],
      focusField: 'name',
      fieldsToValidateOnNext: [
        'categoryId',
        'name',
        'description',
        'upc',
        'itemNo',
        'sku',
        'qtyPerShelf',
        'shelvesPerRack',
        'price',
        'isProvenWinner',
      ],
    },
    {
      text: 'Store Availability',
      fieldsInStep: [
        'isSellable',
        'sellToPopes',
        'sellToPremium',
        'sellToStandard',
        'location',
        'orderLimit',
      ],
      focusField: 'isSellable',
      fieldsToValidateOnNext: [
        'isSellable',
        'sellToPopes',
        'sellToPremium',
        'sellToStandard',
        'location',
        'orderLimit',
      ],
    },
  ]);

  const form = useMultiStepForm<UpdateProductDto>({
    resolver: classValidatorResolver(UpdateProductDto, {
      forbidUnknownValues: true,
    }),
    defaultValues: {
      name: product.name,
      categoryId: product.category.id,
      description: product.description,
      upc: product.upc,
      itemNo: product.itemNo,
      sku: product.sku,
      qtyPerShelf: product.qtyPerShelf,
      shelvesPerRack: product.shelvesPerRack,
      price: product.price.toString(),
      isProvenWinner: product.isProvenWinner,
      isSellable: product.isSellable,
      sellToPopes: product.sellToPopes,
      sellToPremium: product.sellToPremium,
      sellToStandard: product.sellToStandard,
      location: product.location,
      orderLimit: product.orderLimit,
    },
    steps,
    setSteps,
  });

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<UpdateProductDto>();

  const onSubmit: SubmitHandler<UpdateProductDto> = async (
    formData: UpdateProductDto,
  ) => {
    clearFormSubmitError();

    const updateDto = plainToInstance(UpdateProductDto, formData, {
      excludeExtraneousValues: true,
      exposeDefaultValues: false,
      exposeUnsetFields: false,
    });

    try {
      const updatedProduct = await updateMutation.mutateAsync(updateDto);
      queryClient.invalidateQueries([ProductQueryKeys.findAllProducts]);
      queryClient.invalidateQueries([
        ProductQueryKeys.findProductById,
        { id: product.id },
      ]);
      handleShowMessage(`Updated Product '${updateDto.name}'`);
      onSuccess?.(updatedProduct);
    } catch (err: any) {
      processFormSubmitError(err, form.setError);
    }
  };

  return (
    <FormProvider {...form}>
      <MultiStepForm
        handleSubmit={form.handleSubmit(onSubmit)}
        serverErrorMessage={serverErrorMessage}
        steps={steps}
      >
        <MultiStepFormPage
          step={steps[0]}
          onNext={form.handleNextClick}
          errors={form.formState.errors}
        >
          <UpdateProductFormDetailsPartial
            product={product}
            categories={categories?.data}
          />
        </MultiStepFormPage>

        <MultiStepFormPage
          step={steps[1]}
          onBack={form.handleBackClick}
          errors={form.formState.errors}
        >
          <UpdateProductFormStoreAvailabilityPartial product={product} />
        </MultiStepFormPage>
      </MultiStepForm>
    </FormProvider>
  );
}
