import { Expose } from 'class-transformer';
import Decimal from 'decimal.js';
import TransformDecimalToString from '../../../common/custom-transformers/TransformDecimalToString';
import TransformStringToDecimal from '../../../common/custom-transformers/TransformStringToDecimal';
import { DefaultUUIDViewDto } from '../../../common/dto/default-uuid-view.dto';

export enum CustomerType {
  PopesRetailCenter = 'PopesRetailCenter',
  Premium = 'Premium',
  Standard = 'Standard',
}
export class ViewCustomerDto extends DefaultUUIDViewDto {
  @Expose() name: string;
  @Expose() type: CustomerType;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true, emptyString: 'SetToZero' })
  @TransformDecimalToString({ toPlainOnly: true })
  discountRate: Decimal;

  @Expose() notes: string;
}
