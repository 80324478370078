import { useState, MouseEvent, useRef } from 'react';
import { styled } from '@mui/material/styles';
import MuiListItemButton from '@mui/material/ListItemButton';
import { NavigationItem, NavigationItemExpandable } from './AdminSidebarNav';
import { ListItemIcon, ListItemText, Menu } from '@mui/material';
import { useCustomerLayoutContext } from '../context/CustomerLayoutContext';
import useAuth from '../../../lib/api/auth/useAuth';
import { useNavigate } from 'react-router-dom';

interface Props {
  page: NavigationItem | NavigationItemExpandable;
}

export default function CustomerNavItem({ page }: Props) {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const anchorEl = useRef<any | null>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleCloseMenu = () => {
    setMenuIsOpen(false);
  };

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setMenuIsOpen(true);
  };

  const ListItemButtonStyled = styled(
    MuiListItemButton,
    {},
  )(({ theme }) => ({
    minHeight: 48,
    justifyContent: 'center',
    px: 2.5,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      '& svg': {
        fill: theme.palette.primary.contrastText,
      },
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '& svg': {
        fill: theme.palette.primary.contrastText,
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      '& svg': {
        fill: theme.palette.primary.contrastText,
      },
    },
  }));

  const hasMenu = Object.hasOwn(page, 'children');

  return (
    <>
      <ListItemButtonStyled
        ref={anchorEl}
        selected={page.active}
        onClick={
          page.key === 'logout'
            ? logout
            : hasMenu
            ? (event) => handleOpenMenu(event)
            : () => navigate(page.key)
        }
        aria-controls={hasMenu ? page.key : undefined}
        aria-haspopup={hasMenu ? 'true' : undefined}
        aria-expanded={hasMenu && menuIsOpen ? 'true' : undefined}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            justifyContent: 'center',
            mr: 2,
          }}
        >
          {page.icon}
        </ListItemIcon>

        <ListItemText primary={page.displayText} sx={{ opacity: 1 }} />
      </ListItemButtonStyled>
      {menuIsOpen && (
        <Menu
          anchorEl={anchorEl.current}
          key={page.key}
          id={`${page.key}-menu`}
          open={menuIsOpen}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        ></Menu>
      )}
    </>
  );
}
