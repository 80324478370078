import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useAppContext } from '../../../contexts/AppContext';
import { CustomerQueryKeys } from '../../../lib/api/customers/customers.service';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { plainToInstance } from 'class-transformer';
import useFormSubmitErrorAlert from '../../ui/forms/useFormSubmitErrorAlert';
import { UpdateBillingAddressDto } from '../../../lib/api/addresses/dto/update-billing-address.dto';
import { updateBillingAddress } from '../../../lib/api/addresses/addresses.service';
import CustomForm, { onSuccessCallback } from '../../ui/forms/CustomForm';
import BillingAddressPartial from './BillingAddressPartial';
import { Grid } from '@mui/material';
import FromSubmitButton from '../../ui/forms/FormSubmitButton';
import { ViewBillingAddressExtendedDto } from '../../../lib/api/addresses/dto/view-billing-address-extended.dto';
import { VALIDATION_ERR_DELAY } from '../../../config/AppConstants';

interface Props {
  address: ViewBillingAddressExtendedDto;
  onSuccess?: onSuccessCallback;
}

export default function UpdateBillingAddressForm({
  address,
  onSuccess,
}: Props) {
  const updateBillingAddressDto = plainToInstance(
    UpdateBillingAddressDto,
    address,
    {
      excludeExtraneousValues: true,
      exposeUnsetFields: false,
      exposeDefaultValues: false,
    },
  );

  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();

  const updateMutation = useMutation(
    (updateBillingAddressDto: UpdateBillingAddressDto) =>
      updateBillingAddress(address.id, updateBillingAddressDto),
  );

  const form = useForm<UpdateBillingAddressDto>({
    resolver: classValidatorResolver(UpdateBillingAddressDto),
    mode: 'onTouched',
    delayError: VALIDATION_ERR_DELAY,
    defaultValues: { ...updateBillingAddressDto },
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isSubmitted },
    setError,
  } = form;

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<UpdateBillingAddressDto>();

  const onSubmit: SubmitHandler<UpdateBillingAddressDto> = async (
    formData: UpdateBillingAddressDto,
  ) => {
    clearFormSubmitError();

    const updateBillingAddressDto = plainToInstance(
      UpdateBillingAddressDto,
      formData,
      {
        excludeExtraneousValues: true,
        exposeDefaultValues: false,
        exposeUnsetFields: false,
      },
    );

    try {
      const updatedAddress = await updateMutation.mutateAsync(
        updateBillingAddressDto,
      );
      queryClient.invalidateQueries([
        CustomerQueryKeys.findCustomerById,
        { id: updatedAddress.customerId },
      ]);
      const addressShortName =
        updatedAddress.street1 || `PO Box ${updatedAddress.poBox}`;

      handleShowMessage(`Updated Billing Address @ '${addressShortName}'`);
      onSuccess?.(updatedAddress);
    } catch (err: any) {
      processFormSubmitError(err, setError);
    }
  };

  return (
    <FormProvider {...form}>
      <CustomForm
        serverErrorMessage={serverErrorMessage}
        handleSubmit={handleSubmit(onSubmit)}
      >
        <BillingAddressPartial />
        <Grid item xs={12}>
          <FromSubmitButton
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            isValid={isValid}
            text="Update Billing Address"
          />
        </Grid>
      </CustomForm>
    </FormProvider>
  );
}
