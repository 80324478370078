import { Box, Button, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Navigate, useNavigate } from 'react-router-dom';
import useAuth from '../../../lib/api/auth/useAuth';
import {
  CategoryQueryKeys,
  getAllCategories,
} from '../../../lib/api/categories/categories.service';
import CategoryProductList from '../expandable-rows/CategoryProductList';
import StoreCategoryRow from '../expandable-rows/StoreCategoryRow';
import CartSummary from '../../../components/cart-summary/CartSummary';
import StoreClosed from './StoreClosed';
import { useCartContext } from '../../../contexts/CartContext';
import { downloadStoreAvailability } from '../../../lib/common/report-download.service';

export default function StorePageCustomer() {
  const { authed } = useAuth();
  const { storeStatus } = useCartContext();
  const navigate = useNavigate();

  const {
    isError: categoriesIsError,
    data: categoriesRes,
    error: categoriesError,
    isFetching: categoriesIsFetching,
  } = useQuery([CategoryQueryKeys.findAllCategories], () => getAllCategories());

  if (!authed()) return <Navigate to="/auth/login" />;
  if (!storeStatus) return null;

  if (!storeStatus.inWindow) return <StoreClosed storeStatus={storeStatus} />;

  return (
    <>
      <Typography variant="h3" component="h1">
        Availability for Week of{' '}
        {new Date(storeStatus.currentWeek.closeAt).toLocaleString('en-US', {
          weekday: 'long',
          month: 'short',
          day: 'numeric',
        })}
      </Typography>
      <Box>
        <Button
          variant="outlined"
          sx={{ mt: 2, mb: 2 }}
          onClick={downloadStoreAvailability}
        >
          Download Availability
        </Button>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={5} md={7}>
          {categoriesRes?.data?.map((category) => (
            <StoreCategoryRow
              key={`category-${category.id}`}
              category={category}
              expandedComponent={<CategoryProductList category={category} />}
            />
          ))}
        </Grid>
        <Grid item xs={12} sm={7} md={5}>
          <CartSummary onCheckout={() => navigate('/cart')} />
        </Grid>
      </Grid>
    </>
  );
}
