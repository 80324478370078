import { Context, createContext, PropsWithChildren, useContext } from 'react';
import { AlertColor } from '@mui/material';

interface AppContextType {
  handleShowMessage: (
    message: string,
    type?: AlertColor,
    autoHideDurationMs?: number,
  ) => void;
  handleChangeTheme: () => void;
}

const AppContext = createContext<AppContextType>(null);

export const AppProvider = ({
  children,
  ...rest
}: PropsWithChildren<AppContextType>) => {
  return <AppContext.Provider value={rest}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext<AppContextType>(
    AppContext as unknown as Context<AppContextType>,
  );
  if (!context) throw new Error('useAppContext must be used under AppProvider');
  return context;
};
