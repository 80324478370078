import { useCallback, useState } from 'react';

export default function useModal() {
  const [modalOpen, setModalOpen] = useState(false);

  const handleLaunchModal = useCallback(() => {
    setModalOpen(true);
  }, []);
  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return {
    handleLaunchModal,
    handleCloseModal,
    modalOpen,
  };
}
