import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../lib/api/auth/useAuth';

interface Props {
  adminOnly?: boolean;
}

function PrivateOutlet({
  adminOnly = false,
}: Props): React.ReactElement | null {
  const { authed, loggedInUser } = useAuth();
  if (!authed()) return <Navigate to="/auth/login" />;

  if (adminOnly && !loggedInUser?.isAdmin)
    return <Navigate to="/forbidden" replace />;
  return <Outlet />;
}

export default PrivateOutlet;
