import { Equals, IsEnum, IsString } from 'class-validator';
import CustomIsNotEmpty from '../../../common/custom-class-validators/CustomIsNotEmpty';
import { Expose } from 'class-transformer';

export enum SupportedImageFileExtentions {
  jpg = 'jpg',
  jpeg = 'jpeg',
  png = 'png',
  webp = 'webp',
}

export class RequestImageUploadUrlBase {
  @IsEnum(SupportedImageFileExtentions, {
    message: `Image should be in one of the following formats: ${Object.keys(
      SupportedImageFileExtentions,
    )
      .map((k) => k)
      .join(',')}`,
  })
  @CustomIsNotEmpty('fileExtension')
  @Expose()
  fileExtension: string;
}

export class RequestProductImageUploadUrl extends RequestImageUploadUrlBase {
  constructor(productId: string, fileExtension: string) {
    super();
    this.type = 'product';
    this.productId = productId;
    this.fileExtension = fileExtension;
  }

  @Equals('product')
  type: 'product';
  @IsString()
  productId: string;
}

export class RequestVariantImageUploadUrl extends RequestImageUploadUrlBase {
  constructor(variantId: string, fileExtension: string) {
    super();
    this.type = 'variant';
    this.variantId = variantId;
    this.fileExtension = fileExtension;
  }

  @Equals('variant')
  type: 'variant';
  @IsString()
  variantId: string;
}

export type RequestImageUploadUrl =
  | RequestProductImageUploadUrl
  | RequestVariantImageUploadUrl;
