import { Button, Grid } from '@mui/material';
import { DefaultActionButton } from '../buttons/DefaultActionButton';
import { StepperData } from './CustomStepper';

interface Props {
  children: React.ReactNode;
  step: StepperData<any>;
  errors?: any;
  onSkip?: any;
  onNext?: any;
  onBack?: any;
}

export default function MultiStepFormPage({
  children,
  step,
  errors,
  onSkip,
  onNext,
  onBack,
}: Props) {
  if (!step) return null;

  const pageIsValid = () => {
    let isValid = true;
    step.fieldsInStep.forEach((f) => {
      if (errors.hasOwnProperty(f)) {
        isValid = false;
        return;
      }
    });
    return isValid;
  };

  return (
    step.isActive && (
      <>
        {children}
        <Grid container justifyContent="flex-end">
          {onNext && (
            <Grid item xs={12}>
              <Button
                type="button"
                onClick={onNext}
                // disabled={isValid === undefined ? false : !isValid}
                disabled={!pageIsValid()}
                fullWidth
                sx={{ mt: 2, mb: 0 }}
                color="primary"
                variant="contained"
              >
                Next
              </Button>
            </Grid>
          )}
          {(onSkip || onBack) && (
            <Grid item>
              {onSkip && (
                <DefaultActionButton
                  type="button"
                  onClick={onNext}
                  variant="text"
                  sx={{ mr: 1 }}
                >
                  Skip
                </DefaultActionButton>
              )}
              {onBack && (
                <DefaultActionButton
                  type="button"
                  onClick={onBack}
                  variant="text"
                >
                  Back
                </DefaultActionButton>
              )}
            </Grid>
          )}
        </Grid>
      </>
    )
  );
}
