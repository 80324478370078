import { MaxLength, ValidationOptions } from 'class-validator';

export default function CustomMaxLength(
  max: number,
  name: string,
  options?: ValidationOptions,
): PropertyDecorator {
  return MaxLength(max, {
    message: `${name} must not have more than $constraint1 characters`,
    ...options,
  });
}
