import { Typography } from '@mui/material';
import Decimal from 'decimal.js';
import useAuth from '../../../../lib/api/auth/useAuth';
import { usd } from '../../../../lib/util/misc-utils';

interface Props {
  availableQty: Decimal;
  orderIncrement: Decimal;
  orderLimit: number;
  price: Decimal;
}
export default function SellableItemStats({
  availableQty,
  orderIncrement,
  orderLimit,
  price,
}: Props) {
  const { loggedInUser } = useAuth();

  const isCustomer = !loggedInUser.isAdmin && !loggedInUser.isRetail;

  const showOrderLimit = orderLimit && isCustomer;

  const highlightNegativeQty = isCustomer
    ? false
    : availableQty
    ? availableQty?.lessThan(0)
    : false;

  const qtyAvailableStyles = {
    color: highlightNegativeQty ? 'red' : 'text.secondary',
    backgroundColor: highlightNegativeQty ? 'yellow' : null,
  };

  return (
    <>
      <Typography
        component="p"
        sx={{ color: 'text.secondary', fontWeight: '700' }}
      >
        {usd(price)}
      </Typography>
      <Typography component="p" sx={qtyAvailableStyles}>{`${
        availableQty?.toString() || '0'
      } available`}</Typography>
      {orderIncrement.toNumber() !== 1 && (
        <Typography
          component="p"
          sx={{ color: 'text.secondary' }}
        >{`Order in multiples of ${orderIncrement}`}</Typography>
      )}
      {showOrderLimit && (
        <Typography component="p" sx={{ color: 'error.light' }}>{`Limit of ${
          orderLimit || 0
        } per customer`}</Typography>
      )}
    </>
  );
}
