import { Grid, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useEffect } from 'react';
import { updateUser, UserQueryKeys } from '../../lib/api/users/users.service';
import { ViewUserDto } from '../../lib/api/users/dto/view-user.dto';
import { UpdateUserDto } from '../../lib/api/users/dto/update-user.dto';
import { plainToInstance } from 'class-transformer';
import { useAppContext } from '../../contexts/AppContext';
import FromSubmitButton from '../ui/forms/FormSubmitButton';
import { CustomerQueryKeys } from '../../lib/api/customers/customers.service';
import useFormSubmitErrorAlert from '../ui/forms/useFormSubmitErrorAlert';
import CustomForm, { onSuccessCallback } from '../ui/forms/CustomForm';
import RhfTextField from '../ui/forms/RhfTextField';
import RhfPhoneInput from '../ui/forms/RhfPhoneInput';
import { VALIDATION_ERR_DELAY } from '../../config/AppConstants';

interface Props {
  user: ViewUserDto;
  onSuccess?: onSuccessCallback;
}

export default function UpdateUserForm({ user, onSuccess }: Props) {
  const action = 'Update User';

  const updateUserDto = plainToInstance(UpdateUserDto, user, {
    excludeExtraneousValues: true,
  });

  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  interface UpdateUserMutationProps {
    id: string;
    data: UpdateUserDto;
  }
  const updateUserMutation = useMutation(
    ({ id, data }: UpdateUserMutationProps) => updateUser(id, data),
  );

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isSubmitted },
    setError,
    setFocus,
    control,
  } = useForm<UpdateUserDto>({
    resolver: classValidatorResolver(UpdateUserDto),
    mode: 'onTouched',
    delayError: VALIDATION_ERR_DELAY,
    defaultValues: {
      profile: { ...updateUserDto.profile },
    },
  });

  useEffect(() => {
    setFocus('profile.firstName');
  }, [setFocus]);

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<UpdateUserDto>();

  const onSubmit: SubmitHandler<UpdateUserDto> = async (
    formData: UpdateUserDto,
  ) => {
    clearFormSubmitError();

    const updateUserDto = plainToInstance(UpdateUserDto, formData, {
      excludeExtraneousValues: true,
    });

    try {
      const updatedUser = await updateUserMutation.mutateAsync({
        data: updateUserDto,
        id: user.id,
      });

      if (!user.isAdmin) {
        queryClient.invalidateQueries([
          CustomerQueryKeys.findCustomerById,
          { id: user.customer.id },
        ]);
      }

      queryClient.invalidateQueries([UserQueryKeys.findAllUsers]);
      queryClient.invalidateQueries([
        UserQueryKeys.findUserById,
        { id: user.id },
      ]);
      handleShowMessage(`Updated User '${updatedUser.email}'`);
      onSuccess?.(updatedUser);
    } catch (err: any) {
      processFormSubmitError(err, setError);
    }
  };

  return (
    <CustomForm
      serverErrorMessage={serverErrorMessage}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h3" variant="h6">
            Profile
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <RhfTextField
            control={control}
            name="profile.firstName"
            label="First Name"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <RhfTextField
            control={control}
            name="profile.lastName"
            label="Last Name"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <RhfPhoneInput
            name="profile.mobilePhone"
            label="Mobile Phone"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <RhfPhoneInput
            name="profile.officePhone"
            label="Office Phone"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <FromSubmitButton
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            isValid={isValid}
            text={action}
          />
        </Grid>
      </Grid>
    </CustomForm>
  );
}
