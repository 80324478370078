import {
  Alert,
  Avatar,
  Box,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import useAuth from '../../lib/api/auth/useAuth';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { LoginCredentialsDto } from '../../lib/api/auth/dto/login-credentials.dto';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import FromSubmitButton from '../ui/forms/FormSubmitButton';
import useFormSubmitErrorAlert from '../ui/forms/useFormSubmitErrorAlert';
import CustomForm from '../ui/forms/CustomForm';
import { VALIDATION_ERR_DELAY } from '../../config/AppConstants';

export default function LoginForm() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isSubmitted },
    setError,
  } = useForm<LoginCredentialsDto>({
    resolver: classValidatorResolver(LoginCredentialsDto),
    mode: 'onTouched',
    delayError: VALIDATION_ERR_DELAY,
    defaultValues: {
      email: null,
      password: null,
    },
  });

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<LoginCredentialsDto>();

  const onSubmit: SubmitHandler<LoginCredentialsDto> = async (
    credentials: LoginCredentialsDto,
  ) => {
    clearFormSubmitError();
    try {
      const user = await login(credentials);

      if (user?.isAdmin) {
        navigate((state as string) || '/admin/orders');
      } else {
        navigate((state as string) || '/store');
      }
    } catch (err: any) {
      processFormSubmitError(err, setError);
    }
  };

  return (
    <CustomForm
      serverErrorMessage={serverErrorMessage}
      handleSubmit={handleSubmit(onSubmit)}
      formContainerSx={{ maxWidth: '500px' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        gap={3}
      >
        <Typography component="h2" variant="h5"></Typography>
        <Avatar sx={{ bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h3" variant="h5">
          Availability for Regional Program
        </Typography>
      </Box>

      <Box>
        <TextField
          {...register('email')}
          helperText={errors.email && errors.email.message}
          error={errors.email ? true : false}
          label="Email Address"
          autoComplete="email"
          required
          autoFocus
          margin="normal"
          fullWidth
        />

        <TextField
          {...register('password')}
          helperText={errors.password && errors.password.message}
          error={errors.password ? true : false}
          label="Password"
          type="password"
          required
          autoComplete="current-password"
          margin="normal"
          fullWidth
        />

        <FromSubmitButton
          isSubmitted={isSubmitted}
          isSubmitting={isSubmitting}
          isValid={isValid}
          text="Sign In"
          sx={{ mt: 3, mb: 2 }}
        />

        <Grid container>
          <Grid item xs>
            <Link
              component={RouterLink}
              to="/auth/reset-password-request"
              variant="body2"
            >
              Forgot password?
            </Link>
          </Grid>
        </Grid>
      </Box>
    </CustomForm>
  );
}
