import { Grid, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { VALIDATION_ERR_DELAY } from '../../../config/AppConstants';
import { CreateShippingAddressDto } from '../../../lib/api/addresses/dto/create-shipping-address.dto';
import { UpdateShippingAddressDto } from '../../../lib/api/addresses/dto/update-shipping-address.dto';
import { States } from '../../../lib/api/addresses/enums/states.enum';
import RhfAutocomplete from '../../ui/forms/RhfAutocomplete';
import RhfTextField from '../../ui/forms/RhfTextField';

export default function ShippingAddressPartial() {
  const {
    formState: { dirtyFields },
    setFocus,
    watch,
    trigger,
    control,
  } = useFormContext<CreateShippingAddressDto | UpdateShippingAddressDto>();

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  //street1 and street2 fields have
  //cross field validation. I'll watch them
  //and trigger validation on all three when
  //any of them change.
  const watchStreet1 = watch('street1');
  const watchStreet2 = watch('street2');

  const debouncedCrossValidationTrigger = useMemo(
    () =>
      debounce(() => {
        if (dirtyFields.street1 || dirtyFields.street2) {
          trigger('street1');
          trigger('street2');
        }
      }, VALIDATION_ERR_DELAY),
    [trigger, dirtyFields],
  );

  useEffect(() => {
    debouncedCrossValidationTrigger();
  }, [watchStreet1, watchStreet2, debouncedCrossValidationTrigger]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h3" variant="h5">
          Address
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RhfTextField
          control={control}
          name="name"
          label="Ship to Company Name"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <RhfTextField
          control={control}
          name="street1"
          label="Street"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <RhfTextField control={control} name="street2" label="Street 2" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RhfTextField control={control} name="city" label="City" required />
      </Grid>
      <Grid item xs={6} sm={3}>
        <RhfAutocomplete
          control={control}
          name="state"
          defaultText="State"
          optionsAsEnum={States}
          required
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <RhfTextField control={control} name="zip" label="Zip" required />
      </Grid>
    </Grid>
  );
}
