import { Box, TableCell, TableRow } from '@mui/material';
import ManualQtyInput from '../inputs/ManualQtyInput';
import PhysicalCountInput from '../inputs/PhysicalCountInput';
import { InventoryWithCalculatedFields } from './InventoryAvailabilityTable';
import { FixedTableCell, LastCellOfRow, StartGroupCell } from './styled-items';
import InventoryAvailabilitySwitch from '../../../components/products/InventoryAvailabilitySwitch';

interface Props {
  item: InventoryWithCalculatedFields;
}

export default function InventoryAvailabilityTableRow({ item }: Props) {
  let statusStyles: any;
  if (item.manualQtyForWeekComplete) {
    statusStyles = {
      backgroundColor: '#C8DECD',
      ':hover': {
        backgroundColor: '#99C2A2 !important',
      },
    };
  } else if (item.physicalCountForWeekComplete) {
    statusStyles = {
      backgroundColor: '#FFE4CA',
      ':hover': {
        backgroundColor: '#B78A5F !important',
      },
    };
  }
  return (
    <TableRow hover tabIndex={-1} key={item.key} sx={statusStyles}>
      <FixedTableCell sx={{ maxWidth: '200px' }}>
        <>
          <Box>{item.name}</Box>
          {item.variantId && (
            <>
              <InventoryAvailabilitySwitch
                inventoryItem={item}
                flag="isSellableVariant"
              />
              <InventoryAvailabilitySwitch
                inventoryItem={item}
                flag="sellToPopesVariant"
              />
              <InventoryAvailabilitySwitch
                inventoryItem={item}
                flag="sellToPremiumVariant"
              />
              <InventoryAvailabilitySwitch
                inventoryItem={item}
                flag="sellToStandardVariant"
              />
            </>
          )}
          {!item.variantId && (
            <>
              <InventoryAvailabilitySwitch
                inventoryItem={item}
                flag="isSellableProduct"
              />
              <InventoryAvailabilitySwitch
                inventoryItem={item}
                flag="sellToPopesProduct"
              />
              <InventoryAvailabilitySwitch
                inventoryItem={item}
                flag="sellToPremiumProduct"
              />
              <InventoryAvailabilitySwitch
                inventoryItem={item}
                flag="sellToStandardProduct"
              />
            </>
          )}
        </>
      </FixedTableCell>
      <TableCell align="right">{item.numOrderedThisWeek.toFixed(2)}</TableCell>
      <TableCell align="right">
        {item.numAlreadyShippedThisWeek.toFixed(2)}
      </TableCell>
      <StartGroupCell align="right">
        {item.numDueNextWeek.toFixed(2)}
      </StartGroupCell>
      <TableCell align="right">{item.numDueInTwoWeeks.toFixed(2)}</TableCell>
      <TableCell align="right">
        {item.numDueInThreeOrMoreWeeks.toFixed(2)}
      </TableCell>
      <StartGroupCell>
        <PhysicalCountInput name={`${item.key}-pc`} label="Count" item={item} />
      </StartGroupCell>
      <TableCell align="center">
        {item.physicalCountForWeekComplete
          ? item.physicalCountAsOf.toFormat('M/dd/yy h:mm a')
          : `----------`}
      </TableCell>
      <StartGroupCell align="right">
        {item.currentQtyInSystem.toFixed(2)}
      </StartGroupCell>
      <TableCell align="right">
        {item.estimatedQty ? item.estimatedQty.toFixed(2) : `---`}
      </TableCell>
      <TableCell>
        <ManualQtyInput name={`${item.key}-mq`} label="Qty" item={item} />
      </TableCell>
      <LastCellOfRow align="center">
        {item.manualQtyForWeekComplete
          ? item.manualQtyAsOf.toFormat('M/dd/yy h:mm a')
          : `----------`}
      </LastCellOfRow>
    </TableRow>
  );
}
