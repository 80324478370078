import { Box } from '@mui/material';
import LoginForm from '../../components/auth/LoginForm';
import useAuth from '../../lib/api/auth/useAuth';

export default function LoginPage() {
  const { loggedInUser, logout } = useAuth();

  if (loggedInUser) logout();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <LoginForm />
    </Box>
  );
}
