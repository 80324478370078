import { Expose } from 'class-transformer';
import Decimal from 'decimal.js';
import TransformStringToDecimal from '../../../common/custom-transformers/TransformStringToDecimal';
import { DefaultIncrementViewDto } from '../../../common/dto/default-increment-view.dto';

export default class ViewCartItemDto extends DefaultIncrementViewDto {
  @Expose() cartId: string;
  @Expose() productId: string;
  @Expose() variantId: string;
  @Expose() @TransformStringToDecimal({ toClassOnly: true }) qty: Decimal;
  @Expose() @TransformStringToDecimal({ toClassOnly: true }) shelves: Decimal;
  @Expose() @TransformStringToDecimal({ toClassOnly: true }) racks: Decimal;
  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  productTotalBeforeDiscount: Decimal;
  @Expose() notes: string;
}
