import { AlertColor } from '@mui/material';
import { useCallback, useState } from 'react';

export interface SnackbarInfo {
  open: boolean;
  message: string;
  severity: AlertColor;
  autoHideDurationMs: number;
}

export default function useSnackbar() {
  const [snackbarConfig, setSnackbarConfig] = useState<SnackbarInfo>({
    open: false,
    message: '',
    severity: 'info',
    autoHideDurationMs: 4000,
  });

  const handleCloseSnackbar = useCallback(() => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  }, []);

  const handleShowMessage = useCallback(
    (
      message: string,
      type: AlertColor = 'success',
      autoHideDurationMs: number = 4000,
    ) => {
      setSnackbarConfig({
        open: true,
        message,
        severity: type,
        autoHideDurationMs,
      });
    },
    [],
  );

  return {
    handleShowMessage,
    handleCloseSnackbar,
    snackbarConfig,
  };
}
