import { Typography } from '@mui/material';
import { AddressType } from '../../lib/api/addresses/enums/address-type.enum';
import { getAddressFirstLine } from './address-utils';

export interface AddressFragment {
  type: AddressType;
  city: string;
  name: string;
  code?: string;
  street1?: string;
  street2?: string;
  poBox?: string;
  state: string;
  zip: string;
}

interface Props {
  address: AddressFragment;
}

export default function AddressContent({ address }: Props) {
  const addressLine2 = address.street2 || null;
  const addressLine3 = `${address.city}, ${address.state} ${address.zip}`;

  return (
    <>
      <Typography component="p">{getAddressFirstLine(address)}</Typography>
      {addressLine2 && <Typography component="p">{addressLine2}</Typography>}
      <Typography component="p">{addressLine3}</Typography>
    </>
  );
}
