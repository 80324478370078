import { plainToInstance } from 'class-transformer';
import { buildFindAllQueryParams, getHeaders } from '../../util/http-utils';
import { TableState } from '../../util/table-util';
import { CreateBillingAddressDto } from '../addresses/dto/create-billing-address.dto';
import { CreateShippingAddressDto } from '../addresses/dto/create-shipping-address.dto';
import { ViewShippingAddressExtendedDto } from '../addresses/dto/view-shipping-address-extended.dto';
import { apiClientFactory } from '../../common/clients';
import { ViewManyDto } from '../../common/dto/view-many.dto';
import { CreateUserDto } from '../users/dto/create-user.dto';
import { ViewUserDto } from '../users/dto/view-user.dto';
import { CreateOrUpdateCustomerDto } from './dto/create-or-update-customer.dto';
import { ViewCustomerDto } from './dto/view-customer.dto';
import { ViewBillingAddressExtendedDto } from './../addresses/dto/view-billing-address-extended.dto';
import { ViewCustomerExtendedDto } from './dto/view-customer-extended.dto';
import { ViewOrderDto } from '../orders/dto/view-order.dto';

const apiClient = apiClientFactory();

/****************************
Customers
****************************/

const resource = 'customers';

export enum CustomerQueryKeys {
  'findCustomerById' = 'FindCustomerById',
  'findAllCustomers' = 'FindAllCustomers',
  'findAllCustomerOrders' = 'FindAllCustomerOrders',
}

export async function createCustomer(
  createUserDto: CreateOrUpdateCustomerDto,
): Promise<ViewCustomerDto> {
  const { data } = await apiClient.post(`v1/${resource}`, createUserDto, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewCustomerDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function getAllCustomers(
  tableState?: TableState<ViewCustomerDto>,
): Promise<ViewManyDto<ViewCustomerDto>> {
  const queryParams = buildFindAllQueryParams<ViewCustomerDto>(tableState);

  let requestUrl = `v1/${resource}`;
  if (queryParams) requestUrl = `${requestUrl}?${queryParams}`;

  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });

  return {
    ...data,
    data: plainToInstance(ViewCustomerDto, data.data, {
      excludeExtraneousValues: true,
    }),
  };
}

export async function getCustomerById(
  customerId: string,
): Promise<ViewCustomerExtendedDto> {
  const { data } = await apiClient.get(`v1/${resource}/${customerId}`, {
    headers: getHeaders(),
  });

  return plainToInstance(ViewCustomerExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function deleteCustomer(
  customerId: string,
): Promise<ViewCustomerDto> {
  const { data } = await apiClient.delete(`v1/${resource}/${customerId}`, {
    headers: getHeaders(),
  });

  return plainToInstance(ViewCustomerDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function updateCustomer(
  id: string,
  updateCustomerDto: CreateOrUpdateCustomerDto,
): Promise<ViewCustomerExtendedDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${id}`,
    updateCustomerDto,
    {
      headers: getHeaders(),
    },
  );

  return plainToInstance(ViewCustomerExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

/****************************
Customer Orders
****************************/

export async function getCustomerOrders(
  customerId: string,
  tableState?: TableState<ViewOrderDto>,
): Promise<ViewManyDto<ViewOrderDto>> {
  const queryParams = buildFindAllQueryParams<ViewOrderDto>(tableState);

  let requestUrl = `v1/${resource}/${customerId}/orders`;
  if (queryParams) requestUrl = `${requestUrl}?${queryParams}`;

  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });

  return {
    ...data,
    data: plainToInstance(ViewOrderDto, data.data, {
      excludeExtraneousValues: true,
    }),
  };
}

/****************************
Customer Users
****************************/

export async function createCustomerUser(
  customerId: string,
  createUserDto: CreateUserDto,
): Promise<ViewUserDto> {
  const { data } = await apiClient.post(
    `v1/${resource}/${customerId}/users`,
    createUserDto,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewUserDto, data, {
    excludeExtraneousValues: true,
  });
}

/****************************
Customer Addresses
****************************/

export async function createCustomerBillingAddress(
  customerId: string,
  createCustomerAddressDto: CreateBillingAddressDto,
): Promise<ViewBillingAddressExtendedDto> {
  const { data } = await apiClient.post(
    `v1/${resource}/${customerId}/addresses`,
    createCustomerAddressDto,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewBillingAddressExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function createCustomerShippingAddress(
  customerId: string,
  createCustomerAddressDto: CreateShippingAddressDto,
): Promise<ViewShippingAddressExtendedDto> {
  const { data } = await apiClient.post(
    `v1/${resource}/${customerId}/addresses`,
    createCustomerAddressDto,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewShippingAddressExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}
