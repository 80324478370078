import { Expose } from 'class-transformer';
import {
  ArrayNotEmpty,
  IsArray,
  IsInt,
  MaxLength,
  MinLength,
} from 'class-validator';

export class CreateChildProductOptionValueForExistingOptionDto {
  @Expose()
  @IsInt()
  productOptionId: number; //The child option id we are mapping values to.

  @Expose()
  @IsArray()
  @ArrayNotEmpty({ message: 'Option must have at least one value.' })
  @MinLength(1, { each: true })
  @MaxLength(100, { each: true })
  values: string[];
}
