import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';

@ValidatorConstraint()
export class OnlyOnePopulatedConstraint
  implements ValidatorConstraintInterface
{
  validate(value: any, args: ValidationArguments) {
    const [otherPropertyName] = args.constraints;
    const otherValue = (args.object as any)[otherPropertyName];

    //Only one must be populated
    if (!value && !otherValue) return false; //one must be populated.
    if (value && otherValue) return false; //only one can be populated.
    return true;
  }

  defaultMessage(args: ValidationArguments) {
    const [relatedPropertyName] = args.constraints;
    return `Either ${args.property} or ${relatedPropertyName} must be populated, but not both.`;
  }
}

/**
 * Used to ensure that only one of two fields is populated. It requires one
 * of the fields to be populated.
 * @param otherProperty The property this decorator is applied to is checked against the 'otherProperty' you specifiy here.
 * @param validationOptions
 * @returns
 */
export function OnlyOnePopulated(
  otherProperty: string,
  validationOptions?: ValidationOptions,
) {
  return (object: any, propertyName: string) => {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [otherProperty],
      validator: OnlyOnePopulatedConstraint,
    });
  };
}
