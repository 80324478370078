import { plainToInstance } from 'class-transformer';
import { buildFindAllQueryParams, getHeaders } from '../../util/http-utils';
import { TableState } from '../../util/table-util';
import { apiClientFactory } from '../../common/clients';
import { ViewManyDto } from '../../common/dto/view-many.dto';
import { ViewProductOptionDto } from '../product-options/dto/view-product-option.dto';
import { ViewVariantDto } from '../variants/dto/view-variant.dto';
import { CreateProductDto } from './dto/create-product.dto';
import { UpdateProductDto } from './dto/update-product.dto';
import { ViewProductExtendedDto } from './dto/view-product-extended.dto';
import { ViewProductDto } from './dto/view-product.dto';
import { ViewSellableVariantDto } from '../variants/dto/view-sellable-variant.dto';
import { ViewProductStoreViewDto } from './dto/view-product-store-view.dto';
import { UpdateProductAvailabilityFlagsDto } from './dto/update-product-availability-flags.dto';

const apiClient = apiClientFactory();

/****************************
Products
****************************/

const resource = 'products';

export enum ProductQueryKeys {
  'findProductById' = 'FindProductById',
  'findProductByIdStoreView' = 'FindProductByIdStoreView',
  'findAllProducts' = 'FindAllProducts',
  'findProductVariants' = 'FindProductVariants',
  'findProductOptions' = 'FindProductOptions',
  'findSellableProductVariants' = 'FindSellableProductVariants',
}

export async function createProduct(
  createProductDto: CreateProductDto,
): Promise<ViewProductExtendedDto> {
  const { data } = await apiClient.post(`v1/${resource}`, createProductDto, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewProductExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function getAllProducts(
  tableState?: TableState<ViewProductDto>,
): Promise<ViewManyDto<ViewProductDto>> {
  const queryParams = buildFindAllQueryParams<ViewProductDto>(tableState);

  let requestUrl = `v1/${resource}`;
  if (queryParams) requestUrl = `${requestUrl}?${queryParams}`;

  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });

  return {
    ...data,
    data: plainToInstance(ViewProductDto, data.data, {
      excludeExtraneousValues: true,
    }),
  };
}

export async function getProductById(
  productId: string,
): Promise<ViewProductExtendedDto> {
  const { data } = await apiClient.get(`v1/${resource}/${productId}`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewProductExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function getProductByIdStoreView(
  productId: string,
): Promise<ViewProductStoreViewDto> {
  const { data } = await apiClient.get(
    `v1/${resource}/${productId}/store-view`,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewProductStoreViewDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function deleteProduct(
  productId: string,
): Promise<ViewProductDto> {
  const { data } = await apiClient.delete(`v1/${resource}/${productId}`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewProductDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function updateProduct(
  id: string,
  updateProductDto: UpdateProductDto | UpdateProductAvailabilityFlagsDto,
): Promise<ViewProductDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${id}`,
    updateProductDto,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewProductDto, data, {
    excludeExtraneousValues: true,
  });
}

/****************************
Product Variants
****************************/

export async function getProductVariants(
  productId: string,
  tableState?: TableState<ViewVariantDto>,
) {
  const queryParams = buildFindAllQueryParams<ViewProductDto>(tableState);

  let requestUrl = `v1/${resource}/${productId}/variants`;
  if (queryParams) requestUrl = `${requestUrl}?${queryParams}`;

  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });
  //@TODO::Convert me to my correct type with plainToInstance().
  return data;
}

export async function getSellableVariants(
  productId: string,
): Promise<ViewSellableVariantDto[]> {
  const requestUrl = `v1/${resource}/${productId}/sellable-variants`;
  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewSellableVariantDto, data as [], {
    excludeExtraneousValues: true,
  });
}

/****************************
Product Options
****************************/

export async function getProductOptions(
  productId: string,
  tableState?: TableState<ViewProductOptionDto>,
) {
  const queryParams = buildFindAllQueryParams<ViewProductDto>(tableState);

  let requestUrl = `v1/${resource}/${productId}/options`;
  if (queryParams) requestUrl = `${requestUrl}?${queryParams}`;

  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });
  //@TODO::Convert me to my correct type with plainToInstance().
  return data;
}
