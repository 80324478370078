import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CategoryQueryKeys,
  updateManyCategories,
} from '../../lib/api/categories/categories.service';
import { ViewCategoryDto } from '../../lib/api/categories/dto/view-category.dto';
import { BaseTableProps, TableCol } from '../../lib/util/table-util';
import { UpdateManyCategoriesSingleItemDto } from './../../lib/api/categories/dto/update-many-categories-single-item.dto';
import DraggableTable, {
  RowMoveNumericIdInputType,
} from '../ui/tables/draggable-table/DraggableTable';
import { useAppContext } from '../../contexts/AppContext';
import { getErrorMessage } from '../../lib/util/misc-utils';

const columns: TableCol<ViewCategoryDto>[] = [
  {
    id: 'name',
    label: 'Name',
    filterType: 'contains',
    sortable: true,
    widthPct: 25,
    render: (data) => <span>{data.name}</span>,
  },
  {
    id: 'description',
    align: 'left',
    label: 'Description',
    widthPct: 75,
  },
];

export default function CategoriesTable({
  apiRes,
  isFetching,
  setTableState,
  tableState,
  onRowClick,
}: BaseTableProps<ViewCategoryDto>) {
  const queryClient = useQueryClient();

  const updateManyMutation = useMutation(
    (data: UpdateManyCategoriesSingleItemDto[]) => updateManyCategories(data),
  );

  const { handleShowMessage } = useAppContext();

  const handleMoveRow = async (newSortOrder: RowMoveNumericIdInputType) => {
    try {
      const updatedCategories = await updateManyMutation.mutateAsync(
        newSortOrder,
      );
      queryClient.invalidateQueries([CategoryQueryKeys.findAllCategories]);
      handleShowMessage(`Categories Sort Order Changed`);
    } catch (err: any) {
      handleShowMessage(
        `Categories sort order could not be changed. ${getErrorMessage(err)}`,
        'error',
      );
    }
  };

  return (
    <DraggableTable<ViewCategoryDto>
      columns={columns}
      apiRes={apiRes}
      isFetching={isFetching}
      setTableState={setTableState}
      tableState={tableState}
      onRowClick={onRowClick}
      onRowMove={handleMoveRow}
    />
  );
}
