import { TableCell, TableRow } from '@mui/material';
import Decimal from 'decimal.js';
import ViewCartItemExtendedDto from '../../lib/api/carts/dto/view-cart-item-extended.dto';
import { ViewProductDto } from '../../lib/api/products/dto/view-product.dto';
import CartRowVariant from './CartRowVariant';

interface Props {
  product: ViewProductDto;
  items: ViewCartItemExtendedDto[];
}

export default function CartRowVariantHeader({ product, items }: Props) {
  const productShelvesSubtotal = items
    .filter((i) => i.productId === product.id)
    .reduce((acc, cur) => acc.plus(cur.shelves), new Decimal(0));

  const productRacksSubtotal = items
    .filter((i) => i.productId === product.id)
    .reduce((acc, cur) => acc.plus(cur.racks), new Decimal(0));

  const productQtySubtotal = items
    .filter((i) => i.productId === product.id)
    .reduce((acc, cur) => acc.plus(cur.qty), new Decimal(0));

  return (
    <>
      <TableRow
        key={product.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row"></TableCell>
        <TableCell component="th" scope="row" sx={{ fontStyle: 'italic' }}>
          {product.name}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="right"
          sx={{ fontStyle: 'italic' }}
        >
          {productShelvesSubtotal.toFixed(2)}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="right"
          sx={{ fontStyle: 'italic' }}
        >
          {productRacksSubtotal.toFixed(2)}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="right"
          sx={{ fontStyle: 'italic' }}
        >
          {productQtySubtotal.toFixed(2)}
        </TableCell>
        <TableCell component="th" scope="row" colSpan={4}></TableCell>
      </TableRow>
      {items.map((variantItem) => (
        <CartRowVariant key={variantItem.id} item={variantItem} />
      ))}
    </>
  );
}
