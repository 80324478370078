import { Grid } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useEffect } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import FromSubmitButton from '../ui/forms/FormSubmitButton';
import useFormSubmitErrorAlert from '../ui/forms/useFormSubmitErrorAlert';
import CustomForm, { onSuccessCallback } from '../ui/forms/CustomForm';
import RhfTextField from '../ui/forms/RhfTextField';
import { VALIDATION_ERR_DELAY } from '../../config/AppConstants';
import { ViewOrderExtendedDto } from '../../lib/api/orders/dto/view-order-extended.dto';
import { UpdateOrderNoteDto } from '../../lib/api/orders/dto/update-order-note.dto';
import {
  OrderQueryKeys,
  updateOrderNote,
} from '../../lib/api/orders/orders.service';

interface Props {
  order: ViewOrderExtendedDto;
  onSuccess?: onSuccessCallback;
}

export default function UpdateOrderNoteForm({ order, onSuccess }: Props) {
  const { handleShowMessage } = useAppContext();
  const queryClient = useQueryClient();

  const action = order.note ? 'Update Order Note' : 'Add Order Note';

  //Mutations
  const updateMutation = useMutation((updateOrderNoteDto: UpdateOrderNoteDto) =>
    updateOrderNote(order.id, updateOrderNoteDto),
  );

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isSubmitted },
    control,
    setError,
    setFocus,
  } = useForm<UpdateOrderNoteDto>({
    resolver: classValidatorResolver(UpdateOrderNoteDto),
    mode: 'onTouched',
    defaultValues: {
      note: order.note || '',
    },
    delayError: VALIDATION_ERR_DELAY,
  });

  useEffect(() => {
    setFocus('note');
  }, [setFocus]);

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<UpdateOrderNoteDto>();

  const onSubmit: SubmitHandler<UpdateOrderNoteDto> = async (
    formData: UpdateOrderNoteDto,
  ) => {
    clearFormSubmitError();

    try {
      let result: ViewOrderExtendedDto;

      //Updating
      result = await updateMutation.mutateAsync(formData);
      queryClient.invalidateQueries([
        OrderQueryKeys.findOrderById,
        { id: order.id },
      ]);

      queryClient.invalidateQueries([OrderQueryKeys.findAllOrders]);
      handleShowMessage('Order Note Updated');
      onSuccess?.(result);
    } catch (err: any) {
      processFormSubmitError(err, setError);
    }
  };

  return (
    <CustomForm
      serverErrorMessage={serverErrorMessage}
      handleSubmit={handleSubmit(onSubmit)}
      formContainerSx={{ maxWidth: '600px' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RhfTextField
            name="note"
            label="Note"
            control={control}
            multiline
            helperText="Notes displayed on an order can consist of a customer account note and an order level note. This form only displays and edits the order level portion of the note."
          />
        </Grid>
        <Grid item xs={12}>
          <FromSubmitButton
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            isValid={isValid}
            text={action}
          />
        </Grid>
      </Grid>
    </CustomForm>
  );
}
