import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@mui/material';
import { getRowsPerPageDefault, TableState } from '../../lib/util/table-util';
import { useDebounce } from 'use-debounce';
import useModal from '../../components/ui/modal/useModal';
import FullScreenModal from '../../components/ui/modal/FullScreenModal';
import { useAdminLayoutContext } from '../../components/layout/context/AdminLayoutContext';
import useAuth from '../../lib/api/auth/useAuth';
import { Navigate } from 'react-router-dom';
import {
  CustomerQueryKeys,
  getAllCustomers,
} from '../../lib/api/customers/customers.service';
import CustomersTable from '../../components/customers/CustomersTable';
import { ViewCustomerDto } from '../../lib/api/customers/dto/view-customer.dto';
import CustomerDrawerContent from '../../components/customers/customer-drawer-content/CustomerDrawerContent';
import AddOrUpdateCustomerForm from '../../components/customers/AddOrUpdateCustomerForm';
import DetailsDrawer from '../../components/ui/drawer/DetailsDrawer';

export default function CustomersPage() {
  const {
    handleTableRowClick,
    handleDetailsDrawerClose,
    detailsDrawerConfig,
    activeRecord,
  } = useAdminLayoutContext<ViewCustomerDto>();

  const { authed } = useAuth();

  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();

  const [tableState, setTableState] = useState<TableState<ViewCustomerDto>>({
    pagination: { current: 1, pageSize: getRowsPerPageDefault() },
    filters: null,
    sorter: null,
  });

  const [tableStateDebounced] = useDebounce(tableState, 500);

  const onCreateCustomerSuccess = () => {
    handleCloseModal();
  };

  const { isError, data, error, isFetching } = useQuery(
    [CustomerQueryKeys.findAllCustomers, tableStateDebounced],
    () => getAllCustomers(tableStateDebounced),
  );

  console.log({
    component: 'CustomersPage',
    message: 'rendered',
    data: data,
  });

  if (!authed()) return <Navigate to="/auth/login" />;

  return (
    <>
      <CustomersTable
        apiRes={data}
        isFetching={isFetching}
        setTableState={setTableState}
        tableState={tableState}
        onRowClick={handleTableRowClick}
      />
      <Button variant="contained" sx={{ mt: 2 }} onClick={handleLaunchModal}>
        Add Customer
      </Button>
      <FullScreenModal
        title="Create Customer"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <AddOrUpdateCustomerForm onSuccess={onCreateCustomerSuccess} />
      </FullScreenModal>
      {activeRecord && (
        <DetailsDrawer
          open={detailsDrawerConfig.open}
          detailsDrawerWidth={detailsDrawerConfig.width}
          onDrawerClose={handleDetailsDrawerClose}
          title={activeRecord.name}
        >
          <CustomerDrawerContent customerId={activeRecord.id} />
        </DetailsDrawer>
      )}
    </>
  );
}
