import { Divider, Stack } from '@mui/material';
import { useState } from 'react';
import { ViewContactDto } from '../../lib/api/contacts/dto/view-contact.dto';
import useConfirmDialog from '../ui/dialog/useConfirmDialog';
import FullScreenModal from '../ui/modal/FullScreenModal';
import useModal from '../ui/modal/useModal';
import AddOrUpdateContactForm from './AddOrUpdateContactForm';
import ContactListItem from './ContactListItem';
import { DeleteAddressContactConfirmDialog } from './DeleteAddressContactConfirmDialog';

interface Props {
  addressShortName: string;
  contacts: ViewContactDto[];
}

export default function ContactList({ addressShortName, contacts }: Props) {
  const {
    handleCloseModal: handleCloseEditModal,
    handleLaunchModal: handleLaunchEditModal,
    modalOpen: editModalOpen,
  } = useModal();

  const {
    confirmDialogIsOpen,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
  } = useConfirmDialog();

  const [contactToEdit, setContactToEdit] = useState<ViewContactDto>(null);
  const [contactToDelete, setContactToDelete] = useState<ViewContactDto>(null);

  const onEditContactSuccess = () => {
    handleCloseEditModal();
  };

  const handleEditContact = (id: string) => {
    setContactToEdit(contacts.find((a) => a.id === id));
    handleLaunchEditModal();
  };

  const handleDeleteContact = (id: string) => {
    setContactToDelete(contacts.find((a) => a.id === id));
    handleOpenConfirmDialog();
  };

  return (
    <>
      <Stack spacing={1} divider={<Divider />} sx={{ ml: 5, mr: 10 }}>
        {contacts.map((contact) => (
          <ContactListItem
            key={contact.id}
            contact={contact}
            onDelete={handleDeleteContact}
            onEdit={handleEditContact}
          />
        ))}
      </Stack>

      {contactToDelete && (
        <DeleteAddressContactConfirmDialog
          contact={contactToDelete}
          isOpen={confirmDialogIsOpen}
          onClose={handleCloseConfirmDialog}
        />
      )}

      {contactToEdit && (
        <FullScreenModal
          title={`Update Contact for ${addressShortName}`}
          open={editModalOpen}
          onClose={handleCloseEditModal}
        >
          <AddOrUpdateContactForm
            contact={contactToEdit}
            onSuccess={onEditContactSuccess}
          />
        </FullScreenModal>
      )}
    </>
  );
}
