import { Button, SxProps, Theme } from '@mui/material';
import { Color } from '../../../lib/types-and-interfaces/shared-types';
import { CircularProgress } from '@mui/material';

interface FromSubmitButtonProps {
  text: string;
  isSubmitting: boolean;
  isSubmitted: boolean;
  isValid: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  color?: Color;
  variant?: 'contained' | 'outlined' | 'text';
  onClick?: () => void; //If this is present we will use it instead of type="submit"
}

export default function FromSubmitButton({
  text,
  isSubmitting,
  isSubmitted,
  disabled,
  isValid,
  sx,
  color,
  variant,
}: FromSubmitButtonProps) {
  const defaultSxProps = { mt: 2, mb: 0 };
  const defaultDisabledLogic = isSubmitting || (isSubmitted && !isValid);

  return (
    <Button
      type="submit"
      disabled={disabled === undefined ? defaultDisabledLogic : disabled}
      fullWidth
      sx={sx ? { ...defaultSxProps, ...sx } : defaultSxProps}
      color={color || 'primary'}
      variant={variant || 'contained'}
    >
      {text}
      <FormSubmitSpinner show={isSubmitting} />
    </Button>
  );
}

interface FormSubmitSpinnerProps {
  show: boolean;
}

function FormSubmitSpinner({ show }: FormSubmitSpinnerProps) {
  return (
    show && (
      <CircularProgress
        size={24}
        sx={{
          color: 'primary.main',
          position: 'absolute',
        }}
      />
    )
  );
}
