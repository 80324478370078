import TabPanel from '../../ui/tabs/TabPanel';
import {
  getProductVariants,
  ProductQueryKeys,
} from '../../../lib/api/products/products.service';
import { useQuery } from '@tanstack/react-query';
import ProductVariantsTable from '../../variants/ProductVariantsTable';
import { MouseEvent, useEffect, useState } from 'react';
import {
  getRowsPerPageDefault,
  TableState,
} from '../../../lib/util/table-util';
import { ViewVariantDto } from '../../../lib/api/variants/dto/view-variant.dto';
import { useDebounce } from 'use-debounce';
import useModal from '../../ui/modal/useModal';
import UpdateVariantForm from '../../variants/UpdateVariantForm/UpdateVariantForm';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import { ViewProductExtendedDto } from '../../../lib/api/products/dto/view-product-extended.dto';
import ProductOrVariantImageUpload from '../ProductOrVariantImageUpload';
import { findImage } from '../../../lib/api/images/images.service';

interface Props {
  label: string;
  tabValue: number;
  index: number;
  product: ViewProductExtendedDto;
}
export default function ProductVariantsTab({
  label,
  tabValue,
  index,
  product,
}: Props) {
  const {
    handleCloseModal: handleCloseEditVariantModal,
    handleLaunchModal: handleLaunchEditVariantModal,
    modalOpen: editVariantModalOpen,
  } = useModal();

  const {
    handleCloseModal: handleCloseAddVariantImageModal,
    handleLaunchModal: handleLaunchAddVariantImageModal,
    modalOpen: addVariantImageModalOpen,
  } = useModal();

  const [editVariant, setEditVariant] = useState<ViewVariantDto>();
  const [existingImageUrl, setExistingImageUrl] = useState<string | null>(null);

  const [tableState, setTableState] = useState<TableState<ViewVariantDto>>({
    pagination: { current: 1, pageSize: getRowsPerPageDefault() },
    filters: null,
    sorter: null,
  });

  const [tableStateDebounced] = useDebounce(tableState, 500);

  const {
    isError,
    data: variants,
    error,
    isFetching,
  } = useQuery(
    [
      ProductQueryKeys.findProductVariants,
      { productId: product.id, ...tableStateDebounced },
    ],
    () => getProductVariants(product.id, tableStateDebounced),
  );

  useEffect(() => {
    setTableState({
      pagination: { current: 1, pageSize: getRowsPerPageDefault() },
      filters: null,
      sorter: null,
    });

    if (editVariant) {
      findImage('variant', editVariant.id, 'xs').then((res) => {
        if (res.url) setExistingImageUrl(res.url);
      });
    }
  }, [editVariant]);

  const handleClickVariant = (data: ViewVariantDto, e: MouseEvent) => {
    const target = e.target as HTMLElement | SVGElement;
    setEditVariant(data);

    if (target.id.includes('add-variant-image') || target.nodeName === 'path') {
      handleLaunchAddVariantImageModal();
    } else {
      handleLaunchEditVariantModal();
    }
  };

  return (
    <>
      <TabPanel value={tabValue} index={index}>
        <ProductVariantsTable
          apiRes={variants}
          isFetching={isFetching}
          setTableState={setTableState}
          tableState={tableState}
          onRowClick={handleClickVariant}
        />
      </TabPanel>
      <FullScreenModal
        title="Update Variant"
        open={editVariantModalOpen}
        onClose={handleCloseEditVariantModal}
      >
        {editVariant && (
          <UpdateVariantForm
            product={product}
            variantId={editVariant.id}
            onSuccess={handleCloseEditVariantModal}
          />
        )}
      </FullScreenModal>
      <FullScreenModal
        title={`Load Image`}
        open={addVariantImageModalOpen}
        onClose={handleCloseAddVariantImageModal}
      >
        {editVariant && (
          <>
            <h1>Add Image for {editVariant.name}</h1>
            <ProductOrVariantImageUpload
              productId={editVariant.productId}
              variantId={editVariant.id}
              defaultImageFileUrl={existingImageUrl}
              onSuccess={handleCloseAddVariantImageModal}
            />
          </>
        )}
      </FullScreenModal>
    </>
  );
}
