import { Button, Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ViewShippingAddressExtendedDto } from '../../../lib/api/addresses/dto/view-shipping-address-extended.dto';
import { AddressType } from '../../../lib/api/addresses/enums/address-type.enum';
import useAuth from '../../../lib/api/auth/useAuth';
import { ViewCustomerExtendedDto } from '../../../lib/api/customers/dto/view-customer-extended.dto';
import { CreateOrderDto } from '../../../lib/api/orders/dto/create-order.dto';
import RhfAutocomplete from '../../ui/forms/RhfAutocomplete';

interface Props {
  customer: ViewCustomerExtendedDto;
  onClickAddAddress: () => void;
}

export default function ShippingAddressPartial({
  customer,
  onClickAddAddress,
}: Props) {
  const {
    formState: { errors },
    control,
  } = useFormContext<CreateOrderDto>();

  const { loggedInUser } = useAuth();

  if (!customer) return;

  const shippingAddresses = customer.addresses.filter(
    (a) => a.type === AddressType.shipping,
  );

  // const getShippingAddressText = (a: ViewShippingAddressExtendedDto) => {
  //   const text =
  //   return text.trim();
  // };

  const shippingAddressDisplayFn = (a: ViewShippingAddressExtendedDto) => {
    const displayValue = `(${a.name}) 
      ${a.street1 || ''} 
      ${a.street2 || ''} 
      ${a.city || ''}, 
      ${a.state || ''} 
      ${a.zip || ''}`;
    return displayValue.replace(/\s\s+/g, ' ');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5">
          Which location should we ship this order to?
        </Typography>
      </Grid>

      {shippingAddresses && (
        <Grid item xs={12} sm={12} md={12}>
          <RhfAutocomplete
            control={control}
            name="shippingAddressId"
            defaultText="Select Shipping Address"
            optionsAsArray={shippingAddresses}
            displayTextFn={shippingAddressDisplayFn}
            required
          />
        </Grid>
      )}
      {loggedInUser.isAdmin && (
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Button onClick={onClickAddAddress}>
            Create New Shipping Address
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
