import TabPanel from '../../../components/ui/tabs/TabPanel';
import { OrderStatus } from '../../../lib/api/orders/enums/order-status.enum';
import useOrdersFulfillmentTable from '../hooks/useOrdersFulfillmentTable';
import OrdersStagingTable from '../tables/OrdersStagingTable';

interface Props {
  label: string;
  tabValue: number;
  index: number;
}

export default function OrdersStagingTab({ tabValue, index }: Props) {
  const { tableState, setTableState, isFetching, orders, handleChangeStatus } =
    useOrdersFulfillmentTable({
      filters: {
        status: { eq: OrderStatus.staging },
      },
    });

  return (
    <TabPanel value={tabValue} index={index}>
      <OrdersStagingTable
        apiRes={orders}
        isFetching={isFetching}
        setTableState={setTableState}
        tableState={tableState}
        onChangeStatus={handleChangeStatus}
      />
    </TabPanel>
  );
}
