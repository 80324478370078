import { ViewProductDto } from '../../lib/api/products/dto/view-product.dto';
import { usd } from '../../lib/util/misc-utils';
import { BaseTableProps, TableCol } from '../../lib/util/table-util';
import PaginatedTable from '../ui/tables/paginated-table/PaginatedTable';
import ProductTypeChip from './ProductTypeChip';

const columns: TableCol<ViewProductDto>[] = [
  {
    id: 'name',
    label: 'Name',
    filterType: 'contains',
    widthPct: 30,
    headerCellSx: { minWidth: '150px' },
  },
  {
    id: 'description',
    label: 'Description',
    filterType: 'contains',
    widthPct: 20,
    headerCellSx: { minWidth: '300px' },
  },
  {
    id: 'hasVariants',
    label: 'Type',
    render: (data: ViewProductDto) => <ProductTypeChip product={data} />,
    widthPct: 20,
    sortable: true,
  },
  {
    id: 'qtyPerShelf',
    label: 'Qty / Shelf',
    align: 'center',
    widthPct: 10,
  },
  {
    id: 'shelvesPerRack',
    label: 'Shelves / Rack',
    align: 'center',
    widthPct: 10,
  },
  {
    id: 'price',
    label: 'Price',
    align: 'center',
    render: (data: ViewProductDto) => (data.price ? usd(data.price) : '--'),
    widthPct: 10,
  },
  {
    id: 'sku',
    label: 'Sku',
    sortable: true,
    filterType: 'contains',
    widthPct: 20,
  },
];

export default function ProductsTable({
  apiRes,
  isFetching,
  setTableState,
  tableState,
  onRowClick,
}: BaseTableProps<ViewProductDto>) {
  return (
    <PaginatedTable<ViewProductDto>
      columns={columns}
      apiRes={apiRes}
      isFetching={isFetching}
      setTableState={setTableState}
      tableState={tableState}
      onRowClick={onRowClick}
    />
  );
}
