import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
  ValidationOptions,
  registerDecorator,
} from 'class-validator';

@ValidatorConstraint({ async: true })
class IsValidStreet2Constraint implements ValidatorConstraintInterface {
  async validate(value: any, args: ValidationArguments) {
    const { error } = testLogic(args);
    const isValid = !error;
    return isValid;
  }

  defaultMessage(args: ValidationArguments) {
    const { msg } = testLogic(args);
    return msg || '';
  }
}

function testLogic(args: ValidationArguments): {
  error: boolean;
  msg?: string;
} {
  const dataProvided: any = args.object;
  const street1 = dataProvided.street1;
  const street2 = dataProvided.street2;

  if (street2 && !street1)
    return {
      error: true,
      msg: `'street2' must be blank if 'street1' is not populated.`,
    };

  return { error: false };
}

export function IsValidStreet2(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsValidStreet2Constraint,
    });
  };
}
