import { plainToInstance } from 'class-transformer';
import { getHeaders } from '../../util/http-utils';
import { apiClientFactory } from '../../common/clients';
import { AddOrUpdateItemToCartDto } from './dto/add-or-update-item-to-cart.dto';
import { RefreshCartDto } from './dto/refresh-cart-dto';
import { UpdateCartDto } from './dto/update-cart.dto';
import ViewCartExtendedDto from './dto/view-cart-extended.dto';
import ViewCartItemDto from './dto/view-cart-item.dto';
import ViewCartDto from './dto/view-cart.dto';

const apiClient = apiClientFactory();

/****************************
Carts
****************************/

const resource = 'carts';

export enum CartQueryKeys {
  'findCartById' = 'FindCartById',
}

export async function getCartById(
  cartId: string,
): Promise<ViewCartExtendedDto> {
  const { data } = await apiClient.get(`v1/${resource}/${cartId}`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewCartExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function updateCart(
  id: string,
  updateCartDto: UpdateCartDto,
): Promise<ViewCartDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${id}`,
    updateCartDto,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewCartDto, data, { excludeExtraneousValues: true });
}

export async function refreshCart(id: string): Promise<RefreshCartDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${id}/refresh`,
    {},
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(RefreshCartDto, data, {
    excludeExtraneousValues: true,
  });
}

/****************************
Cart Items
****************************/

export async function addOrUpdateCartItem(
  cartId: string,
  addOrUpdateItemToCartDto: AddOrUpdateItemToCartDto,
): Promise<any> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${cartId}/items`,
    addOrUpdateItemToCartDto,
    {
      headers: getHeaders(),
    },
  );
  //@TODO::CONVERT ME TO APPROPRIATE TYPE WITH plainToInstance()
  return data;
}

export async function removeItemFromCart(
  cartId: string,
  itemId: number,
): Promise<ViewCartItemDto> {
  const { data } = await apiClient.delete(
    `v1/${resource}/${cartId}/items/${itemId}`,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewCartItemDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function removeAllItemsFromCart(cartId: string): Promise<any> {
  const { data } = await apiClient.delete(`v1/${resource}/${cartId}/items`, {
    headers: getHeaders(),
  });
  //@TODO::CONVERT ME TO APPROPRIATE TYPE WITH plainToInstance()
  return data;
}
