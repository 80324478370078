import { useQuery } from '@tanstack/react-query';
import useTabs from '../../ui/tabs/useTabs';
import TabGroup from '../../ui/drawer/DetailsDrawerContent';
import ErrorText from '../../ui/typography/ErrorText';
import {
  getOrderById,
  OrderQueryKeys,
} from '../../../lib/api/orders/orders.service';
import OrderDetailsTab from './OrderDetailsTab';

interface DrawerContentProps {
  orderId: string;
}

export default function OrderDrawerContent({ orderId }: DrawerContentProps) {
  const {
    isError: isErrorOrder,
    data: order,
    error: orderError,
  } = useQuery(
    [OrderQueryKeys.findOrderById, { id: orderId }],
    () => getOrderById(orderId),
    { retry: false },
  );

  const { tabValue, handleChangeTab } = useTabs();

  if (!order) return null;
  if (isErrorOrder) return <ErrorText err={orderError as Error} />;

  return (
    <TabGroup tabValue={tabValue} onTabChange={handleChangeTab}>
      <OrderDetailsTab
        label="Details"
        tabValue={tabValue}
        index={0}
        order={order}
      />
    </TabGroup>
  );
}
