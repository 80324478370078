import { Box } from '@mui/material';
import ResetPasswordForm from '../../components/auth/ResetPasswordForm';

export default function ResetPasswordPage() {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <ResetPasswordForm />
    </Box>
  );
}
