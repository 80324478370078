import { TextField } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useAppContext } from '../../../../contexts/AppContext';
import {
  addOrUpdateCartItem,
  CartQueryKeys,
} from '../../../../lib/api/carts/cart.service';
import { AddOrUpdateItemToCartDto } from '../../../../lib/api/carts/dto/add-or-update-item-to-cart.dto';
import { CategoryQueryKeys } from '../../../../lib/api/categories/categories.service';
import { ViewSellableProductDto } from '../../../../lib/api/products/dto/view-sellable-product.dto';
import { useCartContext } from '../../../../contexts/CartContext';
import { getErrorMessage } from '../../../../lib/util/misc-utils';

interface Props {
  name: string;
  label: string;
  decimals?: number;
  product: ViewSellableProductDto;
}

export default function ProductQtyInput({
  name,
  label,
  decimals,
  product,
}: Props) {
  const { cart, handleRemoveItemFromCart } = useCartContext();

  if (!decimals) decimals = 0;

  const itemInCart = cart.items.find(
    (i) => i.productId === product.id && i.variantId === null,
  );
  const [error, setError] = useState(null);

  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  const addOrUpdateItemtoCartMutation = useMutation(
    (addOrUpdateItemToCartDto: AddOrUpdateItemToCartDto) =>
      addOrUpdateCartItem(cart.id, addOrUpdateItemToCartDto),
  );

  const addOrUpdateItemInCart = async (value: string) => {
    setError(null);

    //If value didn't change let's exit
    if (parseFloat(value) === itemInCart?.qty?.toNumber()) return;

    if (value === '' || value === '0' || value === null) {
      //Value is blank, if product is in our cart lets remove it,
      //otherwise no action needed
      if (itemInCart) handleRemoveItemFromCart(itemInCart);
      return;
    }

    try {
      const res = await addOrUpdateItemtoCartMutation.mutateAsync({
        qty: value,
        productId: product.id,
      });

      queryClient.invalidateQueries([
        CartQueryKeys.findCartById,
        {
          id: cart.id,
        },
      ]);

      queryClient.invalidateQueries([
        CategoryQueryKeys.findSellableProducts,
        { id: product.categoryId },
      ]);

      handleShowMessage(`${res.msg.text}`);
    } catch (err: any) {
      setError(getErrorMessage(err));
    }
  };

  return (
    <NumericFormat
      name={name}
      allowNegative={false}
      decimalScale={decimals}
      thousandSeparator=","
      customInput={TextField}
      helperText={error || null}
      error={error ? true : false}
      label={label}
      variant="outlined"
      size="small"
      onBlur={(e) => addOrUpdateItemInCart(e.currentTarget.value)}
      value={itemInCart?.qty?.toNumber() || ''}
      defaultValue=""
      margin="normal"
      fullWidth
    />
  );
}
