import { Chip } from '@mui/material';
import { ViewProductDto } from '../../lib/api/products/dto/view-product.dto';

interface Props {
  product: ViewProductDto;
}

export default function ProductTypeChip({ product }: Props) {
  if (product.hasVariants)
    return <Chip variant="filled" label="Variants" color="primary" />;
  return <Chip variant="filled" label="No Variants" color="secondary" />;
}
