import { Button, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { CreateShippingAddressDto } from '../../../lib/api/addresses/dto/create-shipping-address.dto';
import { UpdateShippingAddressDto } from '../../../lib/api/addresses/dto/update-shipping-address.dto';
import {
  DeliveryRouteQueryKeys,
  getAllDeliveryRoutes,
} from '../../../lib/api/delivery-routes/delivery-routes.service';
import RhfAutocomplete from '../../ui/forms/RhfAutocomplete';
import ErrorText from '../../ui/typography/ErrorText';

interface Props {
  onAddRouteClick: () => void;
}

export default function ShippingAddressDeliveryRoutePartial({
  onAddRouteClick,
}: Props) {
  const {
    isError: isErrorDeliveryRoutes,
    data: deliveryRoutes,
    isFetching,
  } = useQuery(
    [DeliveryRouteQueryKeys.findAllDeliveryRoutes],
    () => getAllDeliveryRoutes(),
    { retry: false },
  );

  const { control, setFocus } = useFormContext<
    CreateShippingAddressDto | UpdateShippingAddressDto
  >();

  useEffect(() => {
    try {
      setFocus('routeId');
    } catch (err) {
      //Prevent get() error when id is not loaded yet.
    }
  }, [setFocus]);

  if (isErrorDeliveryRoutes)
    return (
      <ErrorText message="There was an error getting the delivery routes." />
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h3" variant="h5">
          Route Info
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {deliveryRoutes && (
          <RhfAutocomplete
            control={control}
            name="routeId"
            defaultText="Choose a Delivery Route"
            optionsAsArray={deliveryRoutes.data}
          />
        )}
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button onClick={onAddRouteClick}>Create New Route</Button>
      </Grid>
    </Grid>
  );
}
