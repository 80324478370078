import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { plainToInstance } from 'class-transformer';
import { useAppContext } from '../../contexts/AppContext';
import useFormSubmitErrorAlert from '../ui/forms/useFormSubmitErrorAlert';
import MultiStepFormPage from '../ui/forms/MultiStepFormPage';
import MultiStepForm from '../ui/forms/MultiStepForm';
import { StepperData } from '../ui/forms/CustomStepper';
import useMultiStepForm from '../ui/forms/useMultiStepForm';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  CreateOrderDto,
  OrderType,
} from '../../lib/api/orders/dto/create-order.dto';
import {
  createOrder,
  OrderQueryKeys,
} from '../../lib/api/orders/orders.service';
import OrderTypePartial from './partials/OrderTypePartial';
import CustomerPartial from './partials/CustomerPartial';
import BillingAddressPartial from './partials/BillingAddressPartial';
import {
  CustomerQueryKeys,
  getCustomerById,
} from '../../lib/api/customers/customers.service';
import BillingAddressContactPartial from './partials/BillingAddressContactPartial';
import ShippingAddressPartial from './partials/ShippingAddressPartial';
import ShippingAddressContactPartial from './partials/ShippingAddressContactPartial';
import ReviewAndSubmitPartial from './partials/ReviewAndSubmitPartial';
import { useCartContext } from '../../contexts/CartContext';
import { CartQueryKeys } from '../../lib/api/carts/cart.service';
import FullScreenModal from '../ui/modal/FullScreenModal';
import AddShippingAddressForm from '../addresses/shipping-address/AddShippingAddressForm';
import useModal from '../ui/modal/useModal';
import AddBillingAddressForm from '../addresses/billing-address/AddBillingAddressForm';
import AddOrUpdateCustomerForm from '../customers/AddOrUpdateCustomerForm';
import { onSuccessCallback } from '../ui/forms/CustomForm';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../lib/api/auth/useAuth';

interface Props {
  onSuccess?: onSuccessCallback;
}

export default function AdminCheckoutForm({ onSuccess }: Props) {
  const { handleShowMessage } = useAppContext();
  const { cart, storeStatus } = useCartContext();
  const navigate = useNavigate();
  const { loggedInUser } = useAuth();

  const queryClient = useQueryClient();
  const createMutation = useMutation((createOrderDto: CreateOrderDto) =>
    createOrder(createOrderDto),
  );

  const [steps, setSteps] = useState<StepperData<CreateOrderDto>[]>([
    {
      text: 'Order Type',
      isActive: true,
      fieldsInStep: [],
      fieldsToValidateOnNext: [],
    },
  ]);

  useEffect(() => {
    // Steps can change based on user's selected order
    // type, when that happens, this will set the active
    // step to the 1st page that is not the order type
    // (actually the 2nd in the list of pages)
    const activeStep = steps.find((s) => s.isActive);
    if (!activeStep) form.setActivePage(1);
  }, [steps]);

  const deliverySteps: StepperData<CreateOrderDto>[] = [
    {
      text: 'Order Type',
      fieldsInStep: [],
      fieldsToValidateOnNext: [],
    },
    {
      text: 'Customer',
      isActive: false,
      fieldsInStep: ['customerId'],
      focusField: 'customerId',
      fieldsToValidateOnNext: ['customerId'],
      dontDisplayForCustomer: true,
    },
    {
      text: 'Billing Address',
      fieldsInStep: ['billingAddressId'],
      focusField: 'billingAddressId',
      fieldsToValidateOnNext: ['billingAddressId'],
    },
    {
      text: 'Billing Contact',
      fieldsInStep: ['billingContactId'],
      focusField: 'billingContactId',
      fieldsToValidateOnNext: ['billingContactId'],
    },
    {
      text: 'Shipping Addresss',
      fieldsInStep: ['shippingAddressId'],
      focusField: 'shippingAddressId',
      fieldsToValidateOnNext: ['shippingAddressId'],
    },
    {
      text: 'Shipping Contact',
      fieldsInStep: ['shippingContactId'],
      focusField: 'shippingContactId',
      fieldsToValidateOnNext: ['shippingContactId'],
    },
    {
      text: 'Order Summary',
      fieldsInStep: [],
    },
  ];

  const pickupSteps: StepperData<CreateOrderDto>[] = [
    {
      text: 'Order Type',
      fieldsInStep: [],
      fieldsToValidateOnNext: [],
    },
    {
      text: 'Customer',
      isActive: false,
      fieldsInStep: ['customerId'],
      focusField: 'customerId',
      fieldsToValidateOnNext: ['customerId'],
      dontDisplayForCustomer: true,
    },
    {
      text: 'Billing Address',
      fieldsInStep: ['billingAddressId'],
      focusField: 'billingAddressId',
      fieldsToValidateOnNext: ['billingAddressId'],
    },
    {
      text: 'Billing Contact',
      fieldsInStep: ['billingContactId'],
      focusField: 'billingContactId',
      fieldsToValidateOnNext: ['billingContactId'],
    },
    {
      text: 'Order Summary',
      fieldsInStep: [],
    },
  ];

  const form = useMultiStepForm<CreateOrderDto>({
    resolver: classValidatorResolver(CreateOrderDto, {
      forbidUnknownValues: true,
    }),
    defaultValues: {
      cartId: cart.id,
      type: OrderType.Delivery,
      customerId: null,
      billingAddressId: null,
      shippingAddressId: null,
      billingContactId: null,
      shippingContactId: null,
    },
    steps,
    setSteps,
  });

  const watchedCustomerId = form.watch('customerId');

  const {
    isError,
    data: activeCustomer,
    error,
    isFetching,
  } = useQuery(
    [CustomerQueryKeys.findCustomerById, { id: watchedCustomerId }],
    (): any => {
      if (loggedInUser.customerId) {
        form.setValue('customerId', loggedInUser.customerId);
        return getCustomerById(loggedInUser.customerId);
      }

      if (watchedCustomerId) return getCustomerById(watchedCustomerId);

      return {};
    },
  );

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<CreateOrderDto>();

  const onSubmit: SubmitHandler<CreateOrderDto> = async (
    formData: CreateOrderDto,
  ) => {
    clearFormSubmitError();

    const createDto = plainToInstance(CreateOrderDto, formData, {
      excludeExtraneousValues: true,
      exposeDefaultValues: false,
      exposeUnsetFields: false,
    });

    try {
      const createdOrder = await createMutation.mutateAsync(createDto);

      queryClient.invalidateQueries([OrderQueryKeys.findAllOrders]);
      queryClient.invalidateQueries([
        CartQueryKeys.findCartById,
        { id: createDto.cartId },
      ]);

      handleShowMessage(`Created Order '${createdOrder.id}'`);

      onSuccess?.(createdOrder);
      if (loggedInUser.isAdmin) {
        navigate(`/admin/orders/${createdOrder.id}`);
      } else {
        navigate(`/orders/${createdOrder.id}`);
      }
    } catch (err: any) {
      processFormSubmitError(err, form.setError);
    }
  };

  const handleContactClick = (
    type: 'billing' | 'shipping',
    contactId: string,
  ) => {
    if (type === 'billing') {
      form.setValue('billingContactId', contactId);
      form.handleNextClick();
    } else {
      form.setValue('shippingContactId', contactId);
      form.handleNextClick();
    }
  };

  const handleOrderTypeClick = (type: OrderType) => {
    const isPickup =
      type === OrderType.PickupGreenback || type === OrderType.PickupMaryville;
    form.setValue('type', type);
    let pickupStepsAllowed = pickupSteps;
    let deliveryStepsAllowed = deliverySteps;

    if (loggedInUser.customerId) {
      pickupStepsAllowed = pickupSteps.filter((s) => !s.dontDisplayForCustomer);
      deliveryStepsAllowed = deliverySteps.filter(
        (s) => !s.dontDisplayForCustomer,
      );
    }

    if (isPickup) {
      setSteps(pickupStepsAllowed);
    } else {
      form.setValue('shippingAddressId', null);
      form.setValue('shippingContactId', null);
      setSteps(deliveryStepsAllowed);
    }
  };

  const getStepByName = (name: string) => {
    return steps.find((s) => s.text === name);
  };

  const customerStep = getStepByName('Customer');
  const billingAddressStep = getStepByName('Billing Address');
  const billingContactStep = getStepByName('Billing Contact');
  const shippingAddressStep = getStepByName('Shipping Addresss');
  const shippingContactStep = getStepByName('Shipping Contact');
  const orderSummaryStep = getStepByName('Order Summary');

  const {
    handleCloseModal: handleCloseAddCustomerModal,
    handleLaunchModal: handleLaunchAddCustomerModal,
    modalOpen: addCustomerModalOpen,
  } = useModal();

  const {
    handleCloseModal: handleCloseAddShippingAddressModal,
    handleLaunchModal: handleLaunchAddShippingAddressModal,
    modalOpen: addShippingAddressModalOpen,
  } = useModal();

  const {
    handleCloseModal: handleCloseAddBillingAddressModal,
    handleLaunchModal: handleLaunchAddBillingAddressModal,
    modalOpen: addBillingAddressModalOpen,
  } = useModal();

  const onCreateCustomerSuccess = (data: any) => {
    form.setValue('customerId', data.id);
    form.trigger('customerId');
    handleCloseAddCustomerModal();
  };

  const onCreateShippingAddressSuccess = (data: any) => {
    form.setValue('shippingAddressId', data.id);
    form.trigger('shippingAddressId');
    handleCloseAddShippingAddressModal();
  };

  const onCreateBillingAddressSuccess = (data: any) => {
    form.setValue('billingAddressId', data.id);
    form.trigger('billingAddressId');
    handleCloseAddBillingAddressModal();
  };

  return (
    <>
      <FormProvider {...form}>
        <MultiStepForm
          handleSubmit={form.handleSubmit(onSubmit)}
          serverErrorMessage={serverErrorMessage}
          steps={steps}
        >
          <MultiStepFormPage step={steps[0]} errors={form.formState.errors}>
            <OrderTypePartial
              onClick={handleOrderTypeClick}
              items={cart.items}
              storeStatus={storeStatus}
            />
          </MultiStepFormPage>

          <MultiStepFormPage
            step={customerStep}
            errors={form.formState.errors}
            onNext={form.handleNextClick}
            onBack={form.handleBackClick}
          >
            <CustomerPartial
              onClickAddCustomer={handleLaunchAddCustomerModal}
            />
          </MultiStepFormPage>

          <MultiStepFormPage
            step={billingAddressStep}
            errors={form.formState.errors}
            onBack={form.handleBackClick}
            onNext={form.handleNextClick}
          >
            <BillingAddressPartial
              customer={activeCustomer}
              onClickAddAddress={handleLaunchAddBillingAddressModal}
            />
          </MultiStepFormPage>

          <MultiStepFormPage
            step={billingContactStep}
            errors={form.formState.errors}
            onBack={form.handleBackClick}
          >
            <BillingAddressContactPartial
              customer={activeCustomer}
              onClick={handleContactClick}
            />
          </MultiStepFormPage>

          <MultiStepFormPage
            step={shippingAddressStep}
            errors={form.formState.errors}
            onBack={form.handleBackClick}
            onNext={form.handleNextClick}
          >
            <ShippingAddressPartial
              customer={activeCustomer}
              onClickAddAddress={handleLaunchAddShippingAddressModal}
            />
          </MultiStepFormPage>

          <MultiStepFormPage
            step={shippingContactStep}
            errors={form.formState.errors}
            onBack={form.handleBackClick}
          >
            <ShippingAddressContactPartial
              customer={activeCustomer}
              onClick={handleContactClick}
            />
          </MultiStepFormPage>

          <MultiStepFormPage
            step={orderSummaryStep}
            errors={form.formState.errors}
            onBack={form.handleBackClick}
          >
            <ReviewAndSubmitPartial customer={activeCustomer} />
          </MultiStepFormPage>
        </MultiStepForm>
      </FormProvider>

      {/* Secondary Froms that can be loaded, these cannot be nested or you will get form submit issues. */}

      <FullScreenModal
        title="Create Customer"
        open={addCustomerModalOpen}
        onClose={handleCloseAddCustomerModal}
      >
        <AddOrUpdateCustomerForm onSuccess={onCreateCustomerSuccess} />
      </FullScreenModal>

      {activeCustomer && (
        <>
          <FullScreenModal
            title={`Create Shipping Address for ${activeCustomer.name}`}
            open={addShippingAddressModalOpen}
            onClose={handleCloseAddShippingAddressModal}
          >
            <AddShippingAddressForm
              customerId={activeCustomer.id}
              onSuccess={onCreateShippingAddressSuccess}
            />
          </FullScreenModal>
          <FullScreenModal
            title={`Create Billing Address for ${activeCustomer.name}`}
            open={addBillingAddressModalOpen}
            onClose={handleCloseAddBillingAddressModal}
          >
            <AddBillingAddressForm
              customerId={activeCustomer.id}
              onSuccess={onCreateBillingAddressSuccess}
            />
          </FullScreenModal>
        </>
      )}
    </>
  );
}
