import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Fragment, ReactElement } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import useAuth from '../../../lib/api/auth/useAuth';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Tooltip } from '@mui/material';

export interface NavigationItem {
  key: string;
  displayText: string;
  icon: ReactElement<any, any>;
  active: boolean;
}

export interface NavigationItemExpandable {
  key: string;
  displayText: string;
  icon: ReactElement<any, any>;
  active: boolean;
  open: boolean;
  children: NavigationItem[];
}

interface SidebarNavProps {
  menuOpen: boolean;
  drawerWidth: number;
  pages: Array<NavigationItem | NavigationItemExpandable>;
  onMenuChange: () => any;
  onMenuNavigation: (pageKey: string) => any;
  onMenuItemExpand: (page: NavigationItemExpandable) => any;
}

export default function AdminSidebarNav({
  menuOpen,
  drawerWidth,
  pages,
  onMenuChange,
  onMenuNavigation,
  onMenuItemExpand,
}: SidebarNavProps) {
  const { logout } = useAuth();

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(10)} + 1px)`,
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.appBar - 1,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }));

  const ListItemButtonStyled = styled(MuiListItemButton, {
    // shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth',
  })(({ theme }) => ({
    minHeight: 48,
    justifyContent: menuOpen ? 'initial' : 'center',
    px: 2.5,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      '& svg': {
        fill: theme.palette.primary.contrastText,
      },
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '& svg': {
        fill: theme.palette.primary.contrastText,
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      '& svg': {
        fill: theme.palette.primary.contrastText,
      },
    },
  }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const isExpandable = (page: NavigationItem | NavigationItemExpandable) => {
    return Object.hasOwn(page, 'open');
  };

  return (
    <Drawer variant="permanent" open={menuOpen}>
      <DrawerHeader>
        <IconButton onClick={onMenuChange}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {pages.map((page) => {
          if (isExpandable(page)) {
            return (
              <Fragment key={page.key}>
                <Tooltip
                  key={page.key}
                  title={page.displayText}
                  placement="right"
                  arrow
                  componentsProps={{ popper: { hidden: menuOpen } }}
                >
                  <ListItemButtonStyled
                    // key={page.key}
                    selected={page.active}
                    onClick={() =>
                      onMenuItemExpand(page as NavigationItemExpandable)
                    }
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: menuOpen ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {page.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={page.displayText}
                      sx={{ opacity: menuOpen ? 1 : 0 }}
                    />

                    {(page as NavigationItemExpandable).open ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButtonStyled>
                </Tooltip>
                <Collapse
                  in={(page as NavigationItemExpandable).open}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {(page as NavigationItemExpandable).children.map(
                      (nestedPage) => (
                        <Tooltip
                          key={nestedPage.key}
                          title={nestedPage.displayText}
                          placement="right"
                          arrow
                          componentsProps={{ popper: { hidden: menuOpen } }}
                        >
                          <ListItemButtonStyled
                            selected={nestedPage.active}
                            onClick={() => onMenuNavigation(nestedPage.key)}
                            sx={{ pl: 4 }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: menuOpen ? 3 : 'auto',
                                justifyContent: 'center',
                              }}
                            >
                              {nestedPage.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={nestedPage.displayText}
                              sx={{ opacity: menuOpen ? 1 : 0 }}
                            />
                          </ListItemButtonStyled>
                        </Tooltip>
                      ),
                    )}
                  </List>
                </Collapse>
              </Fragment>
            );
          } else {
            return (
              <Tooltip
                key={page.key}
                title={page.displayText}
                placement="right"
                arrow
                componentsProps={{ popper: { hidden: menuOpen } }}
              >
                <ListItemButtonStyled
                  selected={page.active}
                  onClick={() => onMenuNavigation(page.key)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: menuOpen ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {page.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={page.displayText}
                    sx={{ opacity: menuOpen ? 1 : 0 }}
                  />
                </ListItemButtonStyled>
              </Tooltip>
            );
          }
        })}
        <Tooltip
          title="logout"
          placement="right"
          arrow
          componentsProps={{ popper: { hidden: menuOpen } }}
        >
          <ListItemButtonStyled selected={false} onClick={logout}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: menuOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" sx={{ opacity: menuOpen ? 1 : 0 }} />
          </ListItemButtonStyled>
        </Tooltip>
      </List>
    </Drawer>
  );
}
