import { useCallback, useState } from 'react';

export default function useTabs() {
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
    },
    [],
  );

  return {
    tabValue,
    handleChangeTab,
  };
}
