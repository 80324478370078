import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useAppContext } from '../../../contexts/AppContext';
import { UpdateOrderStatusOrFlagsDto } from '../../../lib/api/orders/dto/update-order-status-or-flags.dto';
import { ViewOrderDto } from '../../../lib/api/orders/dto/view-order.dto';
import { OrderStatus } from '../../../lib/api/orders/enums/order-status.enum';
import {
  getAllOrders,
  OrderQueryKeys,
  updateOrderFlags,
} from '../../../lib/api/orders/orders.service';
import { getErrorMessage } from '../../../lib/util/misc-utils';
import {
  getRowsPerPageDefault,
  TableState,
  TableStateFilters,
} from '../../../lib/util/table-util';

interface HookProps {
  filters: TableStateFilters<ViewOrderDto>;
}

export default function useOrdersFulfillmentTable({ filters }: HookProps) {
  const [tableState, setTableState] = useState<TableState<ViewOrderDto>>({
    pagination: { current: 1, pageSize: getRowsPerPageDefault() },
    filters,
    sorter: null,
  });

  const [tableStateDebounced] = useDebounce(tableState, 500);

  const {
    isError: ordersIsError,
    data: ordersRes,
    isFetching: ordersIsFetching,
  } = useQuery(
    [OrderQueryKeys.findAllOrders, tableStateDebounced],
    () => getAllOrders(tableStateDebounced),
    {
      retry: false,
    },
  );

  const { handleShowMessage } = useAppContext();
  const queryClient = useQueryClient();

  interface UpdateOrderMutationProps {
    id: string;
    updateObj: UpdateOrderStatusOrFlagsDto;
  }
  const updateOrderMutation = useMutation((props: UpdateOrderMutationProps) =>
    updateOrderFlags(props.id, props.updateObj),
  );

  const handleChangeStatus = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    orderId: string,
    newStatus: OrderStatus,
  ) => {
    e.stopPropagation();
    try {
      const mutateObj = {
        id: orderId,
        updateObj: {
          status: newStatus,
        },
      };

      await updateOrderMutation.mutateAsync(mutateObj);

      queryClient.invalidateQueries([OrderQueryKeys.findAllOrders]);
      handleShowMessage(`Order moved to ${newStatus}.`, 'success');
    } catch (err: any) {
      handleShowMessage(
        `Order could not be moved to ${newStatus}. ${getErrorMessage(err)}`,
        'error',
      );
    }
  };

  return {
    setTableState,
    tableState,
    isFetching: ordersIsFetching,
    orders: ordersRes,
    handleChangeStatus,
  };
}
