import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useCartContext } from '../../contexts/CartContext';
import { ViewCategoryDto } from '../../lib/api/categories/dto/view-category.dto';
import CartListTableHeading from './CartListTableHeading';
import CartRowCategory from './CartRowCategory';
import Empty from './../ui/custom-icons/Empty';
import { usd } from '../../lib/util/misc-utils';

export default function CartTable() {
  const { cart, handleRemoveItemFromCart, handleRemoveAllItemsFromCart } =
    useCartContext();

  if (!cart) return null;

  const categories = cart.items.map((item) => item.product.category);
  const uniqueCategories: ViewCategoryDto[] = [];
  categories.forEach((category) => {
    const indexInUnique = uniqueCategories.findIndex(
      (uniqueCategory) => uniqueCategory.id === category.id,
    );
    if (indexInUnique === -1) uniqueCategories.push(category);
  });

  uniqueCategories.sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <TableContainer sx={{ maxWidth: '90%' }}>
      <Table sx={{ pr: 2 }} size="small" aria-label="summary of items in cart">
        <CartListTableHeading />
        <TableBody>
          {cart.items.length === 0 && (
            <TableRow
              style={{
                height: 33 * 10,
              }}
            >
              <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                <Empty />
              </TableCell>
            </TableRow>
          )}
          {uniqueCategories.map((category) => (
            <CartRowCategory
              key={category.id}
              category={category}
              items={cart.items.filter(
                (item) => item.product.category.id === category.id,
              )}
            />
          ))}
          {cart.productTotalBeforeDiscounts && (
            <TableRow sx={{ 'td, th': { border: 0, fontWeight: 700 } }}>
              <TableCell colSpan={6} align="right">
                Product Total
              </TableCell>
              <TableCell align="right">
                {usd(cart.productTotalBeforeDiscounts)}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
