import { Button } from '@mui/material';
import { useAppContext } from '../../contexts/AppContext';

export default function MySettingsPage() {
  const { handleChangeTheme } = useAppContext();
  return (
    <>
      <h2>Preferences</h2>
      <ul>
        <li>
          Theme{' '}
          <Button variant="contained" onClick={handleChangeTheme}>
            Change
          </Button>
        </li>
        <li>Rows Per Page</li>
      </ul>
    </>
  );
}
