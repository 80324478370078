import { apiClientFactory, generalClientFactory } from '../../common/clients';
import { getHeaders } from '../../util/http-utils';
import { RequestImageUploadUrl } from './dto/request-image-upload-url.dto';

const apiClient = apiClientFactory();
const generalClient = generalClientFactory();

/****************************
Images
****************************/

const resource = 'images';

export enum ImageQueryKeys {
  'findImageById' = 'FindImageById',
  'findAllImages' = 'FindAllImages',
  'findAllProductImages' = 'FindAllProductImages',
  'findAllVariantImages' = 'FindAllVariantImages',
}

export async function findImage(
  type: 'product' | 'variant',
  productOrVariantId: string,
  size: 'xs' | 's' | 'm' | 'l' = 'm',
  fileNameKey?: string,
): Promise<{ fileNameKey: string; url: string }> {
  const { data } = await apiClient.get(`v1/${resource}/`, {
    headers: getHeaders(),
    params: {
      type,
      productOrVariantId,
      fileNameKey,
      size,
    },
  });

  return data;
}

export async function requestImageUploadPresignedUrl(
  requestImageUploadUrlDto: RequestImageUploadUrl,
): Promise<any> {
  const { data } = await apiClient.post(
    `v1/${resource}/request-upload-url`,
    requestImageUploadUrlDto,
    {
      headers: getHeaders(),
    },
  );

  return data;
}

export async function uploadImageToS3(
  url: string,
  fields: Record<string, string>,
  file: File,
) {
  // 1. Build our form data object.
  const formData = new FormData();

  // Add the AWS Fields
  Object.entries(fields).forEach(([field, value]) => {
    formData.append(field, value);
  });

  formData.append('Content-Type', file.type);
  formData.append('file', file);

  console.log('Content-Type', file.type);

  //2. Post to S3
  const res = await generalClient.post(url, formData);
  return res.data;
}
