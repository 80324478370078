import { Grid } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useEffect } from 'react';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { useAppContext } from '../../contexts/AppContext';
import FromSubmitButton from '../ui/forms/FormSubmitButton';
import { CustomerQueryKeys } from '../../lib/api/customers/customers.service';
import useFormSubmitErrorAlert from '../ui/forms/useFormSubmitErrorAlert';
import CustomForm, { onSuccessCallback } from '../ui/forms/CustomForm';
import { CreateOrUpdateContactDto } from '../../lib/api/contacts/dto/create-or-update-contact.dto';
import RhfTextField from '../ui/forms/RhfTextField';
import { createAddressContact } from '../../lib/api/addresses/addresses.service';
import { ViewContactDto } from '../../lib/api/contacts/dto/view-contact.dto';
import RhfPhoneInput from '../ui/forms/RhfPhoneInput';
import { updateContact } from '../../lib/api/contacts/contacts.service';
import { VALIDATION_ERR_DELAY } from '../../config/AppConstants';

interface SharedProps {
  onSuccess?: onSuccessCallback;
}

interface AddProps extends SharedProps {
  addressId: string;
}

interface UpdateProps extends SharedProps {
  contact: ViewContactDto;
}

export default function AddOrUpdateContactForm({
  onSuccess,
  ...rest
}: AddProps | UpdateProps) {
  const { handleShowMessage } = useAppContext();
  const queryClient = useQueryClient();

  const contact = (rest as any).contact || undefined;
  const addressId = (rest as any).addressId || undefined;

  const action = contact ? 'Udpate Address Contact' : 'Create Address Contact';

  const defaultValues: CreateOrUpdateContactDto = contact
    ? plainToInstance(CreateOrUpdateContactDto, instanceToPlain(contact), {
        excludeExtraneousValues: true,
      })
    : {
        email: null,
        firstName: null,
        lastName: null,
        mobilePhone: null,
        officePhone: null,
        fax: null,
      };

  //Mutations
  const createMutation = useMutation(
    (createContactDto: CreateOrUpdateContactDto) =>
      createAddressContact(addressId, createContactDto),
  );

  const updateMutation = useMutation(
    (updateContactDto: CreateOrUpdateContactDto) =>
      updateContact(contact.id, updateContactDto),
  );

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isSubmitted },
    setError,
    setFocus,
    control,
  } = useForm<CreateOrUpdateContactDto>({
    resolver: classValidatorResolver(CreateOrUpdateContactDto),
    defaultValues,
    mode: 'onTouched',
    delayError: VALIDATION_ERR_DELAY,
  });

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<CreateOrUpdateContactDto>();

  const onSubmit: SubmitHandler<CreateOrUpdateContactDto> = async (
    formData: CreateOrUpdateContactDto,
  ) => {
    clearFormSubmitError();

    try {
      let result: ViewContactDto;

      if (contact) {
        result = await updateMutation.mutateAsync(formData);
      } else {
        result = await createMutation.mutateAsync(formData);
      }

      queryClient.invalidateQueries([CustomerQueryKeys.findCustomerById]);
      handleShowMessage(
        `${contact ? 'Updated' : 'Created'} Contact '${result.firstName} ${
          result.lastName
        }'`,
      );
      onSuccess?.(result);
    } catch (err: any) {
      processFormSubmitError(err, setError);
    }
  };

  return (
    <CustomForm
      serverErrorMessage={serverErrorMessage}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RhfTextField control={control} name="email" label="Email" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RhfTextField control={control} name="firstName" label="First Name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RhfTextField control={control} name="lastName" label="Last Name" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RhfPhoneInput
            name="mobilePhone"
            label="Mobile Phone"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RhfPhoneInput
            name="officePhone"
            label="Office Phone"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RhfPhoneInput name="fax" label="Fax" control={control} />
        </Grid>

        <Grid item xs={12}>
          <FromSubmitButton
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            isValid={isValid}
            text={action}
          />
        </Grid>
      </Grid>
    </CustomForm>
  );
}
