import { plainToInstance } from 'class-transformer';
import { buildFindAllQueryParams, getHeaders } from '../../util/http-utils';
import { TableState } from '../../util/table-util';
import { apiClientFactory } from '../../common/clients';
import { ViewManyDto } from '../../common/dto/view-many.dto';
import { CreateUserDto } from './dto/create-user.dto';
import { UpdateUserDto } from './dto/update-user.dto';
import { ViewUserDto } from './dto/view-user.dto';

const apiClient = apiClientFactory();

/****************************
Users
****************************/

const resource = 'users';

export enum UserQueryKeys {
  'findUserById' = 'FindUserById',
  'findAllUsers' = 'FindAllUsers',
  'me' = 'Me',
}

export async function createAdminUser(
  createUserDto: CreateUserDto,
): Promise<ViewUserDto> {
  const { data } = await apiClient.post(`v1/${resource}`, createUserDto, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewUserDto, data, { excludeExtraneousValues: true });
}

export async function getAllUsers(
  tableState: TableState<ViewUserDto>,
): Promise<ViewManyDto<ViewUserDto>> {
  const queryParams = buildFindAllQueryParams<ViewUserDto>(tableState);

  let requestUrl = `v1/${resource}`;
  if (queryParams) requestUrl = `${requestUrl}?${queryParams}`;

  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });

  return {
    ...data,
    data: plainToInstance(ViewUserDto, data.data, {
      excludeExtraneousValues: true,
    }),
  };
}

export async function getMe(): Promise<ViewUserDto> {
  const { data } = await apiClient.get(`v1/${resource}/me`, {
    headers: getHeaders(),
  });
  //@TODO::update to a ViewMeDto
  return plainToInstance(ViewUserDto, data, { excludeExtraneousValues: true });
}

export async function getUserById(userId: string): Promise<ViewUserDto> {
  const { data } = await apiClient.get(`v1/${resource}/${userId}`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewUserDto, data, { excludeExtraneousValues: true });
}

export async function deleteUser(userId: string): Promise<ViewUserDto> {
  const { data } = await apiClient.delete(`v1/${resource}/${userId}`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewUserDto, data, { excludeExtraneousValues: true });
}

export async function updateUser(
  id: string,
  updateUserDto: UpdateUserDto,
): Promise<ViewUserDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${id}`,
    updateUserDto,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewUserDto, data, { excludeExtraneousValues: true });
}
