import { Expose, Type } from 'class-transformer';
import {
  ArrayNotEmpty,
  ArrayUnique,
  IsArray,
  IsOptional,
  IsString,
  MaxLength,
  MinLength,
  ValidateNested,
} from 'class-validator';
import CustomIsNotEmpty from '../../../common/custom-class-validators/CustomIsNotEmpty';
import CustomLength from '../../../common/custom-class-validators/CustomLength';
import { CreateChildProductOptionForNewProductDto } from './create-child-product-option-for-new-product.dto';

export class CreateProductOptionForNewProductDto {
  @Expose()
  @IsString()
  @CustomLength(3, 25, 'Option Name')
  @CustomIsNotEmpty('Option Name')
  name: string;

  @Expose()
  @IsArray()
  @ArrayNotEmpty({ message: 'Option must have at least one value.' })
  @ArrayUnique({ message: 'Each option value must be unique.' })
  @MinLength(1, { each: true })
  @MaxLength(100, { each: true })
  values: string[];

  @Expose()
  @ValidateNested({ each: true })
  @Type(() => CreateChildProductOptionForNewProductDto)
  @IsOptional()
  children?: CreateChildProductOptionForNewProductDto[];
}
