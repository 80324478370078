import { useState } from 'react';

export default function useConfirmDialog() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return {
    confirmDialogIsOpen: isOpen,
    handleCloseConfirmDialog: handleClose,
    handleOpenConfirmDialog: handleOpen,
  };
}
