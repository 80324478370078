import { Avatar, Box, Grid, TextField, Typography, Link } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import useAuth from '../../lib/api/auth/useAuth';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useState } from 'react';
import { ResetPasswordRequestDto } from '../../lib/api/auth/dto/reset-password-request.dto';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FromSubmitButton from '../ui/forms/FormSubmitButton';
import useFormSubmitErrorAlert from '../ui/forms/useFormSubmitErrorAlert';
import { VALIDATION_ERR_DELAY } from '../../config/AppConstants';
import CustomForm from '../ui/forms/CustomForm';

export default function ResetPasswordRequestForm() {
  const { requestPasswordReset } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isSubmitted },
    setError,
  } = useForm<ResetPasswordRequestDto>({
    resolver: classValidatorResolver(ResetPasswordRequestDto),
    mode: 'onTouched',
    delayError: VALIDATION_ERR_DELAY,
    defaultValues: {
      email: null,
    },
  });

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<ResetPasswordRequestDto>();
  const [emailRequested, setEmailRequested] = useState<boolean>(false);

  const onSubmit: SubmitHandler<ResetPasswordRequestDto> = async (
    passwordResetRequestDto: ResetPasswordRequestDto,
  ) => {
    clearFormSubmitError();
    try {
      await requestPasswordReset(passwordResetRequestDto);
      setEmailRequested(true);
    } catch (err: any) {
      processFormSubmitError(err, setError);
    }
  };

  return (
    <CustomForm
      serverErrorMessage={serverErrorMessage}
      handleSubmit={handleSubmit(onSubmit)}
      formContainerSx={{ maxWidth: '500px' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        gap={3}
      >
        {emailRequested && 'Password Reset Email has been requested.'}
        {!emailRequested && (
          <>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h3" variant="h5">
              Request Password Reset
            </Typography>
          </>
        )}
      </Box>

      {!emailRequested && (
        <Box>
          <TextField
            {...register('email')}
            helperText={errors.email && errors.email.message}
            error={errors.email ? true : false}
            label="Email Address"
            autoComplete="email"
            required
            autoFocus
            margin="normal"
            fullWidth
          />
          <FromSubmitButton
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            isValid={isValid}
            text="Send Reset Email"
            sx={{ mt: 3, mb: 2 }}
          />
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to="/auth/login" variant="body2">
                Know you password, Login.
              </Link>
            </Grid>
          </Grid>
        </Box>
      )}
    </CustomForm>
  );
}
