import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { onSuccessCallback } from '../../ui/forms/CustomForm';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../contexts/AppContext';
import useFormSubmitErrorAlert from '../../ui/forms/useFormSubmitErrorAlert';
import { ViewOrderExtendedDto } from '../../../lib/api/orders/dto/view-order-extended.dto';
import { UpdateOrderShipToDto } from '../../../lib/api/orders/dto/update-order-shipto-address.dto';
import {
  OrderQueryKeys,
  updateOrderShipTo,
} from '../../../lib/api/orders/orders.service';
import { OrderType } from '../../../lib/api/orders/dto/create-order.dto';
import MultiStepForm from '../../ui/forms/MultiStepForm';
import MultiStepFormPage from '../../ui/forms/MultiStepFormPage';
import { StepperData } from '../../ui/forms/CustomStepper';
import useMultiStepForm from '../../ui/forms/useMultiStepForm';
import OrderTypePartial from '../../checkout-form/partials/OrderTypePartial';
import ShippingAddressPartial from '../../checkout-form/partials/ShippingAddressPartial';
import useModal from '../../ui/modal/useModal';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import AddShippingAddressForm from '../../addresses/shipping-address/AddShippingAddressForm';
import ShippingAddressContactPartial from '../../checkout-form/partials/ShippingAddressContactPartial';
import {
  CustomerQueryKeys,
  getCustomerById,
} from '../../../lib/api/customers/customers.service';
import UpdateOrderShipToAddressReviewAndSubmitPartial from './UpdateOrderShipToAddressReviewAndSubmitPartial';
import { plainToInstance } from 'class-transformer';

interface Props {
  order: ViewOrderExtendedDto;
  onSuccess?: onSuccessCallback;
}

export default function UpdateOrderShipToAddressForm({
  order,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();
  const queryClient = useQueryClient();

  const {
    isError,
    data: customer,
    error,
    isFetching,
  } = useQuery(
    [CustomerQueryKeys.findCustomerById, { id: order.customerId }],
    () => getCustomerById(order.customerId),
  );

  //Mutations
  const updateMutation = useMutation(
    (updateOrderShipToDto: UpdateOrderShipToDto) =>
      updateOrderShipTo(order.id, updateOrderShipToDto),
  );

  const [steps, setSteps] = useState<StepperData<UpdateOrderShipToDto>[]>([
    {
      text: 'Delivery Method',
      isActive: true,
      fieldsInStep: [],
      fieldsToValidateOnNext: [],
    },
  ]);

  useEffect(() => {
    // Steps can change based on user's selected order
    // type, when that happens, this will set the active
    // step to the 1st page that is not the order type
    // (actually the 2nd in the list of pages)
    const activeStep = steps.find((s) => s.isActive);
    if (!activeStep) form.setActivePage(1);
  }, [steps]);

  const deliverySteps: StepperData<UpdateOrderShipToDto>[] = [
    {
      text: 'Delivery Method',
      fieldsInStep: [],
      fieldsToValidateOnNext: [],
    },
    {
      text: 'Shipping Addresss',
      fieldsInStep: ['shippingAddressId'],
      focusField: 'shippingAddressId',
      fieldsToValidateOnNext: ['shippingAddressId'],
    },
    {
      text: 'Shipping Contact',
      fieldsInStep: ['shippingContactId'],
      focusField: 'shippingContactId',
      fieldsToValidateOnNext: ['shippingContactId'],
    },
    {
      text: 'Change Summary',
      fieldsInStep: [],
    },
  ];

  const pickupSteps: StepperData<UpdateOrderShipToDto>[] = [
    {
      text: 'Delivery Method',
      fieldsInStep: [],
      fieldsToValidateOnNext: [],
    },
    {
      text: 'Change Summary',
      fieldsInStep: [],
    },
  ];

  const form = useMultiStepForm<UpdateOrderShipToDto>({
    resolver: classValidatorResolver(UpdateOrderShipToDto, {
      forbidUnknownValues: true,
    }),
    defaultValues: {
      type: order.type,
      shippingAddressId: null,
      shippingContactId: null,
    },
    steps,
    setSteps,
  });

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<UpdateOrderShipToDto>();

  const onSubmit: SubmitHandler<UpdateOrderShipToDto> = async (
    formData: UpdateOrderShipToDto,
  ) => {
    clearFormSubmitError();

    const updateDto = plainToInstance(UpdateOrderShipToDto, formData, {
      excludeExtraneousValues: true,
      exposeDefaultValues: false,
      exposeUnsetFields: false,
    });

    try {
      let result: ViewOrderExtendedDto;

      //Updating
      result = await updateMutation.mutateAsync(updateDto);
      queryClient.invalidateQueries([
        OrderQueryKeys.findOrderById,
        { id: order.id },
      ]);

      queryClient.invalidateQueries([OrderQueryKeys.findAllOrders]);
      handleShowMessage('Order Delivery Method Updated');
      onSuccess?.(result);
    } catch (err: any) {
      processFormSubmitError(err, form.setError);
    }
  };

  const handleContactClick = (type: 'shipping', contactId: string) => {
    form.setValue('shippingContactId', contactId);
    form.handleNextClick();
  };

  const handleOrderTypeClick = (type: OrderType) => {
    form.setValue('type', type);
    form.setValue('shippingAddressId', null);
    form.setValue('shippingContactId', null);

    const isPickup =
      type === OrderType.PickupGreenback || type === OrderType.PickupMaryville;

    if (isPickup) {
      setSteps(pickupSteps);
    } else {
      setSteps(deliverySteps);
    }
  };

  const getStepByName = (name: string) => {
    return steps.find((s) => s.text === name);
  };

  const shippingAddressStep = getStepByName('Shipping Addresss');
  const shippingContactStep = getStepByName('Shipping Contact');
  const changeSummaryStep = getStepByName('Change Summary');

  const {
    handleCloseModal: handleCloseAddShippingAddressModal,
    handleLaunchModal: handleLaunchAddShippingAddressModal,
    modalOpen: addShippingAddressModalOpen,
  } = useModal();

  const onCreateShippingAddressSuccess = (data: any) => {
    form.setValue('shippingAddressId', data.id);
    form.trigger('shippingAddressId');
    handleCloseAddShippingAddressModal();
  };

  return (
    <>
      <FormProvider {...form}>
        <MultiStepForm
          handleSubmit={form.handleSubmit(onSubmit)}
          serverErrorMessage={serverErrorMessage}
          steps={steps}
        >
          <MultiStepFormPage step={steps[0]} errors={form.formState.errors}>
            <OrderTypePartial
              onClick={handleOrderTypeClick}
              items={order.items}
              hidePickupAtGreenback={order.type === OrderType.PickupGreenback}
              hidePickupAtMaryville={order.type === OrderType.PickupMaryville}
            />
          </MultiStepFormPage>

          <MultiStepFormPage
            step={shippingAddressStep}
            errors={form.formState.errors}
            onBack={form.handleBackClick}
            onNext={form.handleNextClick}
          >
            <ShippingAddressPartial
              customer={customer}
              onClickAddAddress={handleLaunchAddShippingAddressModal}
            />
          </MultiStepFormPage>

          <MultiStepFormPage
            step={shippingContactStep}
            errors={form.formState.errors}
            onBack={form.handleBackClick}
          >
            <ShippingAddressContactPartial
              customer={customer}
              onClick={handleContactClick}
            />
          </MultiStepFormPage>
          <MultiStepFormPage
            step={changeSummaryStep}
            errors={form.formState.errors}
          >
            <UpdateOrderShipToAddressReviewAndSubmitPartial
              order={order}
              customer={customer}
            />
          </MultiStepFormPage>
        </MultiStepForm>
      </FormProvider>
      <FullScreenModal
        title={`Create Shipping Address for ${order.customer.name}`}
        open={addShippingAddressModalOpen}
        onClose={handleCloseAddShippingAddressModal}
      >
        <AddShippingAddressForm
          customerId={order.customer.id}
          onSuccess={onCreateShippingAddressSuccess}
        />
      </FullScreenModal>
    </>
  );
}
