import { Alert, Box, Button, Typography } from '@mui/material';
import { ViewStoreStatusDto } from '../../../lib/api/store/dto/view-store-status.dto';
import { downloadStoreAvailability } from '../../../lib/common/report-download.service';

interface Props {
  storeStatus: ViewStoreStatusDto;
}

export default function StoreClosed({ storeStatus }: Props) {
  return (
    <>
      <Typography variant="h3" component="h1">
        {`Closed until ${new Date(storeStatus.nextWeek.openAt).toLocaleString(
          'en-US',
          {
            weekday: 'long',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
          },
        )}`}
      </Typography>
      <Alert severity="info" variant="filled" sx={{ mb: 2 }}>
        {`Shopping for this week (${
          storeStatus.currentWeek.orderWeek
        }) ended on ${new Date(storeStatus.currentWeek.closeAt).toLocaleString(
          'en-US',
          {
            weekday: 'long',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
          },
        )}.`}

        <p>
          {`Window to place orders for next week starts ${new Date(
            storeStatus.nextWeek.openAt,
          ).toLocaleString('en-US', {
            weekday: 'long',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
          })} and goes until ${new Date(
            storeStatus.nextWeek.closeAt,
          ).toLocaleString('en-US', {
            weekday: 'long',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
          })}.
      Orders palced during this window will be shipped between ${new Date(
        storeStatus.nextWeek.shippingStartAt,
      ).toLocaleString('en-US', {
        weekday: 'long',
        month: 'short',
        day: 'numeric',
      })} and
      ${new Date(storeStatus.nextWeek.shippingEndAt).toLocaleString('en-US', {
        weekday: 'long',
        month: 'short',
        day: 'numeric',
      })}.`}
        </p>
      </Alert>
      <Box>
        <Button
          variant="outlined"
          sx={{ mt: 2, mb: 2 }}
          onClick={downloadStoreAvailability}
        >
          Download Current Availability
        </Button>
      </Box>
    </>
  );
}
