import { plainToInstance } from 'class-transformer';
import { getHeaders } from '../../util/http-utils';
import { apiClientFactory } from '../../common/clients';
import { CreateChildProductOptionValueForExistingOptionAndValueDto } from '../product-option-values/dto/create-child-product-option-value-for-existing-option-and-value.dto';
import { CreateRootProductOptionValueForExistingOptionDto } from '../product-option-values/dto/create-root-product-option-value-for-existing-option.dto';
import { ViewProductOptionValueDto } from '../product-option-values/dto/view-product-option-value.dto';

const apiClient = apiClientFactory();

const resource = 'product-options';

/****************************
Product Options
****************************/

/****************************
Product Option Values
****************************/

// export enum ProductOptionQueryKeys {
//   'findProductById' = 'FindProductById',
//   'findAllProducts' = 'FindAllProducts',
//   'findProductVariants' = 'FindProductVariants',
// }

export async function createProductOptionValue(
  productOptionId: number,
  createProductOptionValueDto:
    | CreateRootProductOptionValueForExistingOptionDto
    | CreateChildProductOptionValueForExistingOptionAndValueDto,
): Promise<ViewProductOptionValueDto> {
  const { data } = await apiClient.post(
    `v1/${resource}/${productOptionId}/values`,
    createProductOptionValueDto,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewProductOptionValueDto, data, {
    excludeExtraneousValues: true,
  });
}
