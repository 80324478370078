import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { ViewOrderExtendedDto } from '../../../../lib/api/orders/dto/view-order-extended.dto';
import { usd } from '../../../../lib/util/misc-utils';
import LineItemsTableCategoryRow from './LineItemsTableCategoryRow';

interface Props {
  order: ViewOrderExtendedDto;
}

export default function LineItemsTable({ order }: Props) {
  const categories = order.items.map((item) => item.categoryName);

  const uniqueCategories: string[] = [];
  categories.forEach((category) => {
    const indexInUnique = uniqueCategories.findIndex(
      (uniqueCategory) => uniqueCategory === category,
    );
    if (indexInUnique === -1) uniqueCategories.push(category);
  });

  uniqueCategories.sort((a, b) => (a > b ? 1 : -1));

  return (
    <Paper sx={{ width: `calc(100% - 20px)` }}>
      <TableContainer sx={{ maxWidth: '100%', overflow: 'auto' }}>
        <Table stickyHeader size="small">
          <TableHeading order={order} />
          <TableBody>
            {uniqueCategories.map((categoryName) => (
              <LineItemsTableCategoryRow
                key={categoryName}
                categoryName={categoryName}
                order={order}
                items={order.items.filter(
                  (i) => i.categoryName === categoryName,
                )}
              />
            ))}
          </TableBody>
          <TableFooting order={order} />
        </Table>
      </TableContainer>
    </Paper>
  );
}

function TableHeading({ order }: Props) {
  return (
    <TableHead>
      <TableRow
        sx={{
          th: {
            fontWeight: 700,
            borderTop: '1px solid',
            borderBottom: '1px solid',
            backgroundColor: 'grey.300',
          },
        }}
      >
        <TableCell>Category/Product</TableCell>
        <TableCell align="center">Item #</TableCell>
        <TableCell align="right">Qty</TableCell>
        <TableCell align="right">Unit Price</TableCell>
        <TableCell align="right">Extended Price</TableCell>
        {order?.customerDiscountRate?.greaterThan(0) && (
          <>
            <TableCell align="right">{`Discount (${order.customerDiscountRate.toString()}%)`}</TableCell>
            <TableCell align="right">Price After Discount</TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
}

function TableFooting({ order }: Props) {
  const hasDiscount = order.customerDiscountTotal.greaterThan(0);
  return (
    <TableFooter
      sx={{
        td: {
          fontSize: '1rem',
          fontWeight: '600',
          color: 'text.primary',
          border: '0px none',
        },
        'tr:first-of-type td': { borderTop: '1px solid ' },
        'tr:last-child td': {
          fontSize: '1.05rem',
          fontWeight: 700,
          borderTop: '1px solid grey.400',
        },
      }}
    >
      <TableRow>
        <TableCell colSpan={hasDiscount ? 4 : 4} align="right">
          Sub Totals
        </TableCell>
        <TableCell align="right">
          {usd(order.productTotalBeforeDiscounts)}
        </TableCell>
        {order?.customerDiscountTotal.greaterThan(0) && (
          <>
            <TableCell align="right">
              {usd(order.customerDiscountTotal.neg())}
            </TableCell>
            <TableCell align="right">
              {usd(order.orderTotal.minus(order.fuelSurchargeTotal))}
            </TableCell>
          </>
        )}
      </TableRow>
      {order.fuelSurchargeTotal.greaterThan(0) && (
        <TableRow>
          <TableCell colSpan={hasDiscount ? 6 : 4} align="right">
            Fuel Surcharge
          </TableCell>
          <TableCell align="right">{usd(order.fuelSurchargeTotal)}</TableCell>
        </TableRow>
      )}
      <TableRow
        sx={{
          td: {
            fontWeight: 700,
            fontSize: '1.05rem',
          },
        }}
      >
        <TableCell colSpan={hasDiscount ? 6 : 4} align="right">
          Order Total
        </TableCell>
        <TableCell align="right">{usd(order.orderTotal)}</TableCell>
      </TableRow>
    </TableFooter>
  );
}
