import { Box, styled } from '@mui/material';
import { ViewContactDto } from '../../../lib/api/contacts/dto/view-contact.dto';
import ContactCard from '../../contacts/ContactCard';

interface Props {
  contacts: ViewContactDto[];
  onClick: (contact: ViewContactDto) => void;
}

export const ContactOption = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  borderRadius: `1rem`,
  padding: `0.6rem`,
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.grey[100]
      : theme.palette.grey[900],
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.grey[900]
      : theme.palette.grey[200],
  ':hover': {
    cursor: 'pointer',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[100]
        : theme.palette.grey[900],
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.common.black
        : theme.palette.grey[300],
  },
}));

export default function ContactSelectionList({ contacts, onClick }: Props) {
  return (
    <Box
      display="flex"
      gap={1}
      justifyContent="flex-start"
      alignItems="stretch"
      alignContent="flex-start"
      flexWrap="wrap"
      sx={{ m: 2 }}
    >
      {contacts.map((c, index) => (
        <ContactOption key={c.id} onClick={() => onClick(c)} tabIndex={index}>
          <ContactCard contact={c} />
        </ContactOption>
      ))}
    </Box>
  );
}
