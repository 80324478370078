import { Grid } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import BadgeIcon from '@mui/icons-material/Badge';
import AddressContent, { AddressFragment } from '../addresses/AddressContent';
import ContactContent, { ContactFragment } from '../contacts/ContactContent';
import AddressTitle from '../addresses/AddressTitle';

interface Props {
  address: AddressFragment;
  billingCompanyName?: string;
  contact?: ContactFragment;
}

export default function SelectedAddressCard({
  address,
  contact,
  billingCompanyName,
}: Props) {
  return (
    <Grid container spacing={1}>
      <AddressTitle address={address} customerName={billingCompanyName} />
      <Grid item xs={2} sm={1}>
        <MapIcon sx={{ color: 'info.main' }} />
      </Grid>
      <Grid item xs={10} sm={11}>
        <AddressContent address={address} />
      </Grid>
      <Grid item xs={2} sm={1}>
        <BadgeIcon sx={{ color: 'info.main' }} />
      </Grid>
      <Grid item xs={10} sm={11}>
        <ContactContent contact={contact} />
      </Grid>
    </Grid>
  );
}
