import { Context, createContext, PropsWithChildren, useContext } from 'react';
import { ObjectWithId } from './../../../lib/util/table-util';

export interface DetailsDrawerConfig {
  open: boolean;
  width: number;
}

interface AdminLayoutContextType<ViewDto extends ObjectWithId> {
  handleTableRowClick: (data: ViewDto) => void;
  handleDetailsDrawerClose: () => void;
  setDetailsDrawerConfig: ({ open, width }: DetailsDrawerConfig) => void;
  activeRecord: ViewDto | undefined;
  detailsDrawerConfig: DetailsDrawerConfig;
}

const AdminLayoutContext =
  createContext<AdminLayoutContextType<ObjectWithId> | null>(null);

export const AdminLayoutProvider = <ViewDto extends ObjectWithId>({
  children,
  ...rest
}: PropsWithChildren<AdminLayoutContextType<ViewDto>>) => {
  return (
    //@ts-ignore
    <AdminLayoutContext.Provider value={rest}>
      {children}
    </AdminLayoutContext.Provider>
  );
};

export const useAdminLayoutContext = <ViewDto extends ObjectWithId>() => {
  const context = useContext<AdminLayoutContextType<ViewDto>>(
    AdminLayoutContext as unknown as Context<AdminLayoutContextType<ViewDto>>,
  );
  if (!context)
    throw new Error(
      'useAdminLayoutContext must be used under AdminLayoutProvider',
    );
  return context;
};
