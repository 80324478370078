import { ViewCustomerDto } from '../../lib/api/customers/dto/view-customer.dto';
import { BaseTableProps, TableCol } from '../../lib/util/table-util';
import PaginatedTable from '../ui/tables/paginated-table/PaginatedTable';
import CustomerTypeChip from './CustomerTypeChip';

const columns: TableCol<ViewCustomerDto>[] = [
  {
    id: 'name',
    label: 'Name',
    filterType: 'contains',
    widthPct: 20,
  },
  {
    id: 'type',
    align: 'center',
    label: 'Type',
    sortable: true,
    render: (customer: ViewCustomerDto) => (
      <CustomerTypeChip type={customer.type} />
    ),
    widthPct: 10,
  },
  {
    id: 'discountRate',
    align: 'center',
    label: 'Discount %',
    sortable: true,
    render: (customer: ViewCustomerDto) => customer.discountRate.toString(),
    widthPct: 10,
  },
  {
    id: 'notes',
    label: 'Notes',
    filterType: 'contains',
    widthPct: 50,
  },
];

export default function CustomersTable({
  apiRes,
  isFetching,
  setTableState,
  tableState,
  onRowClick,
}: BaseTableProps<ViewCustomerDto>) {
  return (
    <PaginatedTable<ViewCustomerDto>
      columns={columns}
      apiRes={apiRes}
      isFetching={isFetching}
      setTableState={setTableState}
      tableState={tableState}
      onRowClick={onRowClick}
    />
  );
}
