import { IsNotEmpty, ValidationOptions } from 'class-validator';

export default function CustomIsNotEmpty(
  name: string,
  options?: ValidationOptions,
): PropertyDecorator {
  return IsNotEmpty({
    message: `${name} must be provided`,
    ...options,
  });
}
