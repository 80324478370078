import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@mui/material';
import { TableState } from '../../lib/util/table-util';
import { useDebounce } from 'use-debounce';
import useModal from '../../components/ui/modal/useModal';
import FullScreenModal from '../../components/ui/modal/FullScreenModal';
import { useAdminLayoutContext } from '../../components/layout/context/AdminLayoutContext';
import useAuth from '../../lib/api/auth/useAuth';
import { Navigate } from 'react-router-dom';
import {
  CategoryQueryKeys,
  getAllCategories,
} from '../../lib/api/categories/categories.service';
import CategoriesTable from '../../components/categories/CategoriesTable';
import { ViewCategoryDto } from '../../lib/api/categories/dto/view-category.dto';
import CategoryDrawerContent from '../../components/categories/category-drawer-content/CategoryDrawerContent';
import DetailsDrawer from '../../components/ui/drawer/DetailsDrawer';
import AddOrUpdateCategoryForm from '../../components/categories/AddOrUpdateCategoryForm';

export default function CategoriesPage() {
  const {
    handleTableRowClick,
    handleDetailsDrawerClose,
    detailsDrawerConfig,
    activeRecord,
  } = useAdminLayoutContext<ViewCategoryDto>();

  const { authed } = useAuth();

  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();

  const [tableState, setTableState] = useState<TableState<ViewCategoryDto>>({
    pagination: null,
    filters: null,
    sorter: null,
  });

  const [tableStateDebounced] = useDebounce(tableState, 500);

  const onCreateCategorySuccess = () => {
    handleCloseModal();
  };

  console.log({
    component: 'CategoriesPage',
    message: 'rendered',
  });

  const { isError, data, error, isFetching } = useQuery(
    [CategoryQueryKeys.findAllCategories, tableStateDebounced],
    () => getAllCategories(tableStateDebounced),
  );

  return !authed() ? (
    <Navigate to="/auth/login" />
  ) : (
    <>
      <CategoriesTable
        apiRes={data}
        isFetching={isFetching}
        setTableState={setTableState}
        tableState={tableState}
        onRowClick={handleTableRowClick}
      />
      <Button variant="contained" sx={{ mt: 2 }} onClick={handleLaunchModal}>
        Add Category
      </Button>
      <FullScreenModal
        title="Create Category"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <AddOrUpdateCategoryForm onSuccess={onCreateCategorySuccess} />
      </FullScreenModal>
      {activeRecord && (
        <DetailsDrawer
          open={detailsDrawerConfig.open}
          detailsDrawerWidth={detailsDrawerConfig.width}
          onDrawerClose={handleDetailsDrawerClose}
          title={activeRecord.name}
        >
          <CategoryDrawerContent categoryId={activeRecord.id} />
        </DetailsDrawer>
      )}
    </>
  );
}
