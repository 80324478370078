import { Grid, Typography } from '@mui/material';

export default function Footer() {
  return (
    <Grid item xs={12}>
      <Typography
        variant="body2"
        sx={{
          zIndex: `calc(zIndex.appBar - 1)`,
          fontFamily: "'Contrail One', 'Raleway', sans-serif",
          color: 'text.secondary',
          textAlign: 'center',
          backgroundColor: 'background.paper',
          position: 'fixed',
          bottom: '0',
          padding: '8px 0px',
          width: '100%',
          '& a': {
            color: 'text.secondary',
            textDecoration: 'none',
          },
          '& a:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        &copy; {new Date().getFullYear()} Created by{' '}
        <a href="https://reappdev.com">ReAppDev, Inc.</a>
      </Typography>
    </Grid>
  );
}
