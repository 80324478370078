import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { DtoStringKeysAsPath } from '../../../lib/util/type-utils';

interface Props<Dto> {
  name: DtoStringKeysAsPath<Dto>;
  label: string | [onText: string, offText: string];
  control: Control<any, any>;
}

export default function RhfSwitch<Dto>({ control, label, name }: Props<Dto>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { name, onChange, ref, value } }) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name={name}
                onChange={onChange}
                inputRef={ref}
                checked={value}
              />
            }
            label={
              typeof label === 'object' ? (value ? label[0] : label[1]) : label
            }
          />
        </FormGroup>
      )}
    />
  );
}
