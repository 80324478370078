import { SxProps, Theme } from '@mui/material';
import FormContainer from './FormContainer';
import ServerErrorAlert from './ServerErrorAlert';

export type onSuccessCallback = (successData?: any) => void;

interface Props {
  children: React.ReactNode;
  handleSubmit: any;
  serverErrorMessage: string;
  formContainerSx?: SxProps<Theme>;
}

export default function CustomForm({
  children,
  handleSubmit,
  serverErrorMessage,
  formContainerSx,
}: Props) {
  return (
    <FormContainer formContainerSx={formContainerSx}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <ServerErrorAlert message={serverErrorMessage} />
        {children}
      </form>
    </FormContainer>
  );
}
