import { Expose, Type } from 'class-transformer';
import ViewCartExtendedDto from './view-cart-extended.dto';

export class RefreshCartDto {
  @Type(() => ViewCartExtendedDto)
  @Expose()
  cart: ViewCartExtendedDto;

  @Type(() => RefreshItemInCartErrorDto)
  @Expose()
  errors: RefreshItemInCartErrorDto[];
}

export class RefreshItemInCartErrorDto {
  @Expose()
  cartItemId: number;
  @Expose()
  productId: string;
  @Expose()
  variantId?: string | null;
  @Expose()
  code?: string | null;
  @Expose()
  msg: string;
}
