import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { Navigate } from 'react-router-dom';
import useAuth from '../../lib/api/auth/useAuth';
import {
  CategoryQueryKeys,
  getAllCategories,
} from '../../lib/api/categories/categories.service';
import {
  getStoreStatus,
  StoreQueryKeys,
} from '../../lib/api/store/store.service';
import StoreCategoryRow from '../store/expandable-rows/StoreCategoryRow';
import InventoryCategoryProductList from './InventoryCategoryProductList';

export default function InventoryPage() {
  const { authed } = useAuth();

  const {
    isError: storeStatusIsError,
    data: storeStatusRes,
    error: storeStatusError,
    isFetching: storeStatusIsFetching,
  } = useQuery([StoreQueryKeys.findStatus], () => getStoreStatus());

  const {
    isError: categoriesIsError,
    data: categoriesRes,
    error: categoriesError,
    isFetching: categoriesIsFetching,
  } = useQuery([CategoryQueryKeys.findAllCategories], () => getAllCategories());

  if (!authed()) <Navigate to="/auth/login" />;

  return (
    <>
      {categoriesRes?.data?.map((category) => (
        <StoreCategoryRow
          key={`category-${category.id}`}
          category={category}
          expandedComponent={
            <InventoryCategoryProductList category={category} />
          }
        />
      ))}
    </>
  );
}
