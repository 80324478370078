import { Box, TableCell, TableRow } from '@mui/material';
import { ViewLineItemDto } from '../../../../lib/api/orders/dto/view-line-item.dto';
import { ViewOrderExtendedDto } from '../../../../lib/api/orders/dto/view-order-extended.dto';
import { usd } from '../../../../lib/util/misc-utils';

interface Props {
  order: ViewOrderExtendedDto;
  item: ViewLineItemDto;
}

export default function LineItemsTableProductRow({ item, order }: Props) {
  const discountRate = order.customerDiscountRate;
  let discountAmt;
  let lineAmtAfterDiscount;

  if (discountRate) {
    discountAmt = discountRate
      .dividedBy(100)
      .times(item.productTotalBeforeDiscount)
      .toNearest(0.01);
    lineAmtAfterDiscount = item.productTotalBeforeDiscount.minus(discountAmt);
  }
  return (
    <TableRow hover>
      <TableCell>
        <Box display="flex" flexDirection="column">
          <span>{item.productName}</span>
          <span>{item.productDescription}</span>
        </Box>
      </TableCell>
      <TableCell align="center">{item.productItemNo || '-'}</TableCell>
      <TableCell align="right">{item.qty.toNumber()}</TableCell>
      <TableCell align="right">{usd(item.price)}</TableCell>
      <TableCell align="right">
        {usd(item.productTotalBeforeDiscount)}
      </TableCell>
      {discountRate.greaterThan(0) && (
        <>
          <TableCell align="right">{usd(discountAmt.neg())}</TableCell>
          <TableCell align="right">{usd(lineAmtAfterDiscount)}</TableCell>
        </>
      )}
    </TableRow>
  );
}
