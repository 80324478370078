import { Expose } from 'class-transformer';
import { IsInt, IsString } from 'class-validator';
import CustomIsNotEmpty from '../../../common/custom-class-validators/CustomIsNotEmpty';
import CustomLength from '../../../common/custom-class-validators/CustomLength';

export class CreateChildProductOptionValueForExistingOptionAndValueDto {
  @Expose()
  @IsInt()
  @CustomIsNotEmpty('Product Option')
  productOptionId: number; //The child option id we are mapping values to.

  @Expose()
  @IsInt()
  @CustomIsNotEmpty('Parent Option Value')
  parentId: number;

  @Expose()
  @IsString()
  @CustomLength(1, 100, 'Value')
  @CustomIsNotEmpty('Value')
  value: string;
}
