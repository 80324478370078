import { Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  getInventoryForSimpleProductsByCategoryId,
  InventoryQueryKeys,
} from '../../lib/api/inventory/inventory.service';
import InventoryAvailabilityTable from './table/InventoryAvailabilityTable';

interface Props {
  categoryId: number;
}

export default function SimpleProductsAvailabilityTableWrapper({
  categoryId,
}: Props) {
  const {
    isError: inventoryIsError,
    data: inventoryRes,
    error: inventoryError,
    isFetching: inventoryIsFetching,
  } = useQuery(
    [
      InventoryQueryKeys.findInventoryForSimpleProductsByCategoryId,
      { id: categoryId },
    ],
    () => getInventoryForSimpleProductsByCategoryId(categoryId),
  );

  if (inventoryIsFetching && !inventoryRes)
    return (
      <>
        <Skeleton height="50px"></Skeleton>
        <Skeleton height="50px"></Skeleton>
        <Skeleton height="50px"></Skeleton>
        <Skeleton height="50px"></Skeleton>
      </>
    );

  return <InventoryAvailabilityTable inventory={inventoryRes} />;
}
