import { Divider, Grid, Paper, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { OrderType } from '../../../lib/api/orders/dto/create-order.dto';
import GreenbackAddressCard from '../../checkout-form/GreenbackAddressCard';
import MaryvilleAddressCard from '../../checkout-form/MaryvilleAddressCard';
import SelectedAddressCard from '../../checkout-form/SelectedAddressCard';
import FromSubmitButton from '../../ui/forms/FormSubmitButton';
import { ViewOrderExtendedDto } from '../../../lib/api/orders/dto/view-order-extended.dto';
import { UpdateOrderShipToDto } from '../../../lib/api/orders/dto/update-order-shipto-address.dto';
import { ViewCustomerExtendedDto } from '../../../lib/api/customers/dto/view-customer-extended.dto';

interface Props {
  order: ViewOrderExtendedDto;
  customer: ViewCustomerExtendedDto;
}

export default function UpdateOrderShipToAddressReviewAndSubmitPartial({
  order,
  customer,
}: Props) {
  const {
    formState: { isSubmitting, isValid, isSubmitted },
    getValues,
  } = useFormContext<UpdateOrderShipToDto>();

  const shippingAddress = customer?.addresses.find(
    (a) => a.id === getValues('shippingAddressId'),
  );

  const shippingContact = shippingAddress?.contacts.find(
    (c) => c.id === getValues('shippingContactId'),
  );

  const pickupAtGreenback = getValues('type') === OrderType.PickupGreenback;
  const pickupAtMaryville = getValues('type') === OrderType.PickupMaryville;
  const isDelivery = getValues('type') === OrderType.Delivery;

  const orderWasOriginalPickup =
    order.type === OrderType.PickupGreenback ||
    order.type === OrderType.PickupMaryville;

  const orderIsNowPickup = pickupAtGreenback || pickupAtMaryville;

  const addFuelSurcharge = orderWasOriginalPickup && isDelivery;
  const removeFuelSurcharge = !orderWasOriginalPickup && orderIsNowPickup;

  return (
    <Paper sx={{ p: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h5">
            Review & Submit
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            component="h2"
            variant="h6"
            sx={{ textDecoration: 'underline' }}
          >
            Original Delivery Method
          </Typography>
          {order.type === OrderType.PickupGreenback && <GreenbackAddressCard />}
          {order.type === OrderType.PickupMaryville && <MaryvilleAddressCard />}
          {order.type === OrderType.Delivery && (
            <SelectedAddressCard
              address={order.shippingAddress}
              contact={order.shippingContact}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            component="h2"
            variant="h6"
            sx={{ textDecoration: 'underline' }}
          >
            New Delivery Method
          </Typography>

          {pickupAtGreenback && <GreenbackAddressCard />}
          {pickupAtMaryville && <MaryvilleAddressCard />}
          {isDelivery && (
            <SelectedAddressCard
              address={shippingAddress}
              contact={shippingContact}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {addFuelSurcharge && (
            <Typography component="em" variant="subtitle2" color="GrayText">
              Note: Changing the order's delivery method from pickup to delivery
              will add a per rack fuel surchage to the order.
            </Typography>
          )}
          {removeFuelSurcharge && (
            <Typography component="em" variant="subtitle2" color="GrayText">
              Note: Changing the order's delivery method from delivery to pickup
              will remove the fuel surchage from the order.
            </Typography>
          )}

          <Divider />
        </Grid>

        <Grid item xs={12}>
          <FromSubmitButton
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            isValid={isValid}
            text="Update Delivery Method"
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
