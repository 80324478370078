import { Box, ListItem } from '@mui/material';
import { useCartContext } from '../../contexts/CartContext';
import ViewCartItemExtendedDto from '../../lib/api/carts/dto/view-cart-item-extended.dto';
import { usd } from '../../lib/util/misc-utils';
import DeleteIconButton from '../ui/buttons/DeleteIconButton';
interface Props {
  item: ViewCartItemExtendedDto;
  indent?: boolean;
}

export default function CartSummaryListItemRow({ item, indent }: Props) {
  const { handleRemoveItemFromCart } = useCartContext();
  return (
    <ListItem
      secondaryAction={
        <DeleteIconButton
          onClick={() => handleRemoveItemFromCart(item)}
          resource={`${item?.product?.name || ''} ${
            item?.variant?.name || ''
          } from cart`}
        />
      }
      sx={{ pl: indent ? 4 : 2 }}
    >
      <Box width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          sx={{ fontWeight: 600, color: 'primary.light' }}
        >
          <span>{`${
            item.product.hasVariants
              ? `-${item?.variant?.name}`
              : `${item?.product?.name} - ${item.racks} racks`
          }`}</span>
          <span>{usd(item.productTotalBeforeDiscount)}</span>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          sx={{ color: 'grey.500' }}
        >
          <span>{`${item.qty} @ ${usd(item.product.price)}`}</span>
        </Box>
      </Box>
    </ListItem>
  );
}
