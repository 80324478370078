import { Box, Tooltip } from '@mui/material';
import Decimal from 'decimal.js';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { usd } from '../../lib/util/misc-utils';

interface Props {
  orderAmt: Decimal;
  min: Decimal;
}

export default function OrderMinimumMsg({ orderAmt, min }: Props) {
  if (!orderAmt) return null;
  return (
    <Tooltip
      placement="top"
      title={`There is an order minimum of ${usd(min)} for delivery orders.`}
    >
      <Box display="flex" width="400px" sx={{ mb: 3 }}>
        {orderAmt.greaterThanOrEqualTo(min) ? (
          <>
            <CheckCircleIcon color="primary" />
            <span>Order Minimum for Delivery Orders Met</span>
          </>
        ) : (
          <>
            <InfoIcon color="warning" />
            <span style={{ marginLeft: '0.4rem' }}>
              <span style={{ fontWeight: 600 }}>
                {usd(min.minus(orderAmt))}{' '}
              </span>
              more needed to meet order minimum for delivery orders. If placing
              a pickup order, there is no minimum.
            </span>
          </>
        )}
      </Box>
    </Tooltip>
  );
}
