import { Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ViewSellableProductDto } from '../../../lib/api/products/dto/view-sellable-product.dto';
import {
  getSellableVariants,
  ProductQueryKeys,
} from '../../../lib/api/products/products.service';
import VariantRow from '../input-rows/VariantRow';

interface Props {
  product: ViewSellableProductDto;
}

export default function ProductVariants({ product }: Props) {
  const {
    isError: variantsIsError,
    data: variants,
    error: variantsError,
    isFetching: variantsIsFetching,
  } = useQuery(
    [ProductQueryKeys.findSellableProductVariants, { productId: product.id }],
    () => getSellableVariants(product.id),
  );

  console.log(product.description);

  return (
    variants?.length > 0 && (
      <Paper elevation={5} sx={{ p: 2, mt: 1, mb: 5 }}>
        {product.description && (
          <Typography
            component="span"
            sx={{
              fontStyle: 'italic',
              fontWeight: 700,
              fontSize: '1.1rem',
              color: 'grey.600',
            }}
          >
            {product.description}
          </Typography>
        )}
        {variants.map((variant, index) => {
          const isLastEntry = index === variants.length - 1;
          const isOnlyEntry = variants.length === 1;

          return (
            <VariantRow
              key={`variant-row-${variant.id}`}
              // value={value}
              product={product}
              variant={variant}
              showBottomBorder={!isLastEntry && !isOnlyEntry}
            />
          );
        })}
      </Paper>
    )
  );
}
