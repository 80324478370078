import { Expose, Type } from 'class-transformer';
import { IsEmail, ValidateNested } from 'class-validator';
import CustomIsNotEmpty from '../../../common/custom-class-validators/CustomIsNotEmpty';
import { CreateOrUpdateProfileDto } from './create-or-update-profile.dto';

export class CreateUserDto {
  @IsEmail({}, { message: `Email must be a valid email address` })
  @CustomIsNotEmpty('Email')
  @Expose()
  email: string;

  @ValidateNested()
  @Type(() => CreateOrUpdateProfileDto)
  @Expose()
  profile: CreateOrUpdateProfileDto;
}
