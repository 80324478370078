import { Skeleton, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  getInventoryForVariantsByProductId,
  InventoryQueryKeys,
} from '../../lib/api/inventory/inventory.service';
import InventoryAvailabilityTable from './table/InventoryAvailabilityTable';
import { ViewProductDto } from '../../lib/api/products/dto/view-product.dto';
import InventoryAvailabilitySwitch from '../../components/products/InventoryAvailabilitySwitch';

interface Props {
  product: ViewProductDto;
}

export default function ComplexProductAvailabilityTableWrapper({
  product,
}: Props) {
  const {
    isError: inventoryIsError,
    data: inventoryRes,
    error: inventoryError,
    isFetching: inventoryIsFetching,
  } = useQuery(
    [InventoryQueryKeys.findVariantsInventoryByProductId, { id: product.id }],
    () => getInventoryForVariantsByProductId(product.id),
  );

  if (inventoryIsFetching && !inventoryRes)
    return (
      <>
        <Skeleton height="50px"></Skeleton>
        <Skeleton height="50px"></Skeleton>
        <Skeleton height="50px"></Skeleton>
        <Skeleton height="50px"></Skeleton>
      </>
    );

  const item = {
    productId: product.id,
    isSellableProduct: product.isSellable,
    sellToPopesProduct: product.sellToPopes,
    sellToPremiumProduct: product.sellToPremium,
    sellToStandardProduct: product.sellToStandard,
  };

  return (
    <>
      <Stack direction="row">
        <InventoryAvailabilitySwitch
          inventoryItem={item}
          flag="isSellableProduct"
        />
        <InventoryAvailabilitySwitch
          inventoryItem={item}
          flag="sellToPopesProduct"
        />
        <InventoryAvailabilitySwitch
          inventoryItem={item}
          flag="sellToPremiumProduct"
        />
        <InventoryAvailabilitySwitch
          inventoryItem={item}
          flag="sellToStandardProduct"
        />
      </Stack>
      <InventoryAvailabilityTable inventory={inventoryRes} />;
    </>
  );
}
