import { ViewUserDto } from '../../lib/api/users/dto/view-user.dto';
import { BaseTableProps, TableCol } from '../../lib/util/table-util';
import PaginatedTable from '../ui/tables/paginated-table/PaginatedTable';
import UserTypeChip from './UserTypeChip';

const columns: TableCol<ViewUserDto>[] = [
  {
    id: 'email',
    label: 'Email',
    filterType: 'contains',
    widthPct: 20,
  },
  {
    id: 'isAdmin',
    align: 'center',
    label: 'Type',
    sortable: true,
    render: (user: ViewUserDto) => <UserTypeChip user={user} />,
    widthPct: 10,
  },
  {
    id: 'customer.name',
    align: 'center',
    label: 'Customer',
    sortable: true,
    filterType: 'contains',
    widthPct: 20,
  },
  {
    id: 'profile.firstName',
    label: 'First',
    filterType: 'contains',
    widthPct: 10,
  },
  {
    id: 'profile.lastName',
    label: 'Last',
    filterType: 'contains',
    widthPct: 10,
  },
  {
    id: 'profile.mobilePhone',
    label: 'Mobile',
    widthPct: 15,
  },
  {
    id: 'profile.officePhone',
    label: 'Office',
    widthPct: 15,
  },
];

export default function UsersTable({
  apiRes,
  isFetching,
  setTableState,
  tableState,
  onRowClick,
}: BaseTableProps<ViewUserDto>) {
  return (
    <PaginatedTable<ViewUserDto>
      columns={columns}
      apiRes={apiRes}
      isFetching={isFetching}
      setTableState={setTableState}
      tableState={tableState}
      onRowClick={onRowClick}
    />
  );
}
