import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useAppContext } from '../../../contexts/AppContext';
import {
  createProduct,
  ProductQueryKeys,
} from '../../../lib/api/products/products.service';
import useFormSubmitErrorAlert from '../../ui/forms/useFormSubmitErrorAlert';
import MultiStepFormPage from '../../ui/forms/MultiStepFormPage';
import {
  CategoryQueryKeys,
  getAllCategories,
} from '../../../lib/api/categories/categories.service';
import AddProductFormDetailsPartial from './partials/AddProductFormDetailsPartial';
import AddProductFormOptionsPartial from './partials/AddProductFormOptionsPartial';
import MultiStepForm from '../../ui/forms/MultiStepForm';
import { StepperData } from '../../ui/forms/CustomStepper';
import useMultiStepForm from '../../ui/forms/useMultiStepForm';
import ProductTypePartial from './partials/ProductTypePartial';
import { ProductLocation } from '../../../lib/api/products/enums/product-locations.enum';
import { CreateProductDto } from '../../../lib/api/products/dto/create-product.dto';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import AddProductFormStoreAvailabilityPartial from './partials/AddProductFormStoreAvailabilityPartial';
import { onSuccessCallback } from '../../ui/forms/CustomForm';

interface Props {
  onSuccess?: onSuccessCallback;
}

export default function AddProductForm({ onSuccess }: Props) {
  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  const createMutation = useMutation((createProductDto: CreateProductDto) =>
    createProduct(createProductDto),
  );

  const {
    isError,
    data: categories,
    error,
    isFetching,
  } = useQuery([CategoryQueryKeys.findAllCategories], () => getAllCategories());

  const [steps, setSteps] = useState<StepperData<CreateProductDto>[]>([
    {
      text: 'Product Type',
      isActive: true,
      fieldsInStep: ['hasVariants'],
    },
    {
      text: 'Add Product Details',
      fieldsInStep: [
        'categoryId',
        'name',
        'description',
        'upc',
        'itemNo',
        'sku',
        'qtyPerShelf',
        'shelvesPerRack',
        'price',
        'isProvenWinner',
      ],
      focusField: 'name',
      fieldsToValidateOnNext: [
        'categoryId',
        'name',
        'description',
        'upc',
        'itemNo',
        'sku',
        'qtyPerShelf',
        'shelvesPerRack',
        'price',
        'isProvenWinner',
      ],
    },
    {
      text: 'Store Availability',
      fieldsInStep: [
        'isSellable',
        'sellToPopes',
        'sellToPremium',
        'sellToStandard',
        'location',
        'qty',
        'orderLimit',
      ],
      focusField: 'isSellable',
      fieldsToValidateOnNext: [
        'isSellable',
        'sellToPopes',
        'sellToPremium',
        'sellToStandard',
        'location',
        'qty',
        'orderLimit',
      ],
    },
  ]);

  const defaultValues: CreateProductDto = {
    upc: null,
    itemNo: null,
    sku: null,
    isProvenWinner: false,
    isSellable: true,
    sellToPopes: true,
    sellToPremium: true,
    sellToStandard: true,
    options: [],
    location: ProductLocation.Greenback,
    price: null,
    orderLimit: null,
    categoryId: null,
    hasVariants: null,
    name: null,
    qtyPerShelf: null,
    shelvesPerRack: null,
    description: null,
    qty: null,
  };

  const form = useMultiStepForm<CreateProductDto>({
    resolver: classValidatorResolver(CreateProductDto, {
      forbidUnknownValues: true,
    }),
    defaultValues,
    // defaultValues: {
    //Temp ones
    // name: '2" Succulent',
    // categoryId: 1,
    // description: '32 Pots/Flat',
    // upc: '734173910896',
    // itemNo: '91089',
    // sku: '2_IN_SUCC',
    // qtyPerShelf: 5,
    // shelvesPerRack: 8,
    // price: '48.00',
    // hasVariants: true,
    // isProvenWinner: false,
    // isSellable: true,
    // sellToPopes: true,
    // sellToPremium: true,
    // sellToStandard: true,
    // location: ProductLocations.Greenback,
    // qty: null,
    // orderLimit: null,
    // options: [
    //   {
    //     name: 'Variety',
    //     values: [
    //       'Aeonium',
    //       'Agave',
    //       'Aloe Vera',
    //       'Aloe',
    //       'Anacampseros',
    //       'Aptenia',
    //       'Cactus',
    //       'Ceropegia',
    //       'Cotyledon',
    //       'Crassula',
    //       'Delosperma',
    //       'Echeveria',
    //     ],
    //     children: [
    //       {
    //         name: 'Color',
    //         values: [
    //           {
    //             parentOptionValue: 'Aeonium',
    //             values: [
    //               'Black Rose',
    //               'Kiwi',
    //               'Kiwi Verde',
    //               'Mini Tricolor',
    //               'Pinwheel',
    //               'Red Bord',
    //               'Simsii',
    //               'Zwartkop',
    //             ],
    //           },
    //           {
    //             parentOptionValue: 'Agave',
    //             values: ['Shaka Zulu', 'West Jogoo'],
    //           },
    //           { parentOptionValue: 'Aloe Vera', values: ['Medicinal Aloe'] },
    //           {
    //             parentOptionValue: 'Aloe',
    //             values: [
    //               'Midnight',
    //               'Miniature Hybrid',
    //               'Pink Blush',
    //               'Tiger Tooth',
    //               'Vera',
    //             ],
    //           },
    //           {
    //             parentOptionValue: 'Anacampseros',
    //             values: ['Sunrise'],
    //           },
    //           {
    //             parentOptionValue: 'Aptenia',
    //             values: ['Patsy'],
    //           },
    //           {
    //             parentOptionValue: 'Cactus',
    //             values: ['Christmas Cactus', 'Peanut', 'Thimble'],
    //           },
    //           {
    //             parentOptionValue: 'Ceropegia',
    //             values: ['String of Hearts'],
    //           },
    //           {
    //             parentOptionValue: 'Cotyledon',
    //             values: [
    //               "Bear's Paw",
    //               'Choco Line',
    //               'Ladysmithiensis',
    //               'Orbiculata',
    //               'Silver Peak',
    //             ],
    //           },
    //           {
    //             parentOptionValue: 'Crassula',
    //             values: [
    //               'Baby Jade',
    //               'Campfire',
    //               'Capitella Origami',
    //               'Commutata',
    //               'Curly',
    //               'Dew Bush',
    //               'Falcata',
    //               'Hobbit',
    //               'Jade',
    //               'Large Red Carpet Stonecrop',
    //               'Mini Pine Tree',
    //               'Obvallata',
    //               'Pangolin',
    //               'Plathyphylla',
    //               'Princess Pine',
    //               'Saramentosa',
    //               'Shark Tooth',
    //               'Silver Dollar Plant',
    //               'Small Red Carpet Stonecrop',
    //               'Spiralis',
    //               'Springtime',
    //               'String of Buttons',
    //               'Sunset',
    //               'Swaziensis Variegata',
    //               'Tenelli',
    //               'Thrysflora',
    //               'Variegated Jade',
    //               'Variegated String of Buttons',
    //               'Wavey Jade',
    //             ],
    //           },
    //           {
    //             parentOptionValue: 'Delosperma',
    //             values: ['Echinatum', 'Lehmanni'],
    //           },
    //           {
    //             parentOptionValue: 'Echeveria',
    //             values: [
    //               'Afines',
    //               'Agavoides',
    //               'Albicans',
    //               'Arrow Nodulosa',
    //               'Arrow Setosa',
    //               'Atroviridis',
    //               'Aurora',
    //               'Azul',
    //               'Big Blue',
    //               'Big Lilac',
    //               'Black Flash',
    //               'Black Knight',
    //               'Black Prince',
    //               'Blue',
    //               'Blue Balloon',
    //               'Blue Bird',
    //               'Blue Brazil',
    //               'Blue Glauca',
    //               'Blue Prince',
    //               'Blue Rose',
    //               'Boel',
    //               'Branco',
    //               'California Queen',
    //               'Canadian',
    //               'Chihuahuensis',
    //               'Colorata',
    //               'Costa',
    //               'Debbie',
    //               'Early Sunrise',
    //               'Ebony',
    //               'Elegans',
    //               'Elegans Hybrid',
    //               'Estrella',
    //               'Frosty',
    //               'Fury',
    //               'Garotto',
    //               'Giant Blue',
    //               'Globulosa',
    //               'Grande Cinza',
    //               'Green Gilva',
    //               'Green Pearl',
    //               'Green Spoon',
    //               'Grey Red',
    //               'Grosso',
    //               'Hadar',
    //               'Hagaii Tolimanensis',
    //               'Hybrid Blue',
    //               'Hybrid Mira',
    //               'Ice Crystal',
    //               'Jasiri',
    //               'Lila Cina',
    //               'Lilac Wonder',
    //               'Linda',
    //               'Lotus',
    //               'Machette',
    //               'Marrom',
    //               'Melaco',
    //               'Minima',
    //               'Miranda',
    //               'Moon Dust',
    //               'Moranni',
    //               'Morning Dew',
    //               'Mystery',
    //               'New Miranda',
    //               'Nodulosa',
    //               'Orpet',
    //               'Parva',
    //               'Peacockii',
    //               'Perle Von Nurnberg',
    //               'Ponto Rosso',
    //               'Preta',
    //               'Pulidonis',
    //               'Pulvinata',
    //               'Purple Pearl',
    //               'Purpusorum',
    //               'Red Prince',
    //               'Red Sky',
    //               'Red Tip',
    //               'Romeo',
    //               'Rosea',
    //               'Round Leaf',
    //               'Scheideckeri',
    //               'Setosa',
    //               'Setosa Demunita',
    //               'Shaviana',
    //               'Shaviana Blue',
    //               'Silver on Pink',
    //               'Subsessilis Blue',
    //               'Summer',
    //               'Topsy Turvey',
    //               'Violet',
    //               'Yvonii',
    //             ],
    //           },
    //         ],
    //       },
    //     ],
    //   },
    // ],
    // },
    steps,
    setSteps,
  });

  const hasVariants = form.watch('hasVariants');

  const handleProductTypeClick = (type: 'simple' | 'complex') => {
    if (type === 'simple') {
      //Update form values for product without variant
      form.setValue('hasVariants', false);
      form.setValue('isProvenWinner', false);
      // form.setValue('options', null);

      //Remove step for Configuring variants if it is on the for
      setSteps((prev) => prev.filter((s) => s.text !== 'Configure Variants'));
    } else {
      //Update form values for product with variant
      form.setValue('hasVariants', true);
      form.setValue('isProvenWinner', null);
      form.setValue('qty', null);
      form.setValue('orderLimit', null);
      // form.setValue('options', []);

      //Add step for Configuring variants if we don't have it
      const configureVariantIndex = steps.findIndex(
        (s) => s.text === 'Configure Variants',
      );

      if (configureVariantIndex === -1)
        setSteps((prev) => {
          return [
            ...prev,
            {
              text: 'Configure Variants',
              fieldsInStep: ['options'],
            },
          ];
        });
    }
    form.setActivePage(1);
  };

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<CreateProductDto>();

  const onSubmit: SubmitHandler<CreateProductDto> = async (
    formData: CreateProductDto,
  ) => {
    clearFormSubmitError();

    try {
      const createdProduct = await createMutation.mutateAsync(formData);
      queryClient.invalidateQueries([ProductQueryKeys.findAllProducts]);
      handleShowMessage(`Created Product '${formData.name}'`);
      onSuccess?.(createdProduct);
    } catch (err: any) {
      processFormSubmitError(err, form.setError);
    }
  };

  return (
    <FormProvider {...form}>
      <MultiStepForm
        handleSubmit={form.handleSubmit(onSubmit)}
        serverErrorMessage={serverErrorMessage}
        steps={steps}
      >
        <MultiStepFormPage step={steps[0]} errors={form.formState.errors}>
          <ProductTypePartial onClick={handleProductTypeClick} />
        </MultiStepFormPage>

        <MultiStepFormPage
          step={steps[1]}
          onBack={form.handleBackClick}
          onNext={form.handleNextClick}
          errors={form.formState.errors}
        >
          {categories && (
            <AddProductFormDetailsPartial categories={categories.data} />
          )}
        </MultiStepFormPage>

        <MultiStepFormPage
          step={steps[2]}
          onBack={form.handleBackClick}
          onNext={hasVariants ? form.handleNextClick : null}
          errors={form.formState.errors}
        >
          <AddProductFormStoreAvailabilityPartial />
        </MultiStepFormPage>

        {steps[3] && (
          <MultiStepFormPage
            step={steps[3]}
            onBack={form.handleBackClick}
            errors={form.formState.errors}
          >
            <AddProductFormOptionsPartial />
          </MultiStepFormPage>
        )}
      </MultiStepForm>
    </FormProvider>
  );
}
