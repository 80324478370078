import { DateTime } from 'luxon';
import TabPanel from '../../../components/ui/tabs/TabPanel';
import { OrderStatus } from '../../../lib/api/orders/enums/order-status.enum';
import useOrdersFulfillmentTable from '../hooks/useOrdersFulfillmentTable';
import OrdersShippedTable from '../tables/OrdersShippedTable';

interface Props {
  label: string;
  tabValue: number;
  index: number;
}

export default function OrdersShippedTab({ tabValue, index }: Props) {
  const { tableState, setTableState, isFetching, orders, handleChangeStatus } =
    useOrdersFulfillmentTable({
      filters: {
        shippedAt: {
          gte: DateTime.utc()
            .startOf('week')
            .startOf('day')
            .toSQL({ includeOffset: false }),
        },
      },
    });
  return (
    <TabPanel value={tabValue} index={index}>
      <OrdersShippedTable
        apiRes={orders}
        isFetching={isFetching}
        setTableState={setTableState}
        tableState={tableState}
      />
    </TabPanel>
  );
}
