import { ViewProductDto } from '../../lib/api/products/dto/view-product.dto';
import ExpandableTypography from '../../components/ui/typography/ExpandableTypography';
import ComplexProductAvailabilityTableWrapper from './ComplexProductAvailabilityTableWrapper';

interface Props {
  product: ViewProductDto;
}

export default function InventoryProductWithVariantsRow({ product }: Props) {
  return (
    <ExpandableTypography
      fontWeight={700}
      component="p"
      expandedComponent={
        <ComplexProductAvailabilityTableWrapper product={product} />
      }
      expandIconSize="small"
    >
      {product.name}
    </ExpandableTypography>
  );
}
