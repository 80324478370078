import { styled, TableCell, TableHead, TableRow } from '@mui/material';

export default function CartListTableHeading() {
  const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[800]
        : theme.palette.grey[200],
    color: 'red',
    fontWeight: 'bolder',
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
    fontWeight: 'bolder',
  }));

  return (
    <StyledTableHead>
      <TableRow>
        <StyledTableCell sx={{ width: '10px' }}>Category</StyledTableCell>
        <StyledTableCell>Product</StyledTableCell>
        <StyledTableCell align="right">Shelfs</StyledTableCell>
        <StyledTableCell align="right">Racks</StyledTableCell>
        <StyledTableCell align="right">Qty</StyledTableCell>
        <StyledTableCell align="right">Unit Price</StyledTableCell>
        <StyledTableCell align="right">Amount</StyledTableCell>
      </TableRow>
    </StyledTableHead>
  );
}
