import FormContainer from './FormContainer';
import CustomStepper, { StepperData } from './CustomStepper';
import ServerErrorAlert from './ServerErrorAlert';

interface Props<Dto> {
  steps: StepperData<Dto>[];
  children: React.ReactNode;
  handleSubmit: any;
  serverErrorMessage: string;
}

export default function MultiStepForm<Dto>({
  steps,
  serverErrorMessage,
  handleSubmit,
  children,
}: Props<Dto>) {
  return (
    <FormContainer>
      <CustomStepper steps={steps} />
      <form onSubmit={handleSubmit} autoComplete="off">
        <ServerErrorAlert message={serverErrorMessage} />
        {children}
      </form>
    </FormContainer>
  );
}
