import { ListItem, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import ViewCartItemExtendedDto from '../../lib/api/carts/dto/view-cart-item-extended.dto';
import { ViewProductWithCategoryDto } from '../../lib/api/products/dto/view-product-with-category.dto';

interface Props {
  product: ViewProductWithCategoryDto;
  itemsForProduct: ViewCartItemExtendedDto[];
}

export function CartSummaryListProductSubtotalRow({
  product,
  itemsForProduct,
}: Props) {
  const productQtySubtotal = itemsForProduct.reduce(
    (acc, cur) => acc.plus(cur.qty),
    new Decimal(0),
  );
  const productRacksSubtotal = itemsForProduct.reduce(
    (acc, cur) => acc.plus(cur.racks),
    new Decimal(0),
  );
  return (
    <ListItem>
      <Typography
        component="span"
        sx={{ fontWeight: 600, color: 'primary.light' }}
      >
        {`${product.name} - ${productQtySubtotal} in cart - ${productRacksSubtotal} racks`}
      </Typography>
    </ListItem>
  );
}
