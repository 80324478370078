import { Button, Divider, Stack } from '@mui/material';
import { useState } from 'react';
import useConfirmDialog from '../../ui/dialog/useConfirmDialog';
import useModal from '../../ui/modal/useModal';
import { ViewUserDto } from '../../../lib/api/users/dto/view-user.dto';
import Empty from '../../ui/custom-icons/Empty';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import TabPanel from '../../ui/tabs/TabPanel';
import AddUserForm from '../../users/AddUserForm';
import { DeleteUserConfirmDialog } from '../../users/DeleteUserConfirmDialog';
import UpdateUserForm from '../../users/UpdateUserForm';
import UserCard from '../../users/UserCard';
import DrawerPaper from '../../ui/drawer/DrawerPaper';
import { ViewCustomerExtendedDto } from '../../../lib/api/customers/dto/view-customer-extended.dto';

interface Props {
  label: string;
  tabValue: number;
  index: number;
  customer: ViewCustomerExtendedDto;
}
export default function CustomerUsersTab({
  label,
  tabValue,
  index,
  customer,
}: Props) {
  const {
    handleCloseModal: handleCloseEditModal,
    handleLaunchModal: handleLaunchEditModal,
    modalOpen: editModalOpen,
  } = useModal();

  const {
    handleCloseModal: handleCloseAddModal,
    handleLaunchModal: handleLaunchAddModal,
    modalOpen: addModalOpen,
  } = useModal();

  const {
    confirmDialogIsOpen,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
  } = useConfirmDialog();

  const [userToDelete, setUserToDelete] = useState<ViewUserDto>(null);
  const [userToEdit, setUserToEdit] = useState<ViewUserDto>(null);

  const onCreateUserSuccess = () => {
    handleCloseAddModal();
  };

  const onEditUserSuccess = () => {
    handleCloseEditModal();
  };

  const handleDelete = (id: string) => {
    setUserToDelete(customer.users.find((u) => u.id === id));
    handleOpenConfirmDialog();
  };

  const handleAddUser = () => {
    setUserToEdit(null);
    handleLaunchAddModal();
  };

  const handleEditUser = (id: string) => {
    setUserToEdit(customer.users.find((u) => u.id === id));
    handleLaunchEditModal();
  };

  return (
    <>
      <TabPanel value={tabValue} index={index}>
        <Button variant="contained" onClick={handleAddUser} sx={{ mb: 2 }}>
          Add Customer User
        </Button>
        <DrawerPaper>
          <Stack
            spacing={2}
            divider={<Divider orientation="horizontal" variant="middle" />}
          >
            {(customer.users?.length === 0 || !customer.users) && <Empty />}
            {customer.users?.map((u, index) => (
              <UserCard
                key={u.id}
                user={u}
                onDelete={handleDelete}
                onEdit={handleEditUser}
              />
            ))}
          </Stack>
        </DrawerPaper>
      </TabPanel>
      {userToDelete && (
        <DeleteUserConfirmDialog
          user={userToDelete}
          isOpen={confirmDialogIsOpen}
          onClose={handleCloseConfirmDialog}
        />
      )}
      {userToEdit && (
        <FullScreenModal
          title={`Update User for ${customer.name}`}
          open={editModalOpen}
          onClose={handleCloseEditModal}
        >
          <UpdateUserForm user={userToEdit} onSuccess={onEditUserSuccess} />
        </FullScreenModal>
      )}

      <FullScreenModal
        title={`Create User for ${customer.name}`}
        open={addModalOpen}
        onClose={handleCloseAddModal}
      >
        <AddUserForm customerId={customer.id} onSuccess={onCreateUserSuccess} />
      </FullScreenModal>
    </>
  );
}
