import { Expose, Type } from 'class-transformer';
import { ValidateNested } from 'class-validator';
import { CreateOrUpdateProfileDto } from './create-or-update-profile.dto';

export class UpdateUserDto {
  @ValidateNested()
  @Type(() => CreateOrUpdateProfileDto)
  @Expose()
  profile: CreateOrUpdateProfileDto;
}
