import {
  Box,
  styled,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import {
  ObjectWithId,
  Order,
  SearchFilter,
  TableCol,
  TableStateFilters,
  TableStateSorter,
} from '../../../../lib/util/table-util';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { DtoStringKeysAsPath } from '../../../../lib/util/type-utils';

interface CustomTableHeadProps<ViewDto extends ObjectWithId> {
  columns: TableCol<ViewDto>[];
  filters: TableStateFilters<ViewDto>;
  sorter: TableStateSorter<ViewDto>;
  onRequestSort: (property: DtoStringKeysAsPath<ViewDto> | 'id') => void;
  onSearch?: (filter: SearchFilter<ViewDto>) => void;
  onRefresh?: () => void;
  onDownload?: () => void;
  order: Order;
  orderBy: DtoStringKeysAsPath<ViewDto> | 'id';
}

export default function CustomTableHead<ViewDto extends ObjectWithId>({
  order,
  orderBy,
  filters,
  onRequestSort,
  columns,
  onSearch,
  onRefresh,
  onDownload,
}: CustomTableHeadProps<ViewDto>) {
  return (
    <TableHead>
      <TableRow>
        {columns.map((col) => {
          const columnsSortKey = col.sortKey || col.id;
          return (
            <TableHeadCell
              key={col.id}
              align={col.align || 'left'}
              padding="normal"
              sortDirection={orderBy === columnsSortKey ? order : false}
              width={col.widthPct ? `${col.widthPct}%` : null}
              sx={col.headerCellSx || null}
            >
              {col?.sortable && (
                <TableSortLabel
                  tabIndex={-1} //Don't want tab to stop on sorters
                  active={orderBy === columnsSortKey}
                  direction={orderBy === columnsSortKey ? order : 'asc'}
                  onClick={() => onRequestSort(columnsSortKey)}
                >
                  {col.label}
                  {orderBy === columnsSortKey ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
              {!col?.sortable && col.label}
              {col?.filterType === 'contains' && (
                <Box sx={{ mt: 0 }}>
                  <SearchTextField
                    id={`search-input-${col.id}`}
                    type="search"
                    autoComplete="off"
                    autoSave="off"
                    label={`Search`}
                    variant="outlined"
                    size="small"
                    onChange={(event: any) =>
                      onSearch?.({
                        field: col.id,
                        type: 'contains',
                        value: event.target.value,
                      })
                    }
                    inputProps={{
                      'aria-label': `filter on ${col.label}`,
                      'data-lpignore': 'true',
                    }}
                    // @ts-ignore -- I know that contains might not exist on filters b/c it is not on all types, but we handle that here
                    value={filters?.[col.id]?.contains || ''}
                  />
                </Box>
              )}
              {col?.filterType === 'eq' && (
                <Box sx={{ mt: 0 }}>
                  <SearchTextField
                    id={`search-input-${col.id}`}
                    type="search"
                    autoComplete="off"
                    autoSave="off"
                    label={`Search (=)`}
                    variant="outlined"
                    size="small"
                    onChange={(event: any) =>
                      onSearch?.({
                        field: col.id,
                        type: 'eq',
                        value: event.target.value,
                      })
                    }
                    inputProps={{
                      'aria-label': `filter on ${col.label}`,
                      'data-lpignore': 'true',
                    }}
                    // @ts-ignore -- I know that eq might not exist on filters b/c it is not on all types, but we handle that here
                    value={filters?.[col.id]?.eq || ''}
                  />
                </Box>
              )}
            </TableHeadCell>
          );
        })}
      </TableRow>
      {(onRefresh || onDownload) && (
        <TableRow>
          <TableHeadActionCell colSpan={columns.length} align="right">
            {onRefresh && (
              <RefreshIcon
                onClick={onRefresh}
                aria-label="refresh"
                titleAccess="refresh"
              />
            )}
            {onDownload && (
              <DownloadForOfflineIcon
                onClick={onDownload}
                aria-label="download"
                titleAccess="download"
              />
            )}
          </TableHeadActionCell>
        </TableRow>
      )}
    </TableHead>
  );
}

const TableHeadActionCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
  '& .MuiSvgIcon-root:hover': {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

const TableHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    '& .MuiTableSortLabel-icon': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiTableSortLabel-root': {
      color: theme.palette.primary.contrastText,
    },
    '& .Mui-active > svg': {
      color: '#DAB590',
      fontSize: '1.5rem',
    },
  },
}));

const SearchTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.background.default,
    fontSize: '0.8rem',
    height: '25px',
    marginTop: '0.4rem',
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.8rem',
    // height: '1rem',
    backgroundColor: theme.palette.background.default,
    transform: 'translate(0.5rem, 0.5rem) scale(1)',
    padding: '2px',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    backgroundColor: theme.palette.background.default,
    transform: 'translate(0.5rem, -0.05rem) scale(0.75)',
    padding: '0 0.6rem',
    color: theme.palette.text.primary,
  },
  '& .MuiFormLabel-filled': {
    backgroundColor: theme.palette.background.default,
    transform: 'translate(0.5rem, -0.05rem) scale(0.75)',
    padding: '0 0.6rem',
    color: theme.palette.text.primary,
  },
}));
