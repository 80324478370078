import { Box, Button } from '@mui/material';
import useConfirmDialog from '../../ui/dialog/useConfirmDialog';
import useModal from '../../ui/modal/useModal';
import useAuth from '../../../lib/api/auth/useAuth';
import { ViewUserDto } from '../../../lib/api/users/dto/view-user.dto';
import DescriptionContainer from '../../ui/description/DescriptionContainer';
import DescriptionItem from '../../ui/description/DescriptionItem';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import TabPanel from '../../ui/tabs/TabPanel';
import { useAdminLayoutContext } from '../../layout/context/AdminLayoutContext';
import { DeleteUserConfirmDialog } from '../DeleteUserConfirmDialog';
import UpdateUserForm from '../UpdateUserForm';
import UserTypeChip from '../UserTypeChip';

interface Props {
  tabValue: number;
  index: number;
  user: ViewUserDto;
}
export default function UserDetailsTab({ tabValue, index, user }: Props) {
  const { handleDetailsDrawerClose } = useAdminLayoutContext<ViewUserDto>();
  const { loggedInUser } = useAuth();
  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();
  const {
    confirmDialogIsOpen,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
  } = useConfirmDialog();

  const onUpdateUserSuccess = () => {
    handleCloseModal();
  };

  const onDeleteUserSuccess = () => {
    handleDetailsDrawerClose();
  };

  return (
    <>
      <TabPanel value={tabValue} index={index}>
        <DescriptionContainer>
          <DescriptionItem label="Type">
            <UserTypeChip user={user} />
          </DescriptionItem>
          <DescriptionItem label="Customer">
            {user.customer ? user.customer.name : 'n/a'}
          </DescriptionItem>
          <DescriptionItem label="User #">{user.id}</DescriptionItem>

          <DescriptionItem label="Email">{user.email}</DescriptionItem>
          {user.profile && (
            <>
              <DescriptionItem label="Name">
                {`${user.profile.firstName} ${user.profile.lastName}`}
              </DescriptionItem>
              <DescriptionItem label="Mobile">
                {user.profile.mobilePhone || '---'}
              </DescriptionItem>
              <DescriptionItem label="Office">
                {user.profile.officePhone || '---'}
              </DescriptionItem>
            </>
          )}
        </DescriptionContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button variant="contained" onClick={handleLaunchModal}>
            Edit User
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'secondary.main',
              '&:hover': { backgroundColor: 'secondary.dark' },
            }}
            onClick={handleOpenConfirmDialog}
            disabled={user.id === loggedInUser.id}
          >
            Delete User
          </Button>
        </Box>
      </TabPanel>
      {user && (
        <DeleteUserConfirmDialog
          user={user}
          isOpen={confirmDialogIsOpen}
          onClose={handleCloseConfirmDialog}
          onSuccess={onDeleteUserSuccess}
        />
      )}
      <FullScreenModal
        title="Update User"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <UpdateUserForm user={user} onSuccess={onUpdateUserSuccess} />
      </FullScreenModal>
    </>
  );
}
