import {
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import Decimal from 'decimal.js';
import { useFormContext } from 'react-hook-form';
import { useCartContext } from '../../../contexts/CartContext';
import useAuth from '../../../lib/api/auth/useAuth';
import {
  CreateOrderDto,
  OrderType,
} from '../../../lib/api/orders/dto/create-order.dto';
import GreenbackAddressCard from '../GreenbackAddressCard';
import MaryvilleAddressCard from '../MaryvilleAddressCard';
import SelectedAddressCard from '../SelectedAddressCard';
import FromSubmitButton from '../../ui/forms/FormSubmitButton';
import { ViewCustomerExtendedDto } from '../../../lib/api/customers/dto/view-customer-extended.dto';
import { usd } from '../../../lib/util/misc-utils';

interface Props {
  customer: ViewCustomerExtendedDto;
}

export default function ReviewAndSubmitPartial({ customer }: Props) {
  const { cart } = useCartContext();
  const {
    formState: { isSubmitting, isValid, isSubmitted },
    getValues,
  } = useFormContext<CreateOrderDto>();
  const { loggedInUser } = useAuth();

  const billingAddress = customer?.addresses.find(
    (a) => a.id === getValues('billingAddressId'),
  );

  const shippingAddress = customer?.addresses.find(
    (a) => a.id === getValues('shippingAddressId'),
  );

  const billingContact = billingAddress?.contacts.find(
    (c) => c.id === getValues('billingContactId'),
  );
  const shippingContact = shippingAddress?.contacts.find(
    (c) => c.id === getValues('shippingContactId'),
  );

  const pickupAtGreenback = getValues('type') === OrderType.PickupGreenback;
  const pickupAtMaryville = getValues('type') === OrderType.PickupMaryville;
  const isDelivery = getValues('type') === OrderType.Delivery;

  const discountRate = new Decimal(customer.discountRate).dividedBy(100);
  const discountTotal = cart.productTotalBeforeDiscounts.times(discountRate);

  const orderTotal = cart.productTotalBeforeDiscounts
    .plus(cart.fuelSurchargeTotal)
    .minus(discountTotal);

  const summaryItems = [
    {
      id: 1,
      name: 'Order Summary',
      value: `${cart.racks.toFixed(2).toString()} Racks`,
    },
    {
      id: 2,
      name: 'Product Total',
      value: usd(cart.productTotalBeforeDiscounts),
    },
    {
      id: 3,
      name: `Customer Discount (${customer.discountRate
        .toFixed(2)
        .toString()}%)`,
      value: usd(discountTotal.neg()),
      hide: discountTotal.isZero(),
    },
    {
      id: 4,
      name: 'Fuel Surcharge',
      value: usd(cart.fuelSurchargeTotal),
    },
    {
      id: 5,
      name: 'Order Total',
      value: usd(orderTotal),
    },
  ];

  return (
    <Paper sx={{ p: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h5">
            Review & Submit
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            component="h2"
            variant="h6"
            sx={{ textDecoration: 'underline' }}
          >
            Billing
          </Typography>
          <SelectedAddressCard
            address={billingAddress}
            contact={billingContact}
            billingCompanyName={billingAddress.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            component="h2"
            variant="h6"
            sx={{ textDecoration: 'underline' }}
          >
            Shipping
          </Typography>

          {pickupAtGreenback && <GreenbackAddressCard />}
          {pickupAtMaryville && <MaryvilleAddressCard />}
          {isDelivery && (
            <SelectedAddressCard
              address={shippingAddress}
              contact={shippingContact}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          sx={{ backgroundColor: 'grey.100', ml: 3, pr: 3 }}
        >
          <Table
            sx={{ maxWidth: '700px' }}
            aria-label="charge summary table"
            size="small"
          >
            <TableBody>
              {summaryItems.map((row) => (
                <SummaryItemTableRow key={row.name} {...row} />
              ))}
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12}>
          <FromSubmitButton
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            isValid={isValid}
            text="Place Order"
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

interface SummaryItemTableRowData {
  name: string;
  value: string;
  hide?: boolean;
}

function SummaryItemTableRow({ name, value, hide }: SummaryItemTableRowData) {
  if (hide) return null;
  return (
    <TableRow
      key={name}
      sx={{
        'td, th': { border: 0 },
        '&:last-child td, &:last-child th': {
          borderTop: '1px solid lightgray',
          fontWeight: 700,
        },
      }}
    >
      <TableCell component="th" scope="row">
        <Typography component="p" fontWeight={700}>
          {name}
        </Typography>
      </TableCell>
      <TableCell align="right">{value}</TableCell>
    </TableRow>
  );
}
