import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationOptions,
  registerDecorator,
  ValidationArguments,
} from 'class-validator';
import Decimal from 'decimal.js';

@ValidatorConstraint()
class IsNumericStringConstraint implements ValidatorConstraintInterface {
  validate(value: any, args: ValidationArguments) {
    if (typeof value !== 'string') return false;

    try {
      const decimal = new Decimal(value);

      if (decimal.isNaN()) return false;

      const minDecimalPlaces: number = args.constraints[0];
      const maxDecimalPlaces: number = args.constraints[1];

      const greaterThanEqualToMin = decimal.decimalPlaces() >= minDecimalPlaces;
      const lessThanEqualToMax = decimal.decimalPlaces() <= maxDecimalPlaces;

      return greaterThanEqualToMin && lessThanEqualToMax;
    } catch (err) {
      return false;
    }
  }

  defaultMessage(args: ValidationArguments) {
    return `${args.property} must be a numeric string with '${args.constraints[0]}' to '${args.constraints[1]}' decimal places.`;
  }
}

export function IsNumericString(
  minDecimalPlaces = 0,
  maxDecimalPlaces = 2,
  validationOptions?: ValidationOptions,
) {
  return (object: any, propertyName: string) => {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [minDecimalPlaces, maxDecimalPlaces],
      validator: IsNumericStringConstraint,
    });
  };
}
