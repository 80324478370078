import { Expose } from 'class-transformer';
import { IsEnum, IsInt, IsOptional, IsString } from 'class-validator';
import CustomIsNotEmpty from '../../../common/custom-class-validators/CustomIsNotEmpty';
import CustomLength from '../../../common/custom-class-validators/CustomLength';
import { IsValidStreet2 } from '../custom-class-validators/IsValidStreet2';
import { States } from '../enums/states.enum';

export class UpdateShippingAddressDto {
  @Expose()
  @IsString()
  @CustomLength(3, 100, 'Company Name')
  name: string;

  @Expose()
  @IsString()
  @CustomLength(3, 35, 'Street')
  @CustomIsNotEmpty('Street')
  street1: string;

  @Expose()
  @IsOptional()
  @IsString()
  @CustomLength(3, 35, 'Street')
  @IsValidStreet2()
  street2?: string;

  @Expose()
  @IsString()
  @CustomLength(3, 30, 'City')
  @CustomIsNotEmpty('City')
  city: string;

  @Expose()
  @IsEnum(States, {
    message: 'State must be populated from the list provided.',
  })
  @CustomIsNotEmpty('State')
  state: States;

  @Expose()
  @IsString()
  @CustomLength(5, 11, 'Zip')
  @CustomIsNotEmpty('Zip')
  zip: string;

  @Expose()
  @IsOptional()
  @IsInt()
  routeId?: number;
}
