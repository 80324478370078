import { IsString } from 'class-validator';
import { IsValidPassword } from '../../../common/custom-class-validators/IsValidPassword';
import { Match } from '../../../common/custom-class-validators/Match';

export class ResetPasswordDto {
  @IsString()
  userId: string;

  @IsValidPassword()
  password: string;

  @Match('password')
  passwordConfirm: string;
}
