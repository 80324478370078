import useConfirmDialog from '../../ui/dialog/useConfirmDialog';
import useModal from '../../ui/modal/useModal';
import TabPanel from '../../ui/tabs/TabPanel';
import { useAdminLayoutContext } from '../../layout/context/AdminLayoutContext';
import { ViewProductDto } from '../../../lib/api/products/dto/view-product.dto';
import ProductOptionsList from '../../product-options/ProductOptionsList';
import {
  getRowsPerPageDefault,
  TableState,
} from '../../../lib/util/table-util';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  ViewChildProductOptionDto,
  ViewProductOptionDto,
} from '../../../lib/api/product-options/dto/view-product-option.dto';
import {
  getProductOptions,
  ProductQueryKeys,
} from '../../../lib/api/products/products.service';
import { useQuery } from '@tanstack/react-query';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import AddProductOptionValueForm from '../../product-options/AddProductOptionValueForm';
import {
  ViewProductOptionValueDto,
  ViewProductOptionValueValuesDto,
} from './../../../lib/api/product-option-values/dto/view-product-option-value.dto';
import { ViewProductExtendedDto } from '../../../lib/api/products/dto/view-product-extended.dto';

interface Props {
  label: string;
  tabValue: number;
  index: number;
  product: ViewProductExtendedDto;
}
export default function ProductOptionsTab({
  label,
  tabValue,
  index,
  product,
}: Props) {
  const { handleDetailsDrawerClose } =
    useAdminLayoutContext<ViewProductExtendedDto>();
  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();

  const [tableState, setTableState] = useState<
    TableState<ViewProductOptionDto>
  >({
    pagination: { current: 1, pageSize: getRowsPerPageDefault() },
    filters: null,
    sorter: null,
  });

  const [tableStateDebounced] = useDebounce(tableState, 500);

  const [optionToAddValueTo, setOptionToAddValueTo] = useState<
    ViewProductOptionDto | ViewChildProductOptionDto
  >();

  const [parentOptionValue, setParentOptionValue] =
    useState<null | ViewProductOptionValueValuesDto>(null);

  const launchAddProductOptionModal = (
    option: ViewProductOptionDto | ViewChildProductOptionDto,
    parentOptionValue?: ViewProductOptionValueValuesDto,
  ) => {
    setOptionToAddValueTo(option);
    parentOptionValue
      ? setParentOptionValue(parentOptionValue)
      : setParentOptionValue(null);

    handleLaunchModal();
  };

  const onAddProductOptionValueSuccess = () => {
    handleCloseModal();
  };

  const onDeleteProductSuccess = () => {
    handleDetailsDrawerClose();
  };

  return (
    <>
      <TabPanel value={tabValue} index={index}>
        <ProductOptionsList
          product={product}
          handleAddProductOptionValue={launchAddProductOptionModal}
        />
        <FullScreenModal
          title={`Add ${optionToAddValueTo?.name}`}
          open={modalOpen}
          onClose={handleCloseModal}
        >
          <AddProductOptionValueForm
            option={optionToAddValueTo}
            parentOptionValue={parentOptionValue}
            onSuccess={onAddProductOptionValueSuccess}
          />
        </FullScreenModal>
      </TabPanel>
    </>
  );
}
