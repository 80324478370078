import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ConfirmDialog,
  ConfirmDeleteBaseProps,
} from '../ui/dialog/ConfirmDialog';
import { useAppContext } from '../../contexts/AppContext';
import { ViewCustomerDto } from '../../lib/api/customers/dto/view-customer.dto';
import {
  CustomerQueryKeys,
  deleteCustomer,
} from '../../lib/api/customers/customers.service';
import { getErrorMessage } from '../../lib/util/misc-utils';

interface Props extends ConfirmDeleteBaseProps {
  customer: ViewCustomerDto;
}

export function DeleteCustomerConfirmDialog({
  customer,
  isOpen,
  onClose,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  const deleteMutation = useMutation((customerId: string) =>
    deleteCustomer(customerId),
  );

  const handleOnConfirm = async (customerId: string) => {
    //We close the modal regardless of success.
    onClose();
    try {
      const deletedCustomer = await deleteMutation.mutateAsync(customerId);
      queryClient.invalidateQueries([CustomerQueryKeys.findAllCustomers]);
      handleShowMessage(`Deleted customer '${customer.name}'`);
      onSuccess?.(deletedCustomer);
    } catch (err: any) {
      handleShowMessage(getErrorMessage(err), 'error');
    }
  };

  return (
    <ConfirmDialog
      title={`Delete Customer: ${customer.name}`}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() => handleOnConfirm(customer.id)}
    >
      <>
        <p>
          Are you sure you want to delete the customer '{customer.name}'?{' '}
          <strong>This action cannot be undone.</strong>
        </p>
        <p>
          {' '}
          Deleting the customer will also delete the customer's users and
          addresses.
        </p>
        <p>
          All of the orders in the system that are associated with this customer
          will reamin.
        </p>
      </>
    </ConfirmDialog>
  );
}
