import { Grid } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import CardItemTitle from '../ui/CardItemTitle';

export default function MaryvilleAddressCard() {
  return (
    <Grid container spacing={1}>
      <CardItemTitle text={`Pickup @ Maryville`} />
      <Grid item xs={2} sm={1}>
        <MapIcon sx={{ color: 'info.main' }} />
      </Grid>
      <Grid item xs={10} sm={11}>
        <span>
          736 Alcoa Trail <br />
          Maryville, TN 37804
        </span>
      </Grid>
    </Grid>
  );
}
