import { Navigate } from 'react-router-dom';
import useAuth from '../../lib/api/auth/useAuth';
import StorePageCustomer from './customer-users/StorePageCustomer';
import StorePageInternal from './popes-users/StorePageInternal';

export default function StorePageSwitch() {
  const { authed, loggedInUser } = useAuth();

  if (!authed()) return <Navigate to="/auth/login" />;

  if (loggedInUser.isAdmin || loggedInUser.isRetail)
    return <StorePageInternal />;
  return <StorePageCustomer />;
}
