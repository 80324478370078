import { Button, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import {
  CustomerQueryKeys,
  getAllCustomers,
} from '../../../lib/api/customers/customers.service';
import { CreateOrderDto } from '../../../lib/api/orders/dto/create-order.dto';
import RhfAutocomplete from '../../ui/forms/RhfAutocomplete';

interface Props {
  onClickAddCustomer: () => void;
}

export default function CustomerPartial({ onClickAddCustomer }: Props) {
  const {
    formState: { errors },
    control,
  } = useFormContext<CreateOrderDto>();

  const {
    isError,
    data: customers,
    error,
    isFetching,
  } = useQuery([CustomerQueryKeys.findAllCustomers], () => getAllCustomers());

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5">
          Who is this order for?
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <RhfAutocomplete
          control={control}
          name="customerId"
          defaultText="Select Customer"
          optionsAsArray={customers?.data || []}
          required
        />
      </Grid>

      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button onClick={onClickAddCustomer}>Create New Customer</Button>
      </Grid>
    </Grid>
  );
}
