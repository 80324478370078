import { Chip } from '@mui/material';
import { CustomerType } from '../../lib/api/customers/dto/view-customer.dto';

interface Props {
  type: CustomerType;
  abbreviated?: boolean;
}

export default function CustomerTypeChip({ type, abbreviated }: Props) {
  const lableMap = {
    standard: {
      [CustomerType.Premium]: 'Premium',
      [CustomerType.PopesRetailCenter]: 'Popes Retail Center',
      [CustomerType.Standard]: 'Standard',
    },
    abbreviated: {
      [CustomerType.Premium]: 'P',
      [CustomerType.PopesRetailCenter]: 'R',
      [CustomerType.Standard]: 'S',
    },
  };

  let labels;

  if (abbreviated) {
    labels = lableMap.abbreviated;
  } else {
    labels = lableMap.standard;
  }

  switch (type) {
    case CustomerType.Premium:
      return (
        <Chip
          variant={abbreviated ? 'outlined' : 'filled'}
          label={labels[CustomerType.Premium]}
          color="primary"
          size={abbreviated ? 'small' : 'medium'}
        />
      );
    case CustomerType.PopesRetailCenter:
      return (
        <Chip
          variant={abbreviated ? 'outlined' : 'filled'}
          label={labels[CustomerType.PopesRetailCenter]}
          color="secondary"
          size={abbreviated ? 'small' : 'medium'}
        />
      );
    case CustomerType.Standard:
      return (
        <Chip
          variant={abbreviated ? 'outlined' : 'filled'}
          label={labels[CustomerType.Standard]}
          color="default"
          size={abbreviated ? 'small' : 'medium'}
        />
      );
    default:
      return (
        <Chip
          variant={abbreviated ? 'outlined' : 'filled'}
          label={`Unknwon: ${type}`}
          color="warning"
        />
      );
  }
}
