import { Alert, Button, Stack, Tooltip } from '@mui/material';
import { useCartContext } from '../../contexts/CartContext';
import InfoIcon from '@mui/icons-material/Info';

export default function ExpiredAlert() {
  const { cart, handleRemoveAllItemsFromCart, handleRefreshCart } =
    useCartContext();

  if (!cart) return null;

  if (cart.items.length === 0) return null;

  return (
    <Alert severity="error" sx={{ mt: 1 }}>
      <span>
        Your cart is either expired or for an old order week. You need to either{' '}
        <Tooltip
          title="A Refresh automatically attempts to re-reserve the items in your
          cart"
        >
          <span
            style={{
              fontWeight: 600,
            }}
          >
            Refresh
            <InfoIcon
              sx={{
                fontSize: '0.9rem',
                position: 'relative',
                top: '-6px',
                paddingTop: '2px',
              }}
            />
          </span>
        </Tooltip>{' '}
        or{' '}
        <Tooltip
          title="Clearing automatically removes all the itesm from your cart.
          cart"
        >
          <span
            style={{
              fontWeight: 600,
            }}
          >
            Clear
            <InfoIcon
              sx={{
                fontSize: '0.9rem',
                position: 'relative',
                top: '-6px',
                paddingTop: '2px',
              }}
            />
          </span>
        </Tooltip>{' '}
        your cart to continue shopping.
      </span>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Button
          sx={{ width: '50%' }}
          variant="contained"
          onClick={() => handleRefreshCart(cart.id)}
        >
          Refresh Cart
        </Button>
        <Button
          sx={{ width: '50%' }}
          variant="contained"
          color="secondary"
          onClick={() => handleRemoveAllItemsFromCart(cart.id)}
        >
          Clear Cart
        </Button>
      </Stack>
    </Alert>
  );
}
