import { plainToInstance } from 'class-transformer';
import { buildFindAllQueryParams, getHeaders } from '../../util/http-utils';
import { TableState } from '../../util/table-util';
import { apiClientFactory } from '../../common/clients';
import { ViewManyDto } from '../../common/dto/view-many.dto';
import { CreateOrderDto } from './dto/create-order.dto';
import { ViewOrderExtendedDto } from './dto/view-order-extended.dto';
import { ViewOrderDto } from './dto/view-order.dto';
import { UpdateOrderStatusOrFlagsDto } from './dto/update-order-status-or-flags.dto';
import { UpdateOrderNoteDto } from './dto/update-order-note.dto';
import { UpdateOrderPODto } from './dto/update-order-po.dto';
import { UpdateOrderShipToDto } from './dto/update-order-shipto-address.dto';

const apiClient = apiClientFactory();

/****************************
Orders
****************************/

const resource = 'orders';

export enum OrderQueryKeys {
  'findOrderById' = 'FindOrderById',
  'findAllOrders' = 'FindAllOrders',
}

export async function createOrder(
  createOrderDto: CreateOrderDto,
): Promise<ViewOrderExtendedDto> {
  const { data } = await apiClient.post(`v1/${resource}`, createOrderDto, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewOrderExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function getAllOrders(
  tableState?: TableState<ViewOrderDto>,
): Promise<ViewManyDto<ViewOrderDto>> {
  const queryParams = buildFindAllQueryParams<ViewOrderDto>(tableState);

  let requestUrl = `v1/${resource}`;
  if (queryParams) requestUrl = `${requestUrl}?${queryParams}`;

  const { data } = await apiClient.get(requestUrl, {
    headers: getHeaders(),
  });

  return {
    ...data,
    data: plainToInstance(ViewOrderDto, data.data, {
      excludeExtraneousValues: true,
    }),
  };
}

export async function getOrderById(
  orderId: string,
): Promise<ViewOrderExtendedDto> {
  const { data } = await apiClient.get(`v1/${resource}/${orderId}`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewOrderExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function updateOrderFlags(
  id: string,
  updateOrderFlagsDto: UpdateOrderStatusOrFlagsDto,
): Promise<ViewOrderExtendedDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${id}`,
    updateOrderFlagsDto,
    {
      headers: getHeaders(),
    },
  );

  return plainToInstance(ViewOrderExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function updateOrderNote(
  id: string,
  updateOrderNoteDto: UpdateOrderNoteDto,
): Promise<ViewOrderExtendedDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${id}`,
    updateOrderNoteDto,
    {
      headers: getHeaders(),
    },
  );

  return plainToInstance(ViewOrderExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function updateOrderPO(
  id: string,
  updateOrderPODto: UpdateOrderPODto,
): Promise<ViewOrderExtendedDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${id}`,
    updateOrderPODto,
    {
      headers: getHeaders(),
    },
  );

  return plainToInstance(ViewOrderExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function updateOrderShipTo(
  id: string,
  updateOrderShipToDto: UpdateOrderShipToDto,
): Promise<ViewOrderExtendedDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${id}/update-delivery-method`,
    updateOrderShipToDto,
    {
      headers: getHeaders(),
    },
  );

  return plainToInstance(ViewOrderExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function deleteOrder(orderId: string): Promise<ViewOrderDto> {
  const { data } = await apiClient.delete(`v1/${resource}/${orderId}`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewOrderDto, data, { excludeExtraneousValues: true });
}
