import { Box } from '@mui/material';
import ActivateUserForm from './../../components/auth/ActivateUserForm';

export default function ActivateAccountPage() {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <ActivateUserForm />
    </Box>
  );
}
