import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ConfirmDialog,
  ConfirmDeleteBaseProps,
} from '../ui/dialog/ConfirmDialog';
import { useAppContext } from '../../contexts/AppContext';
import { ViewProductOptionValueValuesDto } from '../../lib/api/product-option-values/dto/view-product-option-value.dto';
import { deleteProductOptionValue } from '../../lib/api/product-option-values/product-option-values.service';
import { ProductQueryKeys } from '../../lib/api/products/products.service';
import { getErrorMessage } from '../../lib/util/misc-utils';

interface Props extends ConfirmDeleteBaseProps {
  productOptionValue: ViewProductOptionValueValuesDto;
}

export function DeleteProductOptionValueConfirmDialog({
  productOptionValue,
  isOpen,
  onClose,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  const deleteMutation = useMutation((optionValueId: number) =>
    deleteProductOptionValue(optionValueId),
  );

  const handleOnConfirm = async (optionValueId: number) => {
    //We close the modal regardless of success.
    onClose();
    try {
      const deletedOptionValue = await deleteMutation.mutateAsync(
        optionValueId,
      );
      queryClient.invalidateQueries([ProductQueryKeys.findProductById]);
      queryClient.invalidateQueries([ProductQueryKeys.findProductVariants]);
      handleShowMessage(`Deleted option value '${productOptionValue.value}'`);
      onSuccess?.(deletedOptionValue);
    } catch (err: any) {
      handleShowMessage(getErrorMessage(err), 'error');
    }
  };

  return (
    <ConfirmDialog
      title={`Delete Option Value: ${productOptionValue.value}`}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() => handleOnConfirm(productOptionValue.id)}
    >
      <>
        <p>
          Are you sure you want to delete the option value '
          {productOptionValue.value}'?{' '}
          <strong>This action cannot be undone.</strong>
        </p>
        <p>
          {' '}
          Deleting the option value will also delete any child options that
          depend on it and all of the variants associated with this option
          value.
        </p>
      </>
    </ConfirmDialog>
  );
}
