import { Grid } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useEffect } from 'react';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { useAppContext } from '../../contexts/AppContext';
import FromSubmitButton from '../ui/forms/FormSubmitButton';
import {
  createDeliveryRoute,
  DeliveryRouteQueryKeys,
  updateDeliveryRoute,
} from '../../lib/api/delivery-routes/delivery-routes.service';
import { ViewDeliveryRouteDto } from '../../lib/api/delivery-routes/dto/view-delivery-route.dto';
import { CreateOrUpdateDeliveryRouteDto } from '../../lib/api/delivery-routes/dto/create-or-update-delivery-route.dto';
import useFormSubmitErrorAlert from '../ui/forms/useFormSubmitErrorAlert';
import CustomForm, { onSuccessCallback } from '../ui/forms/CustomForm';
import RhfTextField from '../ui/forms/RhfTextField';
import { VALIDATION_ERR_DELAY } from '../../config/AppConstants';

interface Props {
  deliveryRoute?: ViewDeliveryRouteDto;
  onSuccess?: onSuccessCallback;
}

export default function AddOrUpdateDeliveryRouteForm({
  deliveryRoute,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();
  const queryClient = useQueryClient();

  const action = deliveryRoute
    ? 'Update Delivery Route'
    : 'Create Delivery Route';

  const defaultValues: CreateOrUpdateDeliveryRouteDto = deliveryRoute
    ? plainToInstance(
        CreateOrUpdateDeliveryRouteDto,
        instanceToPlain(deliveryRoute),
        {
          excludeExtraneousValues: true,
        },
      )
    : {
        name: null,
        description: null,
      };

  //Mutations
  const createMutation = useMutation(
    (createDeliveryRouteDto: CreateOrUpdateDeliveryRouteDto) =>
      createDeliveryRoute(createDeliveryRouteDto),
  );

  const updateMutation = useMutation(
    (updateDeliveryRouteDto: CreateOrUpdateDeliveryRouteDto) =>
      updateDeliveryRoute(deliveryRoute.id, updateDeliveryRouteDto),
  );

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isSubmitted },
    setError,
    setFocus,
    control,
  } = useForm<CreateOrUpdateDeliveryRouteDto>({
    resolver: classValidatorResolver(CreateOrUpdateDeliveryRouteDto),
    mode: 'onTouched',
    defaultValues,
    delayError: VALIDATION_ERR_DELAY,
  });

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<CreateOrUpdateDeliveryRouteDto>();

  const onSubmit: SubmitHandler<CreateOrUpdateDeliveryRouteDto> = async (
    formData: CreateOrUpdateDeliveryRouteDto,
  ) => {
    clearFormSubmitError();

    try {
      let result: ViewDeliveryRouteDto;

      if (deliveryRoute) {
        result = await updateMutation.mutateAsync(formData);
        queryClient.invalidateQueries([
          DeliveryRouteQueryKeys.findDeliveryRouteById,
          { id: deliveryRoute.id },
        ]);
      } else {
        result = await createMutation.mutateAsync(formData);
      }

      queryClient.invalidateQueries([
        DeliveryRouteQueryKeys.findAllDeliveryRoutes,
      ]);

      handleShowMessage(
        `${deliveryRoute ? 'Updated' : 'Created'} Delviery Route '${
          result.name
        }'`,
      );
      onSuccess?.(result);
    } catch (err: any) {
      processFormSubmitError(err, setError);
    }
  };

  return (
    <CustomForm
      serverErrorMessage={serverErrorMessage}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RhfTextField control={control} name="name" label="Name" />
        </Grid>
        <Grid item xs={12}>
          <RhfTextField
            name="description"
            label="Description"
            control={control}
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <FromSubmitButton
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            isValid={isValid}
            text={action}
          />
        </Grid>
      </Grid>
    </CustomForm>
  );
}
