import { MouseEvent } from 'react';
import { SxProps, Theme } from '@mui/material';
import ViewCartDto from '../api/carts/dto/view-cart.dto';
import { ViewManyDto } from '../common/dto/view-many.dto';
import { getItemFromLocalStoragePref } from './local-storage-utils';
import { DtoStringKeysAsPath } from './type-utils';

//======================
//GENERAL
//======================

export interface BaseTableProps<ViewDto extends ObjectWithId> {
  apiRes?: ViewManyDto<ViewDto> | undefined;
  data?: ViewDto[];
  isFetching: boolean;
  setTableState: React.Dispatch<React.SetStateAction<TableState<ViewDto>>>;
  tableState: TableState<ViewDto>;
  onRowClick?: (data: ViewDto, event?: MouseEvent) => void;
  onRefresh?: () => void;
  onDownload?: () => void;
  showHeading?: boolean;
  fullWidth?: boolean;
  paperElevation?: number;
  hoverDisabled?: boolean;
}

export interface ObjectWithId {
  id: string | number;
}

export interface TableCol<ViewDto extends ObjectWithId> {
  id: DtoStringKeysAsPath<ViewDto>;
  label: string;
  align?: 'right' | 'left' | 'center';
  filterType?: AllowedFilterTypes;
  defaultSort?: Order;
  sortable?: boolean;
  sortKey?: DtoStringKeysAsPath<ViewDto>;
  widthPct?: number;
  headerCellSx?: SxProps<Theme>;
  bodyCellSx?: SxProps<Theme>;
  render?: (data: any) => React.ReactNode;
}

//======================
//TABLE STATE
//======================
export interface TableState<ViewDto extends ObjectWithId> {
  pagination: TablePaginationConfig | null;
  filters: TableStateFilters<ViewDto>;
  sorter: TableStateSorter<ViewDto>;
}

export type TableStateSorter<ViewDto extends ObjectWithId> =
  SorterParams<ViewDto> | null;
export type TableStateFilters<ViewDto extends ObjectWithId> =
  FindAllFilterParams<ViewDto> | null;

//======================
//TABLE PAGINATION
//======================
export const DEFAULT_PAGE_SIZE = 10;

export interface TablePaginationConfig {
  current: number;
  pageSize: number;
}

export function getRowsPerPageDefault() {
  const rowsPerPage = getItemFromLocalStoragePref('rowsPerPage');
  return rowsPerPage || DEFAULT_PAGE_SIZE;
}

//======================
//TABLE FILTERS
//======================
export type FindAllFilterParams<ViewDto extends ObjectWithId> = Partial<
  Record<
    DtoStringKeysAsPath<ViewDto>,
    | EqualsFilter
    | ContainsFilter
    | InFilter
    | GreaterThanFilter
    | GreaterThanEqualFilter
    | LessThanFilter
    | LessThanEqualFilter
    | RangeFilter
  >
>;

export interface SearchFilter<ViewDto extends ObjectWithId> {
  field: DtoStringKeysAsPath<ViewDto>;
  type: AllowedFilterTypes;
  value: any;
}

export type AllowedFilterTypes =
  | keyof EqualsFilter
  | keyof ContainsFilter
  | keyof InFilter
  | keyof GreaterThanFilter
  | keyof GreaterThanEqualFilter
  | keyof LessThanFilter
  | keyof LessThanEqualFilter
  | keyof RangeFilter;

interface EqualsFilter {
  eq: string | number;
}

interface ContainsFilter {
  contains: string;
}

interface InFilter {
  in: string[] | number[];
}

interface GreaterThanFilter {
  gt: number | string;
}

interface GreaterThanEqualFilter {
  gte: number | string;
}

interface LessThanFilter {
  lt: number | string;
}

interface LessThanEqualFilter {
  lte: number | string;
}

interface RangeFilter {
  range: [number | string, number | string];
}

//======================
//TABLE SORTING
//======================
export type Order = 'asc' | 'desc';

export type SorterParams<ViewDto extends ObjectWithId> = Partial<
  Record<keyof ViewDto | any, Order>
>;
