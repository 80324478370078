import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Skeleton } from '@mui/material';
import {
  BaseTableProps,
  DEFAULT_PAGE_SIZE,
  FindAllFilterParams,
  getRowsPerPageDefault,
  ObjectWithId,
  Order,
  SearchFilter,
  SorterParams,
  TableCol,
} from '../../../../lib/util/table-util';
import Empty from '../../custom-icons/Empty';
import CustomTableHead from '../shared/CustomTableHead';
import { setItemInLocalStoragePref } from '../../../../lib/util/local-storage-utils';
import { getArrayRange } from '../../../../lib/util/misc-utils';
import _ from 'lodash';
import { StyledTableRow } from '../shared/StyledTableRow';
import { DtoStringKeysAsPath } from '../../../../lib/util/type-utils';

interface PaginatedTableProps<ViewDto extends ObjectWithId>
  extends BaseTableProps<ViewDto> {
  columns: TableCol<ViewDto>[];
}

export default function PaginatedTable<ViewDto extends ObjectWithId>({
  columns,
  apiRes,
  data,
  isFetching,
  setTableState,
  tableState,
  onRowClick,
  onRefresh,
  onDownload,
  paperElevation,
  fullWidth,
  hoverDisabled,
}: PaginatedTableProps<ViewDto>) {
  //If provided data, mock an apiRes for our table to use
  if (data) {
    // const filteredData = data.filter()
    if (tableState.filters) {
      //filter the data down based on our filters
      // const filteredData = data.filter(d => )
    }
    apiRes = {
      data: data,
      pagination: {
        current: 1,
        pageSize: getRowsPerPageDefault(),
        total: data.length,
      },
    };
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  let emptyRows = getRowsPerPageDefault();

  if (apiRes?.data && !isFetching)
    emptyRows = apiRes.pagination.pageSize - apiRes.data.length;

  const handleRequestSort = (property: DtoStringKeysAsPath<ViewDto> | 'id') => {
    //get existing sort order
    const { order, orderBy } = getSortOrder();
    const currentlyIsAsc = orderBy === property && order === 'asc';

    //build new sorter
    const sorter = {
      [property]: currentlyIsAsc ? 'desc' : 'asc',
    } as SorterParams<ViewDto>;

    setTableState({
      pagination: tableState.pagination
        ? { ...tableState.pagination, current: 1 }
        : { current: 1, pageSize: DEFAULT_PAGE_SIZE },
      filters: tableState.filters ? { ...tableState.filters } : null,
      sorter: sorter,
    });
  };

  const handleSearch = (filter: SearchFilter<ViewDto>) => {
    if (filter.value === '' && tableState.filters) {
      const filtersCopy = { ...tableState.filters };
      delete filtersCopy[filter.field];
      setTableState({
        pagination: tableState.pagination
          ? { ...tableState.pagination, current: 1 }
          : { current: 1, pageSize: getRowsPerPageDefault() },
        filters: filtersCopy,
        sorter: null,
      });
    } else {
      const filterField = {
        [filter.field]: { [filter.type]: filter.value },
      } as FindAllFilterParams<ViewDto>;
      setTableState({
        pagination: tableState.pagination
          ? { ...tableState.pagination, current: 1 }
          : { current: 1, pageSize: getRowsPerPageDefault() },
        filters: tableState.filters
          ? {
              ...tableState.filters,
              ...filterField,
            }
          : filterField,
        sorter: null,
      });
    }
  };

  const handlePageChange = (event: any, page: number) => {
    setTableState({
      filters: tableState.filters ? { ...tableState.filters } : null,
      sorter: tableState.sorter ? { ...tableState.sorter } : null,
      pagination: tableState.pagination
        ? { ...tableState.pagination, current: page }
        : { current: 1, pageSize: getRowsPerPageDefault() },
    });
  };

  const handlePageSizeChange = (pageSize: number) => {
    setItemInLocalStoragePref('rowsPerPage', pageSize);
    setTableState({
      filters: tableState.filters ? { ...tableState.filters } : null,
      sorter: tableState.sorter ? { ...tableState.sorter } : null,
      pagination: { current: 1, pageSize },
    });
  };

  const getSortOrder = (): {
    order: Order;
    orderBy: DtoStringKeysAsPath<ViewDto> | 'id';
  } => {
    if (!tableState.sorter) {
      //no sorter, if default specified on columns use it.
      //otherwise use id asc.
      const defaultSortColumn = columns.find((col) => col.defaultSort);

      return {
        order: defaultSortColumn?.defaultSort || 'asc',
        orderBy: defaultSortColumn?.id || 'id',
      };
    } else {
      //{name:'asc'}
      const keys = Object.keys(tableState.sorter);
      if (!keys) return { order: 'asc', orderBy: 'id' };

      const orderBy = keys[0] as DtoStringKeysAsPath<ViewDto> | 'id';
      const order = tableState.sorter[orderBy] as Order;
      return {
        order,
        orderBy,
      };
    }
  };

  const { order, orderBy } = getSortOrder();

  return (
    <Paper
      elevation={paperElevation === 0 ? 0 : paperElevation || 10}
      sx={{ maxWidth: fullWidth ? '100%' : '95%', overflowX: 'auto' }}
    >
      <Table aria-labelledby="Table" size="small" stickyHeader>
        <CustomTableHead<ViewDto>
          columns={columns}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          onSearch={handleSearch}
          onRefresh={onRefresh}
          onDownload={onDownload}
          filters={tableState.filters}
          sorter={tableState.sorter}
        />
        <TableBody>
          {isFetching && (
            <>
              <TableRow>
                {columns.map((col) => (
                  <TableCell
                    key={col.id}
                    align="right"
                    sx={col.bodyCellSx}
                  ></TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell colSpan={columns.length}>
                  {getArrayRange(1, emptyRows, 1).map((num) => (
                    <Skeleton height={44} key={num} />
                  ))}
                </TableCell>
              </TableRow>
            </>
          )}
          {apiRes?.data && apiRes.data.length > 0 && !isFetching && (
            <>
              {apiRes.data.map((data, index) => (
                <StyledTableRow
                  hover={!hoverDisabled}
                  onClick={
                    onRowClick ? (e) => onRowClick(data, e) : (): any => null
                  }
                  tabIndex={-1}
                  key={data.id}
                >
                  {columns.map((col) => (
                    <TableCell
                      key={col.id}
                      align={col.align || 'left'}
                      sx={col.bodyCellSx}
                    >
                      {col.render ? col.render(data) : _.get(data, col.id)}
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 44 * emptyRows,
                  }}
                >
                  <TableCell colSpan={columns.length} />
                </TableRow>
              )}
            </>
          )}
          {apiRes?.data.length === 0 && !isFetching && (
            <>
              <TableRow>
                {columns.map((col) => (
                  <TableCell key={col.id} align="right"></TableCell>
                ))}
              </TableRow>
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell
                  colSpan={columns.length}
                  sx={{ textAlign: 'center' }}
                >
                  <Empty />
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((col) => (
                  <TableCell key={col.id} align="right"></TableCell>
                ))}
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={apiRes?.pagination?.total || 0}
        rowsPerPage={getRowsPerPageDefault()}
        page={apiRes?.pagination?.current ? apiRes.pagination.current - 1 : 0}
        onPageChange={(event, page) => handlePageChange(event, page + 1)}
        onRowsPerPageChange={(event) =>
          handlePageSizeChange(parseInt(event.target.value))
        }
      />
    </Paper>
  );
}
