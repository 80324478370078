import { ListItem, Typography } from '@mui/material';
import ViewCartItemExtendedDto from '../../lib/api/carts/dto/view-cart-item-extended.dto';
import { getUnique } from '../../lib/util/misc-utils';
import CartSummaryListItemRow from './CartSummaryListItemRow';
import { uniqBy } from 'lodash';
import { CartSummaryListComplexProductSection } from './CartSummaryListComplexProductSection';

interface Props {
  name: string;
  items: ViewCartItemExtendedDto[];
}
export function CartSummaryListCategoryRow({ name, items }: Props) {
  const simpleItemsForCategory = items
    .filter((i) => i.product.category.name === name && !i.product.hasVariants)
    .sort((a, b) =>
      a.product.name === b.product.name
        ? 0
        : a.product.name > b.product.name
        ? 1
        : -1,
    );

  const productsOfComplexItemsForCategory = items
    .filter((i) => i.product.category.name === name && i.product.hasVariants)
    .map((i) => i.product);

  const uniqueProducts = uniqBy(
    productsOfComplexItemsForCategory,
    (e) => e.id,
  ).sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <>
      <ListItem>
        <Typography
          component="p"
          sx={{
            fontSize: '1.3rem',
            textDecoration: 'underline',
            fontWeight: 600,
            color: 'secondary.main',
          }}
        >
          {name}
        </Typography>
      </ListItem>
      {simpleItemsForCategory.map((i) => (
        <CartSummaryListItemRow key={i.id} item={i} />
      ))}
      {uniqueProducts.map((product) => (
        <CartSummaryListComplexProductSection
          key={product.id}
          product={product}
          items={items}
        />
      ))}
    </>
  );
}
