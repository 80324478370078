import { ElementType, ReactElement, useState } from 'react';
import { styled } from '@mui/material';
import MuiTypography from '@mui/material/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  children: string | ReactElement;
  expandedComponent: ReactElement;
  fontWeight?: number;
  expandIconSize?: 'small' | 'large' | 'medium';
  component?: ElementType;
  variant?:
    | 'body1'
    | 'body2'
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2';
}

export default function ExpandableTypography({
  children,
  expandedComponent,
  expandIconSize,
  ...rest
}: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded((prev) => !prev);
  };

  const TypographyStyled = styled(MuiTypography)(({ theme }) => ({
    '&:hover': {
      color: theme.palette.primary.light,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
  }));

  return (
    <>
      <TypographyStyled
        {...rest}
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
      >
        {children}
        {isExpanded ? (
          <ExpandLessIcon fontSize={expandIconSize || 'large'} />
        ) : (
          <ExpandMoreIcon fontSize={expandIconSize || 'large'} />
        )}
      </TypographyStyled>
      {isExpanded && expandedComponent}
    </>
  );
}
