import { Divider, Grid } from '@mui/material';
import { ViewSellableVariantDto } from '../../../lib/api/variants/dto/view-sellable-variant.dto';
import ProductVariantQtyInput from './parts/ProductVariantQtyInput';
import SellableItemDescription from './parts/SellableItemDescription';
import SellableItemStats from './parts/SellableItemStats';
import { ViewSellableProductDto } from '../../../lib/api/products/dto/view-sellable-product.dto';

interface Props {
  product: ViewSellableProductDto;
  variant: ViewSellableVariantDto;
  showBottomBorder: boolean;
}

export default function VariantRow({
  product,
  variant,
  showBottomBorder,
}: Props) {
  if (!variant) return;

  return (
    <Grid
      container
      alignItems="center"
      flexDirection="row"
      justifyContent="flex-start"
      columnSpacing={2}
      rowSpacing={0}
    >
      <Grid item sm={12} md={4}>
        <SellableItemDescription
          name={variant.name}
          description={variant.description}
          pw={variant.isProvenWinner}
          sellToPopes={variant.sellToPopes}
          sellToPremium={variant.sellToPremium}
          sellToStandard={variant.sellToStandard}
          imageUrl={variant.defaultImageFileUrl}
          productOrVariantId={variant.id}
          type="variant"
          defaultImageFileNameKey={variant.defaultImageFileNameKey}
        />
      </Grid>
      <Grid item sm={6} md={3}>
        <ProductVariantQtyInput
          label="Qty"
          name={`${product.id}-${variant.id}-qty`}
          decimals={2}
          product={product}
          variant={variant}
        />
      </Grid>
      <Grid item sm={6} md={5}>
        <SellableItemStats
          availableQty={variant.availableQty}
          orderIncrement={product.orderIncrement}
          orderLimit={variant.orderLimit}
          price={product.price}
        />
      </Grid>
      {showBottomBorder && (
        <Grid item xs={12}>
          <Divider
            sx={{
              borderColor: 'grey.500',
              borderStyle: 'dotted',
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}
