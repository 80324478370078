import { Context, createContext, PropsWithChildren, useContext } from 'react';
import { ObjectWithId } from '../../../lib/util/table-util';
import { DetailsDrawerConfig } from './AdminLayoutContext';

interface CustomerLayoutContextType<ViewDto extends ObjectWithId> {
  handleTableRowClick: (data: ViewDto) => void;
  handleDetailsDrawerClose: () => void;
  setDetailsDrawerConfig: ({ open, width }: DetailsDrawerConfig) => void;
  activeRecord: ViewDto | undefined;
  detailsDrawerConfig: DetailsDrawerConfig;
}

const CustomerLayoutContext =
  createContext<CustomerLayoutContextType<ObjectWithId> | null>(null);

export const CustomerLayoutProvider = <ViewDto extends ObjectWithId>({
  children,
  ...rest
}: PropsWithChildren<CustomerLayoutContextType<ViewDto>>) => {
  return (
    //@ts-ignore
    <CustomerLayoutContext.Provider value={rest}>
      {children}
    </CustomerLayoutContext.Provider>
  );
};

export const useCustomerLayoutContext = <ViewDto extends ObjectWithId>() => {
  const context = useContext<CustomerLayoutContextType<ViewDto>>(
    CustomerLayoutContext as unknown as Context<
      CustomerLayoutContextType<ViewDto>
    >,
  );
  if (!context)
    throw new Error(
      'useCustomerLayoutContext must be used under CustomerLayoutProvider',
    );
  return context;
};
