import { plainToInstance } from 'class-transformer';
import { getHeaders } from '../../util/http-utils';
import { apiClientFactory } from '../../common/clients';
import { CreateOrUpdateContactDto } from './dto/create-or-update-contact.dto';
import { ViewContactDto } from './dto/view-contact.dto';

const apiClient = apiClientFactory();

const resource = 'contacts';

/****************************
Contacts
****************************/

export async function deleteContact(
  contactId: string,
): Promise<ViewContactDto> {
  const { data } = await apiClient.delete(`v1/${resource}/${contactId}`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewContactDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function updateContact(
  contactId: string,
  updateContactDto: CreateOrUpdateContactDto,
): Promise<ViewContactDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${contactId}`,
    updateContactDto,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewContactDto, data, {
    excludeExtraneousValues: true,
  });
}
