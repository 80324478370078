import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@mui/material';
import { getRowsPerPageDefault, TableState } from '../../lib/util/table-util';
import { useDebounce } from 'use-debounce';
import useModal from '../../components/ui/modal/useModal';
import FullScreenModal from '../../components/ui/modal/FullScreenModal';
import { useAdminLayoutContext } from '../../components/layout/context/AdminLayoutContext';
import useAuth from '../../lib/api/auth/useAuth';
import { Navigate } from 'react-router-dom';
import { ViewUserDto } from '../../lib/api/users/dto/view-user.dto';
import { getAllUsers, UserQueryKeys } from '../../lib/api/users/users.service';
import UsersTable from '../../components/users/UsersTable';
import UserDrawerContent from '../../components/users/users-drawer-content/UserDrawerContent';
import DetailsDrawer from '../../components/ui/drawer/DetailsDrawer';
import AddUserForm from '../../components/users/AddUserForm';

export default function UsersPage() {
  const {
    handleTableRowClick,
    handleDetailsDrawerClose,
    detailsDrawerConfig,
    activeRecord,
  } = useAdminLayoutContext<ViewUserDto>();

  const { authed } = useAuth();

  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();

  const [tableState, setTableState] = useState<TableState<ViewUserDto>>({
    pagination: { current: 1, pageSize: getRowsPerPageDefault() },
    filters: null,
    sorter: null,
  });

  const [tableStateDebounced] = useDebounce(tableState, 500);

  const onCreateUserSuccess = () => {
    handleCloseModal();
  };

  const { isError, data, error, isFetching } = useQuery(
    [UserQueryKeys.findAllUsers, tableStateDebounced],
    () => getAllUsers(tableStateDebounced),
  );

  console.log({
    component: 'UsersPage',
    message: 'rendered',
  });

  return !authed() ? (
    <Navigate to="/auth/login" />
  ) : (
    <>
      <UsersTable
        apiRes={data}
        isFetching={isFetching}
        setTableState={setTableState}
        tableState={tableState}
        onRowClick={handleTableRowClick}
      />
      <Button variant="contained" sx={{ mt: 2 }} onClick={handleLaunchModal}>
        Add Admin User
      </Button>
      <FullScreenModal
        title="Create Admin User"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <AddUserForm onSuccess={onCreateUserSuccess} />
      </FullScreenModal>
      {activeRecord && (
        <DetailsDrawer
          open={detailsDrawerConfig.open}
          detailsDrawerWidth={detailsDrawerConfig.width}
          onDrawerClose={handleDetailsDrawerClose}
          title={activeRecord.email}
        >
          <UserDrawerContent userId={activeRecord.id} />
        </DetailsDrawer>
      )}
    </>
  );
}
