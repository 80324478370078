import { Box } from '@mui/material';
import ResetPasswordRequestForm from '../../components/auth/ResetPasswordRequestForm';

export default function ResetPasswordRequestPage() {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <ResetPasswordRequestForm />
    </Box>
  );
}
