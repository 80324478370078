import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactElement } from 'react';
import { Typography } from '@mui/material';

interface MainProps {
  open?: boolean;
  detailsDrawerWidth: number;
}

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'detailsDrawerWidth',
})<MainProps>(({ theme, open, detailsDrawerWidth }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: `-min(${detailsDrawerWidth}px,'100%')`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-start',
}));

interface DrawerProps<ViewDto> {
  open: boolean;
  detailsDrawerWidth: number;
  onDrawerClose: () => any;
  title: string;
  children: ReactElement;
}

export default function DetailsDrawer<ViewDto>({
  open,
  detailsDrawerWidth,
  onDrawerClose,
  title,
  children,
}: DrawerProps<ViewDto>) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Main open={open} detailsDrawerWidth={detailsDrawerWidth}>
        <DrawerHeader />
      </Main>
      <Drawer
        sx={{
          width: `min(100%,${detailsDrawerWidth}px)`,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: `min(100%,${detailsDrawerWidth}px)`,
            zIndex: `zindex.appBar` + 1,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={onDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
          <Typography variant="h6" component="h3" sx={{ lineHeight: 3.25 }}>
            {title}
          </Typography>
        </DrawerHeader>
        {children}
        <Box sx={{ mb: '2rem' }} />
      </Drawer>
    </Box>
  );
}
