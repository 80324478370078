import { Paper, Typography } from '@mui/material';
import TabGroup from '../../components/ui/drawer/DetailsDrawerContent';
import useTabs from '../../components/ui/tabs/useTabs';
import OrdersBacklogTab from './tabs/OrdersBacklogTab';
import { useQuery } from '@tanstack/react-query';
import {
  getStoreStatus,
  StoreQueryKeys,
} from '../../lib/api/store/store.service';
import OrdersPickingTab from './tabs/OrdersPickingTab';
import OrdersShippedTab from './tabs/OrdersShippedTab';
import OrdersStagingTab from './tabs/OrdersStagingTab';

interface Props {
  d?: any;
}

export default function FulfillmentPage({}: Props) {
  const { tabValue, handleChangeTab } = useTabs();

  const {
    isError: storeStatusIsError,
    data: storeStatusRes,
    error: storeStatusError,
    isFetching: storeStatusIsFetching,
  } = useQuery([StoreQueryKeys.findStatus], () => getStoreStatus());

  if (!storeStatusRes) return;

  const { orderWeek, shippingStartAt, shippingEndAt } =
    storeStatusRes.currentWeek;

  const startDate = new Date(shippingStartAt).toLocaleString('en-US', {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  });

  const endDate = new Date(shippingEndAt).toLocaleString('en-US', {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  });

  return (
    <>
      <Typography
        component="h2"
        variant="h6"
        sx={{ fontWeight: 700, color: 'primary.light' }}
      >{`Order Week: ${orderWeek}`}</Typography>
      <Typography
        component="p"
        sx={{ fontWeight: 700, mb: 3, color: 'grey.500' }}
      >{`Shipping from ${startDate} to ${endDate}`}</Typography>
      <Paper elevation={5} sx={{ width: `calc(100% - 80px)` }}>
        <TabGroup tabValue={tabValue} onTabChange={handleChangeTab}>
          <OrdersBacklogTab
            label="Backlog"
            tabValue={tabValue}
            index={0}
            storeStatus={storeStatusRes}
          />
          <OrdersStagingTab label="Staging" tabValue={tabValue} index={1} />
          <OrdersPickingTab
            label="Selected for Picking"
            tabValue={tabValue}
            index={2}
          />
          <OrdersShippedTab label="Shipped" tabValue={tabValue} index={3} />
        </TabGroup>
      </Paper>
    </>
  );
}
