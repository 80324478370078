import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ConfirmDialog,
  ConfirmDeleteBaseProps,
} from '../ui/dialog/ConfirmDialog';
import { useAppContext } from '../../contexts/AppContext';
import { ViewDeliveryRouteDto } from '../../lib/api/delivery-routes/dto/view-delivery-route.dto';
import {
  deleteDeliveryRoute,
  DeliveryRouteQueryKeys,
} from '../../lib/api/delivery-routes/delivery-routes.service';
import { getErrorMessage } from '../../lib/util/misc-utils';

interface Props extends ConfirmDeleteBaseProps {
  deliveryRoute: ViewDeliveryRouteDto;
}

export function DeleteDeliveryRouteConfirmDialog({
  deliveryRoute,
  isOpen,
  onClose,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  const deleteMutation = useMutation((deliveryRouteId: number) =>
    deleteDeliveryRoute(deliveryRouteId),
  );

  const handleOnConfirm = async (deliveryRouteId: number) => {
    //We close the modal regardless of success.
    onClose();
    try {
      const deletedDeliveryRoute = await deleteMutation.mutateAsync(
        deliveryRouteId,
      );
      queryClient.invalidateQueries([
        DeliveryRouteQueryKeys.findAllDeliveryRoutes,
      ]);
      handleShowMessage(`Deleted Delivery Route '${deliveryRoute.name}'`);
      onSuccess?.(deletedDeliveryRoute);
    } catch (err: any) {
      handleShowMessage(getErrorMessage(err), 'error');
    }
  };

  return (
    <ConfirmDialog
      title={`Delete Delivery Route: ${deliveryRoute.name}`}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() => handleOnConfirm(deliveryRoute.id)}
    >
      <>
        <p>
          Are you sure you want to delete the delivery route '
          {deliveryRoute.name}'? <strong>This action cannot be undone.</strong>
        </p>
        <p>
          {' '}
          Deleting the delivery route will unlink this route from all the
          shipping addresses that are currently assigned to it. These addresses
          will no longer be associated with a delivery route.
        </p>
      </>
    </ConfirmDialog>
  );
}
