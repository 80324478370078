interface LocalStoragePreferences {
  rowsPerPage: number;
}

export function getItemFromLocalStoragePref(
  item: keyof LocalStoragePreferences,
) {
  const preferences = localStorage.getItem('preferences');
  if (!preferences) return undefined;

  const prefObj: LocalStoragePreferences = JSON.parse(preferences);

  return prefObj[item] || undefined;
}

export function setItemInLocalStoragePref(
  item: keyof LocalStoragePreferences,
  value: any,
) {
  const preferences = localStorage.getItem('preferences');
  let preferencesObj: LocalStoragePreferences;

  if (preferences) {
    preferencesObj = JSON.parse(preferences);
    preferencesObj[item] = value;
  } else {
    preferencesObj = {
      [item]: value,
    };
  }

  localStorage.setItem('preferences', JSON.stringify(preferencesObj));
}
