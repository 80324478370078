import { Button } from '@mui/material';
import useModal from '../../ui/modal/useModal';
import { AddressType } from '../../../lib/api/addresses/enums/address-type.enum';
import AddShippingAddressForm from '../../addresses/shipping-address/AddShippingAddressForm';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import TabPanel from '../../ui/tabs/TabPanel';
import DrawerPaper from '../../ui/drawer/DrawerPaper';
import AddressList from '../../addresses/list/AddressList';
import { ViewCustomerExtendedDto } from '../../../lib/api/customers/dto/view-customer-extended.dto';

interface Props {
  label: string;
  tabValue: number;
  index: number;
  customer: ViewCustomerExtendedDto;
}

export default function CustomerShippingAddressesTab({
  label,
  tabValue,
  index,
  customer,
}: Props) {
  const {
    handleCloseModal: handleCloseAddModal,
    handleLaunchModal: handleLaunchAddModal,
    modalOpen: addModalOpen,
  } = useModal();

  const onCreateAddressSuccess = () => {
    handleCloseAddModal();
  };

  const handleAddAddress = () => {
    handleLaunchAddModal();
  };

  const shippingAddresses = customer.addresses?.filter(
    (address) => address.type === AddressType.shipping,
  );

  return (
    <>
      <TabPanel value={tabValue} index={index}>
        <Button
          variant="contained"
          onClick={handleAddAddress}
          sx={{ mb: 2, mr: 2 }}
        >
          Add Shipping Address
        </Button>
        <DrawerPaper>
          <AddressList
            title="Shipping Addresses"
            addresses={shippingAddresses}
            customer={customer}
          />
        </DrawerPaper>
      </TabPanel>

      <FullScreenModal
        title={`Create Shipping Address for ${customer.name}`}
        open={addModalOpen}
        onClose={handleCloseAddModal}
      >
        <AddShippingAddressForm
          customerId={customer.id}
          onSuccess={onCreateAddressSuccess}
        />
      </FullScreenModal>
    </>
  );
}
