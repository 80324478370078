import { ViewOrderDto } from '../../lib/api/orders/dto/view-order.dto';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAdminLayoutContext } from '../layout/context/AdminLayoutContext';

interface Props {
  order: ViewOrderDto;
  onClick?: (e: any, order: ViewOrderDto) => void;
}

export default function OrdersTableIdCol({ order, onClick }: Props) {
  const { handleDetailsDrawerClose } = useAdminLayoutContext<ViewOrderDto>();

  const handleLinkClick = (e: any) => {
    e.stopPropagation();
    handleDetailsDrawerClose();
  };

  const orderIdToShow = order.id.substring(0, 8);
  return (
    <>
      {onClick ? (
        <Link component="p" onClick={(e) => onClick(e, order)}>
          {orderIdToShow}
        </Link>
      ) : (
        <Link
          component={RouterLink}
          to={`${order.id}`}
          onClick={handleLinkClick}
        >
          {orderIdToShow}
        </Link>
      )}

      <p>{order.createdAt.toFormat('M/d/yy, h:mm a')}</p>
    </>
  );
}
