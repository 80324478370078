import { InputAdornment, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { cleanNumericString } from '../../../lib/util/misc-utils';
import RequiredInputLabel from './RequiredInputLabel';

interface Props {
  name: string;
  label: string;
  control: Control<any, any>;
  decimals?: number;
  required?: boolean;
  allowNegative?: boolean;
  showPercent?: boolean;
  showMoney?: boolean;
  isAllowed?: (values: NumberFormatValues) => boolean;
}

export default function RhfDecimalInput({
  name,
  label,
  control,
  decimals,
  required,
  allowNegative,
  showPercent,
  showMoney,
  isAllowed,
}: Props) {
  if (!decimals) decimals = 0;

  const addDecimals = (
    value: string,
    onBlur: any,
    onChange: (params: any) => void,
  ) => {
    onBlur(); //trigger react-hook-form's blur to mark field as touched
    //Change to null if nothing is provided
    if (value === null || value === undefined || value === '')
      return onChange(null);

    // Remove everything that is not a number or decimal place.
    value = cleanNumericString(value);

    const decimalIndex = value.indexOf('.');
    const length = value.length;

    if (decimalIndex === -1) {
      //no decimal found add
      value = `${value}.00`;
    } else {
      //decimal found, check its place and add trailing zeros if needed
      const paddingRequired = decimals - (length - 1 - decimalIndex);

      for (let index = 1; index <= paddingRequired; index++) {
        value = `${value}0`;
      }
    }

    onChange(value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, name, value, ref },
        fieldState: { error },
      }) => (
        <NumericFormat
          inputRef={ref}
          name={name}
          allowNegative={allowNegative || false}
          isAllowed={isAllowed || null}
          decimalScale={decimals}
          thousandSeparator=","
          customInput={TextField}
          helperText={error && error.message}
          error={error ? true : false}
          label={required ? <RequiredInputLabel text={label} /> : label}
          variant="outlined"
          InputProps={{
            inputMode: 'numeric',
            endAdornment: showPercent ? (
              <InputAdornment position="start">%</InputAdornment>
            ) : null,
            startAdornment: showMoney ? (
              <InputAdornment position="start">$</InputAdornment>
            ) : null,
          }}
          onValueChange={(v) => onChange(v.value === '' ? null : v.value)}
          onBlur={(e: any) => addDecimals(e.target.value, onBlur, onChange)} //When we blur, lets pad decimals.
          value={value}
          valueIsNumericString={true}
          margin="normal"
          fullWidth
          inputMode="numeric"
        />
      )}
    />
  );
}
