import { ViewOrderDto } from '../../lib/api/orders/dto/view-order.dto';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  order: ViewOrderDto;
}

export default function OrdersTableIdColCustomer({ order }: Props) {
  const handleLinkClick = (e: any) => {
    e.stopPropagation();
  };

  const orderIdToShow = order.id.substring(0, 8);
  return (
    <>
      <Link
        component={RouterLink}
        to={`/orders/${order.id}`}
        onClick={handleLinkClick}
      >
        {orderIdToShow}
      </Link>
      <p>{order.createdAt.toFormat('M/d/yy, h:mm a')}</p>
    </>
  );
}
