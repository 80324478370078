import { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import Footer from '../components/Footer';
import CustomerAppBarHeader from '../components/CustomerAppBarHeader';
import { ObjectWithId } from '../../../lib/util/table-util';
import { DetailsDrawerConfig } from '../context/AdminLayoutContext';
import { CustomerLayoutProvider } from '../context/CustomerLayoutContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import _ from 'lodash';
import {
  NavigationItem,
  NavigationItemExpandable,
} from '../components/AdminSidebarNav';

const detailsDrawerWidth = 600;

export default function CustomerLayout<ViewDto extends ObjectWithId>() {
  const navigate = useNavigate();
  const location = useLocation();

  const activePath = location.pathname.split('/').pop();

  useEffect(() => {
    if (activePath) handleMenuNavigation(activePath);
  }, [activePath]);

  const [pages, setPages] = useState<
    Array<NavigationItem | NavigationItemExpandable>
  >([
    {
      key: 'store',
      displayText: 'Store',
      icon: <StorefrontIcon />,
      active: activePath === 'store',
    },
    {
      key: 'cart',
      displayText: 'Cart',
      icon: <ShoppingCartIcon />,
      active: activePath === 'cart',
    },
    {
      key: 'orders',
      displayText: 'Orders',
      icon: <ReceiptIcon />,
      active: activePath === 'orders',
    },
    {
      key: 'logout',
      displayText: 'Logout',
      icon: <LogoutIcon fontSize="small" />,
      active: activePath === 'logout',
    },
    // {
    //   key: 'account',
    //   displayText: 'Account',
    //   icon: <AccountCircleIcon />,
    //   active: activePath === 'account',
    //   children: [
    //     {
    //       key: 'me',
    //       displayText: 'My Profile',
    //       icon: <AccountCircleIcon fontSize="small" />,
    //       active: activePath === 'me',
    //     },
    //     {
    //       key: 'settings',
    //       displayText: 'Settings',
    //       icon: <SettingsIcon fontSize="small" />,
    //       active: activePath === 'settings',
    //     },
    //     {
    //       key: 'logout',
    //       displayText: 'Logout',
    //       icon: <LogoutIcon fontSize="small" />,
    //       active: activePath === 'logout',
    //     },
    //   ],
    // },
  ]);

  const [detailsDrawerConfig, setDetailsDrawerConfig] =
    useState<DetailsDrawerConfig>({
      open: false,
      width: detailsDrawerWidth,
    });

  const [activeRecord, setActiveRecord] = useState<ViewDto | any>();

  const handleTableRowClick = useCallback(
    <ViewDto,>(data: ViewDto) => {
      if (activeRecord) {
        setDetailsDrawerConfig((prev) => ({ ...prev, open: false }));
        setActiveRecord(undefined);
      } else {
        setActiveRecord(data);
        setDetailsDrawerConfig((prev) => ({ ...prev, open: true }));
      }
    },
    [activeRecord],
  );

  const handleMenuNavigation = (pageKey: string) => {
    handleDetailsDrawerClose();

    //1. Get a deep copy to work with
    const pagesCopy = _.cloneDeep(pages);

    //2. Find Page to Mark Active
    let pageToMarkActive = pagesCopy.find((p) => p.key === pageKey);

    if (!pageToMarkActive) {
      //search children
      pagesCopy.forEach((parentPage) => {
        if (Object.hasOwn(parentPage, 'children')) {
          //check children
          pageToMarkActive = (
            parentPage as NavigationItemExpandable
          ).children.find((childPage) => childPage.key === pageKey);
        }
      });
    }

    if (!pageToMarkActive) return;

    pageToMarkActive.active = true;

    //3. Find the Previous Active Page and mark it as Inactive
    pagesCopy.forEach((parentPage) => {
      if (Object.hasOwn(parentPage, 'children')) {
        (parentPage as NavigationItemExpandable).children.forEach(
          (nestedPage) => {
            if (nestedPage.key !== pageKey) nestedPage.active = false;
          },
        );
      } else {
        if (parentPage.key !== pageKey) parentPage.active = false;
      }
    });

    //4. Set the pages to updated list
    setPages(pagesCopy);
    navigate(pageKey);
  };

  const handleDetailsDrawerClose = useCallback(() => {
    setActiveRecord(undefined);
    setDetailsDrawerConfig({ ...detailsDrawerConfig, open: false });
  }, []);

  return (
    <CustomerLayoutProvider
      handleTableRowClick={handleTableRowClick}
      handleDetailsDrawerClose={handleDetailsDrawerClose}
      setDetailsDrawerConfig={setDetailsDrawerConfig}
      activeRecord={activeRecord}
      detailsDrawerConfig={detailsDrawerConfig}
    >
      <Grid container alignContent="flex-start">
        <CustomerAppBarHeader
          detailsDrawerWidth={0}
          detailsDrawerOpen={false}
          pages={pages}
        />
        <Box
          component="main"
          sx={{
            mt: '100px',
            mb: '60px',
            width: 'calc(100% - 95px)',
            ml: '95px',
            mr: '80px',
          }}
        >
          <Outlet />
        </Box>
        <Footer />
      </Grid>
    </CustomerLayoutProvider>
  );
}
