import { Grid, Typography, styled } from '@mui/material';
import { useCartContext } from '../../../contexts/CartContext';
import { OrderType } from '../../../lib/api/orders/dto/create-order.dto';
import { ProductLocation } from '../../../lib/api/products/enums/product-locations.enum';
import { ReactComponent as MaryvilleIcon } from '../../../assets/farm-icon-1.svg';
import { ReactComponent as GreenbackIcon } from '../../../assets/farm-icon-2.svg';
import { ReactComponent as DeliveryIcon } from '../../../assets/delivery-icon.svg';
import ViewCartItemExtendedDto from '../../../lib/api/carts/dto/view-cart-item-extended.dto';
import { ViewLineItemDto } from '../../../lib/api/orders/dto/view-line-item.dto';
import Decimal from 'decimal.js';
import { ViewStoreStatusDto } from '../../../lib/api/store/dto/view-store-status.dto';
import useAuth from '../../../lib/api/auth/useAuth';

interface Props {
  onClick?: (orderType: OrderType) => void;
  items: ViewCartItemExtendedDto[] | ViewLineItemDto[];
  storeStatus?: ViewStoreStatusDto;
  hidePickupAtMaryville?: boolean;
  hidePickupAtGreenback?: boolean;
}

const OrderTypeOption = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: `1rem`,
  padding: `1rem`,
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.grey[100]
      : theme.palette.grey[900],
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.grey[900]
      : theme.palette.grey[200],
  ':hover': {
    cursor: 'pointer',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[100]
        : theme.palette.grey[900],
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.common.black
        : theme.palette.grey[300],
    fontWeight: 'bold',
  },
}));

const DisabledOrderTypeOption = styled(OrderTypeOption)(({ theme }) => ({
  opacity: 0.4,
  ':hover': {
    cursor: 'not-allowed',
  },
}));

export default function OrderTypePartial({
  onClick,
  items,
  storeStatus,
  hidePickupAtMaryville,
  hidePickupAtGreenback,
}: Props) {
  const { loggedInUser } = useAuth();
  if (!items) return null;
  if (items.length === 0) return null;

  //Note: Can only pickup at Maryville if no Greenback items.
  //Note: Can only place order for delivery if order total is greater than or equal to the minimum order value.
  //      or if the order shipping address is being adjusted by an admin.
  let canPickupAtMaryville: boolean;
  let canPlaceOrderForDelivery: boolean;
  if (items[0].hasOwnProperty('orderId')) {
    // Admin is adjusting delivery method of existing order.
    const orderItems = items as ViewLineItemDto[];

    canPickupAtMaryville =
      orderItems.filter((item) => item.location === ProductLocation.Greenback)
        .length === 0;

    canPlaceOrderForDelivery = true;
  } else {
    // User is creating a new order.
    const cartItems = items as ViewCartItemExtendedDto[];

    canPickupAtMaryville =
      cartItems.filter(
        (item) => item?.product.location === ProductLocation.Greenback,
      ).length === 0;

    const orderProductTotal = cartItems.reduce(
      (pv, cv) => cv.productTotalBeforeDiscount.plus(pv),
      new Decimal(0),
    );

    canPlaceOrderForDelivery =
      orderProductTotal.greaterThanOrEqualTo(storeStatus.minimumOrderValue) ||
      loggedInUser.isRetail ||
      loggedInUser.isAdmin;
  }

  return (
    <Grid container textAlign="center">
      <Grid item sx={{ mb: 2 }} xs={12}>
        <Typography component="h2" variant="h5">
          Pickup or Delivery
        </Typography>
      </Grid>
      <Grid
        item
        display="flex"
        xs={12}
        columnGap={2}
        sx={{ mb: 2 }}
        justifyContent="center"
      >
        {!hidePickupAtGreenback && (
          <Grid item xs={4}>
            <OrderTypeOption onClick={() => onClick(OrderType.PickupGreenback)}>
              <GreenbackIcon />
              <Typography component="h3" variant="h6">
                Pickup @ Greenback, TN
              </Typography>
            </OrderTypeOption>
          </Grid>
        )}

        {!hidePickupAtMaryville && (
          <Grid item xs={4}>
            {canPickupAtMaryville ? (
              <OrderTypeOption
                onClick={() => onClick(OrderType.PickupMaryville)}
              >
                <MaryvilleIcon />
                <Typography component="h3" variant="h6">
                  Pickup @ Maryville, TN
                </Typography>
              </OrderTypeOption>
            ) : (
              <DisabledOrderTypeOption>
                <MaryvilleIcon />
                <Typography component="h3" variant="h6">
                  Pickup @ Maryville, TN
                </Typography>
                <span>Not allowed because order has Greenback Items</span>
              </DisabledOrderTypeOption>
            )}
          </Grid>
        )}

        <Grid item xs={4}>
          {canPlaceOrderForDelivery ? (
            <OrderTypeOption onClick={() => onClick(OrderType.Delivery)}>
              <DeliveryIcon />
              <Typography component="h3" variant="h6">
                Delivery
              </Typography>
            </OrderTypeOption>
          ) : (
            <DisabledOrderTypeOption>
              <DeliveryIcon />
              <Typography component="h3" variant="h6">
                Delivery
              </Typography>
              <span>
                Not allowed because order minimum for delivery is not met
              </span>
            </DisabledOrderTypeOption>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
