import { Typography } from '@mui/material';

export interface ContactFragment {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobilePhone?: string;
  officePhone?: string;
  fax?: string;
}

interface Props {
  contact: ContactFragment;
}

export default function ContactContent({ contact }: Props) {
  if (!contact) return <span>No contact on file</span>;

  const getName = () => {
    if (contact.firstName && contact.lastName)
      return `${contact.firstName} ${contact.lastName}`;

    if (contact.firstName) return contact.firstName;
    if (contact.lastName) return contact.lastName;

    return null;
  };

  const createLine = (data: any, prependText?: string, bold?: boolean) => {
    if (!data) return;

    const content = prependText ? `${prependText}: ${data}` : data;

    if (bold)
      return (
        <Typography component="p" fontWeight={700}>
          {content} <br />
        </Typography>
      );

    return (
      <Typography component="p">
        {content} <br />
      </Typography>
    );
  };

  return (
    <>
      {createLine(getName(), null, true)}
      {createLine(contact.email)}
      {createLine(contact.mobilePhone, 'Cell')}
      {createLine(contact.officePhone, 'Office')}
      {createLine(contact.fax, 'Fax')}
    </>
  );
}
