import { TableCell, TableRow } from '@mui/material';
import ViewCartItemExtendedDto from '../../lib/api/carts/dto/view-cart-item-extended.dto';
import { ViewCategoryDto } from '../../lib/api/categories/dto/view-category.dto';
import { ViewProductDto } from '../../lib/api/products/dto/view-product.dto';
import CartRowProduct from './CartRowProduct';
import CartRowVariantHeader from './CartRowVariantHeader';

interface Props {
  category: ViewCategoryDto;
  items: ViewCartItemExtendedDto[];
}

export default function CartRowCategory({ category, items }: Props) {
  const productItems = items
    .filter((item) => item.variant === null)
    .sort((a, b) =>
      a?.product?.name === b?.product?.name
        ? 0
        : a?.product?.name > b?.product?.name
        ? 1
        : -1,
    );
  const variantItems = items
    .filter((item) => item.variant)
    .sort((a, b) =>
      a?.variant?.name === b?.variant?.name
        ? 0
        : a?.variant?.name > b?.variant?.name
        ? 1
        : -1,
    )
    .sort((a, b) =>
      a?.product?.name === b?.product?.name
        ? 0
        : a?.product?.name > b?.product?.name
        ? 1
        : -1,
    );

  const uniqueVariantProducts: ViewProductDto[] = [];
  variantItems.forEach((variantItem) => {
    const indexInUnique = uniqueVariantProducts.findIndex(
      (uniqueVariantProduct) =>
        uniqueVariantProduct.id === variantItem.productId,
    );

    if (indexInUnique === -1) uniqueVariantProducts.push(variantItem.product);
  });

  // uniqueVariantProducts.sort((a, b) => (a.name > b.name ? 1 : 0));

  return (
    <>
      <TableRow
        key={category.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell
          component="th"
          scope="row"
          colSpan={7}
          sx={{ fontWeight: 'bold' }}
        >
          {category.name}
        </TableCell>
      </TableRow>
      {productItems.map((productItem) => (
        <CartRowProduct key={productItem.id} item={productItem} />
      ))}
      {uniqueVariantProducts.map((variantProduct) => (
        <CartRowVariantHeader
          key={variantProduct.id}
          product={variantProduct}
          items={variantItems.filter(
            (vi) => vi.productId === variantProduct.id,
          )}
        />
      ))}
    </>
  );
}
