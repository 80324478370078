import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@mui/material';
import { getRowsPerPageDefault, TableState } from '../../lib/util/table-util';
import { useDebounce } from 'use-debounce';
import useModal from '../../components/ui/modal/useModal';
import FullScreenModal from '../../components/ui/modal/FullScreenModal';
import { useAdminLayoutContext } from '../../components/layout/context/AdminLayoutContext';
import useAuth from '../../lib/api/auth/useAuth';
import { Navigate } from 'react-router-dom';
import {
  ProductQueryKeys,
  getAllProducts,
} from '../../lib/api/products/products.service';
import ProductsTable from '../../components/products/ProductsTable';
import { ViewProductDto } from '../../lib/api/products/dto/view-product.dto';
import ProductDrawerContent from '../../components/products/product-drawer-content/ProductDrawerContent';
import AddProductForm from '../../components/products/AddProductForm/AddProductForm';
import DetailsDrawer from '../../components/ui/drawer/DetailsDrawer';

export default function ProductsPage() {
  const {
    handleTableRowClick,
    handleDetailsDrawerClose,
    detailsDrawerConfig,
    activeRecord,
  } = useAdminLayoutContext<ViewProductDto>();

  const { authed } = useAuth();

  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();

  const [tableState, setTableState] = useState<TableState<ViewProductDto>>({
    pagination: { current: 1, pageSize: getRowsPerPageDefault() },
    filters: null,
    sorter: null,
  });

  const [tableStateDebounced] = useDebounce(tableState, 500);

  const onCreateProductSuccess = () => {
    handleCloseModal();
  };

  const { isError, data, error, isFetching } = useQuery(
    [ProductQueryKeys.findAllProducts, tableStateDebounced],
    () => getAllProducts(tableStateDebounced),
  );

  console.log({
    component: 'ProductsPage',
    message: 'rendered',
    data,
    isFetching,
  });

  return !authed() ? (
    <Navigate to="/auth/login" />
  ) : (
    <>
      <ProductsTable
        apiRes={data}
        isFetching={isFetching}
        setTableState={setTableState}
        tableState={tableState}
        onRowClick={handleTableRowClick}
      />
      <Button variant="contained" sx={{ mt: 2 }} onClick={handleLaunchModal}>
        Add Product
      </Button>
      <FullScreenModal
        title="Create Product"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <AddProductForm onSuccess={onCreateProductSuccess} />
      </FullScreenModal>
      {activeRecord && (
        <DetailsDrawer
          open={detailsDrawerConfig.open}
          detailsDrawerWidth={detailsDrawerConfig.width}
          onDrawerClose={handleDetailsDrawerClose}
          title={activeRecord.name}
        >
          <ProductDrawerContent productId={activeRecord.id} />
        </DetailsDrawer>
      )}
    </>
  );
}
