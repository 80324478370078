import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ConfirmDialog,
  ConfirmDeleteBaseProps,
} from '../ui/dialog/ConfirmDialog';
import { useAppContext } from '../../contexts/AppContext';
import { ViewCategoryDto } from '../../lib/api/categories/dto/view-category.dto';
import {
  deleteCategory,
  CategoryQueryKeys,
} from '../../lib/api/categories/categories.service';
import { getErrorMessage } from '../../lib/util/misc-utils';

interface Props extends ConfirmDeleteBaseProps {
  category: ViewCategoryDto;
}

export function DeleteCategoryConfirmDialog({
  category,
  isOpen,
  onClose,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  const deleteMutation = useMutation((categoryId: number) =>
    deleteCategory(categoryId),
  );

  const handleOnConfirm = async (categoryId: number) => {
    //We close the modal regardless of success.
    onClose();
    try {
      const deletedCategory = await deleteMutation.mutateAsync(categoryId);
      queryClient.invalidateQueries([CategoryQueryKeys.findAllCategories]);
      handleShowMessage(`Deleted Category '${category.name}'`);
      onSuccess?.(deletedCategory);
    } catch (err: any) {
      handleShowMessage(getErrorMessage(err), 'error');
    }
  };

  return (
    <ConfirmDialog
      title={`Delete Category: ${category.name}`}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() => handleOnConfirm(category.id)}
    >
      <>
        <p>
          Are you sure you want to delete the category '{category.name}'?{' '}
          <strong>This action cannot be undone.</strong>
        </p>
        <p>
          {' '}
          Deleting the category will unlink this category from all the products
          that are currently assigned to it. These products will remain in the
          system, but will not be associated to a category.
        </p>
      </>
    </ConfirmDialog>
  );
}
