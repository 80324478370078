import { Box, Button, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import {
  PickerBase,
  type DateRange,
  DefinedRange,
} from 'mui-daterange-picker-plus';

import { DateTime } from 'luxon';

interface Props {
  description: string;
  btnText: string;
  onSubmit: (dateRange: DateRange) => void;
}

export default function DateRangeForm({
  description,
  btnText,
  onSubmit,
}: Props) {
  const [dateRange, setDateRange] = useState<DateRange>({});

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    dateRange: DateRange,
  ) => {
    try {
      e.preventDefault();
      onSubmit(dateRange);
    } catch (err: any) {}
  };

  const definedRanges: DefinedRange[] = [];

  return (
    <form onSubmit={(e) => handleSubmit(e, dateRange)}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        rowGap={3}
        sx={{ mt: 2, mb: 2 }}
      >
        <Box sx={{ maxWidth: '750px' }}>
          <Typography component="p" sx={{ fontWeight: 'bold', mb: 2 }}>
            {description}
          </Typography>
          <Paper
            sx={{
              borderRadius: '16px',
              boxShadow: 'rgba(0, 0, 0, 0.21) 0px 0px 4px',
            }}
          >
            <PickerBase
              onChange={(range) => setDateRange(range)}
              definedRanges={definedRanges}
              minDate={DateTime.local().minus({ years: 1 }).toJSDate()}
            />
          </Paper>
        </Box>

        <Box>
          <Button
            type="submit"
            variant="contained"
            disabled={!dateRange?.startDate || !dateRange?.endDate}
          >
            {btnText}
          </Button>
        </Box>
      </Box>
    </form>
  );
}
