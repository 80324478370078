import { OrderType } from '../../lib/api/orders/dto/create-order.dto';
import { ViewOrderDto } from '../../lib/api/orders/dto/view-order.dto';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Chip } from '@mui/material';

interface Props {
  order: ViewOrderDto;
}

export default function OrdersTableTypeCol({ order }: Props) {
  if (order.type === OrderType.Delivery) {
    return <LocalShippingIcon sx={{ color: 'grey.500' }} />;
  } else if (order.type === OrderType.PickupGreenback) {
    return <Chip variant="filled" label="Greenback" color="secondary"></Chip>;
  } else {
    return <Chip variant="filled" label="Maryville" color="primary"></Chip>;
  }
}
