import { Expose, Type } from 'class-transformer';
import { DefaultUUIDViewDto } from '../../../common/dto/default-uuid-view.dto';
import { ViewDeliveryRouteDto } from '../../delivery-routes/dto/view-delivery-route.dto';
import { AddressType } from '../enums/address-type.enum';
import { States } from '../enums/states.enum';

export class ViewShippingAddressDto extends DefaultUUIDViewDto {
  @Expose() type: AddressType.shipping;
  @Expose() name: string;
  @Expose() street1: string;
  @Expose() street2?: string;
  @Expose() city: string;
  @Expose() state: States;
  @Expose() zip: string;
  @Expose() customerId: string;
  @Expose() routeId: number;
  @Expose() @Type(() => ViewDeliveryRouteDto) route: ViewDeliveryRouteDto;
}
