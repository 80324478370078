import { createTheme } from '@mui/material';

const HEADER_FONTS = ['Contrail One', 'Quicksand', 'sans-serif'].join(',');

export const DARK_THEME = createTheme({
  typography: {
    fontFamily: ['Quicksand', 'sans-serif'].join(','),
    h1: {
      fontFamily: HEADER_FONTS,
    },
    h2: {
      fontFamily: HEADER_FONTS,
    },
    h3: {
      fontFamily: HEADER_FONTS,
    },
    h4: {
      fontFamily: HEADER_FONTS,
    },
    h5: {
      fontFamily: HEADER_FONTS,
    },
    h6: {
      fontFamily: HEADER_FONTS,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#2c6b39',
    },
    secondary: {
      // main: '#ffa723', //orange
      // main: '#bb1c83', //pink
      main: '#6c21a2', //purple
      // main: '#ea2336', //red
    },
    background: {
      default: '#111111',
      paper: '#191919',
    },
    info: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      // dark: '',
      // light: '',
      // main: '#29b6f6',
      main: '#5A8087',
    },
    success: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      // dark: '',
      // light: '',
      main: '#66bb6a',
    },
    error: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      // dark: '',
      // light: '',
      main: '#f44336',
    },
    warning: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      // dark: '',
      // light: '',
      main: '#ffa726',
    },
  },
});

export const LIGHT_THEME = createTheme({
  typography: {
    fontFamily: ['Quicksand', 'sans-serif'].join(','),
    h1: {
      fontFamily: HEADER_FONTS,
    },
    h2: {
      fontFamily: HEADER_FONTS,
    },
    h3: {
      fontFamily: HEADER_FONTS,
    },
    h4: {
      fontFamily: HEADER_FONTS,
    },
    h5: {
      fontFamily: HEADER_FONTS,
    },
    h6: {
      fontFamily: HEADER_FONTS,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#2c6b39',
    },
    secondary: {
      // main: '#ffa723', //orange
      // main: '#bb1c83', //pink
      // main: '#6c21a2', //purple
      //main: '#ea2336', //red
      main: '#8E633A', //brown
    },
    info: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      // dark: '',
      // light: '',
      // main: '#0288d1',
      main: '#153F47',
    },
    success: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      // dark: '',
      // light: '',
      main: '#2e7d32',
    },
    error: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      // dark: '',
      // light: '',
      main: '#d32f2f',
    },
    warning: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      // dark: '',
      // light: '',
      main: '#ed6c02',
    },
  },
});

export const VALIDATION_ERR_DELAY = 1500;
