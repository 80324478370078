import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';

export default function BlankLayout() {
  return (
    <Grid
      container
      sx={{
        minHeight: '100vh',
      }}
      alignContent="flex-start"
      justifyContent="center"
    >
      <Header></Header>
      <Outlet />
      <Footer></Footer>
    </Grid>
  );
}
