import { Button, Grid, TextField } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CreateProductDto } from '../../../lib/api/products/dto/create-product.dto';
import DeleteIconButton from '../../ui/buttons/DeleteIconButton';
import ChildrenProductOptionValuesArray from './ChildrenProductOptionValuesArray';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface Props {
  nestIndex: number;
  totalOptions: number;
}

export default function ChildrenProductOptionsArray({
  nestIndex,
  totalOptions,
}: Props) {
  const {
    register,
    control,
    setFocus,
    watch,
    formState: { errors },
  } = useFormContext<CreateProductDto>();

  const { fields, remove, append } = useFieldArray({
    control,
    name: `options.${nestIndex}.children`,
  });

  const options = watch().options;

  const parentValues = options[nestIndex].values;
  const parentOptName = options[nestIndex].name;

  const showAppendNestedBtn = () => {
    const isLessThanMaxOptions = totalOptions < 3;
    const isLastNestedItem = nestIndex === options.length - 1;

    const parentOptIsPopulated =
      parentValues.length > 0 && parentOptName.length > 0;

    return isLessThanMaxOptions && isLastNestedItem && parentOptIsPopulated;
  };

  return (
    <Grid container spacing={2} sx={{ pl: 5 }}>
      {
        // @ts-ignore 'children' field circular reference
        fields.map((item, childIndex) => (
          <Grid key={item.id} container spacing={2} item>
            <Grid item xs={8}>
              <TextField
                // @ts-ignore 'children' field circular reference
                {...register(
                  // @ts-ignore 'children' field circular reference
                  `options.${nestIndex}.children.${childIndex}.name` as const,
                )}
                autoComplete="off"
                helperText={
                  errors?.options?.[nestIndex]?.children?.[childIndex]?.name
                    ?.message || ''
                }
                error={
                  errors?.options?.[nestIndex]?.children?.[childIndex]?.name
                    ?.message
                    ? true
                    : false
                }
                label="Nested Option Name"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <DeleteIconButton onClick={() => remove(childIndex)} />
            </Grid>
            <ChildrenProductOptionValuesArray
              nestIndex={nestIndex}
              childIndex={childIndex}
            />
          </Grid>
        ))
      }
      {showAppendNestedBtn() && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddCircleOutlineIcon />}
            type="button"
            onClick={() => {
              append({
                name: '',
                values: parentValues.map((v) => ({
                  parentOptionValue: v,
                  values: [] as string[],
                })),
              });
              setTimeout(() => {
                try {
                  setFocus(
                    `options.${nestIndex}.children.${
                      options[nestIndex].children.length - 1
                    }.name`,
                  );
                } catch (err) {
                  console.log(
                    `focus could not be set because control was not available.`,
                  );
                }
              }, 250);
            }}
          >
            Add Child Option
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
