import pwLogo from '../../../../assets/pwlogo.jpg';

export default function ProvenWinnerMark() {
  return (
    <img
      src={pwLogo}
      style={{ height: '40px' }}
      alt="Proven Winner &#174; Logo"
    />
  );
}
