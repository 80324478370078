import { ObjectWithId, TableState } from './table-util';

export const getHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
});

export const buildFindAllQueryParams = <ViewDto extends ObjectWithId>(
  tableState?: TableState<ViewDto>,
): string => {
  let queryParams = '';

  if (tableState) {
    const { pagination, filters, sorter } = tableState;

    let queryParamsArray: string[] = [];

    if (pagination)
      queryParamsArray.push(
        `page=${pagination.current}&pageSize=${pagination.pageSize}`,
      );

    if (filters) queryParamsArray.push(`filters=${JSON.stringify(filters)}`);

    if (sorter) queryParamsArray.push(`sort=${JSON.stringify(sorter)}`);

    if (queryParamsArray) {
      queryParams = encodeURI(queryParamsArray.join('&'));
    }
  }

  return queryParams;
};
