import { Paper } from '@mui/material';
import { ReactElement } from 'react';

interface Props {
  children: ReactElement | ReactElement[];
}

export default function DrawerPaper({ children }: Props) {
  return (
    <Paper elevation={5} sx={{ padding: '1rem' }}>
      {children}
    </Paper>
  );
}
