import { InputAdornment, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import RequiredInputLabel from './RequiredInputLabel';

interface Props {
  name: string;
  label: string;
  control: Control<any, any>;
  required?: boolean;
  allowNegative?: boolean;
  showPercent?: boolean;
  showMoney?: boolean;
  isAllowed?: (values: NumberFormatValues) => boolean;
}

export default function RhfIntegerInput({
  name,
  label,
  control,
  required,
  allowNegative,
  showPercent,
  showMoney,
  isAllowed,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, name, value, ref },
        fieldState: { error },
      }) => (
        <NumericFormat
          inputRef={ref}
          name={name}
          allowNegative={allowNegative || false}
          isAllowed={isAllowed || null}
          decimalScale={0}
          thousandSeparator=","
          customInput={TextField}
          helperText={error && error.message}
          error={error ? true : false}
          label={required ? <RequiredInputLabel text={label} /> : label}
          variant="outlined"
          InputProps={{
            inputMode: 'numeric',
            endAdornment: showPercent ? (
              <InputAdornment position="start">%</InputAdornment>
            ) : null,
            startAdornment: showMoney ? (
              <InputAdornment position="start">%</InputAdornment>
            ) : null,
          }}
          onValueChange={(v) =>
            onChange(v.value === '' ? null : parseInt(v.value))
          }
          onBlur={onBlur}
          value={value}
          valueIsNumericString={true}
          margin="normal"
          fullWidth
        />
      )}
    />
  );
}
