import { Box, Button, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../../lib/api/auth/useAuth';
import {
  CategoryQueryKeys,
  getAllCategories,
} from '../../../lib/api/categories/categories.service';
import CategoryProductList from '../expandable-rows/CategoryProductList';
import StoreCategoryRow from '../expandable-rows/StoreCategoryRow';
import CartSummary from '../../../components/cart-summary/CartSummary';
import ShoppingForWeekPicker from './ShoppingForWeekPicker';
import { useCartContext } from '../../../contexts/CartContext';
import { downloadStoreAvailability } from '../../../lib/common/report-download.service';

export default function StorePageInternal() {
  const { authed, loggedInUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const underAdminRoute =
    location.pathname.split('/').findIndex((part) => part === 'admin') !== -1;
  const { storeStatus } = useCartContext();

  const {
    isError: categoriesIsError,
    data: categoriesRes,
    error: categoriesError,
    isFetching: categoriesIsFetching,
  } = useQuery([CategoryQueryKeys.findAllCategories], () => getAllCategories());

  if (!storeStatus) return null;
  if (!authed()) return <Navigate to="/auth/login" />;

  return (
    <>
      <ShoppingForWeekPicker />
      <Box>
        <Button
          variant="outlined"
          sx={{ mt: 2, mb: 2 }}
          onClick={downloadStoreAvailability}
        >
          Download Availability
        </Button>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={5} md={7}>
          {categoriesRes?.data?.map((category) => (
            <StoreCategoryRow
              key={`category-${category.id}`}
              category={category}
              expandedComponent={<CategoryProductList category={category} />}
            />
          ))}
        </Grid>
        <Grid item xs={12} sm={7} md={5}>
          <CartSummary
            onCheckout={() =>
              navigate(underAdminRoute ? '/admin/cart' : '/cart')
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
