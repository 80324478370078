import { ViewDeliveryRouteDto } from '../../lib/api/delivery-routes/dto/view-delivery-route.dto';
import { BaseTableProps, TableCol } from '../../lib/util/table-util';
import PaginatedTable from '../ui/tables/paginated-table/PaginatedTable';

const columns: TableCol<ViewDeliveryRouteDto>[] = [
  {
    id: 'name',
    label: 'Name',
    filterType: 'contains',
    sortable: true,
    widthPct: 25,
    render: (data) => <span>{data.name}</span>,
  },
  {
    id: 'description',
    align: 'left',
    label: 'Description',
    widthPct: 75,
  },
];

export default function DeliveryRotuesTable({
  apiRes,
  isFetching,
  setTableState,
  tableState,
  onRowClick,
}: BaseTableProps<ViewDeliveryRouteDto>) {
  return (
    <PaginatedTable<ViewDeliveryRouteDto>
      columns={columns}
      apiRes={apiRes}
      isFetching={isFetching}
      setTableState={setTableState}
      tableState={tableState}
      onRowClick={onRowClick}
    />
  );
}
