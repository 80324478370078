import { styled } from '@mui/material';
import { ReactElement } from 'react';

interface Props {
  label: string;
  children: ReactElement | string | number;
}

export default function DescriptionItem({ label, children }: Props) {
  const DescriptionItem = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexGrow: '1',
    flexWrap: 'wrap',
    maxWidth: '100%',
    borderBottom: `1px solid ${theme.palette.grey[600]}`,
    borderTop: `1px solid ${theme.palette.grey[600]}`,
    outline: `1px solid ${theme.palette.grey[600]}`,
    '& > span': {
      padding: '0.4rem',
      flexGrow: 1,
    },
    '& .description-item-label': {
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.grey[900]
          : theme.palette.grey[200],
      fontWeight: 'bold',
    },
  }));
  return (
    <DescriptionItem>
      <span className="description-item-label">{label}</span>
      <span className="description-item-content">{children}</span>
    </DescriptionItem>
  );
}
