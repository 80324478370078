import { Alert, Grid, Typography } from '@mui/material';
import { CreateProductDto } from '../../../lib/api/products/dto/create-product.dto';
import RhfSwitch from '../../ui/forms/RhfSwitch';
import { useFormContext } from 'react-hook-form';
import FromSubmitButton from '../../ui/forms/FormSubmitButton';
import RhfIntegerInput from '../../ui/forms/RhfIntegerInput';
import { ViewVariantDto } from '../../../lib/api/variants/dto/view-variant.dto';
import { ViewProductDto } from '../../../lib/api/products/dto/view-product.dto';

interface Props {
  product: ViewProductDto;
  variant: ViewVariantDto;
}

export default function UpdateVariantFormStoreAvailabilityPartial({
  product,
  variant,
}: Props) {
  const {
    formState: { isSubmitting, isValid, isSubmitted },
    control,
    watch,
  } = useFormContext<CreateProductDto>();

  const isSellable = watch('isSellable');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5">
          Store Availability
        </Typography>
        <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
          The availability at the product level takes priority over the values
          here. If you mark a variant as 'Live on Store' but it's product is
          marked 'Not Sellable', then the variant won't show up in the store.
          <br />
          <br />
          This variant's product is currently{' '}
          <span style={{ fontWeight: 700 }}>
            {product.isSellable ? 'live on store' : 'not sellable'}
          </span>
        </Alert>
      </Grid>

      <Grid container spacing={2} sx={{ pl: 2, pr: 2 }}>
        <Grid item xs={12} sm={6} md={3}>
          <RhfSwitch<CreateProductDto>
            label={['Live on Store', 'Not Sellable']}
            name="isSellable"
            control={control}
          />
        </Grid>

        {isSellable && (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <RhfSwitch<CreateProductDto>
                label="Sell to Popes"
                name="sellToPopes"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <RhfSwitch<CreateProductDto>
                label="Sell to Premium"
                name="sellToPremium"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <RhfSwitch<CreateProductDto>
                label="Sell to Standard"
                name="sellToStandard"
                control={control}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Grid item xs={6} sm={3}>
        <RhfIntegerInput
          control={control}
          name="orderLimit"
          label="Order Limit"
        />
      </Grid>

      <Grid item xs={12}>
        <FromSubmitButton
          isSubmitted={isSubmitted}
          isSubmitting={isSubmitting}
          isValid={isValid}
          text="Update Product"
        />
      </Grid>
    </Grid>
  );
}
