import { Box, Tab, Tabs } from '@mui/material';
import { ReactElement } from 'react';

interface Props {
  children: ReactElement | ReactElement[];
  tabValue: number;
  onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export default function TabGroup({ tabValue, children, onTabChange }: Props) {
  const tabProps = (index: number) => {
    return {
      key: index,
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  };

  const childrenIsAnArray = Array.isArray(children);

  const getTabsAriaLabel = () =>
    childrenIsAnArray
      ? children
          .filter((c) => c)
          .map((c: ReactElement) => c.props.label)
          .join(', ')
      : children.props.label;

  const getTabs = () =>
    childrenIsAnArray ? (
      children
        .filter((c) => c)
        .map((c: ReactElement) => (
          <Tab label={c.props.label} {...tabProps(c.props.index)} />
        ))
    ) : (
      <Tab label={children.props.label} {...tabProps(children.props.index)} />
    );

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          aria-label={`Tabs for ${getTabsAriaLabel()}`}
          variant="fullWidth"
        >
          {getTabs()}
        </Tabs>
      </Box>
      {children}
    </Box>
  );
}
