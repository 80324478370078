import { Transform, TransformOptions } from 'class-transformer';
import Decimal from 'decimal.js';

/**
 * Transforms decimal.js Decimal value to a string.
 * @Returns string with only digits, negative sign, and decimal place
 * @Returns null if if value is empty string, null, or undefined
 */
export default function TransformDecimalToString(options?: TransformOptions) {
  return Transform(({ value }) => {
    if (value === '' || value === null || value === undefined) return null;
    return (value as Decimal).toString();
  }, options);
}
