import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useQuery } from '@tanstack/react-query';
import {
  AlreadyShippedTooltip,
  EstimatedQtyTooltip,
  NewQtyTooltip,
} from './column-tooltips';
import { ViewInventoryItemDto } from '../../../lib/api/inventory/dto/view-inventory-item.dto';
import { DateTime } from 'luxon';
import {
  getStoreStatus,
  StoreQueryKeys,
} from '../../../lib/api/store/store.service';
import {
  LastCellOfRow,
  StartGroupCell,
  TableHeadStyled,
  TableStyled,
} from './styled-items';
import InventoryAvailabilityTableRow from './InventoryAvailabilityTableRow';
import { useMemo } from 'react';
import Decimal from 'decimal.js';

interface Props {
  inventory: ViewInventoryItemDto[];
}

export interface InventoryWithCalculatedFields extends ViewInventoryItemDto {
  key: string;
  physicalCountForWeekComplete: boolean;
  manualQtyForWeekComplete: boolean;
  estimatedQty: Decimal;
}

export default function InventoryAvailabilityTable({ inventory }: Props) {
  const {
    isError: storeStatusIsError,
    data: storeStatusRes,
    error: storeStatusError,
    isFetching: storeStatusIsFetching,
  } = useQuery([StoreQueryKeys.findStatus], () => getStoreStatus());

  const storeOpenedAt = storeStatusRes
    ? DateTime.fromISO(storeStatusRes.currentWeek.openAt)
    : null;
  const currentWeek = storeStatusRes
    ? DateTime.fromISO(storeStatusRes.currentWeek.orderWeek).toFormat(
        'MM/dd/yy',
      )
    : null;
  const nextWeek = storeStatusRes
    ? DateTime.fromISO(storeStatusRes.nextWeek.orderWeek).toFormat('MM/dd/yy')
    : null;

  //Extend Inventory with Computed Fields
  const inventoryExtended: InventoryWithCalculatedFields[] = useMemo(
    () =>
      inventory.map((i) => {
        const physicalCountForWeekComplete = !i.physicalCountAsOf
          ? false
          : i.physicalCountAsOf.toMillis() > storeOpenedAt.toMillis();
        const manualQtyForWeekComplete = !i.manualQtyAsOf
          ? false
          : i.manualQtyAsOf.toMillis() > storeOpenedAt.toMillis();

        let estimatedQty: Decimal;

        if (physicalCountForWeekComplete && !manualQtyForWeekComplete) {
          const stillNeededThisWeek = i.numOrderedThisWeek.minus(
            i.numAlreadyShippedThisWeek,
          );
          estimatedQty = i.physicalCount
            .minus(stillNeededThisWeek)
            .minus(i.numDueNextWeek);
        }
        return {
          ...i,
          key: `${i.productId}_${i.variantId}`,
          physicalCountForWeekComplete,
          manualQtyForWeekComplete,
          estimatedQty,
        };
      }),
    [inventory, storeOpenedAt],
  );

  if (!inventory) return;
  if (!storeStatusRes) return;

  return (
    <Paper sx={{ width: `calc(100% - 80px)` }}>
      <TableContainer
        sx={{ maxHeight: '500px', maxWidth: '100%', overflow: 'auto' }}
      >
        <TableStyled stickyHeader size="small">
          <TableHeadStyled>
            <TableRow
              sx={{
                th: {
                  fontWeight: 700,
                  borderTop: '1px solid',
                  borderBottom: '1px solid',
                  borderLeft: '1px solid',
                },
              }}
            >
              <TableCell rowSpan={2}>Product</TableCell>
              <TableCell align="center" rowSpan={1} colSpan={2}>
                {`This Week (${currentWeek})`}
              </TableCell>
              <TableCell align="center" colSpan={3} rowSpan={1}>
                Coming Due
              </TableCell>
              <TableCell align="center" colSpan={2} rowSpan={1}>
                Physical Count
              </TableCell>
              <LastCellOfRow align="center" colSpan={4} rowSpan={1}>
                {`Available for ${nextWeek}`}
              </LastCellOfRow>
            </TableRow>
            <TableRow
              sx={{
                th: {
                  borderBottom: '1px solid',
                  top: 38,
                },
              }}
            >
              <StartGroupCell align="center"># Ordered</StartGroupCell>
              <AlreadyShippedTooltip>
                <TableCell align="center" rowSpan={2}>
                  # Already Shipped
                </TableCell>
              </AlreadyShippedTooltip>
              <StartGroupCell align="center">Next Week</StartGroupCell>
              <TableCell align="center">2 Weeks</TableCell>
              <TableCell align="center">3+ Weeks</TableCell>
              <StartGroupCell align="center" sx={{ minWidth: '125px' }}>
                Counted Qty
              </StartGroupCell>
              <TableCell align="center">As of</TableCell>
              <StartGroupCell align="center">
                Current Qty in System
              </StartGroupCell>
              <EstimatedQtyTooltip>
                <TableCell align="center">Estimated Qty</TableCell>
              </EstimatedQtyTooltip>
              <NewQtyTooltip>
                <TableCell align="center" sx={{ minWidth: '125px' }}>
                  New Qty
                </TableCell>
              </NewQtyTooltip>
              <LastCellOfRow align="center">Manually Updated At</LastCellOfRow>
            </TableRow>
          </TableHeadStyled>
          <TableBody>
            {inventoryExtended.map((item) => (
              <InventoryAvailabilityTableRow key={item.key} item={item} />
            ))}
          </TableBody>
        </TableStyled>
      </TableContainer>
    </Paper>
  );
}
