import { TextField, Typography } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useAppContext } from '../../../contexts/AppContext';
import { useCartContext } from '../../../contexts/CartContext';
import { CartQueryKeys, updateCart } from '../../../lib/api/carts/cart.service';
import { UpdateCartDto } from '../../../lib/api/carts/dto/update-cart.dto';

export default function ShoppingForWeekPicker() {
  const { cart } = useCartContext();

  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  const updateMutation = useMutation((updateCartDto: UpdateCartDto) =>
    updateCart(cart.id, updateCartDto),
  );

  const handleChangeOrderWeek = async (newValue: DateTime) => {
    try {
      await updateMutation.mutateAsync({
        orderWeek: newValue.toISODate(),
      });
      queryClient.invalidateQueries([CartQueryKeys.findCartById]);
      handleShowMessage(
        `You are now shoping for the week of '${newValue.toISODate()}'`,
      );
    } catch (err: any) {
      const errObj = JSON.parse(err.message);

      if (errObj.statusCode !== 400 || !errObj.errors) {
        handleShowMessage(
          `There was an error updating the cart week.`,
          'error',
        );
        return;
      }

      handleShowMessage(
        `There was an error updating the cart week. ${errObj.message}`,
        'error',
      );
    }
  };

  if (!cart) return;

  // Need to get the ISO in the tz of the User's Browser so that the
  // order week will be displayed correctly on the calendar picker.
  const orderWeek = DateTime.fromFormat(cart.orderWeek, 'yyyy-MM-dd').toISO();

  return (
    <Typography variant="h3" component="h1">
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <MobileDatePicker
          label="Order Week"
          inputFormat="MM/dd/yyyy"
          value={orderWeek}
          onChange={handleChangeOrderWeek}
          closeOnSelect={true}
          renderInput={(params) => <TextField {...params} />}
          shouldDisableDate={(day: DateTime) =>
            day.weekday !== 1 ||
            day.diffNow('days').days > 365 ||
            day.diffNow('days').days < -5
          }
        />
      </LocalizationProvider>
    </Typography>
  );
}
