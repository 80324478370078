import { Grid, Typography, Alert } from '@mui/material';
import { CreateProductDto } from '../../../../lib/api/products/dto/create-product.dto';
import { ProductLocation } from '../../../../lib/api/products/enums/product-locations.enum';
import RhfSwitch from '../../../ui/forms/RhfSwitch';
import RhfAutocomplete from '../../../ui/forms/RhfAutocomplete';
import { useFormContext } from 'react-hook-form';
import FromSubmitButton from '../../../ui/forms/FormSubmitButton';
import RhfDecimalInput from '../../../ui/forms/RhfDecimalInput';
import RhfIntegerInput from '../../../ui/forms/RhfIntegerInput';

export default function AddProductFormStoreAvailabilityPartial() {
  const {
    formState: { isSubmitting, isValid, isSubmitted },
    control,
    watch,
  } = useFormContext<CreateProductDto>();

  const hasVariants = watch('hasVariants');
  const isSellable = watch('isSellable');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5">
          Store Availability
        </Typography>
        {hasVariants && (
          <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
            When creating a product with variants the values set for store
            availability will be the defaults applied to each variant. After you
            create the product, you'll be able to manage the store availabity at
            both the product level and at the variant level.
          </Alert>
        )}
      </Grid>

      <Grid container spacing={2} sx={{ pl: 2, pr: 2 }}>
        <Grid item xs={12} sm={6} md={3}>
          <RhfSwitch<CreateProductDto>
            label={['Live on Store', 'Not Sellable']}
            name="isSellable"
            control={control}
          />
        </Grid>

        {isSellable && (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <RhfSwitch<CreateProductDto>
                label="Sell to Popes"
                name="sellToPopes"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <RhfSwitch<CreateProductDto>
                label="Sell to Premium"
                name="sellToPremium"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <RhfSwitch<CreateProductDto>
                label="Sell to Standard"
                name="sellToStandard"
                control={control}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        <RhfAutocomplete
          control={control}
          name="location"
          defaultText="Select location"
          optionsAsEnum={ProductLocation}
        />
      </Grid>

      {!hasVariants && (
        <>
          <Grid item xs={6} sm={3}>
            <RhfDecimalInput
              control={control}
              name="qty"
              label="Qty on Hand"
              decimals={2}
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <RhfIntegerInput
              control={control}
              name="orderLimit"
              label="Order Limit"
            />
          </Grid>
          <Grid item xs={12}>
            <FromSubmitButton
              isSubmitted={isSubmitted}
              isSubmitting={isSubmitting}
              isValid={isValid}
              text="Create Product"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
