import { Expose } from 'class-transformer';
import Decimal from 'decimal.js';
import TransformDecimalToString from '../../../common/custom-transformers/TransformDecimalToString';
import TransformStringToDecimal from '../../../common/custom-transformers/TransformStringToDecimal';
import { DefaultIncrementViewDto } from '../../../common/dto/default-increment-view.dto';
import { ProductLocation } from '../../products/enums/product-locations.enum';

export class ViewLineItemDto extends DefaultIncrementViewDto {
  @Expose() line: number;
  @Expose() orderId: string;

  /**************************** 
   PRODUCT FIELDS
  *****************************/

  @Expose() productId: string;
  @Expose() productName: string;
  @Expose() productDescription: string;
  @Expose() upc: string;
  @Expose() productItemNo: string;
  @Expose() productSku: string;

  @TransformStringToDecimal({ toClassOnly: true })
  @TransformDecimalToString({ toPlainOnly: true })
  @Expose()
  qtyPerShelf: Decimal;

  @TransformStringToDecimal({ toClassOnly: true })
  @TransformDecimalToString({ toPlainOnly: true })
  @Expose()
  shelvesPerRack: Decimal;

  @TransformStringToDecimal({ toClassOnly: true })
  @TransformDecimalToString({ toPlainOnly: true })
  @Expose()
  price: Decimal;

  @Expose() location: ProductLocation;

  /**************************** 
   VARIANT FIELDS
  *****************************/
  @Expose() variantId: string;
  @Expose() variantName: string;
  @Expose() variantDescription: string;
  @Expose() variantSku: string;
  @Expose() variantItemNo: string;

  /**************************** 
   REST FIELDS
  *****************************/
  @Expose() categoryId: number;
  @Expose() categoryName: string;

  @TransformStringToDecimal({ toClassOnly: true })
  @TransformDecimalToString({ toPlainOnly: true })
  @Expose()
  qty: Decimal;

  /**************************** 
   COMPUTED FIELDS
  *****************************/
  @TransformStringToDecimal({ toClassOnly: true, emptyString: 'SetToZero' })
  @TransformDecimalToString({ toPlainOnly: true })
  @Expose()
  shelves: Decimal;

  @TransformStringToDecimal({ toClassOnly: true, emptyString: 'SetToZero' })
  @TransformDecimalToString({ toPlainOnly: true })
  @Expose()
  racks: Decimal;

  @TransformStringToDecimal({ toClassOnly: true, emptyString: 'SetToZero' })
  @TransformDecimalToString({ toPlainOnly: true })
  @Expose()
  productTotalBeforeDiscount: Decimal;
}
