import { Expose, Type } from 'class-transformer';
import { ProductLocation } from '../enums/product-locations.enum';
import { ViewCategoryDto } from '../../categories/dto/view-category.dto';
import TransformStringToDecimal from '../../../common/custom-transformers/TransformStringToDecimal';
import Decimal from 'decimal.js';

export class ViewSellableProductDto {
  @Expose() id: string;
  @Expose() hasVariants: boolean;
  @Expose() name: string;
  @Expose() description?: string;
  @Expose() upc?: string;
  @Expose() sku: string;
  @Expose() itemNo?: string;
  @Expose() qtyPerShelf: number;
  @Expose() shelvesPerRack: number;
  @Expose() orderLimit?: number;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  price: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  orderIncrement: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  qty: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  availableQty: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  reservedQty: Decimal;

  @Expose()
  defaultImageFileUrl?: string;

  @Expose()
  defaultImageFileNameKey?: string;

  @Expose() location: ProductLocation;

  @Expose() isProvenWinner: boolean;
  @Expose() sellToPopes: boolean;
  @Expose() sellToPremium: boolean;
  @Expose() sellToStandard: boolean;
  @Expose() categoryId: number;

  @Expose()
  @Type(() => ViewCategoryDto)
  category: ViewCategoryDto;
}
