import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { Context, createContext, PropsWithChildren, useContext } from 'react';
import useAuth from '../lib/api/auth/useAuth';
import {
  CartQueryKeys,
  getCartById,
  refreshCart,
  removeAllItemsFromCart,
  removeItemFromCart,
} from '../lib/api/carts/cart.service';
import ViewCartExtendedDto from '../lib/api/carts/dto/view-cart-extended.dto';
import ViewCartItemExtendedDto from '../lib/api/carts/dto/view-cart-item-extended.dto';
import { CategoryQueryKeys } from '../lib/api/categories/categories.service';
import { ProductQueryKeys } from '../lib/api/products/products.service';
import { ViewStoreStatusDto } from '../lib/api/store/dto/view-store-status.dto';
import { getStoreStatus, StoreQueryKeys } from '../lib/api/store/store.service';
import { getErrorMessage } from '../lib/util/misc-utils';
import { useAppContext } from './AppContext';

interface CartContextOutput {
  cart: ViewCartExtendedDto;
  isExpired: boolean;
  isPreviousOrderWeek: boolean;
  isCurrentOrderWeek: boolean;
  isFutureOrderWeek: boolean;
  storeStatus: ViewStoreStatusDto;
  handleRemoveItemFromCart: (item: ViewCartItemExtendedDto) => void;
  handleRemoveAllItemsFromCart: (cartId: string) => void;
  handleRefreshCart: (cartId: string) => void;
}

interface DeleteCartItemMutateInput {
  cartId: string;
  itemId: number;
}

const CartContext = createContext<CartContextOutput>(null);

export const CartProvider = ({ children }: PropsWithChildren) => {
  const { loggedInUser } = useAuth();
  const queryClient = useQueryClient();
  const { handleShowMessage } = useAppContext();

  const {
    isError: cartIsError,
    data: cart,
    error: cartError,
    isFetching: cartIsFetching,
  } = useQuery([CartQueryKeys.findCartById, { id: loggedInUser.cartId }], () =>
    getCartById(loggedInUser.cartId),
  );

  const {
    isError: storeStatusIsError,
    data: storeStatusRes,
    error: storeStatusError,
    isFetching: storeStatusIsFetching,
  } = useQuery([StoreQueryKeys.findStatus], () => getStoreStatus());

  const removeItemFromCartMutation = useMutation(
    ({ cartId, itemId }: DeleteCartItemMutateInput) =>
      removeItemFromCart(cartId, itemId),
  );

  const removeAllItemsFromCartMutation = useMutation((cartId: string) =>
    removeAllItemsFromCart(cartId),
  );

  const refreshCartMutation = useMutation((cartId: string) =>
    refreshCart(cartId),
  );

  const handleRemoveItemFromCart = async (item: ViewCartItemExtendedDto) => {
    try {
      await removeItemFromCartMutation.mutateAsync({
        cartId: item.cartId,
        itemId: item.id,
      });
      queryClient.invalidateQueries([
        CartQueryKeys.findCartById,
        { id: item.cartId },
      ]);
      queryClient.invalidateQueries([
        CategoryQueryKeys.findSellableProducts,
        { id: item.product.categoryId },
      ]);
      queryClient.invalidateQueries([
        ProductQueryKeys.findSellableProductVariants,
        { productId: item.productId },
      ]);

      handleShowMessage(
        `Removed ${item?.product?.name || item?.variant?.name} from cart.`,
      );
    } catch (err: any) {
      handleShowMessage(getErrorMessage(err), 'error');
    }
  };

  const handleRemoveAllItemsFromCart = async (cartId: string) => {
    try {
      await removeAllItemsFromCartMutation.mutateAsync(cartId);
      queryClient.invalidateQueries([
        CartQueryKeys.findCartById,
        { id: cartId },
      ]);
      queryClient.invalidateQueries([CategoryQueryKeys.findSellableProducts]);
      queryClient.invalidateQueries([
        ProductQueryKeys.findSellableProductVariants,
      ]);
      handleShowMessage(`Removed all items from cart.`);
    } catch (err) {
      handleShowMessage(getErrorMessage(err), 'error');
    }
  };

  const handleRefreshCart = async (cartId: string) => {
    try {
      const refreshRes = await refreshCartMutation.mutateAsync(cartId);

      queryClient.invalidateQueries([
        CartQueryKeys.findCartById,
        { id: cartId },
      ]);
      queryClient.invalidateQueries([CategoryQueryKeys.findSellableProducts]);
      queryClient.invalidateQueries([
        ProductQueryKeys.findSellableProductVariants,
      ]);

      if (refreshRes.errors.length > 0) {
        handleShowMessage(
          `Cart Refreshed. ${refreshRes.errors
            .map((error) => error.msg)
            .join('; ')}`,
          'warning',
          15000,
        );
      } else {
        handleShowMessage(`Cart Refreshed.`);
      }
    } catch (err: any) {
      handleShowMessage(getErrorMessage(err), 'error');
    }
  };

  if (!cart) return;
  if (!storeStatusRes) return;

  const isExpired = DateTime.now() > DateTime.fromISO(cart.expiresAt);

  const storeStatusOrderWeek = DateTime.fromISO(
    storeStatusRes.currentWeek.orderWeek,
  );
  const cartOrderWeek = DateTime.fromISO(cart.orderWeek);

  const isPreviousOrderWeek =
    cartOrderWeek.toMillis() < storeStatusOrderWeek.toMillis();
  const isCurrentOrderWeek =
    cartOrderWeek.toMillis() === storeStatusOrderWeek.toMillis();
  const isFutureOrderWeek =
    cartOrderWeek.toMillis() > storeStatusOrderWeek.toMillis();

  return (
    <CartContext.Provider
      value={{
        cart,
        isExpired,
        isPreviousOrderWeek,
        isCurrentOrderWeek,
        isFutureOrderWeek,
        storeStatus: storeStatusRes,
        handleRemoveItemFromCart,
        handleRemoveAllItemsFromCart,
        handleRefreshCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  const context = useContext<CartContextOutput>(
    CartContext as unknown as Context<CartContextOutput>,
  );
  if (!context)
    throw new Error('useCartContext must be used under CartProvider');
  return context;
};
