import { Box, Button } from '@mui/material';
import useConfirmDialog from '../../ui/dialog/useConfirmDialog';
import useModal from '../../ui/modal/useModal';
import DescriptionContainer from '../../ui/description/DescriptionContainer';
import DescriptionItem from '../../ui/description/DescriptionItem';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import TabPanel from '../../ui/tabs/TabPanel';
import { useAdminLayoutContext } from '../../layout/context/AdminLayoutContext';
import { DeleteCustomerConfirmDialog } from '../DeleteCustomerConfirmDialog';
import { ViewCustomerDto } from '../../../lib/api/customers/dto/view-customer.dto';
import CustomerTypeChip from '../CustomerTypeChip';
import AddOrUpdateCustomerForm from '../AddOrUpdateCustomerForm';

interface Props {
  label: string;
  tabValue: number;
  index: number;
  customer: ViewCustomerDto;
}
export default function CustomerDetailsTab({
  label,
  tabValue,
  index,
  customer,
}: Props) {
  const { handleDetailsDrawerClose } = useAdminLayoutContext<ViewCustomerDto>();
  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();
  const {
    confirmDialogIsOpen,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
  } = useConfirmDialog();

  const onUpdateCustomerSuccess = () => {
    handleCloseModal();
  };

  const onDeleteCustomerSuccess = () => {
    handleDetailsDrawerClose();
  };

  return (
    <>
      <TabPanel value={tabValue} index={index}>
        <DescriptionContainer>
          <DescriptionItem label="id">{customer.id}</DescriptionItem>
          <DescriptionItem label="Name">{customer.name}</DescriptionItem>
          <DescriptionItem label="Type">
            <CustomerTypeChip type={customer.type} />
          </DescriptionItem>
          <DescriptionItem label="Discount %">
            {`${customer.discountRate.toDecimalPlaces(2)}%`}
          </DescriptionItem>
          <DescriptionItem label="Notes">
            {customer.notes ? customer.notes : 'No Notes on File'}
          </DescriptionItem>
        </DescriptionContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button variant="contained" onClick={handleLaunchModal}>
            Edit Customer
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'secondary.main',
              '&:hover': { backgroundColor: 'secondary.dark' },
            }}
            onClick={handleOpenConfirmDialog}
          >
            Delete Customer
          </Button>
        </Box>
      </TabPanel>

      <DeleteCustomerConfirmDialog
        customer={customer}
        isOpen={confirmDialogIsOpen}
        onClose={handleCloseConfirmDialog}
        onSuccess={onDeleteCustomerSuccess}
      />

      <FullScreenModal
        title="Update Customer"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <AddOrUpdateCustomerForm
          customer={customer}
          onSuccess={onUpdateCustomerSuccess}
        />
      </FullScreenModal>
    </>
  );
}
