import { useQuery } from '@tanstack/react-query';
import ExpandableTypography from '../../components/ui/typography/ExpandableTypography';
import {
  CategoryQueryKeys,
  getListOfProductsWithVariantsForCategory,
} from '../../lib/api/categories/categories.service';
import { ViewCategoryDto } from '../../lib/api/categories/dto/view-category.dto';
import InventoryProductWithVariantsRow from './InventoryProductWithVariantsRow';
import SimpleProductsAvailabilityTableWrapper from './SimpleProductsAvailabilityTableWrapper';

interface Props {
  category: ViewCategoryDto;
}

export default function InventoryCategoryProductList({ category }: Props) {
  const {
    isError: complexProductsIsError,
    data: complexProductsRes,
    error: complexProductsError,
    isFetching: complexProductsIsFetching,
  } = useQuery(
    [CategoryQueryKeys.findListOfProductsWithVariants, { id: category.id }],
    () => getListOfProductsWithVariantsForCategory(category.id),
  );

  return (
    <>
      <ExpandableTypography
        fontWeight={700}
        component="p"
        expandedComponent={
          <SimpleProductsAvailabilityTableWrapper categoryId={category.id} />
        }
        expandIconSize="small"
      >
        No Variant Products
      </ExpandableTypography>
      <>
        {complexProductsRes?.map((product) => (
          <InventoryProductWithVariantsRow key={product.id} product={product} />
        ))}
      </>
    </>
  );
}
