import { Button, styled } from '@mui/material';

export const DefaultActionButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.grey[100]
      : theme.palette.primary.contrastText,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.grey[400],
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.secondary.main
        : theme.palette.grey[600],
  },
}));
