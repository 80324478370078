import { Expose } from 'class-transformer';
import Decimal from 'decimal.js';
import TransformStringToDecimal from '../../../common/custom-transformers/TransformStringToDecimal';

export class ViewStoreStatusDto {
  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  minimumOrderValue: Decimal;
  @Expose() inWindow: boolean;
  @Expose() currentWeek: {
    orderWeek: string;
    openAt: string;
    closeAt: string;
    shippingStartAt: string;
    shippingEndAt: string;
  };
  @Expose() nextWeek: {
    orderWeek: string;
    openAt: string;
    closeAt: string;
    shippingStartAt: string;
    shippingEndAt: string;
  };
}
