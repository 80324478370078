import { Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import RhfTextField from '../ui/forms/RhfTextField';
import { CreateBillingAddressDto } from '../../lib/api/addresses/dto/create-billing-address.dto';
import { CreateShippingAddressDto } from '../../lib/api/addresses/dto/create-shipping-address.dto';
import RhfPhoneInput from '../ui/forms/RhfPhoneInput';

export default function CreateAddressContactPartial() {
  const { control } = useFormContext<
    CreateBillingAddressDto | CreateShippingAddressDto
  >();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h3" variant="h5">
          Contact
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RhfTextField control={control} name="contact.email" label="Email" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RhfTextField
          control={control}
          name="contact.firstName"
          label="First Name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RhfTextField
          control={control}
          name="contact.lastName"
          label="Last Name"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <RhfPhoneInput
          name="contact.mobilePhone"
          label="Mobile Phone"
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <RhfPhoneInput
          name="contact.officePhone"
          label="Office Phone"
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <RhfPhoneInput name="contact.fax" label="Fax" control={control} />
      </Grid>
    </Grid>
  );
}
