import ExpandableTypography from '../../../components/ui/typography/ExpandableTypography';
import { ViewSellableProductDto } from '../../../lib/api/products/dto/view-sellable-product.dto';
import ProductVariants from './ProductVariants';

interface Props {
  product: ViewSellableProductDto;
}

export default function ProductWithVariantsRow({ product }: Props) {
  return (
    <ExpandableTypography
      component="p"
      expandedComponent={<ProductVariants product={product} />}
      fontWeight={700}
      expandIconSize="small"
    >
      {product.name}
    </ExpandableTypography>
  );
}
