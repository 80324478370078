import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationOptions,
  registerDecorator,
  ValidationArguments,
} from 'class-validator';
import Decimal from 'decimal.js';

@ValidatorConstraint()
class NumericStringMinConstraint implements ValidatorConstraintInterface {
  validate(value: any, args: ValidationArguments) {
    try {
      const decimal = new Decimal(value);

      if (decimal.isNaN()) return false;
      const minValue: number = args.constraints[0];
      return decimal.greaterThanOrEqualTo(minValue);
    } catch (err) {
      return false;
    }
  }

  defaultMessage(args: ValidationArguments) {
    return `${args.property} must be greater than or equal to ${args.constraints[0]}.`;
  }
}

export function NumericStringMin(
  minValue: number,
  validationOptions?: ValidationOptions,
) {
  return (object: any, propertyName: string) => {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [minValue],
      validator: NumericStringMinConstraint,
    });
  };
}
