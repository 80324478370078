import { Step, StepLabel, Stepper } from '@mui/material';
import { DtoStringKeysAsPath } from '../../../lib/util/type-utils';

export interface StepperData<Dto> {
  text: string;
  isActive?: boolean;
  fieldsInStep: DtoStringKeysAsPath<Dto>[];
  focusField?: DtoStringKeysAsPath<Dto>;
  fieldsToValidateOnNext?: DtoStringKeysAsPath<Dto>[];
  dontDisplayForAdmin?: boolean;
  dontDisplayForCustomer?: boolean;
}

interface Props<Dto> {
  steps: StepperData<Dto>[];
}

export default function CustomStepper<Dto>({ steps }: Props<Dto>) {
  return (
    <Stepper
      activeStep={steps.findIndex((s) => s.isActive)}
      alternativeLabel
      sx={{ mt: 2, mb: 2 }}
    >
      {steps.map((stepItemData) => (
        <Step key={stepItemData.text}>
          <StepLabel>{stepItemData.text}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
