import { plainToInstance } from 'class-transformer';
import { apiClientFactory } from '../../common/clients';
import { getHeaders } from '../../util/http-utils';
import { UpdateInventoryItemManualQtyDto } from './dto/udpate-inventory-item-manual-qty.dto';
import { UpdateInventoryItemPhysicalCountDto } from './dto/udpate-inventory-item-physical-count.dto';
import { ViewInventoryItemDto } from './dto/view-inventory-item.dto';
import { ViewUpdateInventoryItemResDto } from './dto/view-update-inventory-item-res.dto';

const apiClient = apiClientFactory();

/****************************
Orders
****************************/

const resource = 'inventory';

export enum InventoryQueryKeys {
  'findInventoryForSimpleProductsByCategoryId' = 'FindInventoryForSimpleProductsByCategoryId',
  'findVariantsInventoryByProductId' = 'FindVariantsInventoryByProductId',
}

export async function getInventoryForSimpleProductsByCategoryId(
  categoryId: number,
): Promise<ViewInventoryItemDto[]> {
  const { data } = await apiClient.get(
    `v1/${resource}?categoryId=${categoryId}`,
    {
      headers: getHeaders(),
    },
  );

  return plainToInstance(ViewInventoryItemDto, data as [], {
    excludeExtraneousValues: true,
  });
}

export async function getInventoryForVariantsByProductId(
  productId: string,
): Promise<ViewInventoryItemDto[]> {
  const { data } = await apiClient.get(`v1/${resource}/${productId}/variants`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewInventoryItemDto, data as [], {
    excludeExtraneousValues: true,
  });
}

export async function updateInventoryItem(
  updateInventoryItemDto:
    | UpdateInventoryItemPhysicalCountDto
    | UpdateInventoryItemManualQtyDto,
): Promise<ViewUpdateInventoryItemResDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}`,
    updateInventoryItemDto,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewUpdateInventoryItemResDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function removeInventoryItemPhysicalCount(
  id: string,
  type: 'product' | 'variant',
): Promise<ViewUpdateInventoryItemResDto> {
  const { data } = await apiClient.delete(
    `v1/${resource}/physical-count/${type}/${id}`,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewUpdateInventoryItemResDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function removeInventoryItemManualQty(
  id: string,
  type: 'product' | 'variant',
): Promise<ViewUpdateInventoryItemResDto> {
  const { data } = await apiClient.delete(
    `v1/${resource}/manual-qty/${type}/${id}`,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewUpdateInventoryItemResDto, data, {
    excludeExtraneousValues: true,
  });
}
