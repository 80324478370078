import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Alert, CssBaseline, Snackbar, ThemeProvider } from '@mui/material';
import { useState } from 'react';
import { AppProvider } from './contexts/AppContext';
import useSnackbar from './hooks/useSnackbar';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AppRoutes from './routes/AppRoutes';
import { LIGHT_THEME, DARK_THEME } from './config/AppConstants';

const queryClient = new QueryClient();

function App() {
  const [theme, setTheme] = useState(LIGHT_THEME);
  const handleChangeTheme = () => {
    if (theme.palette.mode === 'dark') {
      setTheme(LIGHT_THEME);
    } else {
      setTheme(DARK_THEME);
    }
  };

  const { handleCloseSnackbar, handleShowMessage, snackbarConfig } =
    useSnackbar();

  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider
        handleShowMessage={handleShowMessage}
        handleChangeTheme={handleChangeTheme}
      >
        {snackbarConfig.open && (
          <Snackbar
            open={snackbarConfig.open}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={snackbarConfig.autoHideDurationMs}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbarConfig.severity}
              sx={{ width: '100%' }}
            >
              {snackbarConfig.message}
            </Alert>
          </Snackbar>
        )}
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <ReactQueryDevtools initialIsOpen={false} />
          <AppRoutes />
        </ThemeProvider>
      </AppProvider>
    </QueryClientProvider>
  );
}

export default App;
