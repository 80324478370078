import { useState } from 'react';
import { decodeToken, isExpired } from 'react-jwt';
import { Expose, plainToInstance } from 'class-transformer';
import { apiClientFactory } from '../../common/clients';
import { LoginCredentialsDto } from './dto/login-credentials.dto';
import { ActivateUserDto } from './dto/activate-user.dto';
import { ResetPasswordDto } from './dto/reset-password.dto';
import { ResetPasswordRequestDto } from './dto/reset-password-request.dto';
import { useNavigate } from 'react-router-dom';

export class AuthenticatedUser {
  @Expose() id: string;
  @Expose() email: string;
  @Expose() isAdmin: boolean;
  @Expose() isRetail: boolean;
  @Expose() isPremium: boolean;
  @Expose() isStandard: boolean;
  @Expose() customerId: string;
  @Expose() cartId: string;
}

export default function useAuth() {
  const apiClient = apiClientFactory();
  const navigate = useNavigate();

  const [loggedInUser, setLoggedInUser] = useState<AuthenticatedUser | null>(
    () => {
      const token = localStorage.getItem('access_token');

      if (token && !isExpired(token)) {
        return plainToInstance(
          AuthenticatedUser,
          decodeToken(token) as string,
          {
            excludeExtraneousValues: true,
          },
        );
      }
      return null;
    },
  );

  return {
    authed() {
      const token = localStorage.getItem('access_token');
      return token && !isExpired(token);
    },
    loggedInUser,
    async login(credentials: LoginCredentialsDto) {
      const res = await apiClient.post('v1/auth/login', credentials);
      const token = res.data.access_token;
      localStorage.setItem('access_token', token);

      const user = plainToInstance(
        AuthenticatedUser,
        decodeToken(token) as string,
        {
          excludeExtraneousValues: true,
        },
      );

      setLoggedInUser(user);

      return user;
    },
    logout() {
      setLoggedInUser(null);
      localStorage.removeItem('access_token');
      navigate('/auth/login');
      return;
    },
    async activate(activateUserDto: ActivateUserDto, passcode: string) {
      const res = await apiClient.post(
        `v1/auth/activate/${passcode}`,
        activateUserDto,
      );
      return res;
    },
    async requestPasswordReset(
      resetPasswordRequestDto: ResetPasswordRequestDto,
    ) {
      const res = await apiClient.post(
        `v1/auth/reset-password-request`,
        resetPasswordRequestDto,
      );
      return res;
    },
    async resetPassword(resetPasswordDto: ResetPasswordDto, passcode: string) {
      const res = await apiClient.post(
        `v1/auth/reset-password/${passcode}`,
        resetPasswordDto,
      );
      return res;
    },
  };
}
