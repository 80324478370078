import { Expose, Type } from 'class-transformer';
import Decimal from 'decimal.js';
import TransformStringToDecimal from '../../../common/custom-transformers/TransformStringToDecimal';
import { ViewProductOptionDto } from '../../product-options/dto/view-product-option.dto';
import { ProductLocation } from '../enums/product-locations.enum';

export class ViewProductStoreViewDto {
  @Expose() id: string;
  @Expose() hasVariants: boolean;
  @Expose() name: string;
  @Expose() description?: string;
  @Expose() upc?: string;
  @Expose() sku: string;
  @Expose() itemNo?: string;
  @Expose() qtyPerShelf: number;
  @Expose() shelvesPerRack: number;
  @Expose() @TransformStringToDecimal({ toClassOnly: true }) price: Decimal;
  @Expose() orderLimit?: number;

  @TransformStringToDecimal({ toClassOnly: true })
  @Expose()
  orderIncrement: Decimal;

  @Expose() location: ProductLocation;
  @Expose() @TransformStringToDecimal({ toClassOnly: true }) qty: Decimal;
  @Expose() isProvenWinner: boolean;
  @Expose() categoryId: number;

  @Expose() @Type(() => ViewProductOptionDto) options: ViewProductOptionDto[];
}
