import { Expose, Type } from 'class-transformer';
import { ViewProductOptionDto } from '../../product-options/dto/view-product-option.dto';
import { ViewProductDto } from './view-product.dto';
import { ViewVariantDto } from '../../variants/dto/view-variant.dto';
import { ViewCategoryDto } from '../../categories/dto/view-category.dto';

export class ViewProductExtendedDto extends ViewProductDto {
  @Expose() @Type(() => ViewCategoryDto) category: ViewCategoryDto;
  @Expose() @Type(() => ViewProductOptionDto) options: ViewProductOptionDto[];
  @Expose()
  @Type(() => ViewVariantDto)
  variants: ViewVariantDto[];
  @Expose()
  defaultImageFileUrl?: string;
}
