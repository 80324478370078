import { Box } from '@mui/material';
import { ViewContactDto } from '../../lib/api/contacts/dto/view-contact.dto';
import BadgeIcon from '@mui/icons-material/Badge';
import ContactContent from './ContactContent';

interface Props {
  contact: ViewContactDto;
  index?: number;
}

export default function ContactCard({ contact }: Props) {
  return (
    <Box display="flex" alignItems="flex-start" gap={1}>
      <BadgeIcon sx={{ color: 'info.main' }} />
      <Box>
        <ContactContent contact={contact} />
      </Box>
    </Box>
  );
}
