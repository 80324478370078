import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@mui/material';
import { getRowsPerPageDefault, TableState } from '../../lib/util/table-util';
import { useDebounce } from 'use-debounce';
import useModal from '../../components/ui/modal/useModal';
import FullScreenModal from '../../components/ui/modal/FullScreenModal';
import { useAdminLayoutContext } from '../../components/layout/context/AdminLayoutContext';
import useAuth from '../../lib/api/auth/useAuth';
import { Navigate } from 'react-router-dom';
import {
  DeliveryRouteQueryKeys,
  getAllDeliveryRoutes,
} from '../../lib/api/delivery-routes/delivery-routes.service';
import DeliveryRoutesTable from '../../components/delivery-routes/DeliveryRoutesTable';
import { ViewDeliveryRouteDto } from '../../lib/api/delivery-routes/dto/view-delivery-route.dto';
import DeliveryRouteDrawerContent from '../../components/delivery-routes/delivery-route-drawer-content/DeliveryRouteDrawerContent';
import DetailsDrawer from '../../components/ui/drawer/DetailsDrawer';
import AddOrUpdateDeliveryRouteForm from '../../components/delivery-routes/AddOrUpdateDeliveryRouteForm';

export default function DeliveryRoutesPage() {
  const {
    handleTableRowClick,
    handleDetailsDrawerClose,
    detailsDrawerConfig,
    activeRecord,
  } = useAdminLayoutContext<ViewDeliveryRouteDto>();

  const { authed } = useAuth();

  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();

  const [tableState, setTableState] = useState<
    TableState<ViewDeliveryRouteDto>
  >({
    pagination: { current: 1, pageSize: getRowsPerPageDefault() },
    filters: null,
    sorter: null,
  });

  const [tableStateDebounced] = useDebounce(tableState, 500);

  const onCreateDeliveryRouteSuccess = () => {
    handleCloseModal();
  };

  const { isError, data, error, isFetching } = useQuery(
    [DeliveryRouteQueryKeys.findAllDeliveryRoutes, tableStateDebounced],
    () => getAllDeliveryRoutes(tableStateDebounced),
  );

  console.log({
    component: 'DeliveryRoutesPage',
    message: 'rendered',
  });

  return !authed() ? (
    <Navigate to="/auth/login" />
  ) : (
    <>
      <DeliveryRoutesTable
        apiRes={data}
        isFetching={isFetching}
        setTableState={setTableState}
        tableState={tableState}
        onRowClick={handleTableRowClick}
      />
      <Button variant="contained" sx={{ mt: 2 }} onClick={handleLaunchModal}>
        Add Delivery Route
      </Button>
      <FullScreenModal
        title="Create Delivery Route"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <AddOrUpdateDeliveryRouteForm
          onSuccess={onCreateDeliveryRouteSuccess}
        />
      </FullScreenModal>
      {activeRecord && (
        <DetailsDrawer
          open={detailsDrawerConfig.open}
          detailsDrawerWidth={detailsDrawerConfig.width}
          onDrawerClose={handleDetailsDrawerClose}
          title={activeRecord.name}
        >
          <DeliveryRouteDrawerContent deliveryRouteId={activeRecord.id} />
        </DetailsDrawer>
      )}
    </>
  );
}
