import { Expose } from 'class-transformer';
import Decimal from 'decimal.js';
import { DateTime } from 'luxon';
import TransformIsoStringToDateTime from '../../../common/custom-transformers/TransformIsoStringToDateTime';
import TransformStringToDecimal from '../../../common/custom-transformers/TransformStringToDecimal';

export class ViewUpdateInventoryItemResDto {
  @Expose() productId: string;
  @Expose() variantId: string;
  @Expose() categoryId: string;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  manualQty: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  physicalCount: Decimal;

  @Expose()
  @TransformIsoStringToDateTime({ toClassOnly: true })
  manualQtyAsOf: DateTime;

  @Expose()
  @TransformIsoStringToDateTime({ toClassOnly: true })
  physicalCountAsOf: string;
}
