import { List } from '@mui/material';
import CustomerNavItem from './CustomerNavItem';
import { NavigationItem, NavigationItemExpandable } from './AdminSidebarNav';

interface Props {
  pages: Array<NavigationItem | NavigationItemExpandable>;
}

export default function CustomerNav({ pages }: Props) {
  return (
    <nav style={{ width: '100%', display: 'flex' }}>
      <List
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          textAlign: 'center',
        }}
      >
        {pages.map((page) => (
          <CustomerNavItem key={page.key} page={page} />
        ))}
      </List>
    </nav>
  );
}
