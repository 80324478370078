import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logo from '../../../assets/logo.png';
import CustomerNav from './CustomerNav';
import { NavigationItem, NavigationItemExpandable } from './AdminSidebarNav';

interface AppBarProps extends MuiAppBarProps {
  detailsDrawerOpen?: boolean;
  detailsDrawerWidth: number;
}

interface AppBarHeaderProps {
  pages: Array<NavigationItem | NavigationItemExpandable>;
  detailsDrawerOpen: boolean;
  detailsDrawerWidth: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'detailsDrawerOpen' && prop !== 'detailsDrawerWidth',
})<AppBarProps>(({ theme, detailsDrawerOpen, detailsDrawerWidth }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.secondary,
  zIndex: theme.zIndex.appBar,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(detailsDrawerOpen && {
    marginRight: `min(100%,${detailsDrawerWidth}px)`,
    width: `calc(100% - min(100%,${detailsDrawerWidth}px))`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& .app-bar-header-company-logo': {
      width: '0%',
    },
  }),
}));

export default function CustomerAppBarHeader({
  detailsDrawerOpen,
  detailsDrawerWidth,
  pages,
}: AppBarHeaderProps) {
  return (
    <AppBar
      position="fixed"
      detailsDrawerOpen={detailsDrawerOpen}
      detailsDrawerWidth={detailsDrawerWidth}
    >
      <Toolbar>
        <CustomerNav pages={pages} />
        <img
          className="app-bar-header-company-logo"
          src={logo}
          style={{ height: '60px', padding: '0.25rem', maxWidth: '90%' }}
          alt="Popes Plant Farm Logo"
        />
      </Toolbar>
    </AppBar>
  );
}
