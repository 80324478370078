import { Expose } from 'class-transformer';
import {
  ViewChildProductOptionValueDto,
  ViewProductOptionValueDto,
} from '../../product-option-values/dto/view-product-option-value.dto';

export class ViewProductOptionDto {
  @Expose() id: number;
  @Expose() depth: number;
  @Expose() name: string;
  @Expose() productId: string;
  @Expose() values: ViewProductOptionValueDto[];
  @Expose() children: ViewChildProductOptionDto[];
}

export class ViewChildProductOptionDto {
  @Expose() id: number;
  @Expose() depth: number;
  @Expose() name: string;
  @Expose() parentId: number;
  @Expose() productId: string;
  @Expose() values: ViewChildProductOptionValueDto[];
}
