import { Button } from '@mui/material';
import { downloadStoreAvailabilityBasic } from '../../lib/common/report-download.service';
import { useEffect } from 'react';

export default function BasicAvailabilityPage() {
  useEffect(() => {
    downloadStoreAvailabilityBasic();
  }, []);

  return (
    <div>
      <h1>Basic Availability Report</h1>
      <p>You report should download automatically.</p>
      <p>
        If it the report doesn't automatically download you can{' '}
        <Button variant="text" onClick={downloadStoreAvailabilityBasic}>
          click here.
        </Button>
      </p>
    </div>
  );
}
