import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ConfirmDialog,
  ConfirmDeleteBaseProps,
} from '../ui/dialog/ConfirmDialog';
import { useAppContext } from '../../contexts/AppContext';
import { ViewProductDto } from '../../lib/api/products/dto/view-product.dto';
import {
  ProductQueryKeys,
  deleteProduct,
} from '../../lib/api/products/products.service';
import { getErrorMessage } from '../../lib/util/misc-utils';

interface Props extends ConfirmDeleteBaseProps {
  product: ViewProductDto;
}

export function DeleteProductConfirmDialog({
  product,
  isOpen,
  onClose,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  const deleteMutation = useMutation((productId: string) =>
    deleteProduct(productId),
  );

  const handleOnConfirm = async (productId: string) => {
    //We close the modal regardless of success.
    onClose();
    try {
      const deletedProduct = await deleteMutation.mutateAsync(productId);
      queryClient.invalidateQueries([ProductQueryKeys.findAllProducts]);
      handleShowMessage(`Deleted product '${product.name}'`);
      onSuccess?.(deletedProduct);
    } catch (err: any) {
      handleShowMessage(getErrorMessage(err), 'error');
    }
  };

  return (
    <ConfirmDialog
      title={`Delete Product: ${product.name}`}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() => handleOnConfirm(product.id)}
    >
      <>
        <p>
          Are you sure you want to delete the product '{product.name}'?{' '}
          <strong>This action cannot be undone.</strong>
        </p>
        <p> Deleting the product will also delete the product's variants.</p>
      </>
    </ConfirmDialog>
  );
}
