import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useAppContext } from '../../contexts/AppContext';
import { updateProduct } from '../../lib/api/products/products.service';
import { updateVariant } from '../../lib/api/variants/variants.service';
import { UpdateProductAvailabilityFlagsDto } from '../../lib/api/products/dto/update-product-availability-flags.dto';
import { UpdateVariantAvailabilityFlagsDto } from '../../lib/api/variants/dto/update-variant-availability-flags.dto';

type AVAILABILITY_FLAGS =
  | 'isSellableProduct'
  | 'sellToPopesProduct'
  | 'sellToPremiumProduct'
  | 'sellToStandardProduct'
  | 'isSellableVariant'
  | 'sellToPopesVariant'
  | 'sellToPremiumVariant'
  | 'sellToStandardVariant';

export interface InventoryItemAvailabilityFlags {
  productId: string;
  variantId?: string;
  isSellableProduct: boolean;
  sellToPopesProduct: boolean;
  sellToPremiumProduct: boolean;
  sellToStandardProduct: boolean;
  isSellableVariant?: boolean;
  sellToPopesVariant?: boolean;
  sellToPremiumVariant?: boolean;
  sellToStandardVariant?: boolean;
}

interface Props {
  inventoryItem: InventoryItemAvailabilityFlags;
  flag: AVAILABILITY_FLAGS;
}

export default function InventoryAvailabilitySwitch({
  inventoryItem,
  flag,
}: Props) {
  const { handleShowMessage } = useAppContext();

  const updateProductAvailabilityMutation = useMutation(
    (props: UpdateProductAvailabilityFlagsDto) =>
      updateProduct(inventoryItem.productId, props),
  );

  const updateVariantAvailabilityMutation = useMutation(
    (props: UpdateVariantAvailabilityFlagsDto) =>
      updateVariant(inventoryItem.variantId, props),
  );

  const handleToggle = async (
    inventoryItem: InventoryItemAvailabilityFlags,
    e: any,
  ) => {
    // NOTE: WE DON'T USE QUERY CLIENT TO FORCE A REFRESH. RATHER
    // WE ASSUME OPTIMISTIC UPADTE HERE AND UPDATE THE ORDER FLAG
    // AUTOMATICALLY. IF OUR MUTATE FAILS WE ROLLBACK THE OPTIMISTIC
    // ASSUMPTION.

    const newValue = e.target.checked as boolean;
    inventoryItem[flag] = !inventoryItem[flag];

    const mapper: Record<AVAILABILITY_FLAGS, string> = {
      isSellableProduct: 'isSellable',
      isSellableVariant: 'isSellable',
      sellToPopesProduct: 'sellToPopes',
      sellToPopesVariant: 'sellToPopes',
      sellToPremiumProduct: 'sellToPremium',
      sellToPremiumVariant: 'sellToPremium',
      sellToStandardProduct: 'sellToStandard',
      sellToStandardVariant: 'sellToStandard',
    };

    try {
      const mutateObj = {
        [mapper[flag]]: newValue,
      };
      if (inventoryItem.variantId) {
        //updating variant flags
        await updateVariantAvailabilityMutation.mutateAsync(mutateObj);
      } else {
        await updateProductAvailabilityMutation.mutateAsync(mutateObj);
      }
    } catch (err: any) {
      inventoryItem[flag] = !inventoryItem[flag]; //Put flag back.
      handleShowMessage('Item availability cound not be updated.', 'error');
    }
  };

  const lableMap: Record<AVAILABILITY_FLAGS, string> = {
    isSellableProduct: 'Store',
    isSellableVariant: 'Store',
    sellToPopesProduct: 'Popes',
    sellToPopesVariant: 'Popes',
    sellToPremiumProduct: 'Premium',
    sellToPremiumVariant: 'Premium',
    sellToStandardProduct: 'Standard',
    sellToStandardVariant: 'Standard',
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            name={flag}
            onClick={(e) => e.stopPropagation()} //Have to keep from trigger row click.
            onChange={(e) => handleToggle(inventoryItem, e)}
            checked={inventoryItem[flag]}
            color="secondary"
          />
        }
        label={lableMap[flag]}
      />
    </FormGroup>
  );
}
