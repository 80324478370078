import { Expose, Type } from 'class-transformer';
import { IsOptional, IsString, ValidateNested } from 'class-validator';
import CustomIsNotEmpty from '../../../common/custom-class-validators/CustomIsNotEmpty';
import CustomLength from '../../../common/custom-class-validators/CustomLength';
import { CreateChildProductOptionValueForExistingOptionDto } from './create-child-product-option-value-for-existing-option.dto';

export class CreateRootProductOptionValueForExistingOptionDto {
  @Expose()
  @IsString()
  @CustomLength(1, 100, 'Value')
  @CustomIsNotEmpty('Value')
  value: string; //the new value we are adding

  @Expose()
  @ValidateNested({ each: true })
  @Type(() => CreateChildProductOptionValueForExistingOptionDto)
  @IsOptional()
  children?: CreateChildProductOptionValueForExistingOptionDto[];
}
