import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ConfirmDialog,
  ConfirmDeleteBaseProps,
} from './../ui/dialog/ConfirmDialog';
import { useAppContext } from '../../contexts/AppContext';
import { CustomerQueryKeys } from '../../lib/api/customers/customers.service';
import { ViewContactDto } from '../../lib/api/contacts/dto/view-contact.dto';
import { deleteContact } from '../../lib/api/contacts/contacts.service';
import { getErrorMessage } from '../../lib/util/misc-utils';

interface Props extends ConfirmDeleteBaseProps {
  contact: ViewContactDto;
}

export function DeleteAddressContactConfirmDialog({
  contact,
  isOpen,
  onClose,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();

  const deleteMutation = useMutation((contactId: string) =>
    deleteContact(contactId),
  );

  const contactName = `${contact.firstName} ${contact.lastName} (${contact.email})`;

  const handleOnConfirm = async (contactId: string) => {
    //We close the modal regardless of success.
    onClose();
    try {
      const deletedContact = await deleteMutation.mutateAsync(contactId);
      queryClient.invalidateQueries([CustomerQueryKeys.findCustomerById]);
      handleShowMessage(`Deleted contact @ '${contactName}'`);
      onSuccess?.(deletedContact);
    } catch (err: any) {
      handleShowMessage(getErrorMessage(err), 'error');
    }
  };

  return (
    <ConfirmDialog
      title={`Delete Contact: ${contactName}`}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() => handleOnConfirm(contact.id)}
    >
      <>
        <p>
          Are you sure you want to delete the contact '{contactName}'?{' '}
          <strong>This action cannot be undone.</strong>
        </p>
      </>
    </ConfirmDialog>
  );
}
