import { Autocomplete, Chip, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import RequiredInputLabel from './RequiredInputLabel';

interface Props {
  control: Control<any, any>;
  name: string;
  defaultText: string;
}

export default function RhfTagInput({ control, name, defaultText }: Props) {
  // TODO:: Currently tags are only added when the enter key is pressed.
  // Should add logic that appends tags on other keys like comma.
  // const handleKeyDown = (event: any) => {
  //   console.log('handling keydown...', event.target.value);
  //   switch (event.key) {
  //     case ',': {
  //       event.preventDefault();
  //       event.stopPropagation();
  //       if (event.target.value.length > 0) {
  //         setValue([...value, event.target.value]);
  //       }
  //       break;
  //     }
  //     default:
  //   }
  // };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={[]}
      render={({ field: { onChange, ...props }, fieldState: { error } }) => (
        <Autocomplete
          multiple
          freeSolo
          disablePortal
          options={[]}
          getOptionLabel={(option) => option}
          fullWidth
          autoHighlight
          autoSelect
          onChange={(event, selected) => onChange(selected)}
          {...props}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="filled"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          placeholder={'Echeveria'}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={<RequiredInputLabel text={defaultText} />}
                margin="normal"
                helperText={error && error.message}
                error={error ? true : false}
                placeholder={'Echeveria'}
              />
            );
          }}
        />
      )}
    />
  );
}
