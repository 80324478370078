import {
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import CustomerTypeChip from '../../../../components/customers/CustomerTypeChip';
import useAuth from '../../../../lib/api/auth/useAuth';
import { CustomerType } from '../../../../lib/api/customers/dto/view-customer.dto';
import ProvenWinnerMark from './ProvenWinnerMark';
import { useState } from 'react';
import { findImage } from '../../../../lib/api/images/images.service';

interface Props {
  name: string;
  description: string;
  pw: boolean;
  sellToPopes: boolean;
  sellToPremium: boolean;
  sellToStandard: boolean;
  imageUrl: string;
  productOrVariantId: string;
  type: 'product' | 'variant';
  defaultImageFileNameKey: string;
}

export default function SellableItemDescription({
  name,
  description,
  pw,
  sellToPopes,
  sellToPremium,
  sellToStandard,
  imageUrl,
  productOrVariantId,
  type,
  defaultImageFileNameKey,
}: Props) {
  const { loggedInUser } = useAuth();

  const [open, setOpen] = useState(false);
  const [bigImageUrl, setBigImageUrl] = useState<string | null>(null);
  const [loadingBigImage, setLoadingBigImage] = useState(false);

  const handleImageClick = async (event: React.MouseEvent<HTMLElement>) => {
    try {
      setLoadingBigImage(true);
      const { url } = await findImage(
        type,
        productOrVariantId,
        'm',
        defaultImageFileNameKey,
      );
      setBigImageUrl(url);
      setLoadingBigImage(false);
      setOpen((previousOpen) => !previousOpen);
    } catch (err) {
      setOpen(false);
      setLoadingBigImage(false);
    }
  };

  const handleClose = async () => {
    setBigImageUrl(null);
    setOpen(false);
    setLoadingBigImage(false);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={8} md={8}>
        <Typography
          component="span"
          sx={{ fontWeight: 700, color: 'primary.light' }}
        >
          {name}
        </Typography>
        <Typography
          component="p"
          sx={{ fontStyle: 'italic', fontSize: '0.7rem', color: 'grey.600' }}
        >
          {description}
        </Typography>
        {pw ? <ProvenWinnerMark /> : null}
        {loggedInUser.isAdmin && (
          <Stack direction="row" gap={1} sx={{ ml: 1, mb: 1, mt: 1 }}>
            {sellToPopes ? (
              <CustomerTypeChip
                type={CustomerType.PopesRetailCenter}
                abbreviated
              />
            ) : null}
            {sellToPremium ? (
              <CustomerTypeChip type={CustomerType.Premium} abbreviated />
            ) : null}
            {sellToStandard ? (
              <CustomerTypeChip type={CustomerType.Standard} abbreviated />
            ) : null}
          </Stack>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {imageUrl && (
          <>
            <img
              src={imageUrl}
              alt={`Product Image for ${name}`}
              onClick={handleImageClick}
              style={{ cursor: 'pointer', borderRadius: '4px' }}
            />
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="Product Image"
              aria-describedby="A popup of the Product Image"
              tabIndex={-1}
            >
              <Paper
                sx={{
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  p: 0,
                  m: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignContent: 'center',
                  tabIndex: -1,
                }}
              >
                {loadingBigImage && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: 'primary.main',
                      position: 'absolute',
                    }}
                  />
                )}
                {!loadingBigImage && (
                  <img
                    src={bigImageUrl}
                    style={{
                      borderRadius: '4px',
                    }}
                    alt={`Larger Product Image for ${name}`}
                  />
                )}
              </Paper>
            </Modal>
          </>
        )}
      </Grid>
    </Grid>
  );
}
