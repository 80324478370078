import { Expose } from 'class-transformer';
import { IsEnum, IsOptional, IsUUID, ValidateIf } from 'class-validator';
import CustomIsNotEmpty from './../../../common/custom-class-validators/CustomIsNotEmpty';

export enum OrderType {
  'Delivery' = 'Delivery',
  'PickupGreenback' = 'PickupGreenback',
  'PickupMaryville' = 'PickupMaryville',
}

export class CreateOrderDto {
  @IsUUID()
  @CustomIsNotEmpty('Cart')
  @Expose()
  cartId: string;

  @IsEnum(OrderType)
  @Expose()
  type: OrderType;

  @IsUUID('all', { message: 'A customer is required to place an order.' })
  @CustomIsNotEmpty('Customer')
  @Expose()
  customerId: string;

  @IsUUID('all', {
    message: 'A billing adddress is required to place an order.',
  })
  @CustomIsNotEmpty('Billing Address')
  @Expose()
  billingAddressId: string;

  @IsUUID('all')
  @CustomIsNotEmpty('Billing Address Contact')
  @Expose()
  billingContactId: string;

  @ValidateIf((o) => o.type === OrderType.Delivery)
  @IsUUID('all', {
    message: 'A shipping adddress is required to place an order for delivery.',
  })
  @Expose()
  shippingAddressId?: string;

  @IsOptional()
  @IsUUID('all')
  @Expose()
  shippingContactId?: string;
}
