import { useState } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { BadRequestErrorRes } from '../../../lib/common/clients';
import { StepperData } from './CustomStepper';

export type FormattedValidationError = Record<string, ValidationErrorInfo[]>;

export interface SeverErrorResponse {
  statusCode?: number;
  errors: FormattedValidationError;
  message: string;
}

interface ValidationErrorInfo {
  message: string;
  type: string;
}

export default function useFormSubmitErrorAlert<Dto>() {
  const [serverErrorRes, setServerErrorRes] = useState<SeverErrorResponse>();

  const processFormSubmitError = (
    err: Error,
    setError: UseFormSetError<Dto>,
    multiStepConfig?: {
      steps: StepperData<Dto>[];
      setActivePage: (index: number) => void;
    },
  ) => {
    const errObj = JSON.parse(err.message);
    setServerErrorRes(errObj);

    if (!errObj.statusCode) return;
    if (errObj.statusCode !== 400) return;

    const { errors: validationErrs } = errObj as BadRequestErrorRes;

    const pagesWithErrors = [];
    for (const err in validationErrs) {
      if (multiStepConfig) {
        const pageFieldIsOn = multiStepConfig.steps.find(
          (s) => s.fieldsInStep.indexOf(err as any) >= 0,
        );
        if (pageFieldIsOn) {
          const indexOfPage = multiStepConfig.steps.indexOf(pageFieldIsOn);
          pagesWithErrors.push(indexOfPage);
        }
      }

      setError(err as any, {
        message: validationErrs[err]
          .map((errInfo: any) => errInfo.message)
          .join('; '),
        type: validationErrs[err]
          .map((errInfo: any) => errInfo.type)
          .join('; '),
      });
    }

    if (pagesWithErrors.length > 0) {
      const firstPageWithErrorIndex = Math.min(...pagesWithErrors);
      multiStepConfig.setActivePage(firstPageWithErrorIndex);
    }
  };

  const clearFormSubmitError = () => {
    setServerErrorRes(null);
  };

  return {
    clearFormSubmitError,
    processFormSubmitError,
    serverErrorMessage: serverErrorRes?.message || null,
  };
}
