import { Box } from '@mui/material';
import { useState } from 'react';
import OrdersTableIdCol from '../../../components/orders/OrdersTableIdCol';
import OrdersTableTypeCol from '../../../components/orders/OrdersTableTypeCol';
import FullScreenModal from '../../../components/ui/modal/FullScreenModal';
import useModal from '../../../components/ui/modal/useModal';
import PaginatedTable from '../../../components/ui/tables/paginated-table/PaginatedTable';
import { ViewOrderDto } from '../../../lib/api/orders/dto/view-order.dto';
import { TableCol } from '../../../lib/util/table-util';
import OrderConfirmationPage from '../../orders/[id]/OrderConfirmationPage';
import { OrderFullfillmentTableProps } from './shared.interfaces';

export default function OrdersShippedTable({
  apiRes,
  isFetching,
  setTableState,
  tableState,
  onRowClick,
}: OrderFullfillmentTableProps) {
  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const columns: TableCol<ViewOrderDto>[] = [
    {
      id: 'id',
      label: 'ID',
      filterType: 'contains',
      render: (order: ViewOrderDto) => (
        <OrdersTableIdCol order={order} onClick={handleOrderIdClicked} />
      ),
      widthPct: 10,
      sortable: true,
      sortKey: 'createdAt',
    },
    {
      id: 'orderWeek',
      label: 'Order Week',
      filterType: 'contains',
      widthPct: 15,
      headerCellSx: { minWidth: '115px' },
    },
    {
      id: 'billingCompanyName',
      label: 'Bill to',
      filterType: 'contains',
      widthPct: 15,
      headerCellSx: { minWidth: '115px' },
    },
    {
      id: 'shippingCompanyName',
      label: 'Ship to',
      filterType: 'contains',
      widthPct: 15,
      headerCellSx: { minWidth: '115px' },
    },
    {
      id: 'status',
      label: 'Status',
      render: (o: ViewOrderDto) => (
        <>{`${o.status} on ${new Date(o.shippedAt).toLocaleDateString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        })}`}</>
      ),
      widthPct: 15,
      headerCellSx: { minWidth: '150px' },
    },
    {
      id: 'type',
      label: 'Type',
      filterType: 'contains',
      render: (order: ViewOrderDto) => <OrdersTableTypeCol order={order} />,
      widthPct: 5,
      align: 'center',
    },
    {
      id: 'racks',
      label: 'Racks',
      render: (order: ViewOrderDto) => <>{order.racks.toFixed(2)}</>,
      widthPct: 10,
    },
  ];

  const handleOrderIdClicked = (e: any, order: ViewOrderDto) => {
    e.stopPropagation();
    setSelectedOrderId(order.id);
    handleLaunchModal();
  };

  return (
    <>
      <PaginatedTable<ViewOrderDto>
        columns={columns}
        apiRes={apiRes}
        isFetching={isFetching}
        setTableState={setTableState}
        tableState={tableState}
        onRowClick={onRowClick}
        paperElevation={0}
        hoverDisabled
        fullWidth
      />
      <FullScreenModal
        title="Order"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <Box sx={{ p: 3 }}>
          {selectedOrderId && (
            <OrderConfirmationPage orderId={selectedOrderId} />
          )}
        </Box>
      </FullScreenModal>
    </>
  );
}
