import TabPanel from '../../../components/ui/tabs/TabPanel';
import { OrderStatus } from '../../../lib/api/orders/enums/order-status.enum';
import { ViewStoreStatusDto } from '../../../lib/api/store/dto/view-store-status.dto';
import useOrdersFulfillmentTable from '../hooks/useOrdersFulfillmentTable';
import OrdersBacklogTable from '../tables/OrdersBacklogTable';

interface Props {
  label: string;
  tabValue: number;
  index: number;
  storeStatus: ViewStoreStatusDto;
}

export default function OrdersBacklogTab({
  tabValue,
  index,
  storeStatus,
}: Props) {
  const { tableState, setTableState, isFetching, orders, handleChangeStatus } =
    useOrdersFulfillmentTable({
      filters: {
        status: { eq: OrderStatus.received },
        orderWeek: { lte: storeStatus.currentWeek.orderWeek },
      },
    });

  return (
    <TabPanel value={tabValue} index={index}>
      <OrdersBacklogTable
        apiRes={orders}
        isFetching={isFetching}
        setTableState={setTableState}
        tableState={tableState}
        onChangeStatus={handleChangeStatus}
      />
    </TabPanel>
  );
}
