import { Chip } from '@mui/material';
import { ViewUserDto } from '../../lib/api/users/dto/view-user.dto';

interface Props {
  user: ViewUserDto;
}

export default function UserTypeChip({ user }: Props) {
  if (user.isAdmin) {
    return <Chip variant="filled" label="Admin" color="primary" />;
  } else {
    return <Chip variant="filled" label="Customer" color="secondary" />;
  }
}
