import { Grid } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import CardItemTitle from '../ui/CardItemTitle';

export default function GreenbackAddressCard() {
  return (
    <Grid container spacing={1}>
      <CardItemTitle text={`Pickup @ Greenback`} />
      <Grid item xs={2} sm={1}>
        <MapIcon sx={{ color: 'info.main' }} />
      </Grid>
      <Grid item xs={10} sm={11}>
        <span>
          6814 Hwy 411 S. <br />
          Greenback, TN 37742
        </span>
      </Grid>
    </Grid>
  );
}
