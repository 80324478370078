import { Divider, Grid } from '@mui/material';
import { ViewSellableProductDto } from '../../../lib/api/products/dto/view-sellable-product.dto';
import ProductQtyInput from './parts/ProductQtyInput';
import SellableItemDescription from './parts/SellableItemDescription';
import SellableItemStats from './parts/SellableItemStats';

interface Props {
  product: ViewSellableProductDto;
  showBottomBorder: boolean;
}

export default function P1SimpleProductRow({
  product,
  showBottomBorder,
}: Props) {
  return (
    <Grid
      container
      alignItems="center"
      flexDirection="row"
      justifyContent="flex-start"
      columnSpacing={2}
      rowSpacing={0}
    >
      <Grid item sm={12} md={4}>
        <SellableItemDescription
          name={product.name}
          description={product.description}
          pw={product.isProvenWinner}
          sellToPopes={product.sellToPopes}
          sellToPremium={product.sellToPremium}
          sellToStandard={product.sellToStandard}
          imageUrl={product.defaultImageFileUrl}
          productOrVariantId={product.id}
          type="product"
          defaultImageFileNameKey={product.defaultImageFileNameKey}
        />
      </Grid>
      <Grid item sm={6} md={3}>
        <ProductQtyInput
          label="Qty"
          name={`${product.id}-qty`}
          decimals={2}
          product={product}
        />
      </Grid>
      <Grid item sm={6} md={5}>
        <SellableItemStats
          availableQty={product.availableQty}
          orderIncrement={product.orderIncrement}
          orderLimit={product.orderLimit}
          price={product.price}
        />
      </Grid>
      {showBottomBorder && (
        <Grid item xs={12}>
          <Divider color="primary" />
        </Grid>
      )}
    </Grid>
  );
}
