import { Grid, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useEffect } from 'react';
import {
  createAdminUser,
  UserQueryKeys,
} from '../../lib/api/users/users.service';
import { useAppContext } from '../../contexts/AppContext';
import FromSubmitButton from '../ui/forms/FormSubmitButton';
import {
  createCustomerUser,
  CustomerQueryKeys,
} from '../../lib/api/customers/customers.service';
import useFormSubmitErrorAlert from '../ui/forms/useFormSubmitErrorAlert';
import CustomForm, { onSuccessCallback } from '../ui/forms/CustomForm';
import RhfTextField from '../ui/forms/RhfTextField';
import { CreateUserDto } from '../../lib/api/users/dto/create-user.dto';
import RhfPhoneInput from '../ui/forms/RhfPhoneInput';
import { VALIDATION_ERR_DELAY } from '../../config/AppConstants';

interface Props {
  onSuccess?: onSuccessCallback;
  customerId?: string;
}

export default function AddUserForm({ onSuccess, customerId }: Props) {
  const resourceName = 'User';
  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  const createMutation = useMutation((createUserDto: CreateUserDto) => {
    if (customerId) return createCustomerUser(customerId, createUserDto);
    return createAdminUser(createUserDto);
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isSubmitted },
    setError,
    setFocus,
    control,
  } = useForm<CreateUserDto>({
    resolver: classValidatorResolver(CreateUserDto),
    mode: 'onTouched',
    delayError: VALIDATION_ERR_DELAY,
    defaultValues: {
      email: null,
      profile: {
        firstName: null,
        lastName: null,
        mobilePhone: null,
        officePhone: null,
      },
    },
  });

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<CreateUserDto>();

  const onSubmit: SubmitHandler<CreateUserDto> = async (
    formData: CreateUserDto,
  ) => {
    clearFormSubmitError();

    try {
      const createdUser = await createMutation.mutateAsync(formData);
      queryClient.invalidateQueries([UserQueryKeys.findAllUsers]);
      queryClient.invalidateQueries([
        CustomerQueryKeys.findCustomerById,
        { id: customerId },
      ]);
      queryClient.invalidateQueries([CustomerQueryKeys.findAllCustomers]);
      handleShowMessage(
        `Created ${customerId ? 'Customer' : 'Admin'} User '${
          createdUser.email
        }'`,
      );
      onSuccess?.(createdUser);
    } catch (err: any) {
      processFormSubmitError(err, setError);
    }
  };

  return (
    <CustomForm
      serverErrorMessage={serverErrorMessage}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RhfTextField control={control} name="email" label="Email" required />
        </Grid>
        <Grid item xs={12}>
          <Typography component="h3" variant="h6">
            Profile
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <RhfTextField
            control={control}
            name="profile.firstName"
            label="First Name"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <RhfTextField
            control={control}
            name="profile.lastName"
            label="Last Name"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <RhfPhoneInput
            name="profile.mobilePhone"
            label="Mobile Phone"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <RhfPhoneInput
            name="profile.officePhone"
            label="Office Phone"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <FromSubmitButton
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            isValid={isValid}
            text={`Create ${customerId ? 'Customer' : 'Admin'} ${resourceName}`}
          />
        </Grid>
      </Grid>
    </CustomForm>
  );
}
