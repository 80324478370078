import { TableCell, TableRow } from '@mui/material';
import Decimal from 'decimal.js';
import { ViewCategoryDto } from '../../../../lib/api/categories/dto/view-category.dto';
import { ViewLineItemDto } from '../../../../lib/api/orders/dto/view-line-item.dto';
import { ViewOrderExtendedDto } from '../../../../lib/api/orders/dto/view-order-extended.dto';
import LineItemsTableProductRow from './LineItemsTableProductRow';
import LineItemsTableVariantHeaderRow from './LineItemsTableVariantHeaderRow';

interface Props {
  categoryName: string;
  items: ViewLineItemDto[];
  order: ViewOrderExtendedDto;
}

export interface LineItemVarianProduct {
  upc: string;
  sku: string;
  name: string;
  description: string;
  price: Decimal;
}

export default function LineItemsTableCategoryRow({
  categoryName,
  items,
  order,
}: Props) {
  const productItems = items
    .filter((item) => item.variantId === null)
    .sort((a, b) =>
      a.productName === b.productName
        ? 0
        : a.productName > b.productName
        ? 1
        : -1,
    );
  const variantItems = items
    .filter((item) => item.variantId)
    .sort((a, b) => (a.variantName > b.variantName ? 1 : -1))
    .sort((a, b) =>
      a.productName === b.productName
        ? 0
        : a.productName > b.productName
        ? 1
        : -1,
    );

  //Get a unique list of products that have variants
  const uniqueVariantProducts: LineItemVarianProduct[] = [];
  variantItems.forEach((variantItem) => {
    const indexInUnique = uniqueVariantProducts.findIndex(
      (uniqueValue) => uniqueValue.sku === variantItem.productSku,
    );

    if (indexInUnique === -1)
      uniqueVariantProducts.push({
        upc: variantItem.upc,
        sku: variantItem.productSku,
        name: variantItem.productName,
        description: variantItem.productDescription,
        price: variantItem.price,
      });
  });

  uniqueVariantProducts.sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <>
      <TableRow
        key={`category-row-${categoryName}`}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell
          component="th"
          scope="row"
          colSpan={7}
          sx={{ fontWeight: 'bold' }}
        >
          {categoryName}
        </TableCell>
      </TableRow>
      {productItems.map((productItem) => (
        <LineItemsTableProductRow
          key={productItem.id}
          item={productItem}
          order={order}
        />
      ))}
      {uniqueVariantProducts.map((variantProduct) => (
        <LineItemsTableVariantHeaderRow
          key={variantProduct.sku}
          product={variantProduct}
          items={variantItems.filter(
            (vi) => vi.productSku === variantProduct.sku,
          )}
          order={order}
        />
      ))}
    </>
  );
}
