import { ReactElement } from 'react';
import { Button, DialogActions, DialogContent } from '@mui/material';
import BootstrapDialog from './BootstrapDialog';
import BootstrapDialogTitle from './BootstrapDialogTitle';
import { onSuccessCallback } from '../forms/CustomForm';

interface ConfirmDialogProps {
  title: string;
  children: ReactElement;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
}

//Base props for our component instances that
//wrap Confirm Dialog to create DeleteXConfirmDialogComponents
export interface ConfirmDeleteBaseProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: onSuccessCallback;
}

export function ConfirmDialog({
  title,
  children,
  isOpen,
  onClose,
  onConfirm,
  onCancel,
}: ConfirmDialogProps) {
  return (
    <div>
      <BootstrapDialog onClose={onClose} aria-labelledby="title" open={isOpen}>
        <BootstrapDialogTitle id="title" onClose={onClose}>
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onConfirm}>
            Confirm
          </Button>
          <Button autoFocus onClick={onCancel}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
