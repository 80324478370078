import { TableCell } from '@mui/material';
import { CSSProperties, useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { StyledTableRow } from '../shared/StyledTableRow';
import { CustomDragLayer } from './CustomDragLayer';
import classes from './DraggableBodyRow.module.css';

const type = 'DraggableBodyRow';

function DraggableBodyRow({
  index,
  moveRow,
  className,
  style,
  children,
  ...restProps
}: any) {
  const tableRowRef = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = (monitor.getItem() || {}) as any;
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index
            ? ` ${classes['drop-over-downward']}`
            : ` ${classes['drop-over-upward']}`,
      };
    },
    drop: (item: { index: number }) => {
      if (item.index === index) return;
      moveRow(item.index, index);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(tableRowRef));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const getStyle = (isDragging: boolean): CSSProperties =>
    isDragging
      ? {
          opacity: 1,
        }
      : {};

  return (
    <StyledTableRow
      ref={tableRowRef}
      className={`${className} ${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style, ...getStyle(isDragging) }}
      {...restProps}
    >
      {children}
    </StyledTableRow>
  );
}

export default DraggableBodyRow;
