import { Expose } from 'class-transformer';
import Decimal from 'decimal.js';
import TransformStringToDecimal from '../../../common/custom-transformers/TransformStringToDecimal';

export default class ViewCartDto {
  @Expose() id: string;
  @Expose() expiresAt: string;
  @Expose() userId: string;
  @Expose() orderWeek: string;
  @Expose()
  @TransformStringToDecimal({ toClassOnly: true, emptyString: 'SetToZero' })
  racks: Decimal;
  @Expose()
  @TransformStringToDecimal({ toClassOnly: true, emptyString: 'SetToZero' })
  fuelSurchargeTotal: Decimal;
  @Expose()
  @TransformStringToDecimal({ toClassOnly: true, emptyString: 'SetToZero' })
  productTotalBeforeDiscounts: Decimal;
}
