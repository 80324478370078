import { Box, Button } from '@mui/material';
import useConfirmDialog from '../../ui/dialog/useConfirmDialog';
import useModal from '../../ui/modal/useModal';
import DescriptionContainer from '../../ui/description/DescriptionContainer';
import DescriptionItem from '../../ui/description/DescriptionItem';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import TabPanel from '../../ui/tabs/TabPanel';
import { useAdminLayoutContext } from '../../layout/context/AdminLayoutContext';
import { ViewOrderExtendedDto } from '../../../lib/api/orders/dto/view-order-extended.dto';

interface Props {
  label: string;
  tabValue: number;
  index: number;
  order: ViewOrderExtendedDto;
}
export default function OrderDetailsTab({
  label,
  tabValue,
  index,
  order,
}: Props) {
  const { handleDetailsDrawerClose } =
    useAdminLayoutContext<ViewOrderExtendedDto>();
  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();
  const {
    confirmDialogIsOpen,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
  } = useConfirmDialog();

  const onUpdateOrderSuccess = () => {
    handleCloseModal();
  };

  const onDeleteOrderSuccess = () => {
    handleDetailsDrawerClose();
  };

  return (
    <>
      <TabPanel value={tabValue} index={index}>
        <DescriptionContainer>
          <DescriptionItem label="id">{order.id}</DescriptionItem>
        </DescriptionContainer>
      </TabPanel>
    </>
  );
}
