import { useQuery } from '@tanstack/react-query';
import CustomerDetailsTab from './CustomerDetailsTab';
import {
  CustomerQueryKeys,
  getCustomerById,
} from '../../../lib/api/customers/customers.service';
import CustomerUsersTab from './CustomerUsersTab';
import CustomerShippingAddressesTab from './CustomerShippingAddressesTab';
import CustomerBillingAddressesTab from './CustomerBillingAddressesTab';
import useTabs from '../../ui/tabs/useTabs';
import TabGroup from '../../ui/drawer/DetailsDrawerContent';
import ErrorText from '../../ui/typography/ErrorText';

interface DrawerContentProps {
  customerId: string;
}

export default function CustomerDrawerContent({
  customerId,
}: DrawerContentProps) {
  const {
    isError: isErrorCustomer,
    data: customer,
    error: customerError,
  } = useQuery(
    [CustomerQueryKeys.findCustomerById, { id: customerId }],
    () => getCustomerById(customerId),
    { retry: false },
  );

  const { tabValue, handleChangeTab } = useTabs();

  if (!customer) return null;
  if (isErrorCustomer) return <ErrorText err={customerError as Error} />;

  return (
    <TabGroup tabValue={tabValue} onTabChange={handleChangeTab}>
      <CustomerDetailsTab
        label="Details"
        tabValue={tabValue}
        index={0}
        customer={customer}
      />
      <CustomerUsersTab
        label="Users"
        tabValue={tabValue}
        index={1}
        customer={customer}
      />
      <CustomerBillingAddressesTab
        label="Billing Addresses"
        tabValue={tabValue}
        index={2}
        customer={customer}
      />
      <CustomerShippingAddressesTab
        label="Shipping Addresses"
        tabValue={tabValue}
        index={3}
        customer={customer}
      />
    </TabGroup>
  );
}
