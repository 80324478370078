import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ConfirmDialog,
  ConfirmDeleteBaseProps,
} from '../ui/dialog/ConfirmDialog';
import { useAppContext } from '../../contexts/AppContext';
import { ViewOrderDto } from '../../lib/api/orders/dto/view-order.dto';
import {
  OrderQueryKeys,
  deleteOrder,
} from '../../lib/api/orders/orders.service';
import { getErrorMessage } from '../../lib/util/misc-utils';

interface Props extends ConfirmDeleteBaseProps {
  order: ViewOrderDto;
}

export function DeleteOrderConfirmDialog({
  order,
  isOpen,
  onClose,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();
  const orderIdToShow = order.id.substring(0, 8);

  const queryClient = useQueryClient();
  const deleteMutation = useMutation((orderId: string) => deleteOrder(orderId));

  const handleOnConfirm = async (orderId: string) => {
    //We close the modal regardless of success.
    onClose();
    try {
      const deletedOrder = await deleteMutation.mutateAsync(orderId);
      onSuccess?.(deletedOrder);
      queryClient.invalidateQueries([OrderQueryKeys.findAllOrders]);
      handleShowMessage(`Deleted order '${order.id}'`);
    } catch (err: any) {
      handleShowMessage(getErrorMessage(err), 'error');
    }
  };

  return (
    <ConfirmDialog
      title={`Delete Order: ${orderIdToShow}`}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() => handleOnConfirm(order.id)}
    >
      <>
        <p>
          Are you sure you want to delete the order '{orderIdToShow}'?{' '}
          <strong>This action cannot be undone.</strong>
        </p>
        <p>
          {' '}
          Deleting the order will add the inventory back to the product or
          variant's available inventory if:
        </p>
        <ul>
          <li>
            The order is for the current week. All line items will have their
            inventory updated to reflect the items being added back.
          </li>
          <li>
            The order is for next week and the inventory for the product or
            variant has been manually verfied this week (i.e., manually verified
            since the store closed on Monday). This is on a line by line basis
            and will only add back inventory for the line items that meet this
            criteria.
          </li>
        </ul>
        <p>
          Deleting orders for past order weeks or orders whose order week are
          beyond next week, will not have any impacts on the availabe inventory.
        </p>
      </>
    </ConfirmDialog>
  );
}
