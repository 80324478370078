import { Box, Button } from '@mui/material';
import useConfirmDialog from '../../ui/dialog/useConfirmDialog';
import useModal from '../../ui/modal/useModal';
import DescriptionContainer from '../../ui/description/DescriptionContainer';
import DescriptionItem from '../../ui/description/DescriptionItem';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import TabPanel from '../../ui/tabs/TabPanel';
import { useAdminLayoutContext } from '../../layout/context/AdminLayoutContext';
import { ViewDeliveryRouteDto } from '../../../lib/api/delivery-routes/dto/view-delivery-route.dto';
import { DeleteDeliveryRouteConfirmDialog } from '../DeleteDeliveryRouteConfirmDialog';
import AddOrUpdateDeliveryRouteForm from '../AddOrUpdateDeliveryRouteForm';

interface Props {
  label: string;
  tabValue: number;
  index: number;
  deliveryRoute: ViewDeliveryRouteDto;
}
export default function DeliveryRouteDetailsTab({
  label,
  tabValue,
  index,
  deliveryRoute,
}: Props) {
  const { handleDetailsDrawerClose } =
    useAdminLayoutContext<ViewDeliveryRouteDto>();
  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();
  const {
    confirmDialogIsOpen,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
  } = useConfirmDialog();

  const onUpdateSuccess = () => {
    handleCloseModal();
  };

  const onDeleteSuccess = () => {
    handleDetailsDrawerClose();
  };

  return (
    <>
      <TabPanel value={tabValue} index={index}>
        <DescriptionContainer>
          <DescriptionItem label="id">{deliveryRoute.id}</DescriptionItem>
          <DescriptionItem label="Name">{deliveryRoute.name}</DescriptionItem>
          <DescriptionItem label="Description">
            {deliveryRoute.description ? deliveryRoute.description : '---'}
          </DescriptionItem>
        </DescriptionContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button variant="contained" onClick={handleLaunchModal}>
            Edit Delivery Route
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'secondary.main',
              '&:hover': { backgroundColor: 'secondary.dark' },
            }}
            onClick={handleOpenConfirmDialog}
          >
            Delete Delivery Route
          </Button>
        </Box>
      </TabPanel>
      {deliveryRoute && (
        <DeleteDeliveryRouteConfirmDialog
          deliveryRoute={deliveryRoute}
          isOpen={confirmDialogIsOpen}
          onClose={handleCloseConfirmDialog}
          onSuccess={onDeleteSuccess}
        />
      )}
      <FullScreenModal
        title="Update Delivery Route"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <AddOrUpdateDeliveryRouteForm
          deliveryRoute={deliveryRoute}
          onSuccess={onUpdateSuccess}
        />
      </FullScreenModal>
    </>
  );
}
