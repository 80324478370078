import { Expose } from 'class-transformer';
import { DateTime } from 'luxon';
import TransformIsoStringToDateTime from '../custom-transformers/TransformIsoStringToDateTime';

export class DefaultIncrementViewDto {
  @Expose() id: number;

  @Expose()
  @TransformIsoStringToDateTime({ toClassOnly: true })
  createdAt: DateTime;

  @Expose()
  @TransformIsoStringToDateTime({ toClassOnly: true })
  updatedAt: DateTime;
}
