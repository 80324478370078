import currency from 'currency.js';
import Decimal from 'decimal.js';
import { DateTime } from 'luxon';
import { get } from 'lodash';

export function getArrayRange(start: number, stop: number, step: number = 1) {
  let range = [];
  for (let i = start; i <= stop; i += step) {
    range.push(i);
  }
  return range;
}

export const maskPhoneNumber = (phone: string) => {
  const aDigitRegEx = /\D/g;
  const maskRegEx = /(\d{0,3})(\d{0,3})(\d{0,4})/;
  const parts = phone.replace(aDigitRegEx, '').match(maskRegEx);
  const areaCode = parts?.[1] || '';
  const prefix = parts?.[2] || '';
  const lastFour = parts?.[3] || '';

  if (lastFour) return `(${areaCode}) ${prefix}-${lastFour}`;
  if (prefix) return `(${areaCode}) ${prefix}`;
  if (areaCode) return `(${areaCode}`;
  return '';
};

export const safeJSONParse = (value: any) => {
  try {
    return JSON.parse(value);
  } catch (err) {
    return false;
  }
};

export function toProperCase(val: string) {
  return val.charAt(0).toUpperCase() + val.substring(1).toLowerCase();
}

export function getErrorMessage(err: any) {
  const defaultMessage = 'There was an error.';
  const errObj = safeJSONParse(err.message);

  return errObj?.message || defaultMessage;
}

export function cleanNumericString(val: string) {
  const removeIfNotNumOrPeriod = /[^\d|\.]/g;
  if (val === '' || val === null || val === undefined) return null;
  return val?.replace(removeIfNotNumOrPeriod, '');
}

export function cleanSkuString(val: string) {
  const keepNumLetterOrUnderscore = /[\d|_|A-Z|a-z]/g;
  if (val === '' || val === null || val === undefined) return null;
  return val?.match(keepNumLetterOrUnderscore)?.join('')?.toUpperCase();
}

export function usd(val: Decimal, options?: any) {
  if (!val) val = new Decimal(0);
  return currency(val.toNearest(0.01).toString()).format({
    negativePattern: '(!#)',
  });
}

/**
 * Returns an array of unique values for a given field path of an array of objects.
 * @param items Array of objects
 * @param fieldPath The path to the field on the objects you want the unique values for. Field should
 * be of type string or number
 * @returns Array of unique values for the field you specify.
 */
export function getUnique<T extends string | number>(
  items: any[],
  fieldPath: string,
): T[] {
  const values = items.map((item) => get(item, fieldPath));

  values.forEach((val) => {
    if (
      typeof val !== 'string' &&
      typeof val !== 'number' &&
      val !== null &&
      val !== undefined
    )
      throw new Error(
        `'fieldToCheck' must be of type 'string' or 'number', received '${typeof val}'`,
      );
  });
  const uniqueValues = new Set<T>();

  values.forEach((entry) => {
    if (entry !== null && entry !== undefined) uniqueValues.add(entry);
  });

  return Array.from(uniqueValues).sort((a, b) => (a > b ? 1 : -1));
}

export const getTimestampAsFileNameString = (zone?: string) => {
  return DateTime.now()
    .setZone(zone || 'America/New_York')
    .toFormat('yyyy-MM-dd_hh-mm-ssa');
};
