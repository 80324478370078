import { Expose } from 'class-transformer';
import Decimal from 'decimal.js';
import { DateTime } from 'luxon';
import TransformIsoStringToDateTime from '../../../common/custom-transformers/TransformIsoStringToDateTime';
import TransformStringToDecimal from '../../../common/custom-transformers/TransformStringToDecimal';

export class ViewInventoryItemDto {
  @Expose() productId: string;
  @Expose() variantId: string;
  @Expose() isSellableProduct: boolean;
  @Expose() sellToPopesProduct: boolean;
  @Expose() sellToPremiumProduct: boolean;
  @Expose() sellToStandardProduct: boolean;
  @Expose() isSellableVariant?: boolean;
  @Expose() sellToPopesVariant?: boolean;
  @Expose() sellToPremiumVariant?: boolean;
  @Expose() sellToStandardVariant?: boolean;

  @Expose() name: string;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  numOrderedThisWeek: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  numAlreadyShippedThisWeek: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  numDueNextWeek: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  numDueInTwoWeeks: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  numDueInThreeOrMoreWeeks: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  currentQtyInSystem: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  manualQty: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  physicalCount: Decimal;

  @Expose()
  @TransformIsoStringToDateTime({ toClassOnly: true })
  manualQtyAsOf: DateTime;

  @Expose()
  @TransformIsoStringToDateTime({ toClassOnly: true })
  physicalCountAsOf: DateTime;
}
