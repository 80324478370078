import { useQuery } from '@tanstack/react-query';
import {
  CategoryQueryKeys,
  getSellableProductsForCategory,
} from '../../../lib/api/categories/categories.service';
import { ViewCategoryDto } from '../../../lib/api/categories/dto/view-category.dto';
import P1SimpleProductRow from '../input-rows/P1SimpleProductRow';
import { Paper } from '@mui/material';
import ProductWithVariantsRow from './ProductWithVariantsRow';

interface Props {
  category: ViewCategoryDto;
}

export default function CategoryProductList({ category }: Props) {
  const {
    isError: availableProductsIsError,
    data: availableProductsRes,
    error: availableProductsError,
    isFetching: availableProductsIsFetching,
  } = useQuery(
    [CategoryQueryKeys.findSellableProducts, { id: category.id }],
    () => getSellableProductsForCategory(category.id),
  );

  const hasVariantProducts = availableProductsRes?.filter((p) => p.hasVariants);
  const simpleProducts = availableProductsRes?.filter((p) => !p.hasVariants);

  return (
    <>
      {simpleProducts?.length > 0 && (
        <Paper elevation={5} sx={{ p: 2, mt: 1, mb: 5 }}>
          {simpleProducts.map((simpleProduct, index) => {
            const isLastEntry = index === simpleProducts.length - 1;
            const isOnlyEntry = simpleProducts.length === 1;

            return (
              <P1SimpleProductRow
                key={simpleProduct.id}
                product={simpleProduct}
                showBottomBorder={!isLastEntry && !isOnlyEntry}
              />
            );
          })}
        </Paper>
      )}

      {hasVariantProducts?.map((complexProduct) => (
        <ProductWithVariantsRow
          key={complexProduct.id}
          product={complexProduct}
        />
      ))}
    </>
  );
}
