import { Grid, styled, Typography } from '@mui/material';
import complexProductIcon from '../../../../assets/complex-product-icon.png';
import simpleProductIcon from '../../../../assets/flower-grey.svg';

interface Props {
  onClick: (type: 'simple' | 'complex') => void;
}

const ProductTypeOption = styled('div')(({ theme }) => ({
  display: 'block',
  textAlign: 'center',
  borderRadius: `1rem`,
  padding: `1rem`,
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.grey[100]
      : theme.palette.grey[900],
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.grey[900]
      : theme.palette.grey[200],
  ':hover': {
    cursor: 'pointer',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[100]
        : theme.palette.grey[900],
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.common.black
        : theme.palette.grey[300],
    fontWeight: 'bold',
  },
}));

export default function ProductTypePartial({ onClick }: Props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5">
          What type of Product do you want to create?
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <ProductTypeOption onClick={() => onClick('simple')}>
          <img
            src={simpleProductIcon}
            style={{ height: '150px' }}
            alt="Simple Product Icon - Product without Variants"
          />
          <Typography component="h3" variant="h6">
            Simple - No Variants
          </Typography>
        </ProductTypeOption>
      </Grid>
      <Grid item xs={6}>
        <ProductTypeOption onClick={() => onClick('complex')}>
          <img
            src={complexProductIcon}
            style={{ height: '150px' }}
            alt="Complex Product Icon - Product with Variants"
          />
          <Typography component="h3" variant="h6">
            Complex - Variants
          </Typography>
        </ProductTypeOption>
      </Grid>
    </Grid>
  );
}
