import { plainToInstance } from 'class-transformer';
import { getHeaders } from '../../util/http-utils';
import { apiClientFactory } from '../../common/clients';
import { ViewStoreStatusDto } from './dto/view-store-status.dto';

const apiClient = apiClientFactory();

/****************************
Store
****************************/

const resource = 'store';

export enum StoreQueryKeys {
  'findStatus' = 'FindStatus',
}

export async function getStoreStatus(): Promise<ViewStoreStatusDto> {
  const { data } = await apiClient.get(`v1/${resource}/status`, {
    headers: getHeaders(),
  });

  return plainToInstance(ViewStoreStatusDto, data, {
    excludeExtraneousValues: true,
  });
}
