import { TableCell, TableRow } from '@mui/material';
import ViewCartItemExtendedDto from '../../lib/api/carts/dto/view-cart-item-extended.dto';
import { usd } from '../../lib/util/misc-utils';

interface Props {
  item: ViewCartItemExtendedDto;
}

export default function CartRowProduct({ item }: Props) {
  return (
    <TableRow
      key={item.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row"></TableCell>
      <TableCell>{item.product.name}</TableCell>
      <TableCell align="right">{item.shelves.toNumber().toFixed(2)}</TableCell>
      <TableCell align="right">{item.racks.toNumber().toFixed(2)}</TableCell>
      <TableCell align="right">{item.qty.toNumber().toFixed(2)}</TableCell>
      <TableCell align="right">{`${usd(item.product.price)}`}</TableCell>
      <TableCell align="right">
        {usd(item.productTotalBeforeDiscount)}
      </TableCell>
    </TableRow>
  );
}
