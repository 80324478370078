import { Expose } from 'class-transformer';
import Decimal from 'decimal.js';
import TransformStringToDecimal from '../../../common/custom-transformers/TransformStringToDecimal';
import { DefaultUUIDViewDto } from '../../../common/dto/default-uuid-view.dto';
import { ProductLocation } from '../enums/product-locations.enum';

export class ViewProductDto extends DefaultUUIDViewDto {
  @Expose() hasVariants: boolean;
  @Expose() name: string;
  @Expose() description?: string;
  @Expose() upc?: string;
  @Expose() sku: string;
  @Expose() itemNo?: string;
  @Expose() qtyPerShelf: number;
  @Expose() shelvesPerRack: number;
  @Expose() @TransformStringToDecimal({ toClassOnly: true }) price: Decimal;
  @Expose() orderLimit?: number;

  @TransformStringToDecimal({ toClassOnly: true })
  @Expose()
  orderIncrement: Decimal;

  @Expose() location: ProductLocation;
  @Expose() @TransformStringToDecimal({ toClassOnly: true }) qty: Decimal;
  @Expose() isSellable: boolean;
  @Expose() sellToPopes: boolean;
  @Expose() sellToPremium: boolean;
  @Expose() sellToStandard: boolean;
  @Expose() isProvenWinner: boolean;
  @Expose() categoryId: number;
}
