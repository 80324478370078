import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { validate } from 'class-validator';
import { useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import {
  RequestImageUploadUrl,
  RequestProductImageUploadUrl,
  RequestVariantImageUploadUrl,
} from '../../lib/api/images/dto/request-image-upload-url.dto';
import {
  requestImageUploadPresignedUrl,
  uploadImageToS3,
} from '../../lib/api/images/images.service';
import { useQueryClient } from '@tanstack/react-query';
import { ProductQueryKeys } from '../../lib/api/products/products.service';
import { VariantQueryKeys } from '../../lib/api/variants/variants.service';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface Props {
  productId: string;
  variantId?: string;
  defaultImageFileUrl?: string;
  onSuccess?: () => void;
}

const ProductOrVariantImageUpload = ({
  productId,
  variantId,
  defaultImageFileUrl,
  onSuccess,
}: Props) => {
  const { handleShowMessage } = useAppContext();
  const queryClient = useQueryClient();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const handleSaveImage = async () => {
    setIsSaving(true);

    // 1. Configure and Validate Data
    const fileExtension = selectedFile?.type.split('/')[1] || '';

    let imageToUpload: RequestImageUploadUrl;

    if (variantId) {
      imageToUpload = new RequestVariantImageUploadUrl(
        variantId,
        fileExtension,
      );
    } else {
      imageToUpload = new RequestProductImageUploadUrl(
        productId,
        fileExtension,
      );
    }

    const errors = await validate(imageToUpload);

    if (errors.length > 0) {
      const firstConstaintKey = Object.keys(errors[0].constraints)[0];
      const firstErrorMsg = `${errors[0].constraints[firstConstaintKey]}`;

      handleShowMessage(
        `Could not load image: ${firstErrorMsg}`,
        'error',
        10000,
      );
      setIsSaving(false);
      setSelectedFile(null);
      return;
    }

    // 2. Validation has passed call API to get URL
    const { url, fields } = await requestImageUploadPresignedUrl(imageToUpload);

    // 3. Upload the Image to S3 using the URL and Fields
    try {
      await uploadImageToS3(url, fields, selectedFile);
      handleShowMessage(`Image uploaded successfully.`, 'success', 10000);
      onSuccess?.();

      setTimeout(() => {
        setIsSaving(false);
        setSelectedFile(null);

        queryClient.invalidateQueries([
          ProductQueryKeys.findProductById,
          { id: productId },
        ]);

        queryClient.invalidateQueries([
          ProductQueryKeys.findSellableProductVariants,
          { productId: productId },
        ]);

        if (variantId) {
          queryClient.invalidateQueries([
            VariantQueryKeys.findVariantById,
            { id: variantId },
          ]);

          queryClient.invalidateQueries([
            ProductQueryKeys.findProductVariants,
            { productId: productId },
          ]);
        }
      }, 15000);
    } catch (err) {
      setIsSaving(false);
      setSelectedFile(null);
      handleShowMessage(`Could not load image.`, 'error', 10000);
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mt: 4, lineHeight: 3.25 }}>
        Image
      </Typography>
      {!selectedFile && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            rowGap: 2,
          }}
        >
          {defaultImageFileUrl && (
            <img
              src={defaultImageFileUrl}
              style={{
                maxHeight: '200px',
                maxWidth: '200px',
                height: 'auto',
                width: 'auto',
                borderRadius: '1rem',
              }}
            />
          )}
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            {defaultImageFileUrl ? 'Select New Image' : 'Select Image'}
            <VisuallyHiddenInput
              type="file"
              accept="image/*"
              onChange={(e) => {
                setSelectedFile(e.currentTarget.files[0]);
              }}
            />
          </Button>
        </Box>
      )}

      {selectedFile && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            rowGap: 2,
          }}
        >
          <Typography component="span" color="gray">
            Preview
          </Typography>
          <img
            src={URL.createObjectURL(selectedFile)}
            style={{
              maxHeight: '200px',
              maxWidth: '200px',
              height: 'auto',
              width: 'auto',
              borderRadius: '1rem',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: 1,
            }}
          >
            <Button
              variant="contained"
              onClick={handleSaveImage}
              disabled={isSaving}
              startIcon={
                isSaving && <CircularProgress size={15} color="inherit" />
              }
            >
              Save Image
            </Button>
            {!isSaving && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'secondary.main',
                  '&:hover': { backgroundColor: 'secondary.dark' },
                }}
                onClick={() => setSelectedFile(null)}
              >
                Discard
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProductOrVariantImageUpload;
