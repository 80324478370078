import { Paper, Typography } from '@mui/material';
import { getErrorMessage } from '../../../lib/util/misc-utils';

interface Props {
  err?: Error;
  message?: string;
}

export default function ErrorText({ err, message }: Props) {
  return (
    <Paper sx={{ m: 2 }}>
      <Typography sx={{ p: 3, color: 'error.main', fontWeight: '700' }}>
        {message ? message : getErrorMessage(err)}
      </Typography>
    </Paper>
  );
}
