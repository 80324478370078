import { Expose, Type } from 'class-transformer';
import { DefaultUUIDViewDto } from '../../../common/dto/default-uuid-view.dto';
import { ViewCustomerDto } from '../../customers/dto/view-customer.dto';
import { ViewProfileDto } from './view-profile.dto';

export class ViewUserDto extends DefaultUUIDViewDto {
  @Expose() email: string;
  @Expose() isAdmin: boolean;
  @Expose() isRetail: boolean;
  @Expose() isPremium: boolean;
  @Expose() isStandard: boolean;
  @Expose() @Type(() => ViewProfileDto) profile?: ViewProfileDto;
  @Expose() @Type(() => ViewCustomerDto) customer: ViewCustomerDto;
}
