import { Tooltip } from '@mui/material';
import { ReactElement } from 'react';

interface Props {
  children: ReactElement;
}

export function EstimatedQtyTooltip({ children }: Props) {
  return (
    <Tooltip
      title={
        <p style={{ fontSize: '1rem' }}>
          Once you populate the physical count. We will estimate the qty
          available for next week. Estimate is computed by subtracting away what
          is still due this week and next week from the physical count. <br />
          <br />
          For example, if you had a physical count of 250 for this week and the
          system reported 100 ordered, 20 already shipped, and 40 due next week.
          The estimated qty for next week would be{' '}
          <em style={{ color: 'salmon' }}>
            <b>250 - (100-20) - 40 = 130</b>
          </em>{' '}
        </p>
      }
      arrow
    >
      {children}
    </Tooltip>
  );
}

export function NewQtyTooltip({ children }: Props) {
  return (
    <Tooltip
      title={
        <p style={{ fontSize: '1rem' }}>
          The value you enter here will automatically overwrite the qty
          available for the product or variant. <br />
          <br />
          For example, if the system shows a current qty of 100 and you enter 55
          as the new qty, the system will be updated to have 55. <br />
          <br />
          <em style={{ color: 'salmon' }}>
            <b>
              Note: If you delete the value shown here, the current qty in the
              system will still show what you had entered here.
            </b>
          </em>{' '}
        </p>
      }
      arrow
    >
      {children}
    </Tooltip>
  );
}

export function AlreadyShippedTooltip({ children }: Props) {
  return (
    <Tooltip
      title={
        <p style={{ fontSize: '1rem' }}>
          The qty that have already been shipped out for what was ordred this
          week.
          <br />
          <br />
          When orders for this week are marked as shipped on the orders page,
          all of the items they contain will count towards this already shipped
          number which help's you determine how many of this week's orders still
          need to be deducted from the amount you physically counted.
          <br />
          <br />
          For example, if you counted 100 and saw that 80 were ordered this week
          with 20 already shipped. Then you would know that you need to deduct
          60 (80-20) from your physical count for this week since those are
          already spoken for.
        </p>
      }
      arrow
    >
      {children}
    </Tooltip>
  );
}
