import { List } from '@mui/material';
import ViewCartItemExtendedDto from '../../lib/api/carts/dto/view-cart-item-extended.dto';
import { getUnique } from '../../lib/util/misc-utils';
import Empty from '../ui/custom-icons/Empty';
import { CartSummaryListCategoryRow } from './CartSummaryListCategoryRow';

interface Props {
  items: ViewCartItemExtendedDto[];
}

export default function CartSummaryItemsList({ items }: Props) {
  const uniqueCategoryNames = getUnique<string>(items, 'product.category.name');

  const hasProductWithNoCategory =
    items.filter((i) => !i?.product || !i?.product?.category).length > 0;
  if (hasProductWithNoCategory) {
    uniqueCategoryNames.push('Category Not Assigned');
  }

  return (
    <List dense>
      {uniqueCategoryNames.map((categoryName) => (
        <CartSummaryListCategoryRow
          key={categoryName}
          name={categoryName}
          items={items}
        />
      ))}
      {items.length === 0 && <Empty />}
    </List>
  );
}
