import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ViewUserDto } from '../../lib/api/users/dto/view-user.dto';
import { deleteUser, UserQueryKeys } from '../../lib/api/users/users.service';
import {
  ConfirmDialog,
  ConfirmDeleteBaseProps,
} from '../ui/dialog/ConfirmDialog';
import { useAppContext } from '../../contexts/AppContext';
import { CustomerQueryKeys } from '../../lib/api/customers/customers.service';
import { onSuccessCallback } from '../ui/forms/CustomForm';
import { getErrorMessage } from '../../lib/util/misc-utils';

interface Props extends ConfirmDeleteBaseProps {
  user: ViewUserDto;
}

export function DeleteUserConfirmDialog({
  user,
  isOpen,
  onClose,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  const deleteMutation = useMutation((userId: string) => deleteUser(userId));

  const handleOnConfirm = async (userId: string) => {
    //We close the modal regardless of success.
    onClose();
    try {
      const deletedUser = await deleteMutation.mutateAsync(userId);
      queryClient.invalidateQueries([UserQueryKeys.findAllUsers]);

      if (user.customer) {
        queryClient.invalidateQueries([
          CustomerQueryKeys.findCustomerById,
          {
            id: user.customer.id,
          },
        ]);

        queryClient.invalidateQueries([CustomerQueryKeys.findAllCustomers]);
      }

      handleShowMessage(`Deleted user '${user.email}'`);
      onSuccess?.(deletedUser);
    } catch (err: any) {
      handleShowMessage(getErrorMessage(err), 'error');
    }
  };

  return (
    <ConfirmDialog
      title={`Delete User: ${user.email}`}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() => handleOnConfirm(user.id)}
    >
      <>
        <p>
          Are you sure you want to delete the user '{user.email}'?{' '}
          <strong>This action cannot be undone.</strong>
        </p>
        <p> Deleting the user will also delete the user's profile. </p>
      </>
    </ConfirmDialog>
  );
}
