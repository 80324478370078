import { Length, ValidationOptions } from 'class-validator';

export default function CustomLength(
  min: number,
  max: number,
  name: string,
  options?: ValidationOptions,
): PropertyDecorator {
  return Length(min, max, {
    message: `${name} must be between $constraint1 and $constraint2 characters`,
    ...options,
  });
}
