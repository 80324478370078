import { plainToInstance } from 'class-transformer';
import { getHeaders } from '../../util/http-utils';
import { apiClientFactory } from '../../common/clients';
import { ViewProductOptionValueDto } from '../product-option-values/dto/view-product-option-value.dto';

const apiClient = apiClientFactory();

const resource = 'product-option-values';

/****************************
Product Option Values
****************************/

export async function deleteProductOptionValue(
  optionValueId: number,
): Promise<ViewProductOptionValueDto> {
  const { data } = await apiClient.delete(`v1/${resource}/${optionValueId}`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewProductOptionValueDto, data, {
    excludeExtraneousValues: true,
  });
}
