import { Expose } from 'class-transformer';
import { DefaultUUIDViewDto } from '../../../common/dto/default-uuid-view.dto';

export class ViewContactDto extends DefaultUUIDViewDto {
  @Expose() firstName?: string;
  @Expose() lastName?: string;
  @Expose() email?: string;
  @Expose() mobilePhone?: string;
  @Expose() officePhone?: string;
  @Expose() fax?: string;
}
