import { Box, SxProps, Theme } from '@mui/material';

interface Props {
  children: React.ReactNode;
  formContainerSx?: SxProps<Theme>;
}

export default function FormContainer({ children, formContainerSx }: Props) {
  return (
    <Box
      sx={{
        ...{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '900px',
        },
        ...formContainerSx,
      }}
    >
      {children}
    </Box>
  );
}
