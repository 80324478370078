import { Expose } from 'class-transformer';
import { IsEnum, IsOptional, IsUUID, ValidateIf } from 'class-validator';
import { OrderType } from './create-order.dto';

export class UpdateOrderShipToDto {
  @IsEnum(OrderType)
  @Expose()
  type: OrderType;

  @ValidateIf((o) => o.type === OrderType.Delivery)
  @IsUUID('all', {
    message: 'A shipping adddress is required to place an order for delivery.',
  })
  @Expose()
  shippingAddressId?: string;

  @IsOptional()
  @IsUUID('all')
  @Expose()
  shippingContactId?: string;
}
