import { ViewOrderDto } from '../../lib/api/orders/dto/view-order.dto';
import { BaseTableProps, TableCol } from '../../lib/util/table-util';
import PaginatedTable from '../ui/tables/paginated-table/PaginatedTable';
import OrdersTableTypeCol from './OrdersTableTypeCol';
import { usd } from '../../lib/util/misc-utils';
import OrdersTableIdColCustomer from './OrdersTableIdColCustomer';

export default function OrdersTableCustomer({
  apiRes,
  isFetching,
  setTableState,
  tableState,
  onRowClick,
}: BaseTableProps<ViewOrderDto>) {
  const columns: TableCol<ViewOrderDto>[] = [
    {
      id: 'id',
      label: 'ID',
      filterType: 'contains',
      render: (order: ViewOrderDto) => (
        <OrdersTableIdColCustomer order={order} />
      ),
      widthPct: 10,
      sortable: true,
      sortKey: 'createdAt',
    },
    {
      id: 'placedBy',
      label: 'Placed By',
      filterType: 'contains',
      widthPct: 15,
    },
    {
      id: 'shippingCompanyName',
      label: 'Ship to',
      filterType: 'contains',
      widthPct: 15,
    },
    {
      id: 'type',
      label: 'Type',
      filterType: 'contains',
      render: (order: ViewOrderDto) => <OrdersTableTypeCol order={order} />,
      widthPct: 5,
      align: 'center',
    },
    {
      id: 'orderWeek',
      label: 'Order Week',
      filterType: 'contains',
      widthPct: 5,
      headerCellSx: { minWidth: '100px' },
      sortable: true,
      align: 'center',
    },
    {
      // @TODO:::alow this field to not be linked to any item.
      //@ts-ignore
      id: 'status',
      label: 'Status',
      filterType: 'contains',
      // render: (order: ViewOrderDto) => <CheckCircleIcon color="primary" />,
      widthPct: 5,
      align: 'center',
    },

    {
      //@ts-ignore
      id: 'orderTotal',
      label: 'Order Total',
      render: (order: ViewOrderDto) => <>{`${usd(order.orderTotal)}`}</>,
      widthPct: 10,
    },
    {
      //@ts-ignore
      id: 'racks',
      label: 'Racks',
      render: (order: ViewOrderDto) => <>{order.racks.toFixed(3)}</>,
      widthPct: 10,
    },
  ];

  return (
    <PaginatedTable<ViewOrderDto>
      columns={columns}
      apiRes={apiRes}
      isFetching={isFetching}
      setTableState={setTableState}
      tableState={tableState}
      onRowClick={onRowClick}
    />
  );
}
