import ViewCartItemExtendedDto from '../../lib/api/carts/dto/view-cart-item-extended.dto';
import { ViewProductWithCategoryDto } from '../../lib/api/products/dto/view-product-with-category.dto';
import CartSummaryListItemRow from './CartSummaryListItemRow';
import { CartSummaryListProductSubtotalRow } from './CartSummaryListProductSubtotalRow';

interface Props {
  product: ViewProductWithCategoryDto;
  items: ViewCartItemExtendedDto[];
}

export function CartSummaryListComplexProductSection({
  product,
  items,
}: Props) {
  const itemsForProduct = items
    .filter((i) => i.product.id === product.id)
    .sort((a, b) => (a.variant.name > b.variant.name ? 1 : -1));
  return (
    <>
      <CartSummaryListProductSubtotalRow
        product={product}
        itemsForProduct={itemsForProduct}
      />
      {itemsForProduct.map((i) => (
        <CartSummaryListItemRow key={i.id} item={i} indent />
      ))}
    </>
  );
}
