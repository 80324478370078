import { Transform, TransformOptions } from 'class-transformer';
import { DateTime } from 'luxon';

/**
 * Transforms datetime represented as an ISO string to a luxon DateTime value
 * @Returns DateTime of value if value is not empty string, null, or undefined.
 * @Returns null if if value is empty string, null, or undefined
 */
export default function TransformIsoStringToDateTime(
  options?: TransformOptions,
) {
  return Transform(({ value }) => {
    if (value === '' || value === null || value === undefined) return null;
    return DateTime.fromISO(value);
  }, options);
}
