import { Box, Button } from '@mui/material';
import useConfirmDialog from '../../ui/dialog/useConfirmDialog';
import useModal from '../../ui/modal/useModal';
import DescriptionContainer from '../../ui/description/DescriptionContainer';
import DescriptionItem from '../../ui/description/DescriptionItem';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import TabPanel from '../../ui/tabs/TabPanel';
import { useAdminLayoutContext } from '../../layout/context/AdminLayoutContext';
import { ViewCategoryDto } from '../../../lib/api/categories/dto/view-category.dto';
import { DeleteCategoryConfirmDialog } from '../DeleteCategoryConfirmDialog';
import AddOrUpdateCategoryForm from '../AddOrUpdateCategoryForm';

interface Props {
  label: string;
  tabValue: number;
  index: number;
  category: ViewCategoryDto;
}
export default function CategoryDetailsTab({
  label,
  tabValue,
  index,
  category,
}: Props) {
  const { handleDetailsDrawerClose } = useAdminLayoutContext<ViewCategoryDto>();
  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();
  const {
    confirmDialogIsOpen,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
  } = useConfirmDialog();

  const onUpdateSuccess = () => {
    handleCloseModal();
  };

  const onDeleteSuccess = () => {
    handleDetailsDrawerClose();
  };

  return (
    <TabPanel value={tabValue} index={index}>
      <DescriptionContainer>
        <DescriptionItem label="id">{category.id}</DescriptionItem>
        <DescriptionItem label="Name">{category.name}</DescriptionItem>
        <DescriptionItem label="Description">
          {category.description || '---'}
        </DescriptionItem>
      </DescriptionContainer>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Button variant="contained" onClick={handleLaunchModal}>
          Edit Category
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'secondary.main',
            '&:hover': { backgroundColor: 'secondary.dark' },
          }}
          onClick={handleOpenConfirmDialog}
        >
          Delete Category
        </Button>
      </Box>

      <DeleteCategoryConfirmDialog
        category={category}
        isOpen={confirmDialogIsOpen}
        onClose={handleCloseConfirmDialog}
        onSuccess={onDeleteSuccess}
      />

      <FullScreenModal
        title="Update Category"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <AddOrUpdateCategoryForm
          category={category}
          onSuccess={onUpdateSuccess}
        />
      </FullScreenModal>
    </TabPanel>
  );
}
