import { MouseEvent } from 'react';
import { Button, Stack } from '@mui/material';
import { ViewVariantDto } from '../../lib/api/variants/dto/view-variant.dto';
import { BaseTableProps, TableCol } from '../../lib/util/table-util';
import PaginatedTable from '../ui/tables/paginated-table/PaginatedTable';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import useModal from '../ui/modal/useModal';
import FullScreenModal from '../ui/modal/FullScreenModal';
import ProductOrVariantImageUpload from '../products/ProductOrVariantImageUpload';
import { useEffect, useState } from 'react';
import { findImage } from '../../lib/api/images/images.service';

const columns: TableCol<ViewVariantDto>[] = [
  {
    // @ts-ignore
    id: 'actions',
    label: 'Action',
    render: (variant: ViewVariantDto) => <AddVariantImage variant={variant} />,
  },
  {
    id: 'name',
    label: 'Name',
    filterType: 'contains',
    widthPct: 20,
  },
  {
    id: 'sku',
    align: 'center',
    label: 'SKU',
    sortable: true,
    widthPct: 10,
  },
];

export default function ProductVariantsTable({
  apiRes,
  isFetching,
  setTableState,
  tableState,
  onRowClick,
}: BaseTableProps<ViewVariantDto>) {
  return (
    <>
      <PaginatedTable<ViewVariantDto>
        columns={columns}
        apiRes={apiRes}
        isFetching={isFetching}
        setTableState={setTableState}
        tableState={tableState}
        onRowClick={onRowClick}
      />
    </>
  );
}

interface AddVariantImageProps {
  variant: ViewVariantDto;
}

function AddVariantImage({ variant }: AddVariantImageProps) {
  // NOTE:: THE CLICK EVENT BUBBLES UP AND GETS HANDLED AT THE PRODUCT VARIANTS TAB
  // COMPONENT. THIS IS NOT IDEAL, BUT IT WORKS FOR NOW.

  return (
    <>
      <Stack
        id={`add-variant-image-stack-${variant.id}`}
        flexDirection="column"
        gap={1}
      >
        <Button
          id={`add-variant-image-btn-${variant.id}`}
          variant="contained"
          size="small"
          color="secondary"
        >
          <AddAPhotoIcon
            id={`add-variant-image-icon-${variant.id}`}
            fontSize="small"
          />
        </Button>
      </Stack>
    </>
  );
}
