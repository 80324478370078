import { Box, Paper } from '@mui/material';
import { ReactElement } from 'react';

interface Props {
  children: ReactElement | ReactElement[];
}

export default function DescriptionContainer({ children }: Props) {
  return (
    <Paper elevation={5}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          mb: '1rem',
        }}
      >
        {children}
      </Box>
    </Paper>
  );
}
