import { Expose } from 'class-transformer';
import Decimal from 'decimal.js';
import TransformDecimalToString from '../../../common/custom-transformers/TransformDecimalToString';
import TransformStringToDecimal from '../../../common/custom-transformers/TransformStringToDecimal';
import { DefaultUUIDViewDto } from '../../../common/dto/default-uuid-view.dto';
import { States } from '../../addresses/enums/states.enum';
import { CustomerType } from '../../customers/dto/view-customer.dto';
import { OrderStatus } from '../enums/order-status.enum';
import { OrderType } from './create-order.dto';

export class ViewOrderDto extends DefaultUUIDViewDto {
  @Expose() type: OrderType;
  @Expose() orderWeek: string;
  @Expose() poNumber: string;
  @Expose() note: string;
  @TransformStringToDecimal({ toClassOnly: true, emptyString: 'SetToZero' })
  @TransformDecimalToString({ toPlainOnly: true })
  fuelSurchargePerRack: Decimal;

  /**************************** 
   USER FIELDS
  *****************************/
  @Expose() userId: string;
  @Expose() placedBy: string;

  /**************************** 
   CUSTOMER FIELDS
  *****************************/
  @Expose() customerId: string;
  @Expose() customerName: string;
  @Expose() customerType: CustomerType;

  @TransformStringToDecimal({ toClassOnly: true, emptyString: 'SetToZero' })
  @TransformDecimalToString({ toPlainOnly: true })
  @Expose()
  customerDiscountRate: Decimal;

  /**************************** 
   BILLING ADDRESS FIELDS
  *****************************/
  @Expose() billingAddressId: string;
  @Expose() billingCompanyName: string;
  @Expose() billingCompanyCode: string;
  @Expose() billingStreet1: string;
  @Expose() billingStreet2: string;
  @Expose() billingPOBox: string;
  @Expose() billingCity: string;
  @Expose() billingState: States;
  @Expose() billingZip: string;

  /**************************** 
   BILLING CONTACT FIELDS
  *****************************/
  @Expose() billingContactId: string;
  @Expose() billingFirstName: string;
  @Expose() billingLastName: string;
  @Expose() billingEmail: string;
  @Expose() billingMobilePhone: string;
  @Expose() billingOfficePhone: string;
  @Expose() billingFax: string;

  /**************************** 
   SHIPPING ADDRESS FIELDS
  *****************************/
  @Expose() shippingAddressId: string;
  @Expose() shippingCompanyName: string;
  @Expose() shippingStreet1: string;
  @Expose() shippingStreet2: string;
  @Expose() shippingCity: string;
  @Expose() shippingState: States;
  @Expose() shippingZip: string;

  /**************************** 
   SHIPPING CONTACT FIELDS
  *****************************/
  @Expose() shippingContactId: string;
  @Expose() shippingFirstName: string;
  @Expose() shippingLastName: string;
  @Expose() shippingEmail: string;
  @Expose() shippingMobilePhone: string;
  @Expose() shippingOfficePhone: string;
  @Expose() shippingFax: string;

  /**************************** 
   DELIVERY ROUTE FIELDS
  *****************************/

  @Expose() deliveryRouteId?: number;
  @Expose() deliveryRouteName?: string;

  /**************************** 
   ORDER FLAGS
  *****************************/
  @Expose() isShipped: boolean;
  @Expose() isInvoiced: boolean;
  @Expose() status: OrderStatus;
  @Expose() stagingAt: Date;
  @Expose() selectedForPickingAt: Date;
  @Expose() shippedAt: Date;
  @Expose() invoicedAt: Date;

  /**************************** 
   COMPUTED FIELDS
  *****************************/
  @TransformStringToDecimal({ toClassOnly: true, emptyString: 'SetToZero' })
  @TransformDecimalToString({ toPlainOnly: true })
  @Expose()
  racks: Decimal;

  @TransformStringToDecimal({ toClassOnly: true, emptyString: 'SetToZero' })
  @TransformDecimalToString({ toPlainOnly: true })
  @Expose()
  fuelSurchargeTotal: Decimal;

  @TransformStringToDecimal({ toClassOnly: true, emptyString: 'SetToZero' })
  @TransformDecimalToString({ toPlainOnly: true })
  @Expose()
  productTotalBeforeDiscounts: Decimal;

  @TransformStringToDecimal({ toClassOnly: true, emptyString: 'SetToZero' })
  @TransformDecimalToString({ toPlainOnly: true })
  @Expose()
  customerDiscountTotal: Decimal;

  @TransformStringToDecimal({ toClassOnly: true, emptyString: 'SetToZero' })
  @TransformDecimalToString({ toPlainOnly: true })
  @Expose()
  orderTotal: Decimal;
}
