import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import BlankLayout from '../components/layout/layouts/BlankLayout';
import ForbiddenPage from './errors/ForbiddenPage';
import NotFoundPage from './errors/NotFoundPage';
import LoginPage from './auth/LoginPage';
import ActivateAccountPage from './auth/ActivateAccountPage';
import ResetPasswordRequestPage from './auth/ResetPasswordRequestPage';
import ResetPasswordPage from './auth/ResetPasswordPage';
import PrivateOutlet from '../components/auth/PrivateOutlet';
import AdminLayout from '../components/layout/layouts/AdminLayout';
import OrdersPageInternal from './orders/OrdersPageInternal';
import OrderConfirmationPage from './orders/[id]/OrderConfirmationPage';
import StorePageInternal from './store/popes-users/StorePageInternal';
import CartPage from './cart/CartPage';
import CustomersPage from './customers/CustomersPage';
import UsersPage from './users/UsersPage';
import DeliveryRoutesPage from './delivery-routes/DeliveryRoutesPage';
import CategoriesPage from './categories/CategoriesPage';
import ProductsPage from './products/ProductsPage';
import MePage from './me/MePage';
import SettingsPage from './settings/SettingsPage';
import InventoryPage from './inventory/InventoryPage';
import CustomerLayout from '../components/layout/layouts/CustomerLayout';
import StorePageCustomer from './store/customer-users/StorePageCustomer';
import { CartProvider } from '../contexts/CartContext';
import OrdersPageCustomer from './orders/OrdersPageCustomer';
import FulfillmentPage from './fulfillment/FulfillmentPage';
import StorePageSwitch from './store/StorePageSwitch';
import BasicAvailabilityPage from './reports/BasicAvailabilityPage';

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/reports" element={<BlankLayout />}>
          <Route
            path="basic-availability"
            element={<BasicAvailabilityPage />}
          />
        </Route>
        {/* AUTH ROUTES */}
        <Route path="/auth" element={<BlankLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route
            path="activate/:passcode/:userId"
            element={<ActivateAccountPage />}
          />
          <Route
            path="reset-password-request"
            element={<ResetPasswordRequestPage />}
          />
          <Route
            path="reset-password/:passcode/:userId"
            element={<ResetPasswordPage />}
          />
        </Route>

        {/* ADMIN ROUTES */}
        <Route path="/admin" element={<PrivateOutlet adminOnly={true} />}>
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="/admin/" element={<Navigate replace to="orders" />} />
            <Route path="orders" element={<OrdersPageInternal />} />
            <Route path="orders/:id" element={<OrderConfirmationPage />} />
            <Route path="fulfillment" element={<FulfillmentPage />} />

            <Route
              path="store"
              element={
                <CartProvider>
                  <StorePageInternal />
                </CartProvider>
              }
            />
            <Route
              path="cart"
              element={
                <CartProvider>
                  <CartPage />
                </CartProvider>
              }
            />
            <Route path="customers" element={<CustomersPage />} />
            <Route path="users" element={<UsersPage />} />
            <Route path="delivery-routes" element={<DeliveryRoutesPage />} />
            <Route path="categories" element={<CategoriesPage />} />
            <Route path="products" element={<ProductsPage />} />
            <Route path="inventory" element={<InventoryPage />} />
          </Route>
        </Route>

        {/* CUSTOMER ROUTES */}
        <Route path="/" element={<PrivateOutlet />}>
          <Route path="/" element={<CustomerLayout />}>
            <Route path="/" element={<Navigate replace to="store" />} />
            <Route path="/orders" element={<OrdersPageCustomer />} />
            <Route path="orders/:id" element={<OrderConfirmationPage />} />
            <Route
              path="/store"
              element={
                <CartProvider>
                  <StorePageSwitch />
                </CartProvider>
              }
            />
            <Route
              path="/cart"
              element={
                <CartProvider>
                  <CartPage />
                </CartProvider>
              }
            />
            <Route path="/" element={<Navigate replace to="settings" />} />
            <Route path="settings" element={<SettingsPage />} />
          </Route>
        </Route>
        <Route path="/" element={<BlankLayout />}>
          <Route path="/forbidden" element={<ForbiddenPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
